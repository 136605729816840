import React from 'react'
import styled, {css, keyframes} from 'styled-components'
import moment from 'moment'
import { Link } from 'react-router-dom'
import Scrollbars from 'react-custom-scrollbars'
import { bgVariantColor, errorColor, secundaryColor, alternateVariantColor, primayVariantColor, bgColor,  iconColor, blackColor, successColor, iconVariantColor } from '../../../constanst'
import Table, {Tr,Th, Td, ButtonsContainer} from '../../common/Table'
import { IconAlert, IconSee } from '../../../assets/icons'
import { Tooltip, TooltipText } from './ComponentsForm'
import { Loading } from '../../common/Loading'
import InputText from '../../common/InputText'
import ButtonSubmit from '../../common/ButtonSubmit'
import { IconSearch } from '../../../assets/icons'
import { DivisorContainer } from "../trabajos/Styles"
import { MenuItem, Select } from '@material-ui/core'

const styles = {
    select: {
        width: '25%', borderRadius: 25, backgroundColor: '#ffffff', border: `1px solid ${iconColor}`, outline: 0, fontSize: 12, color: blackColor, paddingLeft: 15, margin: "10px 0",
    }
}

export default ({ state, onKeyPressFilter, onFilter, onChange, handleMenu, handleChange, onChangeSelect, onChangeDate, onClickFindDate}) => 
<Main>
    
<NavSearch border>

        <DivisorContainer borderC={iconColor} direction="column">
            <Row>{state.typeUser === '1' && 
                <Select
                    name='ds_sedes'
                    value={state.ds_sedes || ''}
                    onChange={onChangeSelect}
                    style={ styles.select }
                >
                    <MenuItem key='0' value='0'>SEDE</MenuItem>
                    {!!state.sedesList.length && 
                        state.sedesList.map( x => (
                            <MenuItem key={x.sed_id} value={x.sed_id}>{x.sed_nombre}</MenuItem>
                    ))}
                </Select>}
                
                <InputText icon={<IconSearch width="20px" height="20px" color={blackColor} />}
                    placeholder="NOMBRE" name="clit_nombre" placeholderColor={iconColor} borderC={iconColor}
                    backgroundC={secundaryColor} textColor={iconColor} width='25%'
                    onChange={onChange} onSearch={onFilter} onKeyPress={onKeyPressFilter} value={state.clit_nombre}
                />
                <InputText icon={<IconSearch width="20px" height="20px" color={blackColor} />}
                    placeholder="DOCUMENTO" name="clit_identificacion" placeholderColor={iconColor} borderC={iconColor}
                    backgroundC={secundaryColor} textColor={iconColor} width='25%'
                    onChange={onChange} onSearch={onFilter} onKeyPress={onKeyPressFilter} value={state.clit_identificacion}
                    
                />
                <InputText icon={<IconSearch width="20px" height="20px" color={blackColor} />}
                    placeholder="PLACA" name="ds_placa" placeholderColor={iconColor} borderC={iconColor}
                    backgroundC={secundaryColor} textColor={iconColor} width='25%'
                    onChange={onChange} onSearch={onFilter} onKeyPress={onKeyPressFilter} value={state.ds_placa}
                />

            </Row>

        </DivisorContainer>
        <DivisorContainer>
            <Row>
                <InputText
                    type="date" name="dateStart" placeholderColor={iconColor} width='35%' onChange={onChangeDate}
                    backgroundC={secundaryColor} textColor={iconColor} borderC={iconColor} value={state.dateStart || ''}
                />
                <InputText
                    type="date" name="dateFinal" placeholderColor={iconColor} width='35%' onChange={onChangeDate}
                    backgroundC={secundaryColor} textColor={iconColor} borderC={iconColor} value={state.dateFinal || ''}
                />
                <ButtonSubmit label={window.screen.width > 600 ? "Buscar por fecha" : "Buscar"} bgColor={successColor} color={secundaryColor} onClick={onClickFindDate} type="button" width={'20%'} />
            </Row>
        </DivisorContainer>
    </NavSearch>
           
    {state.loading && <Loading /> }

    <Scrollbars className="scroll" style={{flex: 1}}>
    <Container>
    <Title>CRM</Title>
        {/* <ButtonsContainer>
            <ButtonSubmit  onClick={() => handleMenu(1)} label="Nuevo agendamiento" type="button"   bgColor={successColor} color={secundaryColor}width={'10%'} icon={<IconPlus width="15px" height="15px" color={secundaryColor} />}  />
        </ButtonsContainer> */}
        
        <ModalContainer  active={state.menuActive === 1 ? 'block' : 'none'} >
            <Content>
                <Title>Nuevo agendamiento</Title>
                <Row>
                {/* <InputText icon={<IconSearch width="20px" height="20px" color={blackColor} />}
                    placeholder="Documento" name="clit_nombre" placeholderColor={iconColor} borderC={iconColor}
                    backgroundC={secundaryColor} textColor={iconColor} width='30%'
                    onChange={handleChange}   value={state.valuesForm.clit_nombre}
                />
                <InputText icon={<IconSearch width="20px" height="20px" color={blackColor} />}
                    placeholder="Apellidos" name="clit_identificacion" placeholderColor={iconColor} borderC={iconColor}
                    backgroundC={secundaryColor} textColor={iconColor} width='30%'
                    onChange={handleChange}   value={state.valuesForm.clit_identificacion}
                    
                />
                <InputText icon={<IconSearch width="20px" height="20px" color={blackColor} />}
                    placeholder="Placa" name="clit_apellido" placeholderColor={iconColor} borderC={iconColor}
                    backgroundC={secundaryColor} textColor={iconColor} width='30%'
                    onChange={handleChange}   value={state.valuesForm.clit_apellido}
                /> */}

            </Row>
                
            <ButtonsContainer>
                <ButtonSubmit  onClick={() => handleMenu(1)} label="Cerrar" type="button"   bgColor={successColor} color={secundaryColor}width={'10%'} />
            </ButtonsContainer>
            </Content>
        </ModalContainer>
    <br />
        <Table
            renderHead={
                <Tr bgColor={true}>
                    <Th>#</Th>
                    {/* <Th>Cons</Th> */}
                    <Th>Moto</Th>
                    <Th>Placa</Th>
                    <Th>Trabajo</Th>
                    <Th>Nombre</Th>
                    <Th>Documento</Th>
                    <Th>Estado</Th>
                    <Th>Fecha</Th>
                    <Th>Recordatorio</Th>
                    <Th>Acciones</Th>
                </Tr>
            }
            renderBody={
                    (state.schedulingList.length > 0) ?
                        state.schedulingList.map( (x, i) => (
                        <Tr key={x.agen_id}>
                            <Td color={primayVariantColor} bold="bold">{i+1}</Td>
                            {/* <Td bold="bold">{x.ser_consecutivo}</Td> */}
                            <Td>{x.rm_nombremodelo}</Td>
                            <Td>{x.ds_placa}</Td>
                            <Td>{ x.ds_otrostrabajos ? x.ds_otrostrabajos : x.tg_nombre}</Td>
                            <Td>{`${x.clit_nombre} ${x.clit_apellido}`}</Td>
                            <Td>{x.clit_identificacion}</Td>
                            <Td>
                                <Tooltip className="tooltip">
                                    <IconAlert width="20px" height="20px" bgColor={errorColor} color={bgColor}/>
                                    <TooltipText className="tooltiptext" bgColor={errorColor}>{diffDays(x.agen_recordatorio)}</TooltipText>
                                </Tooltip>
                            </Td>
                            <Td>{x.fecha}</Td>
                            <Td>{x.agen_recordatorio}</Td>
                            <Td>
                                <Row>
                                    <Tooltip className="tooltip">
                                        <Link to={`/servicios/seguimiento/crm-historia/${x.clo_idcliente}`}>
                                            <IconSee width="30px" height="20px" color={alternateVariantColor} bgColor={alternateVariantColor} />
                                        </Link>
                                        <TooltipText className="tooltiptext" bgColor={alternateVariantColor}>Editar</TooltipText>
                                    </Tooltip>
                                </Row>
                            </Td>
                        </Tr>
                    ))
                    : <Tr>
                        <Td colSpan={11}>No se encontraron ordenes para mostrar</Td>
                    </Tr>
                }>
            </Table>
        </Container>
    </Scrollbars>
</Main>

const diffDays = val => {
    const act = moment().format("YYYY-MM-DD")
    const diff = moment(val).diff(moment(act), 'days')
    return diff >= 0 ? `${diff} días restantes` : `${diff} días de retraso`
}

const Main = styled.main`
    flex: 1;
    position: relative;
`
const Container = styled.div`
    background-color: ${bgVariantColor};
    margin: 0px 1%;
    height: 100;
    box-shadow: 0px 0px 8px 2px rgba(0,0,0,.4);
    margin-bottom: 40px;
 `
const Title = styled.h3`
    color: ${secundaryColor};
    padding: 5px;
    margin: 0px;
    text-align: center;
    width: ${({ width })=> width ? width : '100%'};
    background-color: ${primayVariantColor};
`
const Row = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
`
const NavSearch = styled.nav`
    position: relative;
    background-color: ${iconVariantColor};
    display: flex;
    justify-content: center;
    flex-direction: column;
    ${({ border }) => border && css`border-bottom: 1px solid rgb(180, 180, 180);`}
    margin: 0 20px 0 20px;
    & > div {width: 100%;}
    @media (min-width: 600px){
        flex-direction: row;
        & > div {width: 50%; padding: 0px 5px;}
    }
`
const FadeInAnimation = keyframes`  
    from { opacity: 0; z-index: -1;}
    to { opacity: 1; z-index: 999; }
`
const FadeOutAnimation = keyframes`
    from { opacity: 1; z-index: 999;}
    to { opacity: 0; z-index: -1; }
`
const ModalContainer = styled.div`
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    opacity: 0;
    z-index: -1;
    display: flex;
    align-items: center;    
    justify-content: center;
    animation-name: ${ ({ active }) => active ? FadeInAnimation : FadeOutAnimation };
    animation-duration: .5s;  
    animation-fill-mode: forwards;
    ${ ({ active }) => active && css`display: ${ active };` }
    position:fixed;
`
const Content = styled.div`

    background-color: ${ secundaryColor };
    border-radius: 18px;
    top: 0px;
    z-index: 9999;
    max-height: calc(100% - 70px);   
    flex-direction: row;
    display: wrap;
    flex-wrap: wrap;
    justify-content:center;
    align-items:center;
    margin:auto;
    width:50%;
    height: 200px;
    margin-top:300px;
`