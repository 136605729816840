import React from 'react'
import styled, { css } from 'styled-components'
// import Scrollbars from 'react-custom-scrollbars'
import { /* primayVariantColor, bgVariantColor, secundaryColor, blackColor, */ iconColor } from '../../constanst'


export default (  {state}  ) => 
   
<Main>
    {/* <Container>
        <Scrollbars>
            <Header>
                <Left>
                    <Title>ESTADISTICAS DE PROGRESO</Title>
                </Left>
            </Header>
        <Body>
            <MapContainer>
                <Left width="70%" borderC={primayVariantColor} marginTop="20px" direction={"column"}>
                    <Subtitle>Progreso Mensual</Subtitle>
                    <Table>
                        <thead>
                            <Tr>
                                <Th>Metas y objetivos</Th>
                                <Th>Estado Actual</Th>
                                
                            </Tr>
                        </thead>
                        <tbody>     
                            <Tr >
                                <Td>100%</Td>
                                <Td>{state.progressBar}</Td>
                            </Tr>
                        </tbody>
                    </Table>
                <Contaienrbar>
                    <Right width={'100%'}>
                        <ProgressContainer >
                            <Progress id='Progress' width={state.progressBar}>{state.progressBar}</Progress>
                        </ProgressContainer>
                    </Right>
                    </Contaienrbar>
                    <Graphics>
                        <Canvas lg="500px" md="300px" height="180px" id="myChart3" />
                    </Graphics> 
                </Left>
                <Right width="30%" align="flex-start" marginTop="20px">
                    <Canvas width="500px" height="500px" id="myChart" />
                </Right>
            </MapContainer>
        </Body>
        </Scrollbars>
    </Container> */}
    {/* <ButtonPDF>
        <Button bgColor={primayVariantColor} onClick={onClickPdf} label="GENERAR PDF" color={secundaryColor} marginV="5px" width="100%"  />
        <LinkOculto href="" id="pdfMT"/>
    </ButtonPDF> */}
</Main>


/** Formateo de Valores */
// const format = value => new Intl.NumberFormat('en-US').format(value)

const Main = styled.main`
    flex: 1;
    display: flex;
    overflow: hidden;
    padding: 0px;
`
// const Container = styled.div`
//     position: relative;
//     display: flex;
//     flex-direction: column;
//     overflow: hidden;
//     width: 100%;
//     /* border-radius: 15px; */
//     @media (min-width: 1000px){
//         width: 98%;
//         margin-left: 1%;
//     }
//  `
// const Header = styled.div`
//     position: relative;
//     background-color: ${primayVariantColor};
//     display: flex;
//     width: 100%;
//     align-items: center;
// `
// const Title = styled.h4`
//     color: ${({ color }) => color ? color : secundaryColor};
//     font-size: 16px;
//     font-weight: bold;
//     margin: 7px;
// `
// const Subtitle = styled.h5`
//     color: black;
//     font-size: 16px;
//     font-weight: bold;
//     margin-left: 10px;
// `
// const Right = styled.div`
//     padding: 0px 10px;
//     width: ${({width}) => width ? width : '50%'};
//     display: flex;
//     align-items: ${({ align }) => align ? align : 'center'};
//     justify-content: space-between;
//     ${({marginTop}) => marginTop && css`margin-top: ${marginTop};`}
// `
// const Left = styled.div`
//     display: flex;
//     ${({justify}) => justify && css`justify-content: ${justify};`}
//     padding: 0px 10px;
//     width: ${({width}) => width ? width : '50%'};
//     ${ ({borderC}) => borderC && css`border-right: 2px solid ${borderC};` }
//     ${({marginTop}) => marginTop && css`margin-top: ${marginTop};`}
//     ${({direction}) => css`flex-direction: ${direction};`}
// `
// const Body = styled.div`
//     background-color: ${bgVariantColor};
//     box-shadow: 0px 0px 6px 1px rgba(0,0,0,.4);
//     padding: 10px 3%;
// `
// const MapContainer = styled.div`
//     display: flex;
// `

// /** Información de totales */
// const Graphics = styled.div`
//     width: 70%;
//     padding: 20px 0px;
//     display: flex;
//     align-items: flex-end;
//     justify-content: space-between;
//     border-bottom: 2px solid ${primayVariantColor};
// `
// const Canvas = styled.canvas`
//     width: ${({md}) => md};
//     height: ${({height}) => height};

//     @media (min-width: 1200px){
//         width: ${({lg}) => lg}
//     }
// `
// /** Barra de Progreso */
// const Contaienrbar = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: space-between;  
//     width: 100%; 
//     padding-top: 50px;
//     padding-bottom: 50px;
// `  
// const ProgressContainer = styled.div`
//     width: 70%;
//     background-color: grey;
// `
// const Progress = styled.div`
//     width: ${ ({ width }) => width ? width : '0%' };
//     height: 30px;
//     background-color: green;
//     text-align: center; /* To center it horizontally (if you want) */
//     line-height: 30px; /* To center it vertically */
//     color: white;
//     border-radius: 20px;
// `

// /** Tabla de Progreso Mensual  metas y estado actual */
// const Table = styled.table`
//     border-collapse: collapse;
//     font-size: 12px;
//     color: ${blackColor};
//     width: 30%;
//     text-align: left;
// `
export const Tr = styled.tr`
    :nth-child(even){background-color: #eeeeeefe; }
`
export const Th = styled.th`
    background-color: #efefefef;
    padding: 8px 15px;
    border-bottom: 1px solid ${iconColor};   
`
export const Td = styled.td`
    padding: 8px 15px;
    border-bottom: 1px solid ${iconColor};
    ${({ textAlign }) => textAlign && css`text-align: ${textAlign};` } 
`