import React from 'react'
import { IconCheck, IconSad } from '../../../assets/icons'
import { primayVariantColor } from '../../../constanst'
import Logo from '../../../assets/imagenes/logomotoexplorer.jpg'
import styled from 'styled-components'
import { Loading } from '../../common/Loading'

export default ({state}) => 
<Main>
    <Header>
        <img src={Logo} alt="Logo" />
    </Header>
    <Body>
        {state.loading ? <Loading /> : state.success ?
        <>
            <IconCheck color={primayVariantColor} width="250px" height="250px" />
            <Title>¡Gracias por confirmar su cita!</Title>
        </>
        :
        <>
            <IconSad color={primayVariantColor} width="250px" height="250px" />
            <Title>¡Lo sentimos ha ocurrido un error!</Title>
        </>
        }
        
    </Body>
</Main>


const Main = styled.main`
    display: flex;
    height: 100vh;
    width: 100%;
    flex: 1;
    flex-direction: column;
`

const Header = styled.header`
    width: 100%;
    padding: 15px;
    background-color: ${primayVariantColor};
`
const Body = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    flex-direction: column;
`
const Title = styled.h1`
    color: ${primayVariantColor};
    
`