import React from 'react'
import { connect } from 'react-redux'
import { blackColor, iconColor, secundaryColor } from '../../../constanst'
import { Title, ModuleContainer, BoxIdent } from './Styles'
import ModuleInput from '../../common/ModuleInput'

const IdenMoto = ({ worksList }) =>
    <ModuleContainer>
        <br />
        <Title bgColor="transparent" color={blackColor}>IDENTIFICACIÓN DE LA MOTO</Title>
        <br />
        <BoxIdent>
            <ModuleInput label="N° DE MOTOR" type="text" name="formmotor" disabled
                backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="100%"
                value={worksList.ds_numeromotor || ''}
            />
            <ModuleInput label="TIPO MOTO" type="text" name="formvehiculo" disabled
                backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="100%"
                value={worksList.rm_nombremodelo || ''}
            />
            <ModuleInput label="PLACA" type="text" name="formplaca" disabled
                backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="50%"
                value={worksList.rm_nombremodelo || ''}
            />
            <ModuleInput label="COLOR" type="text" name="formcolor" disabled
                backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="50%"
                value={worksList.ds_color || ''}
            />
        </BoxIdent>
        <BoxIdent>
            <ModuleInput label="TÉCNICO RESPONSABLE" disabled
                backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw={window.screen.width > 600 ? "30%" : "100%"}
                value={`${worksList.per_nombre} ${worksList.per_nombre}`}
            />
            <ModuleInput label="N° ALISTAMIENTO INCOLMOTOS" name="formincolmotos" disabled
                backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw={window.screen.width > 600 ? "25%" : "100%"}
                value={worksList.ds_incolmotos || 'N/A'}
            />
            <ModuleInput label="KM" type="number" name="formkm" disabled
                backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw={window.screen.width > 600 ? "22%" : "50%"}
                value={worksList.ds_km || ''}
            />
            <ModuleInput label="GASOLINA" name="formgasolina" disabled
                backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw={window.screen.width > 600 ? "22%" : "50%"}
                value={worksList.ds_gasolina || ''}
            />
            <ModuleInput label="CUSTODIA DE PAPELES" name="formcustodia" disabled
                backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw={window.screen.width > 600 ? "22%" : "100%"}
                value={worksList.ds_custodiapapeles === '1' ? 'SI' : 'NO'}
            />
        </BoxIdent>
    </ModuleContainer>



const mapStateToProps = ({ worksList }) => ({ worksList: worksList.data || {} })

export default connect(mapStateToProps, null)(IdenMoto)