import React, { Fragment } from 'react'
import styled, {keyframes, css} from 'styled-components'
import { Select, FormControl, MenuItem } from '@material-ui/core'
import { blackColor, iconColor } from '../../constanst'

export default ({ title, name, value, children, key, onChange, label, inputw, margin, disabled, load }) => {
const styles = {
    select: {
        borderRadius: 25, backgroundColor: '#ffffff', border: `1px solid ${iconColor}`, outline: 0, fontSize: 12, color: blackColor, paddingLeft: 15, margin: margin ? margin : "10px 0",
    }
}
return(
    <InputContainer inputw={inputw} load={load}>
        {load ? 
            <Disabled />
            :
            <Fragment>
                <Label>{label}</Label>
                <FormControl style={{width: '100%'}}>
                    <Select
                        disabled={disabled}
                        displayEmpty
                        name={name}
                        key={key}
                        value={!!children.length ? (value || 0) : 0}
                        onChange={onChange}
                        style={ styles.select }
                    >
                        <MenuItem value="0">{!!title ? title : "SELECCIONAR"}</MenuItem>
                        {children}
                    </Select>
                </FormControl>
            </Fragment>
        }
    </InputContainer>
)}

/** estilos para el module container */
const InputContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    flex: 1;
    width: ${({inputw}) => inputw };
    padding: 0 10px;
    ${({load}) => load && css`align-items: center;`}
`
const Label = styled.label`
    color: ${blackColor};
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif;
    font-size: 12px;
    font-weight: 500;
`
const AnimLoading = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`
const Disabled = styled.div`
    display: inline-block;
    width: 15px;
    height: 15px;

    &:after {
        content: " ";
        display: block;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        border: 3px solid ${blackColor};
        border-color: ${blackColor} transparent ${blackColor} transparent;
        animation: ${AnimLoading} 1.2s linear infinite;
    }
`
// const styles = {
//     select: {
//         borderRadius: 25, backgroundColor: '#ffffff', border: `1px solid ${iconColor}`, outline: 0, fontSize: 12, color: blackColor, paddingLeft: 15, margin: 10
//     }
// }
