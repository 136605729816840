import React, { Component } from 'react'
import { connect } from 'react-redux'
import CurriculumVitaeV from '../../components/curriculumVitae'
import { postCurriculumVitae, getIdentityVitae, getwarrantyVitae, getReviewsServices } from '../../../redux/actions/curriculumVitae'
import { getSedes } from '../../../redux/actions/service-orders'
import toastr from 'toastr'
import { errorColor, validations } from '../../../constanst'

class CurriculumVitae extends Component {

    constructor(props) {
        super(props)
        this.state = {
            warrantyVitae: {},
            historyform: {},
            vehicleidentity: {},
            error: {},
            loading: false
        }
        this.isMount = false
        this.errorSubmit = false
    }

    async componentDidMount() {
        const id = this.props.match.params.id
        if (id) {
            this.setState({ loading: true })
            
            await this.props.getSedes()
            const userData = JSON.parse(localStorage.getItem('userdata'))
            const sedeUser = this.props.sedes.filter(x => x.sed_id === userData[0].usu_sede)

            await this.props.getIdentityVitae({ hvv_id: id})
            if (this.props.identityVitae.success) {
                await this.props.getwarrantyVitae({ placa: this.props.identityVitae.data.info.hvv_placa, type: 1 })
                await this.props.getReviewsServices({ placa: this.props.identityVitae.data.info.hvv_placa, type: 2 })
                this.setState({ sede: sedeUser[0] ,vehicleidentity: { ...this.props.identityVitae.data.info }, historyform: { hvv_id: this.props.identityVitae.data.history.hvv_id || this.props.identityVitae.data.info.hvv_id , ...this.props.identityVitae.data.history }, warrantyVitae: this.props.warrantyVitae.data, reviewsVitae: this.props.reviewsVitae.data, loading: false })                
            } else return toastr.warning('No se ha encontrado ningún resultado.')        
        }
    }

    onChangeInput = (e, typeNull, typeLetters, typeNumeric, typeRange, minRange, maxRange, typeEmail) => {
        const error = validations(e, typeNull, typeLetters, typeNumeric, typeRange, minRange, maxRange, typeEmail)
        this.setState({ historyform: { ...this.state.historyform, [e.target.name]: e.target.value }, error: { [e.target.name]: error }})
    }

    handleSubmit =  async e => {
        e.preventDefault()
        /** Validar que todos los campos tengan valores */
        const inputs = document.getElementById('formHistory').elements
        
        for (let i = 0; i < inputs.length; i++) {
            if(!!inputs[i].value === false && inputs[i].type !== "submit" && inputs[i].type !== "button" && inputs[i].type !== "radio"){
                
                /** Validando inputs texts */
                if(inputs[i].dataset.ignore === "false"){
                    this.errorSubmit = true
                    inputs[i].style.border = `1px solid ${errorColor}`
                    inputs[i].nextSibling.innerHTML = "El campo no puede estar vació"
                }
                
                /** Validando selects */
                if(inputs[i].dataset.ignore === undefined) {
                    this.errorSubmit = true
                    inputs[i].parentElement.style.border=`1px solid ${errorColor}`
                    let node = document.createElement("SPAN")
                    node.classList.add("error")
                    node.style.color = errorColor
                    inputs[i].parentElement.parentNode.appendChild(node)
                    inputs[i].parentElement.nextSibling.innerHTML = "Selecciona una opción"
                }
                
            } else {
                if (this.state.error[inputs[i].name])
                    this.errorSubmit = true;
            }
        }
        /** Validando radio button */
        if (!this.state.historyform.hvvh_type_soli) {
            // const a = [...document.getElementById('formHistory')].filter(x => x.type === 'radio').forEach(y => y.parentElement.style.border=`1px solid ${errorColor}`)
        }
        if(!this.errorSubmit && this.state.historyform.hvvh_type_soli){
            this.setState({ loading: true })
            await this.props.postCurriculumVitae(this.state.historyform);
            this.setState({ loading: false })
            const res = this.props.curriculumVitae
            if (res.success) toastr.success('Se han actualizado los datos.')
            else toastr.warning(res.message)
        } else toastr.warning('Por favor revise los campos requeridos.')

        this.errorSubmit = false;
        
    }

    onCheckClick = type => {
        // const a = [...document.getElementById('formHistory').elements].filter(x => x.type === 'radio').forEach(y => y.parentElement.style.border=`none`)
        this.setState({ historyform: { ...this.state.historyform, hvvh_type_soli: type} })
    }

    render() {
        return <CurriculumVitaeV
            state={this.state}
            onChangeInput={this.onChangeInput}
            handleSubmit={this.handleSubmit}
            onCheckClick={this.onCheckClick}
        />
    }
}
const mapStateToProps = ({ curriculumVitae, identityVitae, warrantyVitae, reviewsVitae, sedes  }) => ({ curriculumVitae, identityVitae, warrantyVitae, reviewsVitae, sedes})
export default connect(mapStateToProps, { postCurriculumVitae, getIdentityVitae, getwarrantyVitae, getReviewsServices, getSedes})(CurriculumVitae)
// primero el action luego el iindex de reduces
