import React from 'react'
import styled, { css } from 'styled-components'

import { primayColor } from '../../../constanst'
import { TableContainer, Table, Tr, Th, Td, Subtitle} from './ComponentsForm'


export default  ({ state: { reviewsVitae } }) => (
        <ModuleContainer>
            <TableContainer>
                <Subtitle>HISTORIAL DE REVISIONES Y SERVICIOS</Subtitle>
                <Table>
                    <thead>
                        <Tr>
                            <Th>CONSECUTIVO</Th>
                            <Th>NOMBRE DEL SERVICIO</Th>
                            <Th>FECHA DEL SERVICIO</Th>
                        </Tr>
                    </thead>
                    <tbody>
                        {reviewsVitae?.length ?
                            reviewsVitae.map( (element, index) => (
                            <Tr key={index}>
                                <Td>{element.ser_consecutivo}</Td>
                                <Td>{`${element.tser_nombre} - ${element.tg_nombre}`}</Td>
                                <Td>{element.fecha}</Td>
                            </Tr>
                            ))
                            : <Tr><Td textAlign="center" colSpan="4">No hay elementos para mostrar</Td></Tr>
                        }
                    </tbody>
                </Table>
            </TableContainer>

        </ModuleContainer>

)


const ModuleContainer = styled.div`
    flex: 1;
    width: 100%;
    padding: 0 10px 0px 20px;
    display: inline-block;
    
    ${({ borderLeft }) => borderLeft && css`border-left: 1px solid ${primayColor};`}
`
