import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import toastr from 'toastr'
import "toastr/build/toastr.css"

/** Imports of user */
import { 
    getUltimoConsecutivo, getVehicle, getTypeService, getTypeServiceWork, getSedes, 
    getInventario, postRegisterService, postRegisterGesTaller, postRegistrarLlamadas,
    postRegistrarInventario, postRegistrarAutorizacion, getGenerarPdfCliente, getGenerarPdfTecnico,
    getOrdenServicioId, getRequestServiceId, getCallsServiceId, getIventarioList, getAuthId, updateServiceOrder,
    deleteSol, deleteLlamSer, upRegisterGesTaller, getClientCed, getVehicleMotor, sendEmail
} from '../../../redux/actions/service-orders'
import { getTecnicos } from '../../../redux/actions/userActions'
import { validations, errorColor, blackColor, iconColor }  from '../../../constanst'
import OrdenV  from '../../components/services/OrdenV'
import { onCloseModal } from '../../common/Modal'
import { url_base } from '../../../redux/api/urls'
// import InventarioForm from '../../components/services/InventarioForm'

class Orden extends Component {

    constructor(props){
        super(props)
        this.state = {
            stateImp: {},
            clientform: { formrs: '', formotrotrabajogarantia: '', formtipors: '' },
            vehicleform: {formincolmotos: '', formserie: 0},
            serviceform: { formvalorabono: '0', formfactura: '', formordeninterna: ''},
            solicitudform: { clienteSolicitud: [], clientellamadas: [] },
            inventarioform: {},
            inventario: [],
            conditions: {},
            clientList: [],
            comments: {},
            termsform: {},
            clienteSol: {},
            clienteLlam: {},
            error: false,
            errorChange: {},
            loading: true,
            modalImp: false,
            equal: false,
            idServicio: null
        }
        this.sigPad = {}
        this.errorSubmit = true
        this.radioCheck = undefined
        this.isMount = false
    }

    /** Inicio del componente */
    async componentDidMount(){
        this.isMount = true

        /** Obteniendo los estados */
        const { serviceform, clientform, vehicleform, conditions } = this.state
        await this.props.getVehicle()
        await this.props.getTypeService()
        await this.props.getSedes()
        await this.props.getTecnicos()
        await this.props.getInventario()

        /** Persimos de pagina principal, si no existe el consecutivo redirige al inicio del formulario */
        if(this.state.clientform.formcons === undefined){
            const { id } = this.props.match.params
            /** Actualización */
            if(!!id){
                if (this.isMount){
                    this.setState({ idServicio: id })
                    this.props.history.push(`/servicios/orden/${id}`)
                    await this.props.getOrdenServicioId(id)
                    
                    /** Resultados de la petición para la información personal, vehiculo y servicio */            
                }
                const { success } = this.props.servicesOrden

                if(success === 1){
                    const {data: {clientform: clif, vehicleform: vf, serviceform: sf, conditions: cond}} = this.props.servicesOrden

                    await this.props.getTypeServiceWork(sf.formtiposervicio)
                    this.isMount &&
                        this.setState({
                            typeServicesWork: this.props?.typeServicesWork,
                            ...clientform, clientform: clif,
                            ...vehicleform, vehicleform: vf,
                            ...serviceform, serviceform: sf,
                            ...conditions, conditions: cond
                        })

                    /** Resultados de las solicitudes y solucion de servicios */
                    await this.props.getRequestServiceId(id)
                    
                    if(this.props.servicesRequestList.success === 1 && this.isMount)
                        this.setState({ solicitudform: { ...this.state.solicitudform, clienteSolicitud: this.props.servicesRequestList.data} })
                    else {
                        toastr.error("Ha ocurrido un error cargando la información")
                        this.isMount && this.setState({loading: false})
                        //this.props.history.push('/servicios/seguimiento')
                    }
                    
                    /** Resultados de las llamadas de servicios */
                    await this.props.getCallsServiceId(id)

                    if(this.props.servicesCallsList.success === 1 && this.isMount)
                        this.setState({ solicitudform: { ...this.state.solicitudform, clientellamadas: this.props.servicesCallsList.data} })

                    /** Obteniendo la lista de inventario */
                    await this.props.getIventarioList(id)
                    
                    if(this.props.inventarioList.success === 1 && this.isMount){
                        const { inventarioform, comments } = this.props.inventarioList.data
                        this.setState({ inventarioform, comments })
                    }

                    /** Obteniendo información de la lista de las autorizaciones */
                    await this.props.getAuthId(id)

                    if(this.props.authId.success === 1 && this.isMount)
                    this.setState({ termsform: this.props.authId.data })

                } else {
                    toastr.error("Ha ocurrido un error cargando la información")
                    this.isMount && this.setState({loading: false})
                    this.props.history.push('/servicios/seguimiento')
                }
            } else { /** Registro  */
                const storage = JSON.parse(localStorage.getItem("userdata"))
                const sede = storage[0].usu_sede

                this.props.history.push(`/servicios/orden`)
                        /** CARGANDO ESTADOS NECESARIOS PARA EL REGISTRO */
                await this.props.getUltimoConsecutivo()
                let formhoraentrada = moment().format('hh:mm')

                if(this.isMount){
                    const formcosn = this.props.ultimoConsecutivo[0].consecutivo
                    this.setState({ clientform: { ...clientform, formcosn }, 
                        serviceform: {...this.state.serviceform, formhoraentrada, formsede: sede},
                    })
                }


                /** Cargando estado anterior */
                // const localState = JSON.parse(localStorage.getItem("state"))
                // if(!!localState){
                //     const {clientform, vehicleform, solicitudform, serviceform, comments, inventarioform, termsform, conditions} = localState
                //     this.setState({...this.state.clientform, clientform,
                //         ...this.state.vehicleform.vehicleform, vehicleform,
                //         ...this.state.solicitudform.solicitudform, solicitudform, 
                //         ...this.state.serviceform.serviceform,serviceform,
                //         ...this.state.comments.comments, comments,
                //         ...this.state.inventarioform, inventarioform,
                //         ...this.state.termsform, termsform,
                //         ...this.state.conditions.conditions, conditions
                //     })
                // }
                
            }
        }

            const userdata = JSON.parse(localStorage.getItem("userdata"))
            const idusuario = userdata[0].idusuario
            const sedeUser = this.props.sedes.filter(x => x.sed_id === userdata[0].usu_sede)

            if(this.isMount)
                this.setState( prevState => ({ clientform: { ...prevState.clientform, idusuario } }))

         /**
         * Obetiendo la hora desde la librería moment y
         * actualizando estados con valores de la base de datos
         */
        if(this.isMount) 
            this.setState( prevState => ({
                vehiclesList: this.props.vehicles,
                typeServices: this.props.typeServices,
                sedes: this.props.sedes,
                sedeUser,
                tecnicos: this.props.tecnicos,
                ...prevState.inventario, inventario: this.props.inventario,
                loading: false
            }))

            
        

    }
    componentWillUnmount(){
        this.isMount = false
    }

    /** Click secundario */
    handleClickButton = (e, form) => {
        e.preventDefault()
        let elem
        const {clienteSol, clienteLlam, solicitudform, error } = this.state
        if(form === 1 &&  !error && (!!clienteSol.formsolicitudes) && (!!clienteSol.formsolucion) )
            this.setState( prevState => ({ solicitudform: { ...solicitudform, clienteSolicitud: [...prevState.solicitudform.clienteSolicitud, clienteSol ]}, clienteSol: '' } ))
       
        if(form === 2 &&  !error && (!!clienteLlam.formfecha && !!clienteLlam.time2) )
            this.setState( prevState => ({ solicitudform: { ...solicitudform, clientellamadas: [...prevState.solicitudform.clientellamadas, clienteLlam ]}, clienteLlam: '' } ))
        
        /** Función para validar que los campos no estén vacíos*/
        const validation = () => {
            if(error)
                return false
            else if(!clienteSol.formsolucion) {
                elem = document.getElementsByName("formsolucion")
                elem[0].style.border = `1px solid ${errorColor}`
                elem[0].nextSibling.innerHTML = "El campo no puede estar vació"
                return false
            }
            if(!clienteSol.formsolicitudes) {
                elem = document.getElementsByName("formsolicitudes")
                elem[0].style.border = `1px solid ${errorColor}`
                elem[0].nextSibling.innerHTML = "El campo no puede estar vació"
                return false
            }

            return true
        }
        /** Validando que ambos campos sean llenados, ya que uno depende de otro */
        if(form === 1){
            const res = validation()
            if(!res)
                return false
        }

        if(form === 3){
            const res = validation()
            if(!res)
                return false

            this.setState(ps => {
                const clienteSolicitud = ps.solicitudform.clienteSolicitud.map( x => {
                    if(x.idobs === ps.clienteSol.idobs)
                        return {formsolicitudes: clienteSol.formsolicitudes, formsolucion: clienteSol.formsolucion, idobs: ps.clienteSol.idobs }
                    return x
                })
                return {solicitudform: {...solicitudform, clienteSolicitud}}
            })
        }
        
    }

    /** Limpiar el Canvas de la firma */
    clear = () => {
        this.sigPad.clear()
    }

    /** Obtener la firma del pad */
    trim = e => {
        e.preventDefault()
        const firmacliente = this.sigPad.getTrimmedCanvas().toDataURL('image/png');
        this.setState({ clientform: {...this.state.clientform, firmacliente} })

        document.getElementById("imgfirma").style.border = `1px solid ${iconColor}` 
        this.error = true
        onCloseModal("modalFirma")
    }

    

    handleChange = async (e, ubiF, isNull, isLetter, isNumber, isRange, minRange, maxRange, isEmail) => {
        /** Asignando Constantes y Variables */
        const {name, value, type} = e.target
        const {clientform, vehicleform, serviceform, solicitudform, inventarioform, termsform, comments, errorChange, conditions } = this.state

        /** Validando las entradas */
        const error = validations(e, isNull, isLetter, isNumber, isRange, minRange, maxRange, isEmail)
        this.setState({ errorChange: { ...errorChange, [name]: error } })

        /** Guardando el estado de los inputs  */
        if (ubiF === 1)
            this.setState({ clientform: { ...clientform, [name]: value.toUpperCase() } })
        else if (ubiF === 2)
            this.setState({ vehicleform: { ...vehicleform, [name]: value.toUpperCase() } })
        else if (ubiF === 3)
            this.setState({ serviceform: { ...serviceform, [name]: value.toUpperCase() } })
        else if (ubiF === 4)
            this.setState({ solicitudform: { ...solicitudform, [name]: value.toUpperCase() } })
        else if (ubiF === 5){
            if(type === "radio")
                this.setState({ inventarioform: { ...inventarioform, [name]: value.toUpperCase() } })
            else
                this.setState({ comments: { ...comments, [name]: value.toUpperCase() } })
        }
        else if (ubiF === 6){
            this.setState({ termsform: { ...termsform, [name]: value.toUpperCase() } })
        }
        else if (ubiF === 7){
            this.setState({ conditions: { ...conditions, [name]: value } })
        }

        /** Buscando cliente por numero de cedula */    
        if(name === 'formcedula' && !!value){
            await this.props.getClientCed(value)
        
        if(this.props.clientList.success === 1)
            this.setState({clientList: this.props.clientList.data, menuActive: true})
        else
            this.setState({menuActive: false})
        } else if(name === 'formcedula' && !value)
            this.setState({menuActive: false})
        
        
        /** Buscando motos por número de motor */    
        if(name === 'formmotor' && !!value){
            await this.props.getVehicleMotor(value)
            
        if(this.props.vehiclesList.success === 1)
            this.setState({vehicleFilterList: this.props.vehiclesList.data, menuA: true})
        else 
            this.setState({menuA: false})
        } else if(name === 'formmotor' && !value)
            this.setState({menuA: false})
                
    }

    /** Función que se ejecuta al hacer click en un resultado de la busqueda de clientes o motos */
    handleClickSelect = (e, val, indicator) => {
        e.preventDefault()
        
        if(indicator === 1 && this.isMount){
            const client = this.state.clientList.filter(x => x.clit_identificacion === val)
            const clientform = {
                ...this.state.clientform,
                formidcliente: client[0].clit_id,
                formcedula: client[0].clit_identificacion,
                formnombre: client[0].clit_nombre,
                formapellido: client[0].clit_apellido,
                formemail: client[0].clit_email,
                formdireccion: client[0].clit_direccion,
                formprofesion: client[0].clit_profesion,
                formtelefono: client[0].clit_telefono,
                formcelular: client[0].clit_celular,
                formrs: client[0].clit_rs,
                formtipors: client[0].clit_tipors
            }
            this.setState({...this.state.clientform, clientform})
        
        } else if(indicator === 2 && this.isMount){
            const data = this.state.vehicleFilterList.filter(x => x.hvv_id === val)
            const vehicleform = {
                ...this.state.vehicleform,
                formplaca: data[0].hvv_placa,
                formvehiculo: data[0].hvv_idvehiculo,
                formvehiculonombre: data[0].hvv_id,
                formcolor: data[0].hvv_color,
                formmotor: data[0].hvv_nmotor,
                //formincolmotos: data[0].ds_incolmotos,
                formkm: data[0].hvv_km,
                formserie: data[0].hvv_serie,
                // formgasolina: data[0].ds_gasolina,
                // custodia: data[0].ds_custodiapapeles,
            }
            this.setState({...this.state.vehicleFilterList, vehicleform})
        }
    }

    /** On Change que se ejecuta en el select al cambiar el estado */
    handleChangeSelect = async (e, ubiF, isQuery) => {
        const {name, value} = e.target
        const { clientform, vehicleform, serviceform } = this.state


        if(isQuery === "query"){
            await this.props.getTypeServiceWork(value)
            this.setState({ typeServicesWork: this.props.typeServicesWork })
        }

        this.setState({ error: false })
        // guardando el estado de los selects
        if (ubiF === 1)
            this.setState({ clientform: { ...clientform, [name]: value } })
        if (ubiF === 2)
            this.setState({ vehicleform: { ...vehicleform, [name]: value } })
        if (ubiF === 3)
            this.setState({ serviceform: { ...serviceform, [name]: value } })

          /** Añadiendo estilos */
            const input = document.getElementsByName(e.target.name)
            if(input[0].parentElement.nextSibling){
                input[0].parentElement.style.border = `1px solid ${blackColor}`
                input[0].parentElement.nextSibling.innerHTML = ""
            }
    }

    /** Multiples names */
    handleChangeMultiple = (e, isNull, isLetter, isNumber, isRange, minRange, maxRange, isEmail, form) => {
        const {name, value} = e.target
        const error = validations(e, isNull, isLetter, isNumber, isRange, minRange, maxRange, isEmail)
        
        if(form === 1)
            this.setState({ clienteSol: { ...this.state.clienteSol, [name]: value.toUpperCase() }, error })
        if(form === 2)
            this.setState({ clienteLlam: { ...this.state.clienteLlam, llamstate: '0', [name]: value.toUpperCase() }, error })
    }

    /** Función que se llama cuando el propietario es el mismo que solicita el servicio */
    handleChangeCheck = () => {
        const {formnombre, formapellido, formcedula, formcelular, formemail, formtelefono} = this.state.clientform

        this.setState({equal: this.state.equal ? false : true}, () => {
            if(this.state.equal && this.isMount)
                this.setState({ clientform: {
                    ...this.state.clientform,
                    formpersonaservicio: formnombre && `${formnombre} ${formapellido}`,
                    formcedulaservicio: formcedula,
                    formtelefonocelular: formcelular,
                    formpersonaemail: formemail,
                    formpersonatelefono: formtelefono
                }})
        })

    }

    /** Enevnto de tecla para seleccionar el buttton y no el submit */
    handleKeyPress = (e, id) => {
       
        if(e.charCode === 13){
            e.preventDefault()
            if(this.state.clienteSol.formsolicitudes !== undefined || this.state.clienteSol.formsolucion !== undefined )
                document.getElementById(id).click()
        }
    }

    /** Enviar Formulario  */
    handleSubmit = async e => {

        /** Cancelando la acción por defecto */
        e.preventDefault();

        /** Variables */
        const inputs = e.target.elements
        this.errorSubmit = false
        const {errorChange} = this.state

        /** formulario que recorre los inputs del formulario */
        for (let i = 0; i < inputs.length; i++){
            
            /** Varificando que los campos tengan algún valor */
            if(!!inputs[i].value === false && inputs[i].type !== "submit" && inputs[i].type !== "button"){
                
                /** Validando inputs texts */
                if(inputs[i].dataset.ignore === "false"){
                    this.errorSubmit=true
                    inputs[i].style.border = `1px solid ${errorColor}`
                    inputs[i].nextSibling.innerHTML = "El campo no puede estar vació"
                }
                
                /** Validando selects */
                if(inputs[i].dataset.ignore === undefined){
                    if(inputs[i].name !== 'formtipors'){
                        this.errorSubmit=true
                        inputs[i].parentElement.style.border=`1px solid ${errorColor}`
                        let node = document.createElement("SPAN")
                        node.classList.add("error")
                        node.style.color = errorColor
                        inputs[i].parentElement.parentNode.appendChild(node)
                        inputs[i].parentElement.nextSibling.innerHTML = "Selecciona una opción"
                    }
                }
                
            } else {
                if(errorChange[inputs[i].name])
                    this.errorSubmit = true;
            }

            /** Validando radiobuttons */
            if (inputs[i] !== undefined && inputs[i+1] !== undefined && inputs[i+2] !== undefined && inputs[i].type !== "button") {
                if(inputs[i].type === 'radio' && inputs[i+1].type === 'radio' && inputs[i+2].type === 'radio'){
                    if (inputs[i].name === inputs[i+1].name && inputs[i].name === inputs[i+2].name) {
                        if (!inputs[i].checked && !inputs[i+1].checked && !inputs[i+2].checked) {
                            this.errorSubmit = true
                            inputs[i].parentElement.parentNode.style.backgroundColor = `${errorColor}50`
                            this.radioCheck = inputs[i]
                        }
                    }  else if (inputs[i].name === inputs[i+1].name && inputs[i].name !== inputs[i+2].name) {
                        if (!inputs[i].checked && !inputs[i+1].checked) {
                            this.errorSubmit = true
                            inputs[i].parentElement.parentNode.style.backgroundColor = `${errorColor}50`
                            this.radioCheck = inputs[i]
                        }
                    }  
                }
                if(inputs[i].type === 'radio' && inputs[i+1].type === 'radio'){
                    if (inputs[i].name === inputs[i+1].name && inputs[i].name !== inputs[i+2].name) {
                        if (!inputs[i].checked && !inputs[i+1].checked && inputs[i+2].checked === undefined) {
                            this.errorSubmit = true
                            inputs[i].parentElement.parentNode.style.backgroundColor = `${errorColor}50`
                            this.radioCheck = inputs[i]
                        }
                    }
                }
                setTimeout( () => {
                    if(!!inputs[i])
                        inputs[i].parentElement.parentNode.style.backgroundColor = `transparent`
                }, 3000 )
            }
                

            /** Validando imagen firma */
            if(!!this.state.clientform.firmacliente === false){
                this.errorSubmit = true
                document.getElementById("imgfirma").style.border = `1px solid ${errorColor}`
            } 
        }

        if(!this.state.vehicleform.custodia)
            this.errorSubmit = true

            
        /** Verificando si existe un error */
        if(this.state.error || this.errorSubmit){
            toastr.warning("Verifiqué que los campos estén correctos")
            return false
        }
        
        /** Obteniendo el id de la url */
        const { id } = this.props.match.params


        this.setState({ loading: true })

        if(id){
            await this.updateService()
        } else {
            const response = await this.saveService()
            const { sed_nombre, sed_direccion, sed_telefono } = this.state.sedeUser[0]
            if(!!response){
                await this.props.sendEmail({sede: sed_nombre, dir: sed_direccion, tlf: sed_telefono, email: this.state.clientform.formemail, idSer: response})
                toastr.success("Se ha enviando un correo al cliente")
            }
        }

        await this.infoModal()
        
        this.isMount &&
            setTimeout( () => this.setState({ loading: false, modalImp: true }), 1000)

    }

    /** Función para guardar */
    saveService = async () => {
        const { clientform, vehicleform, solicitudform, serviceform, comments, inventarioform, termsform, conditions } = this.state

                const values = {
                    ...clientform,
                    ...vehicleform,
                    ...serviceform,
                    ...conditions
                }

                // localStorage.setItem("state", JSON.stringify({clientform, vehicleform, solicitudform, serviceform, comments, inventarioform, termsform, conditions}))
                
                /** Guardar Datos de Servicio, vehiculo, cliente */
                await this.props.postRegisterService(values)
                
                if(this.props.servicesId.success === 0){
                    toastr.error(this.props.servicesId.message)
                    this.setState({ loading: false })
                    return false
                }

                const idservicio = this.props.servicesId.data

                /** guardar datos de el cliente solicita, solución y solicitud */
                if(solicitudform.clienteSolicitud.length > 0){
                    solicitudform.clienteSolicitud.map( async x => (
                        await this.props.postRegisterGesTaller({ servicio: x.formsolicitudes, solucion : x.formsolucion, idservicio })
                    ))
                }
                /** Guardar datos de llamadas al cliente */
                if(solicitudform.clientellamadas.length > 0){
                    solicitudform.clientellamadas.map( async x => (
                        await this.props.postRegistrarLlamadas({ fecha: x.formfecha, hora: x.time2, comentario: x.formcomentario, idservicio })
                    )) 
                }
                
                /** Guardar datos del inventario */
                for(let x in inventarioform) {
                    await this.props.postRegistrarInventario({ idinv: x, type: inventarioform[x], comment: comments[`obs${x}`] || '', idservice: idservicio, iduser: clientform.idusuario })
                }
                // /** Guardar datos de autorización de la orden de servicio */
                await this.props.postRegistrarAutorizacion({idOrSer: idservicio, pruRuta: termsform.oneItem, llaTel: termsform.secItem, disRep: termsform.terItem })

                
                /** Ocualtando el loading */
                toastr.success("Registro guardado con éxito")

                return idservicio
    }
    
    /** Función para actualizar */
    updateService = async () => {
        /** Declarando constantes con los valores */
        const { clientform, vehicleform, serviceform, solicitudform, conditions, inventarioform, termsform, comments } = this.state
        const idservicio = this.props.match.params.id

        /** Combirtiendo en un solo objeto para poder guardar en el (backend mal hecho) */
        const values = {
            ser_estado: this.props?.servicesOrden?.data?.ser_estado,
            ...clientform,
            ...serviceform,
            ...vehicleform,
            ...conditions
        }


       await this.props.updateServiceOrder(values)
        /** guardar datos de el cliente solicita, solución y solicitud */
        if(solicitudform.clienteSolicitud.length > 0){
            solicitudform.clienteSolicitud.map( async x => {
                if(!!x.idobs === false)
                   await this.props.postRegisterGesTaller({ servicio: x.formsolicitudes, solucion : x.formsolucion, idservicio })
                else
                   await this.props.upRegisterGesTaller({ servicio: x.formsolicitudes, solucion : x.formsolucion, idobs: x.idobs })
            })
        }
         /** Guardar datos de llamadas al cliente */
         if(solicitudform.clientellamadas.length > 0){
            solicitudform.clientellamadas.map( async x => {
                if(!!x.llamid === false)
                    await this.props.postRegistrarLlamadas({ fecha: x.formfecha, hora: x.time2, comentario: x.formcomentario, idservicio })
            }) 
        }

        if (this.props?.servicesOrden?.data?.ser_estado === '6') {
            /** Guardar datos del inventario */
            for(let x in inventarioform) {
                await this.props.postRegistrarInventario({ idinv: x, type: inventarioform[x], comment: comments[`obs${x}`] || '', idservice: idservicio, iduser: clientform.idusuario })
            }
            /** Guardar datos de autorización de la orden de servicio */
            await this.props.postRegistrarAutorizacion({idOrSer: idservicio, pruRuta: termsform.oneItem, llaTel: termsform.secItem, disRep: termsform.terItem })
        }
    }
    
    /** On click Modal para imprimir */
    onClickModal = async (e, param) => {
        let idservicio
        if(this.props.match.params.id)
            idservicio = this.props.match.params.id
        else
            idservicio = this.props.servicesId.data

        if(param === 'cliente'){
            await this.props.getGenerarPdfCliente(idservicio)
            let ruta = 'orden_' + idservicio + '.pdf';
            window.open(`${url_base}/uploads/uploads_ordenservicio_cliente/${ruta}`, '_blank');
        }
        if(param === 'tecnico'){
            await this.props.getGenerarPdfTecnico(idservicio)
            let ruta = 'orden_' + idservicio + '.pdf';
            window.open(`${url_base}/uploads/uploads_ordenservicio_empresa/${ruta}`, '_blank');
        }
    }

    /** Cerrar modal y redirigir */
    closeModal = (e, id) => {
        e.preventDefault()
        onCloseModal(id)
        this.props.history.push('/servicios/seguimiento')
    }

    /** Eliminando las solicitudes de servicios */
    handleDeleteSolucions = async (e, index, type, id) => {
        e.preventDefault()
        const solicitudform = this.state.solicitudform
            if(type === 1){
                if(this.props.match.params.id && id){
                    await this.props.deleteSol(id);
                    this.setState({ solicitudform: { ...this.state.solicitudform, clienteSolicitud: this.props.servicesRequestList.data} })
                } else {
                    solicitudform.clienteSolicitud.splice(index, 1)
                    this.setState(prevState => ({ ...prevState.solicitudform.clienteSolicitud, solicitudform }))
                }
            } else {
                if(this.props.match.params.id && id){
                    await this.props.deleteLlamSer(id);
                    this.setState({ solicitudform: { ...this.state.solicitudform, clientellamadas: this.props.servicesCallsList.data} })
                }
                solicitudform.clientellamadas.splice(index, 1)
                this.setState(prevState => ({ ...prevState.solicitudform, solicitudform }))
            }
        
    }
    handleUpdateSolucions = (e, idServ) => {
        e.preventDefault()
        
        const x = this.state.solicitudform.clienteSolicitud.find( e => e.idobs === idServ)
        this.setState({ clienteSol: {formsolicitudes: x.formsolicitudes, formsolucion: x.formsolucion, idobs: idServ}, editSol: true })
    }

    handleCancelUpdate = e => {
        e.preventDefault()
        this.setState({ clienteSol: {formsolicitudes: '', formsolucion: '', idobs: ''}, editSol: false })
    }

    /** on blur */
    handleBlur = (e) => { 
        setTimeout(() => this.setState({menuActive: false, menuA: false}), 500)
        /** Validando las entradas */
        const error = validations(e, true)
        this.setState({ errorChange: { ...this.state.errorChange, [e.target.name]: error } })
    }



    /** Cargando datos para el modal pdf */
    infoModal = async () => {
        const { clientform: clif, serviceform: sf, vehicleform: vf, conditions: cond, solicitudform, inventarioform, inventario, comments, termsform } = this.state

         /** Obteniendo datos del técnico */
         const tecn = this.props.tecnicos.length > 1 && this.props.tecnicos.filter(x => x.usu_id === sf.formtecnico)

        /** Buscar la información de la sede */
        await this.props.getTypeServiceWork(sf.formtiposervicio)
        const resSede = this.props.sedes.find(x => x.sed_id === sf.formsede)
        const servWork = this.props.typeServicesWork.find( e => e.tg_id === sf.formTrabajogarantia)
        const vh = this.props.vehicles.find( e => e.rm_id === vf.formvehiculo)
        
        const alternates = {
            servicio: servWork.tser_nombre,
            trabajo: servWork.tg_nombre,
            sede: resSede.sed_nombre,
            vehiculo: vh.rm_nombremodelo
        }

        this.setState( {stateImp: { clif, vf, sf, cond, tecn: tecn[0].per_nombre, resSede, solicitudform, inventario, inventarioform, comments, termsform, alternates }})

    }




    render() {
        
        return (
           <OrdenV
                stateOrder={this.props?.servicesOrden?.data?.ser_estado}
                params={this.props.match.params}
                onClickButton={this.handleClickButton}
                onDel={this.handleDeleteSolucions}
                onUpdate={this.handleUpdateSolucions}
                onCanUp={this.handleCancelUpdate}
                onClickModal={this.onClickModal}
                onCloseModal={this.closeModal}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onClickSelect={this.handleClickSelect}
                onChangeMultiple={this.handleChangeMultiple}
                onChangeSelect={this.handleChangeSelect}
                onChangeCheck={this.handleChangeCheck}
                onKeyPress={this.handleKeyPress}
                sigPad={ref => this.sigPad = ref }
                clearPad={this.clear}
                trimPad={this.trim}
                onSubmit={this.handleSubmit}
                onCheckReadiness={e => this.setState({ ...this.state, serviceform: { ...this.state.serviceform, [e.target.name]: e.target.checked, ...(!e.target.checked ? { ds_mrAssessor: '', ds_mrObs: '' } : {}  ) } })}
                onGoBack={ e => this.props.history.push('/servicios/seguimiento')}
                state={this.state}
           />
        )
    }
}


/** Conectando al store de redux  */
const mapStateToProps = ({serviceConstants, vehicles, typeServices, typeServicesWork, sedes, tecnicos, inventario, servicesId, servicesOrden, servicesRequestList, 
    servicesCallsList, inventarioList, authId, postResponse, clientList, vehiclesList}) => ({
    vehicles,
    ultimoConsecutivo: serviceConstants,
    typeServices,
    typeServicesWork,
    sedes,
    tecnicos,
    inventario,
    servicesId,
    servicesOrden,
    servicesRequestList,
    servicesCallsList,
    inventarioList,
    authId,
    postResponse,
    clientList,
    vehiclesList
})

export default connect( mapStateToProps, 
{ 
    getUltimoConsecutivo, getVehicle, getTypeService, getTypeServiceWork, 
    getSedes, getTecnicos, getInventario, postRegisterService, postRegisterGesTaller,
    postRegistrarLlamadas, postRegistrarInventario, postRegistrarAutorizacion,
    getGenerarPdfCliente, getGenerarPdfTecnico, getOrdenServicioId, getRequestServiceId,
    getCallsServiceId, getIventarioList, getAuthId, updateServiceOrder, deleteSol, deleteLlamSer,
    upRegisterGesTaller, getClientCed, getVehicleMotor, sendEmail
}
)(Orden)