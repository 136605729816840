import React, { Component } from 'react'
import { connect } from 'react-redux'
import toastr from 'toastr'
import "toastr/build/toastr.css"

import  ServicesV from "../../components/parameterization/Services"
import { postTSer, upTSer, delTSer, getTSerWorks, delSerWorks, upSerWorks, postSerWorks } from "../../../redux/actions/Parameterizations"
import { getTypeService } from "../../../redux/actions/service-orders"
import { validations } from '../../../constanst'
import { openModal, onCloseModal } from '../../common/Modal'

class Services extends Component {
    constructor(props){
        super(props)
        this.state = {
            loading: false,
            services: [],
            servicesWorks: [],
            error: {},
            listActive: true
        }
        this.isMount = false
    }

    async componentDidMount(){
        this.isMount = true
        this.setState({loading: true})

        const idUser = JSON.parse(localStorage.getItem('userdata'))
        /** Obteniendo el listado de valores */
        await this.props.getTypeService()
        await this.props.getTSerWorks()
        
        if(this.props.typeServicesWorks.success === 0){
            toastr.warning(this.props.typeServicesWorks.message)
        }
        if(this.isMount){
            this.setState({ services: this.props.typeServices, servicesWorks: this.props.typeServicesWorks.data, idUser: idUser[0].idusuario, loading: false })
        }
    }

    componentWillUnmount(){
        this.isMount = false
    }


    /** Método on Change */
    handleChange = (e, isNull, isLetter, isNumber, isRange, minRange, maxRange, isEmail) => {
        const error = validations(e, isNull, isLetter, isNumber, isRange, minRange, maxRange, isEmail)
        this.setState({ [e.target.name]: e.target.value, error: {[e.target.name]: error} })
    }

    /** Metodo OnSubmit (envío del formulario) */
    handleSubmit = async e => {
        e.preventDefault()
        this.setState({loading: true})
        onCloseModal("modalUpdate")

        let errorSubmit = false 
        const { error, nombre, idSer, listActive, idTg, idTSer, daysRemember, emailText } = this.state

        for(let x in error){
            if(error[x])
                errorSubmit = true
        }
        if(!nombre)
            errorSubmit = true

        if(errorSubmit) {
            toastr.warning("Rellene los campos")           
            this.isMount &&
                this.setState({loading: false})
            return false
        }

    
        if(listActive){
            if(!!idSer)
                await this.props.upTSer({idSer, nombre})
            else
                await this.props.postTSer({nombre})

                if(this.props.postResponseParam.success === 1) {
                    await this.props.getTypeService()
                    if(this.isMount)
                        this.setState({ services: this.props.typeServices, idSer: '', nombre: '' })
                    toastr.success("Operación exitosa")
                } else
                    toastr.warning("Ha ocurrido un error")        
                
        } else {
            if(!!idTg)
                await this.props.upSerWorks({idTg, nombre, idTSer, daysRemember, emailText})
            else
                await this.props.postSerWorks({nombre, idTSer, daysRemember, emailText})

                if(this.props.postResponseParam.success === 1) {
                    await this.props.getTSerWorks()
                    if(this.isMount)
                        this.setState({ servicesWorks: this.props.typeServicesWorks.data, nombre: '', idTg: '', idTSer: '', daysRemember: '', emailText: '' })
                    toastr.success("Operación exitosa")
                } else
                    toastr.warning("Ha ocurrido un error")
                
        }
        

        this.isMount &&
            this.setState({loading: false})
    }

    /** onClickParams */
    handleParams = (e, idElem, idModal) => {
        e.preventDefault()

        if(this.state.listActive && this.isMount){
            const eSel = this.state.services.find(e => e.tser_id === idElem)
            this.setState({ idSer: eSel.tser_id, nombre: eSel.tser_nombre })
        }
        else { 
            const eSel = this.state.servicesWorks.find(e => e.tg_id === idElem)
            this.setState({ idTg: eSel.tg_id, nombre: eSel.tg_nombre, idTSer: eSel.tg_idgarantia, daysRemember: eSel.tg_tiemporecordatorio, emailText: eSel.tg_mensaje })
        }

        openModal(idModal)
    }

    /** Función para eliminar valores de motos */
    handleDelete = async (e, id) => {
        e.preventDefault()
        this.setState({loading: true})

        if(this.state.listActive)
            await this.props.delTSer(id)
        else
            await this.props.delSerWorks(id)

        if(this.props.typeServicesWorks.success === 1 && this.isMount){
            this.state.listActive ? 
                this.setState({ services: this.props.typeServices.data })
            :
                this.setState({ servicesWorks: this.props.typeServicesWorks.data })
            
            toastr.success("Eliminado con exito")
        } else
            toastr.warning("Ha ocurrido un error")

        this.setState({loading: false})
    }

    /** Cancelar modal */
    handleClickCancel = e => {
        e.preventDefault()
        onCloseModal("modalUpdate")

        this.isMount &&
            this.setState({ idSer: '', nombre: '', daysRemember: '', idTg: '', idTSer: '', emailText: '' })
    }
    
    
    render() {
        return (
            <ServicesV
                state={this.state}
                onChange={this.handleChange}
                onChangeSelect={e => this.setState({ [e.target.name]: e.target.value })}
                onClickParams={this.handleParams}
                onClickCancel={this.handleClickCancel}
                onDelete={this.handleDelete}
                onSubmit={this.handleSubmit}
                onChangeList={() => this.setState({listActive: !this.state.listActive})}
                onChangeQuill={value => this.setState({ emailText: value })}
            />
        )
    }
}

const mapStateToProps = ({ typeServicesWorks, postResponseParam, typeServices }) => ({ typeServicesWorks, postResponseParam, typeServices })


export default connect(mapStateToProps, {getTSerWorks, postTSer, upTSer, getTypeService, delTSer, delSerWorks, upSerWorks, postSerWorks})(Services)