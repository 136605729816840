import React from 'react'
import styled from 'styled-components'
import { secundaryColor, primayColor, iconColor, alternateColor, bgColor } from '../../constanst'

import fondoLogin from '../../assets/imagenes/fondologin.png'
import logo from '../../assets/imagenes/logo.jpg'
import { IconPerson, IconLock, IconUser } from '../../assets/icons'
import InputText from '../common/InputText'
import ButtonSubmit from '../common/ButtonSubmit'

export default ({ onChange, data, onClick }) =>  (
    <Container>
    {/** Contenedor que forma el formulario y el logo */}
        <LeftContainer>
            {/** Logo */}
            <Logo />

            {/** Formulario */}
            <FormContainer>
                <FormUser>
                    <IconoUsuario>
                        <IconPerson width="80%" height="80%" color={iconColor} />
                    </IconoUsuario>
                    {/** inputs */}
                    <InputText type="text" name="user" placeholder="Usuario" onChange={onChange} value={data.form.user} borderC={secundaryColor} icon={<IconUser width="15px" height="15px" color={secundaryColor} />} 
                        textColor={secundaryColor} />
                    <InputText type="password" name="password" placeholder="Password" onChange={onChange} value={data.form.password} borderC={bgColor} icon={<IconLock width="15px" height="15px" color={secundaryColor}/>} 
                        textColor={secundaryColor} />
                    <ButtonSubmit bgColor={alternateColor} width="92%" onClick={onClick} label="Ingresar" color={secundaryColor} />
                    <ResetPass>¿Olvidaste tu contraseña?</ResetPass>
                </FormUser>
            </FormContainer>
        </LeftContainer>

        {/** Contenedor para la parte derecha del login */}
        <RightContainer>
            <Headed>
                <TextLight>DIGITAL</TextLight>
                <TextBold>REGISTRO</TextBold>
            </Headed>

            <Footer>
                <TextFooter>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eleifend efficitur purus, et pharetra
                    sapien consectetur sed. Donec id ex. Nullam sed porta sapien, at consectetur turpis.
                </TextFooter>
                <ButtonSubmit bgColor={primayColor} width="120px" onClick={e => e.preventDefault() } label="Registrar" color={secundaryColor} />
            </Footer>
        </RightContainer>
    </Container>
)


const Container = styled.div`
    background: url(${fondoLogin}) no-repeat;
    background-size: 100% 100%;
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: space-between;
`
const LeftContainer = styled.div`
    width: 100%;
    @media (min-width: 600px){
        width: 55%;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`
const Logo = styled.div`
    background: url(${logo}) no-repeat;
    background-size: 90% 70%;
    background-position: center;
    width: 320px;
    height: 145px;
    background-color: ${secundaryColor};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 15px 15px;
`
const FormContainer = styled.div`
    background-color: #ffffff;
    position: relative;
    flex: .7;
    width: 350px;
    padding: 20px;
    border-radius: 15px;
    max-height: 450px;

    @media(min-width: 1300) {
        width: 450px
    }
`
const FormUser = styled.form`
    background-color: ${primayColor};
    width: 100%;
    height: 100%;
    border-radius: 15px;
    padding: 40px;
    padding-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const IconoUsuario = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -20%;
    left: 28%;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    background-color: ${secundaryColor};
`

const ResetPass = styled.a`
    font-size: 12px;
    color: ${secundaryColor};
    text-align: center;
    margin: 10px 5px;

    :hover {cursor: pointer}
`
const RightContainer = styled.div`
    width: 40%;
    display: none;
    flex-direction: column;
    justify-content: center;
    
    @media (min-width: 600px){
        display: flex;
    }
`
const Headed = styled.div`
    flex: .65;
    display: flex;
    justify-content: center;
    flex-direction: column;
`
const TextLight = styled.span`
    font-size: 40px;
    color: #FFFFFF;
    font-weight: 300;
`
const TextBold = styled.span`
    font-size: 40px;
    color: #FFFFFF;
    font-weight: 600;
`
const Footer = styled.div`
    flex: .35;
    display: flex;
    justify-content: center;
    padding-right: 15%;
    text-align: justify;
    flex-direction: column;
`
const TextFooter = styled.p`
    color: ${iconColor};
    font-weight: 600;
    font-size: 14px;
`