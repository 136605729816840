import React from 'react'
import Scrollbars from 'react-custom-scrollbars'
import styled, { css } from 'styled-components'

import { secundaryColor, primayVariantColor, blackColor, iconColor, primayColor } from '../../../constanst'
import Table, { Tr, Th, Td } from '../../common/Table'
import { IconArrowLeft } from '../../../assets/icons'
import ButtonSubmit from '../../common/ButtonSubmit'
// import InputText from '../../common/InputText'
import { Loading } from '../../common/Loading'
import Button from '../../common/Button'
import { Form, FormContent } from '../../components/services/ComponentsForm'
import ModuleInput from '../../common/ModuleInput'

export default ({ state, onSubmit, onClickVal, onChange, onGoBack }) => 
<Main>
    {state.loading && <Loading /> }
    <Scrollbars>
        <Container>
            <ButtonContainer onClick={onGoBack}>
                <Button label="REGRESAR" icon={<IconArrowLeft width="15px" height="15px" color={blackColor} />}></Button>
            </ButtonContainer>

            <Form onSubmit={onSubmit} method="POST" id="formMot">
                <FormContent>
                    <ModuleContainer>
                        <Title>REGISTRO DE METAS</Title>

                        <Row>
                            <Box>
                                <ModuleInput label="VALOR" inputw="80%" name="valor" type="text"
                                    onChange={e => onChange(e, true, false, true, true, 1, 50)}
                                    value={state.valor || ''} 
                                    borderC={iconColor} backgroundC={secundaryColor} 
                                />
                            </Box>
                            <Box>
                                <ButtonSubmit width="30%" label="Guardar" bgColor={primayColor} color={secundaryColor} type="submit" />
                            </Box>
                        </Row>

                        <Table
                            renderHead={
                                <Tr bgColor={primayVariantColor} >
                                    <Th>#</Th>
                                    <Th>NOMBRE</Th>
                                    <Th>VALOR </Th>
                                </Tr>
                            }
                            renderBody={
                                !!state.valuesList.length ?
                                    state.valuesList.map( (x, i) =>
                                        <TrClick key={x.val_id} onClick={e => onClickVal(e, x.val_id, x.val_valor)} title="Presione para editar el valor">
                                            <Td>{i+1}</Td>
                                            <Td>{x.val_nombre}</Td>
                                            <Td>{format(x.val_valor)}</Td>
                                        </TrClick>
                                    )
                                    :
                                    <Tr>
                                        <Td colSpan="3">No se econtraron elementos</Td>
                                    </Tr>
                            }
                        />
                    
                    </ModuleContainer>
                    
                </FormContent>
            </Form>

    
        </Container>
        <br/>
        <br/>
    </Scrollbars>
</Main>

const format = value => value !== '' ? new Intl.NumberFormat('ja-JP').format(parseFloat(`${value}`.replace(/,/g, ""))) : 0

const Main = styled.main`
    flex: 1;
    display: flex;
    overflow: hidden;
    box-sizing: border-box;
`
const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: ${secundaryColor};
    width: 100%;
    margin-bottom: 30px;
    margin: 0px 2%;
    /* border-radius: 15px; */
    box-shadow: 0px 0px 6px 1px rgba(0,0,0,.4);
    @media (min-width: 1000px){
        width: 98%;
        margin-left: 1%;
    }
 `
const Title = styled.h3`
    color: ${secundaryColor};
    padding: 5px;
    margin: 0px;
    text-align: center;
    background-color: ${primayVariantColor};
` 
const ModuleContainer = styled.div`
    flex: 1;
    padding: 0 10px 0px 20px;
    display: inline-block;

    ${({ borderLeft }) => borderLeft && css`border-left: 1px solid ${primayColor};`}
`
const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
`
const Row = styled.div`
    padding: 20px 10px;
    display: flex;
    /* align-items: flex-start; */
    justify-content: center;
    width: 100%;
`
const Box = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
`
const TrClick = styled.tr`
    border-bottom: 1px solid ${iconColor}50;
    cursor: pointer;
    &:hover {
        background-color: ${iconColor}30;
    }
`