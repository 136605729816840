import { handleActions } from 'redux-actions'
import { GET_ULTIMO_CONSECUTIVO, GET_VEHICLE, GET_TYPE_SERVICE, GET_TYPE_SERVICE_WORK, GET_SEDES, GET_INVENTARIO, POST_REGISTER_SERVICE, GET_SERVICES_LIST, 
    GET_ORDEN_SERVICIO_ID, GET_REQUEST_SERVICE_ID, GET_CALLS_SERVICE_ID, GET_INVENTARIO_SERVICE_ID, GET_AUTH_ID, GET_SEDES_ID, GET_PLANILLA_DIARIA, 
    GET_GENERAR_PDF_CLIENTE, GET_GENERAR_PDF_TECNICO, GET_MAPA_TALLER, POST_REGISTRAR_MAPA_TALLER, GET_SERVICES_MAP, UPDATE_MAPA_TALLER, POST_CERRAR_ORDEN, 
    DELETE_SERVICE, DELETE_SOLUCION, GET_GENERAR_PDF_FECHA, UPDATE_REGISTER_GESTION_TALLER, GET_SERVICES_PENDING_LIST, GET_GENERAR_PDF_SERVICIO, GET_CLIENT_CEDULA, 
    GET_VEHICLE_MOTOR, GET_CALL_SERVICES_LIST, UPDATE_REMEMBER_CALL, UPDATE_TECNICO_ORDEN, DEL_TYPE_SERVICE, GET_TOKEN_SERVICE, SEND_EMAIL, GET_AGENDAMIENTO_CRM, GET_CRM_IDCLIENTE, GET_PLANILLA_INCOLMOTOS, SEND_EMAIL_CRM, GET_ALL_SEDES_ID, GET_AGENDAMIENTO_CONFIRMADOS, SET_SCHEDULING_CONFIRM,  GET_SCHEDULING_STATE
} from '../types'

/** Servicios Información */
export const serviceConstants = handleActions({ [GET_ULTIMO_CONSECUTIVO]: (state, action) => action.payload }, [])
export const typeServices = handleActions({ 
    [GET_TYPE_SERVICE]: (state, action) => action.payload,
    [DEL_TYPE_SERVICE]: (prevState, action) => {
        const state = prevState.filter(x => x.tser_id !== action.payload.id)
        return {...prevState, success: action.payload.success, data: state}
    }
}, [])
export const typeServicesWork = handleActions({ [GET_TYPE_SERVICE_WORK]: (state, action) => action.payload }, [])
export const inventario = handleActions({ [GET_INVENTARIO]: (state, action) => action.payload }, [])
export const servicesId = handleActions({ [POST_REGISTER_SERVICE]: (state, action) => action.payload }, [])
export const servicesList = handleActions({ 
    [GET_SERVICES_LIST]: (state, action) => action.payload,
    [GET_SERVICES_PENDING_LIST]: (state, action) => action.payload,
    [GET_CALL_SERVICES_LIST]: (state, action) => action.payload,
    [DELETE_SERVICE]: (state, action) => {

        state.data.find( (x, i) =>  {
            if (x !== undefined)
                if(x.ser_id === action.payload.idServicio) {
                    state.data.splice(i, 1)
                }
            return true
            })

        return state
    }
}, [])
export const servicesOrden = handleActions({[GET_ORDEN_SERVICIO_ID]: (state, action) => action.payload}, [])
export const servicesRequestList = handleActions({ 
    [GET_REQUEST_SERVICE_ID]: (state, action) => action.payload,
    [DELETE_SOLUCION]: (state, action) => {
        
        state.data.find( (x, i) =>  {
            if (x !== undefined)
                if(x.idobs === action.payload.idSol) {
                    return state.data.splice(i, 1)
                }
        
            return false
        })

        return state
    }
}, [])
export const servicesCallsList = handleActions({ [GET_CALLS_SERVICE_ID]: (state, action) => action.payload,
    [DELETE_SOLUCION]: (state, action) => {
        
        state.data.find( (x, i) =>  {
            if (x !== undefined)
                if(x.llamid === action.payload.idLlam) {
                    return state.data.splice(i, 1)
                } 
                  
                return false
            })

        return state
    }
}, [])
export const inventarioList = handleActions({ [GET_INVENTARIO_SERVICE_ID]: (state, action) => action.payload }, [])
export const authId = handleActions({ [GET_AUTH_ID]: (state, action) => action.payload }, [])
export const mapaTaller = handleActions({ [GET_MAPA_TALLER]: (state, action) => action.payload }, [])
export const serviciosMapaTaller = handleActions({ [GET_SERVICES_MAP]: (state, action) => action.payload }, [])
export const clientList = handleActions({ [GET_CLIENT_CEDULA]: (state, action) => action.payload }, [])
export const serviceToken = handleActions({ [GET_TOKEN_SERVICE]: (state, action) => action.payload }, [])
export const schedulingList = handleActions({ 
    [GET_AGENDAMIENTO_CRM]: (state, action) => action.payload,
    [GET_CRM_IDCLIENTE]: (state, action) => action.payload,
}, [])
export const schedulingConfirm = handleActions({ 
    [GET_AGENDAMIENTO_CONFIRMADOS]: (state, action) => action.payload,
}, {})

/** Respuesta a los metodos post */
export const postResponse = handleActions({
    [GET_PLANILLA_DIARIA]: (state, action) => action.payload,
    [GET_GENERAR_PDF_CLIENTE]: (state, action) => action.payload,
    [GET_GENERAR_PDF_TECNICO]: (state, action) => action.payload,
    [POST_REGISTRAR_MAPA_TALLER]: (state, action) => action.payload,
    [UPDATE_MAPA_TALLER]: (state, action) => action.payload,
    [POST_CERRAR_ORDEN]: (state, action) => action.payload,
    [DELETE_SERVICE]: (state, action) => action.payload,
    [DELETE_SOLUCION]: (state, action) => action.payload,
    [GET_GENERAR_PDF_FECHA]: (state, action) => action.payload,
    [UPDATE_REGISTER_GESTION_TALLER]: (state, action) => action.payload,
    [GET_GENERAR_PDF_SERVICIO]: (state, action) => action.payload,
    [UPDATE_REMEMBER_CALL]: (state, action) => action.payload,
    [UPDATE_TECNICO_ORDEN]: (state, action) => action.payload,
    [SEND_EMAIL]: (state, action) => action.payload,
    [SEND_EMAIL_CRM]: (state, action) => action.payload,
    [SET_SCHEDULING_CONFIRM]: (state, action) => action.payload,
}, [])

/** Información de las sedes */
export const sedes = handleActions({ 
    [GET_SEDES]: (state, action) => action.payload,
    [GET_SEDES_ID]: (state, action) => action.payload,
    [GET_ALL_SEDES_ID]: (state, action) => action.payload,
}, [])
export const plIncol = handleActions({
    [GET_PLANILLA_INCOLMOTOS]: (state, action) => action.payload
}, [])
export const vehicles = handleActions({ [GET_VEHICLE]: (state, action) => action.payload}, [])
export const vehiclesList = handleActions({[GET_VEHICLE_MOTOR]: (state, action) => action.payload}, [])

/** Cambiar el estado de los agentes */
export const agen = handleActions({ 
    [GET_SCHEDULING_STATE]: (state, action) => action.payload
}, [])