import { combineReducers } from 'redux'
import { users, tecnicos, postResponseUsers, typeUsers, typeDocument, clients } from "./users"
import { motorcycles, soatList, postResponseParam, valuesVehicles, typeServicesWorks, valuesList } from "./Parameterizations"
import { serviceConstants, vehicles, typeServices, typeServicesWork, sedes, inventario, servicesId, servicesList, servicesOrden, servicesRequestList, postResponse,
    servicesCallsList, inventarioList, authId, mapaTaller, serviciosMapaTaller, clientList, vehiclesList, serviceToken, schedulingList, plIncol, schedulingConfirm, agen } from "./service-orders"
import {inMotosMensuales, facturaTecnicos, valVenTSer, postResponseMT} from './mapa-taller'
import {worksList} from './works'
import { curriculumVitae, reviewsVitae, identityVitae, warrantyVitae } from './curriculumVitae'
import { cotizationsList } from './cotizations'

export default combineReducers({
    authId,
    inventario,
    inventarioList,
    mapaTaller,
    sedes,
    servicesCallsList,
    serviceConstants,
    servicesId,
    servicesList,
    servicesOrden,
    serviciosMapaTaller,
    servicesRequestList,
    postResponse,
    postResponseUsers,
    tecnicos,
    clients,
    typeDocument,
    typeServices,
    typeServicesWork,
    typeServicesWorks,
    typeUsers,
    users,
    vehicles,
    vehiclesList,
    clientList,
    serviceToken,
    plIncol,
    /** CRM - AGENDAMIENTO */
    schedulingList,
    schedulingConfirm,
    agen,

    /** Woks */
    worksList,
    /** Curriculum vitae */
    curriculumVitae, identityVitae,
    warrantyVitae, reviewsVitae,
    
    /** Parametrización */
    motorcycles,
    soatList,
    postResponseParam,
    valuesVehicles,
    valuesList,

    /** mapa de taller  */
    facturaTecnicos,
    inMotosMensuales,
    valVenTSer,
    postResponseMT,

    /** Cotizaciones */
    cotizationsList
})