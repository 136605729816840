import React from 'react'
import styled from 'styled-components'
import Loadingimg from "../../assets/imagenes/loading.gif";

export const Loading = () => 
    <Container>
        <ContainerImage>
            <Image src={Loadingimg} />
        </ContainerImage>
    </Container>


const Container = styled.div`
    position: absolute;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,.25);
`

const ContainerImage = styled.div`
    width: 22%;
    height: 22%;
    background: linear-gradient(transparent, #57575678);
    border-radius: 8px;
`

const Image = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 25px;
`