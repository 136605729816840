import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'
import {Table} from 'react-virtualized'
import { MenuItem } from '@material-ui/core'
import moment from 'moment'
import 'react-virtualized/styles.css'

import CustomSelect from '../../common/CustomSelect'
import {ButtonsContainer, ButtonIcon} from '../../common/Table'
import { bgVariantColor, secundaryColor, primayVariantColor, iconColor, iconVariantColor, blackColor, errorColor, alternateColor, alternateVariantColor, successColor} from "../../../constanst"
import InputText from '../../common/InputText'
import ButtonSubmit from '../../common/ButtonSubmit'
import Button from '../../common/Button'
import { IconSearch, IconArrow, IconSlope, IconPrint, IconPrint2, IconDelete, IconEdit, IconPlus, IconCheck, IconSee, IconWorkshopMap, IconReport, IconTec, IconHourglass2, IconClient } from '../../../assets/icons'
import Scrollbars from 'react-custom-scrollbars'
import { Loading } from '../../common/Loading'
import ModalImprimir from '../../common/ModalImprimir'
import { Tooltip, TooltipText, Box } from './ComponentsForm'
import MultipleSelect from '../../common/MultipleSelect'
import { PDFDownloadLink } from '@react-pdf/renderer'
import RepIncol from '../../print/RepIncol'

export default ({ state, servicesList, onClickPage, onClickArrow, onChange, onChangeDate, onClickFindDate, onClickPlanDiaria, 
    onClickModal, openModal, onDelete, onPrint, onChangeFilterPD, onFilter, onGeneratePla, onCloseModal, onKeyPressFilter, onChangeSel }) => 
    <Fragment>
    <Main>
        {state.loading && <Loading />}
        {state.modalImp && <ModalImprimir onClickModal={onClickModal} onCloseModal={openModal} typeUser={state.typeUser} state={state} />}
        {/** Modal para las llamadas y recordatorios */}
        { state.modalPla &&
            <ModalOptions>
                <ButtonClose onClick={onCloseModal}>cerrar</ButtonClose> 
                <PDFDownloadLink style={{ textDecoration: 'none' }} document={<RepIncol state={state} />} fileName={`Reporte Incolmotos - ${moment().format("DD-MM-YYYY")}.pdf`}>
                    <BoxOption>
                        <IconReport width="85px" height="85px" color={primayVariantColor} />
                        <br/>
                        <LabelOption>Reporte Incolmotos</LabelOption>
                    </BoxOption>
                </PDFDownloadLink>
                <BoxOption onClick={e => onClickPlanDiaria(e, state.userdata.dateF ? 'other' : 'today')}>
                    <IconReport width="85px" height="85px" color={primayVariantColor} />
                    <br/>
                    <LabelOption>Planilla Diaria</LabelOption>
                </BoxOption>
            </ModalOptions>
        }
    
        <Scrollbars>
            <Container>
                <Title color={ secundaryColor }>SERVICIOS</Title>

                {/** panel de busqueda */}
                <NavSearch>
                    <DivisorContainer borderC={iconColor} direction="column">
                        <Row>
                            <InputContainer>
                                <InputText icon={ <IconSearch width="20px" height="20px" color={blackColor} /> } 
                                    placeholder="Identificación" name="clit_identificacion" placeholderColor={iconColor}
                                    backgroundC={secundaryColor} textColor={iconColor} borderC={iconColor}
                                    onChange={onChange} onSearch={onFilter} value={state.clit_identificacion} onKeyPress={onKeyPressFilter}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputText icon={ <IconSearch width="20px" height="20px" color={blackColor} /> } 
                                    placeholder="Consecutivo" name="ser_consecutivo" placeholderColor={iconColor}borderC={iconColor}
                                    backgroundC={secundaryColor} textColor={iconColor} 
                                    onChange={onChange} onSearch={onFilter} onKeyPress={onKeyPressFilter} value={state.ser_consecutivo}
                                />
                            </InputContainer>
                            <InputContainer>
                                <CustomSelect inputw="100%" name="ser_estado" onChange={onChangeSel} value={state.ser_estado} disabled={state.selectDisabled} load={state.selectDisabled}>
                                    <MenuItem value="all">Todos</MenuItem>
                                    <MenuItem value="0">Pendientes</MenuItem>
                                    <MenuItem value="2">Cerrados</MenuItem>
                                </CustomSelect>
                            </InputContainer>
                        </Row>
                        <Row>
                            <InputContainer>
                                <InputText icon={ <IconSearch width="20px" height="20px" color={blackColor} /> } 
                                    placeholder="Nombre del cliente" name="clit_nombre" placeholderColor={iconColor}
                                    backgroundC={secundaryColor} textColor={iconColor} borderC={iconColor}
                                    onChange={onChange} onSearch={onFilter} onKeyPress={onKeyPressFilter} value={state.clit_nombre}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputText icon={ <IconSearch width="20px" height="20px" color={blackColor} /> } 
                                    placeholder="N° de placa" name="ds_placa" placeholderColor={iconColor}borderC={iconColor}
                                    backgroundC={secundaryColor} textColor={iconColor} 
                                    onChange={onChange} onSearch={onFilter} onKeyPress={onKeyPressFilter} value={state.ds_placa}
                                />
                            </InputContainer>
                            <InputContainer>
                                <CustomSelect inputw="100%" name="usu_sede" onChange={onChangeSel} value={state.usu_sede} title='Sede'>
                                    {state.sedesList?.map(x =>  <MenuItem key={x.sed_id} value={x.sed_id}>{x.sed_nombre}</MenuItem>)}
                                </CustomSelect>
                            </InputContainer>
                        </Row>
                    </DivisorContainer>
                    <DivisorContainer>
                        <InputContainer>
                            <InputText 
                                type="date" name="dateStart" placeholderColor={iconColor} onChange={onChangeDate}
                                backgroundC={secundaryColor} textColor={iconColor} borderC={iconColor} value={state.userdata.dateStart}
                            />
                        </InputContainer>
                        <InputContainer>
                            <InputText
                                type="date" name="dateFinal" placeholderColor={iconColor} onChange={onChangeDate}
                                backgroundC={secundaryColor} textColor={iconColor} borderC={iconColor} value={state.userdata.dateFinal}
                            />
                        </InputContainer>
                        <InputContainer align="flex-end">
                            <ButtonSubmit label="Buscar por fecha" bgColor={successColor} color={secundaryColor} onClick={onClickFindDate} type="button" />
                        </InputContainer>
                    </DivisorContainer>
                    
                    {/** Planilla diaria, menú oculto */}
                    <PlanillaDiaria id="menuOculto">
                        <DivisorContainer direction="column" justify={state.typeUser && state.typeUser === "1" && 'flex-start'}>
                            <Box direction="column" width="100%" align="flex-start">
                                <Label alignSelf="center">FILTROS PLANILLA DIARIA</Label>
                                <Box direction="row" width="100%">
                                    {state.typeUser && state.typeUser === "1" && 
                                        <CustomSelect inputw="150px" name="selSede" onChange={onChangeFilterPD} value={state.selSede} title="SEDE">
                                            {!!state.sedesList.length &&
                                                state.sedesList.map(x => (
                                                    <MenuItem key={x.sed_id} value={x.sed_id}>{x.sed_nombre}</MenuItem>
                                                ))
                                            }
                                        </CustomSelect>
                                    }
                                    <CustomSelect inputw="150px" name="selTser" onChange={e => onChangeFilterPD(e, 'query')} value={state.userdata.selTser} title="SERVICIO">
                                        {!!state.tser.length &&
                                            state.tser.map(x => (
                                                <MenuItem key={x.tser_id} value={x.tser_id}>{x.tser_nombre}</MenuItem>
                                            ))
                                        }
                                    </CustomSelect>
                                    <MultipleSelect inputw="150px" name="selTserW" onChange={onChangeDate} value={state.userdata.selTserW} title="TRABAJO">
                                        {!!state.tserWork.length &&
                                            state.tserWork.map(x => (
                                                <MenuItem key={x.tg_id} value={x.tg_id}>{x.tg_nombre}</MenuItem>
                                            ))
                                        }
                                    </MultipleSelect>
                                </Box>
                                <Box direction="row" width="300px">
                                    <InputText
                                        type="date" name="date" value={state.userdata.date || ''} placeholderColor={iconColor} onChange={onChangeDate}
                                        backgroundC={secundaryColor} textColor={iconColor} borderC={iconColor} width="50%" margin="2px 8px"
                                    />
                                    <InputText
                                        type="date" name="dateF" value={state.userdata.dateF || ''} placeholderColor={iconColor} onChange={onChangeDate}
                                        backgroundC={secundaryColor} textColor={iconColor} borderC={iconColor} width="50%" margin="2px 8px"
                                    />
                                </Box>
                                
                                </Box>
                        </DivisorContainer>
                        <DivisorContainer>
                            <DivisorContainer direction="column">
                                <Button label="Generar planillas" type="button" bgColor={primayVariantColor} color={secundaryColor} marginV="5px" onClick={onGeneratePla} icon={<IconSee width="15px" height="15px" color={secundaryColor} />} />
                            </DivisorContainer>
                            <DivisorContainer>
                                <InputText
                                    type="date" name="date" placeholderColor={iconColor} onChange={onChangeDate}
                                    backgroundC={secundaryColor} textColor={iconColor} borderC={iconColor}
                                />
                                <Button label="Buscar" type="button" bgColor={alternateVariantColor} color={secundaryColor} onClick={e => onClickPlanDiaria(e, 'other')} icon={<IconSee width="15px" height="15px" color={secundaryColor} />} />
                            </DivisorContainer>
                        </DivisorContainer>

                    </PlanillaDiaria>
                    <Arrow type="button" onClick={onClickArrow}>
                        <IconArrow width="20px" height="20px" color={iconColor} />
                    </Arrow>
                </NavSearch>


                <ButtonsContainer>
                    <Button label="Nueva Orden" type="button" bgColor={successColor} color={secundaryColor} icon={<IconPlus width="15px" height="15px" color={secundaryColor} />} onClick={onClickPage} />
                    <Button label="Imprimir" type="button" bgColor={primayVariantColor} color={secundaryColor} icon={<IconPrint2 width="20px" height="20px" bgColor="transparent" color={secundaryColor} />} onClick={onPrint} />
                </ButtonsContainer>
                <Table
                    style={{ width: '100%'}}
                    autoContainerWidth
                    gridStyle={{width: '100%'}}
                    height={600}
                    headerHeight={40}
                    width={1850}
                    rowHeight={70}
                    containerStyle={{width: '100%'}}
                    rowCount={servicesList.length}
                    rowGetter={({index}) => servicesList[index]}
                    rowStyle={{width: '100%'}}
                    headerRowRenderer={() => 
                        <Tr className="ReactVirtualized__Table__headerRow" bgColor style={{width: servicesList.length > 8 ? '97%' : '100%'}}>
                            <Th elipsis width={4}  >#</Th>
                            <Th elipsis width={6}  title="consecutivo">Consecutivo</Th>
                            <Th elipsis width={12} title="Modelo de Moto">Moto</Th>
                            <Th elipsis width={8} title="Número de Placa">Placa</Th>
                            <Th elipsis width={15} title="Trabajo Realizado">Trabajo</Th>
                            <Th elipsis width={15} title="Nombre del Cliente">Nombre</Th>
                            <Th elipsis width={9}  title="N° de Teléfono">Teléfono</Th>
                            <Th elipsis width={9}  title="N° de Documento">Documento</Th>
                            <Th elipsis width={5}  title="Estado del Servicio">Estado</Th>
                            <Th elipsis width={8} title="Fecha del Servicio">Fecha</Th>
                            <Th elipsis width={9}  title="Acciones">Acciones</Th>
                        </Tr>
                    }
                    rowRenderer={e => 
                        <Tr className="ReactVirtualized__Table__row" key={e.key} style={e.style} >
                            <Td elipsis width={4} >{e.index + 1}</Td>
                            <Td elipsis width={6} title={e.rowData.ser_consecutivo}>{e.rowData.ser_consecutivo}</Td>
                            <Td elipsis width={12} title={e.rowData.rm_nombremodelo}>{e.rowData.rm_nombremodelo}</Td>
                            <Td elipsis width={8} title={e.rowData.ds_placa}>{e.rowData.ds_placa}</Td>
                            <Td elipsis width={15} title={!!e.rowData.ds_otrostrabajos ? e.rowData.ds_otrostrabajos : e.rowData.tg_nombre}>{!!e.rowData.ds_otrostrabajos ? e.rowData.ds_otrostrabajos : e.rowData.tg_nombre}</Td>
                            <Td elipsis width={15} title={`${e.rowData.clit_nombre} ${e.rowData.clit_apellido}`}>{e.rowData.clit_nombre} {e.rowData.clit_apellido}</Td>
                            <Td elipsis width={9} title={e.rowData.clit_celular}>{e.rowData.clit_celular}</Td>
                            <Td elipsis width={9} title={e.rowData.clit_identificacion}>{e.rowData.clit_identificacion}</Td>
                            <Td width={5} elipsis={false}>
                            {e.rowData.ser_estado === "0" &&
                                <Tooltip className="tooltip">
                                    <IconClient width="20px" height="20px" color={iconColor}/>
                                    <TooltipText className="tooltiptext" bgColor={iconColor}>En Bandeja del Técnico</TooltipText>
                                </Tooltip>
                                }{e.rowData.ser_estado === "2" &&
                                <Tooltip className="tooltip">
                                    <IconCheck width="20px" height="20px" color={successColor}/>
                                    <TooltipText className="tooltiptext" bgColor={successColor}>Confirmado</TooltipText>
                                </Tooltip>
                                }{e.rowData.ser_estado === "3" &&
                                <Tooltip className="tooltip">
                                    <IconTec width="20px" height="20px" color={primayVariantColor}/>
                                    <TooltipText className="tooltiptext" bgColor={primayVariantColor}>En taller</TooltipText>
                                </Tooltip>
                                }{e.rowData.ser_estado === "4" &&
                                <Tooltip className="tooltip">
                                    <IconHourglass2 width="20px" height="20px" color={primayVariantColor}/>
                                    <TooltipText className="tooltiptext" bgColor={primayVariantColor}>Suspendido</TooltipText>
                                </Tooltip>}
                                {e.rowData.ser_estado === "5" &&
                                <Tooltip className="tooltip">
                                    <IconSlope width="20px" height="20px" color={alternateColor}/>
                                    <TooltipText className="tooltiptext" bgColor={alternateColor}>Espera de Cierre</TooltipText>
                                </Tooltip>}
                            </Td>
                            <Td elipsis width={8} title={e.rowData.fecha}>{e.rowData.fecha}</Td>
                            <Td width={9} elipsis={false}>{
                            <>
                                <Row>
                                    <Tooltip className="tooltip">
                                        <ButtonIcon type="button" onClick={x => onClickPage(x, e.rowData.ser_id)}>
                                            <IconEdit width="30px" height="20px" color={secundaryColor} bgColor={alternateVariantColor} />
                                        </ButtonIcon>
                                        <TooltipText className="tooltiptext" bgColor={alternateVariantColor}>Editar</TooltipText>
                                    </Tooltip>
                                    <Tooltip className="tooltip">
                                        <ButtonIcon type="button" onClick={x => openModal(x, e.rowData.ser_id)}>
                                            <IconPrint width="30px" height="20px" color={secundaryColor} bgColor={iconColor} />
                                        </ButtonIcon>
                                        <TooltipText className="tooltiptext" bgColor={iconColor}>Imprimir</TooltipText>
                                    </Tooltip>
                                </Row>
                                <Row>
                                    {e.rowData.ser_estado === '5' &&
                                    <Tooltip className="tooltip">
                                        <ButtonIcon type="button" onClick={x => onClickPage(x, e.rowData.ser_id, true)}>
                                            <IconWorkshopMap width="30px" height="20px" color={secundaryColor} bgColor={successColor} />
                                        </ButtonIcon>
                                        <TooltipText className="tooltiptext" bgColor={successColor}>Facturar Mapa Taller</TooltipText>
                                    </Tooltip>
                                    }{e.rowData.ser_estado === '0' &&
                                    <Tooltip className="tooltip">
                                        <ButtonIcon type="button" onClick={x => onDelete(x, e.rowData.ser_id)}>
                                            <IconDelete width="30px" height="20px" color={secundaryColor} bgColor={errorColor} />
                                        </ButtonIcon>
                                        <TooltipText className="tooltiptext" bgColor={errorColor}>Eliminar</TooltipText>
                                    </Tooltip>
                                    }
                                </Row>
                            </>
                            }</Td>
                        </Tr>
                    }
                    >
                </Table>
            </Container>
        </Scrollbars>
    </Main>
</Fragment>

const Main = styled.main`
    flex: 1;
    display: flex;
    overflow: hidden;
    padding: 0px;
    position: relative;
`
const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: ${bgVariantColor};
    width: 100%;
    margin-bottom: 30px;
    /* border-radius: 15px; */
    box-shadow: 0px 0px 6px 1px rgba(0,0,0,.4);
    @media (min-width: 1000px){
        width: 98%;
        margin-left: 1%;
    }
 `
 //color original ${blackColor}
const Title = styled.h3`
    color: ${({ color }) => color ? color : `${blackColor}`};
    padding: 5px;
    margin: 0px;
    text-align: center;
    background-color: ${primayVariantColor};
`
const NavSearch = styled.nav`
    position: relative;
    background-color: ${iconVariantColor};
    display: flex;
    justify-content: center;   
`
const DivisorContainer = styled.div`
    width: 50%;
    display: flex;
    height: 100%;
    justify-content: ${({justify}) => justify ? justify : 'center'};
    align-items: center;
    ${({ borderC }) => borderC && css`border-right: 1px solid ${borderC};`}
    padding: 10px 5px 20px 5px;
    ${({direction}) => direction && css`flex-direction: ${direction};` }
`
const InputContainer = styled.div`
    width: 33.3%;
    margin: 0px 5px;
    margin-bottom: 20px;
    display: flex;
    ${({align}) => align && css`justify-content: ${align};`}
`
const Arrow = styled.button`
    position: absolute;
    z-index: 1;
    bottom: -10px;
    border: 1px solid ${iconColor};
    outline: none;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${secundaryColor};

    &:hover {cursor: pointer;}
`
/** Planilla diaria, menu oculto */
const PlanillaDiaria = styled.div`
    background-color: ${secundaryColor};
    height: 0px;
    position: absolute;
    z-index: 1;
    bottom: 0px;
    width: 100%;
    overflow: hidden;
    transition: .5s;
    border-bottom: 1px solid ${iconColor};
    border-top: 1px solid ${iconColor};
    display: flex;
    justify-content: space-between;
    align-items: center;

    box-shadow: 1px 5px 8px 1px rgba(0,0,0,.25);
`
const Row = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
`
const Label = styled.label`
    color: ${blackColor};
    font-size: 14px;
    font-weight: 500;
    display: block;
    ${({alignSelf}) => alignSelf && css`align-self: ${alignSelf};`}
`

/** Modal con las opciones */
const ModalOptions = styled.div`
    display: flex;
    flex: 1;
    position: absolute;
    z-index: 999;
    top: 0px;
    left: 0px;
    background-color: #efefefef;
    width: 100%;
    height: 100%;
    padding: 5%;
    align-items: center;
    justify-content: center;
    flex-direction: row;
`
const ButtonClose = styled.button`
    font-size: 16px;
    position: absolute;
    color: ${iconColor};
    border: 1px solid ${iconColor};
    border-radius: 5px;
    background-color: ${iconVariantColor};
    padding: 5px 15px;
    top: 15px;
    right: 25px;
    cursor: pointer;
`
const BoxOption = styled.button`
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid ${iconColor};
    border-radius: 8px;
    padding: 8px;
    margin-right: 15px;
    width: 250px;
    cursor: pointer;
`
const LabelOption = styled.label`
    background-color: ${primayVariantColor};
    width: 85%;
    text-align: center;
    border-radius: 15px;
    color: ${secundaryColor};
    padding: 5px;
`

/** Constants export for use from other components */
const Tr = styled.div`
    display: flex;
    ${({ bgColor }) => bgColor && css`background-color: ${primayVariantColor}50;`}
    border-bottom: 1px solid ${iconColor}50;
`
const Th = styled.div`
    font-weight: 500;
    font-size: 12px;
    color: ${primayVariantColor};
    padding: 5px;
    text-align: center;
    border-left: 1px solid ${blackColor}80;
    ${({ width }) => width && css`width: ${width}%;`}
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:first-child {border-left: 0px;}
`
const Td = styled.div`
    text-align: center;
    font-size: 12px;
    color: ${blackColor};
    padding: 5px;
    ${({elipsis}) => elipsis && css`
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    `}
    ${({ width }) => width && css`width: ${width}%;`}
`