import { handleActions } from 'redux-actions'
import { GET_COTIZATIONS_LIST } from '../types'
import { POST_CURRICULUM_VITAE } from '../types'

export const cotizationList = handleActions({ 
    [GET_COTIZATIONS_LIST]: (state, action) => ({...state, ...action.payload}),
}, {})

export const cotizationsList = handleActions({ 
    [POST_CURRICULUM_VITAE]: (state, action) => ({...state, ...action.payload}),
}, {})
