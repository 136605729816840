import { url_base, url_ocrend } from "./urls"
import axios from 'axios'

/** Obteniendo lista de motocicletas */
export const apiGetMotorcycles = () => () => axios.get(`${url_base}/referenciamoto/modelosdemotos`).then(v => {
    if(v.data.length>0)
        return { success: 1, data: v.data}
    else
        return {success: 0, message:"No se encontraron datos"}
}).catch(e => ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))

/** Obteniendo lista de motocicletas por id */
export const apiGetMotorcyclesId = (id) => () => axios.get(`${url_base}/vehiculo/inventariovehiculo/${id}`).then(v => {
    let inventarioform = {}, invData = []

    const motform = {
        nombre: v.data[0].rm_nombremodelo,
        idmoto: v.data[0].rm_id,
        cilindraje: v.data[0].rm_cilindraje
    }
    v.data.map( x => {
        inventarioform = { ...inventarioform, [x.invm_iditem]: x.invm_iditem }
        return inventarioform
    })
    
    invData = v.data.map( x => ({
            check: x.invm_iditem,
            [x.invm_iditem]: x.invm_iditem,
            estado: x.invm_estado,
            idinvm: x.invm_id
        })
    )

    if(v.data.length > 0)
        return { success: 1, data: {motform, inventarioform, invData}}
    else
        return {success: 0, message:"No se encontraron datos"}
}).catch(e => ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))

/** Registrando motocicletas */
export const apiPostMotorcycles = (data) => () => axios.post(`${url_base}/vehiculos/registrarvehiculo`, data).then(v => {
    if(v.data > 0)
        return { success: 1, data: v.data}
    else
        return {success: 0, message:"No se encontraron datos"}
}).catch(e => ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))

/** Actualizando motocicletas */
export const apiUpMotorcycles = (data) => async () => {
    
    const form = new FormData()
    form.append('cilindraje', data.cilindraje)
    form.append('nombre', data.nombre)
    form.append('idmoto', data.idmoto)
    form.append('idsoat', data.idsoat)

    return axios.post(`${url_ocrend}/motos/actualizar`, form).then(v => {
        if(v.data.success === "1" || v.data.success === 1)
            return { success: 1, data: v.data}
        else
            return {success: 0, message:"Ha ocurrido un error"}
    }).catch(e => ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))
}
/** Actualizando inventario motocicletas */
export const apiUpInvMotorcycles = (data) => async () => {
    
    const form = new FormData()
    form.append('cant', data.cant)
    form.append('idmoto', data.idmot)
    form.append('iditem', data.iditem)
    form.append('idestado', data.idestado)

    return axios.post(`${url_ocrend}/motos/actualizarinvm`, form).then(v => {
        if(v.data.success === "1" || v.data.success === 1)
            return { success: 1, data: v.data}
        else
            return {success: 0, message:"Ha ocurrido un error"}
    }).catch(e => ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))
}
/** Registrando inventario de la motocicleta */
export const apiPostInvMotorcycles = (data) => () => axios.post(`${url_base}/vehiculos/registrarinventariovehiculo`, data).then(v => {
    if(v.data === '1' || v.data === 1)
        return { success: 1, data: v.data}
    else
        return {success: 0, message:"No se encontraron datos"}
}).catch(e => ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))

/** Eliminando motocicletas */
export const apiDelMotorcycles = (id) => () => axios.get(`${url_base}/vehiculo/eliminarmot//${id}`).then(v => {
    if(v.data.length>0)
        return { success: 1, data: v.data, id}
    else
        return {success: 0, message:"No se encontraron datos"}
}).catch(e => ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))

/** obteniendo lista de inventario para motocicletas */
export const apiGetInvMotorcycles = () => () => axios.get(`${url_base}/vehiculo/inventariovehiculo`).then(v => {
    if(v.data.length>0)
        return { success: 1, data: v.data}
    else
        return {success: 0, message:"No se encontraron datos"}
}).catch(e => ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))

/** obteniendo lista de VALORES */
export const apiGetValuesVehicles = () => () => axios.get(`${url_base}/vehiculos/listavehiculos`).then(v => {
    if(v.data.length>0)
        return { success: 1, data: v.data}
    else
        return {success: 0, message:"No se encontraron datos"}
}).catch(e => ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))

/** obteniendo lista de SOAT */
export const apiGetSoatList = () => () => axios.get(`${url_base}/servicio/soat`).then(v => {
    if(v.data.length>0)
        return { success: 1, data: v.data}
    else
        return {success: 0, message:"No se encontraron datos"}
}).catch(e => ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))

/** obteniendo valor por modelo de moto */
export const apiDelValMotorcycle = (data) => () => axios.get(`${url_base}/vehiculos/eliminar/${data}`).then(v => {
    if(v.data  === '1' || v.data  === 1)
        return { success: 1, id: data}
    else
        return {success: 0, message:"No se encontraron datos"}
}).catch(e => ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))

/** Registrando valores para motocicleta */
export const apiPostValMotorcycles = (data) => () => {
    let form = new FormData()
    form.append('idmoto', data.formvehiculo)
    form.append('valor', data.formvalor)
    form.append('idUsuario', data.idUser)

    return axios.post(`${url_ocrend}/motos/newvalue`, form).then(v => {
    if(v.data.success === '1' || v.data.success === 1)
        return { success: 1, data: v.data, id: v.data.id}
    else
        return {success: 0, message:"No se encontraron datos"}
}).catch(e => ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))}

/** Actualizando valores para motocicleta */
export const apiUpValVehicles = (data) => () => {
    let form = new FormData()
    form.append('idval', data.idval)
    form.append('idmoto', data.formvehiculo)
    form.append('valor', data.formvalor)
    form.append('idUsuario', data.idUser)

    return axios.post(`${url_ocrend}/motos/editvalue`, form).then(v => {
    if(v.data.success === '1' || v.data.success === 1)
        return { success: 1, data: v.data, id: v.data.id}
    else
        return {success: 0, message:"No se encontraron datos"}
}).catch(() => ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))}

/** ----------Tipos de servicio y trabajos-------------- */
/** Eliminar tipo de servicio */
export const apiDelTSer = (id) => () => axios.get(`${url_base}/servicio/tiposervicio/eliminar/${id}`).then(v => {
    if(v.data === 1 || v.data === '1')
        return { success:  1, message: 'Operación exitosa', id }
    else 
        return{ success: 0, message: 'Ha ocurrido un error' }
}).catch(() => ({success: 0, message:"Ha ocurrido un error en la petición"}))

/** Actualizar tipo de servicio */
export const apiUpTSer = (data) => () => axios.post(`${url_base}/servicio/tiposervicio/actualizar`, data).then(v => {
    if(v.data === 1 || v.data === '1')
        return { success:  1, message: 'Operación exitosa' }
    else 
        return{ success: 0, message: 'Ha ocurrido un error' }
}).catch(() => ({success: 0, message:"Ha ocurrido un error en la petición"}))

/** Registrar tipo de servicio */
export const apiPostTSer = (data) => () => axios.post(`${url_base}/servicio/tiposervicio/registrar`, data).then(v => {
    if(v.data === 1 || v.data === '1')
        return { success:  1, message: 'Operación exitosa' }
    else 
        return{ success: 0, message: 'Ha ocurrido un error' }
}).catch(() => ({success: 0, message:"Ha ocurrido un error en la petición"}))

/** Obtener trabajos de tipo de servicio */
export const apiGetTSerWorks = () => () => axios.get(`${url_base}/servicio/tiposerviciotrabajo`).then(v => {
    if(v.data.length > 0)
        return { success:  1, data: v.data, message: 'Operación exitosa' }
    else 
        return{ success: 0, message: 'Ha ocurrido un error' }
}).catch(() => ({success: 0, message:"Ha ocurrido un error en la petición"}))

/** Eliminar trabajos de tipo de servicio */
export const apiDelWorks = (id) => () => axios.get(`${url_base}/servicio/trabajogarantia/eliminar/${id}`).then(v => {
    if(v.data === 1 || v.data === '1')
        return { success:  1, id, message: 'Operación exitosa' }
    else 
        return{ success: 0, message: 'Ha ocurrido un error' }
}).catch(() => ({success: 0, message:"Ha ocurrido un error en la petición"}))

/** Actualizar trabajos de tipo de servicio */
export const apiUpWorks = (values) => () => axios.post(`${url_base}/servicio/trabajo/actualizar`, values).then(v => {
    if(v.data === 1 || v.data === '1')
        return { success:  1, message: 'Operación exitosa' }
    else 
        return{ success: 0, message: 'Ha ocurrido un error' }
}).catch(() => ({success: 0, message:"Ha ocurrido un error en la petición"}))

/** Registrando trabajos de tipo de servicio */
export const apiPostWorks = (values) => () => axios.post(`${url_base}/servicio/trabajo/registrar`, values).then(v => {
    if(v.data === 1 || v.data === '1')
        return { success:  1, message: 'Operación exitosa' }
    else 
        return{ success: 0, message: 'Ha ocurrido un error' }
}).catch(() => ({success: 0, message:"Ha ocurrido un error en la petición"}))

/** Obteniendo Valores Fijos Parametrizables */
export const apiGetValues = () => () => axios.get(`${url_ocrend}/valoresfijos`).then(v => {
    if(v.data.success === 1 || v.data.success === '1')
        return { success:  1, data: v.data.data, message: 'Operación exitosa' }
    else 
        return{ success: 0, message: 'Ha ocurrido un error' }
}).catch(() => ({success: 0, message:"Ha ocurrido un error en la petición"}))

/** Actualizando Valores Fijos Parametrizables */
export const apiUpValues = (values) => () => {
    const form = new FormData()
    form.set('id', values.id)
    form.set('valor', values.valor)

    return axios.post(`${url_ocrend}/valoresfijos/actualizar`, form).then(v => {
        if(v.data.success === 1 || v.data.success === '1')
            return { success:  1, data: v.data.data, message: 'Operación exitosa' }
        else 
            return{ success: 0, message: 'Ha ocurrido un error' }
    }).catch(() => ({success: 0, message:"Ha ocurrido un error en la petición"}))
}

/** Obteniendo sedes por id */
export const apiGetAllSedesId = (values) => () =>
    axios.get(`${url_base}/servicio/sedes/${values}`).then(v => {
        if(v.data.length > 0)
            return { success:  1, data: v.data, message: 'Operación exitosa' }
        else 
            return{ success: 0, message: 'Ha ocurrido un error' }
    }).catch(() => ({success: 0, message:"Ha ocurrido un error en la petición"}))