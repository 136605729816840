import React, { Component } from 'react'
import { connect } from 'react-redux'
import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import moment from "moment"
import 'moment/locale/es'

import { WokrshopMapV } from '../../components/services/WokrshopMapV'
import { getInMotMes, getFacTec, getInMotMesId, getValVenSer, getMapaTallerPdf } from "../../../redux/actions/mapa-taller"
import { getTypeService } from "../../../redux/actions/service-orders"
import { getTecnicos } from "../../../redux/actions/userActions"
import { alternateVariantColor, primayColor } from '../../../constanst'
import { url_base } from '../../../redux/api/urls'


class WorkshopMap extends Component {

    constructor(props){
        super(props);
        this.state = {
            months: [],
            data: [],
            data2: [],
            data3: [],
            tecnicos: [],
            monthsList: [],
            tecnicosList: [],
            typeServices: [],
            userSel: [],
            userActive: {},
            valeusFac: {},
            tecnicoSelect: '0',
            monthsSel: '',
            yearSel: '',
            loading: false
        }
        this.isMount = false
        this.chartL1 = ''
        this.chartl2 = ''
        this.chartBar = ''
        this.chartDon = ''
    }

    async componentDidMount() {
        /** Halando datos del localStorage */
        this.setState({ loading: true })
        this.isMount = true

        const userdata = JSON.parse(localStorage.getItem("userdata"))
        
        /** Declaración de variables */
        const values =  userdata[0].usu_sede
        

        /** Obteniendo lista de técnicos */
        await this.props.getTecnicos()
        const tecn = this.props.tecnicos

        /** Obteniendo fecha con la librería moment */
        
        const month = moment().format('M')
        const year = moment().format('YYYY')

        /** Función para calcular totales de servicios */
        await this.getTotals(tecn, month, year, 0)

        /** Obteniendo valores de ventas e ingreso de motos */
        await this.props.getInMotMes(values)
        const dataApi = this.props.inMotosMensuales.data
        
        /** Obteniendo lista de servicios */
        await this.props.getTypeService()
        const typeServices = this.props.typeServices
        
        /** Obteniendo valor de venta por tipo de servicio */
        await this.props.getValVenSer({typeServ: typeServices[0].tser_id, IDsede: userdata[0].usu_sede})
        const dataBar = this.props.valVenTSer

        this.getLinealsCalc(dataApi)
        this.getBarGraph(dataBar.data, typeServices[0].tser_nombre)
        
        /** Actualizando estado */
        this.isMount &&
            this.setState({ tecnicosList: tecn, userSel: tecn,  monthsSel: month, yearSel: year, typeServices, loading: false })
        
    } 

    /** Component Will Mount */
    componentWillUnmount() {
        this.isMount = false   
    }
    
    /** On Change evento que se activa al cambiar el estado de los selects */
    onChange = async e => {
        const {value, name} = e.target

        if(name === "tecnicoSelect"){
            if(value === "0"){
                const userdata = JSON.parse(localStorage.getItem("userdata"))
                const idsede =  userdata[0].usu_sede
                await this.props.getInMotMes(idsede)
                
                this.getTotals(this.state.tecnicosList, this.state.monthsSel, this.state.yearSel, 0)
                this.getLinealsCalc(this.props.inMotosMensuales.data)
                this.setState({ userSel: this.state.tecnicosList, userActive: ''})
            }
            else {
                const user = this.state.tecnicosList.find( x => x.usu_id === value)
                this.setState({userSel: [user], userActive: user }, () => {
                    this.getTotals(this.state.userSel, this.state.monthsSel, this.state.yearSel, 0)
                })
                await this.props.getInMotMesId(value)
                this.getLinealsCalc(this.props.inMotosMensuales.data)
            }
            
            this.setState({ [name]: value })
        } else {
            
            this.setState({ [name]: value }, () => {
                this.getTotals(this.state.userSel, this.state.monthsSel, this.state.yearSel, 0)
            })
        }

        
        
    }

    /** Cambiar entre tipos de servicio */
    handleClick = async (e, value, name) => {
        /** Declaración de variables */
        this.setState({loading: true})
        const userdata = JSON.parse(localStorage.getItem("userdata"))
        const IDsede =  userdata[0].usu_sede

        /** Obteniendo valor de venta por tipo de servicio */
        await this.props.getValVenSer({typeServ: value, IDsede})
        await this.getTotals(this.state.userSel, this.state.monthsSel, this.state.yearSel, value)

        this.getLinealsCalc(this.props.inMotosMensuales.data)
        const dataBar = this.props.valVenTSer

        this.getBarGraph(dataBar.data, name)

        this.setState({loading: false})
    }
    
    /** On Click arrow */
    onClickArrow = e => {
        e.stopPropagation()

        if(!this.activeMenu){
            document.getElementById("menuOculto").style.height = "200px"
            document.getElementById("menuOculto").style.bottom = "-200px"
        } else {
            document.getElementById("menuOculto").style.height = "0px"
            document.getElementById("menuOculto").style.bottom = "0px"
        } 
        this.activeMenu = this.activeMenu ? false : true   
    }

    /** imprimir reporte de mapa de taller */
    handleClickPdf = async () => {
        /** Declaración de variables */
        const userdata = JSON.parse(localStorage.getItem("userdata"))
        const idSede =  userdata[0].usu_sede

        await this.props.getMapaTallerPdf({month: this.state.monthsSel, year: this.state.yearSel, idSede})
        if(this.props.postResponseMT.success === 1){
            // const element = document.getElementById("pdfMT")
            
            window.open(`${url_base}/uploads/uploads_mapaTaller/Planilla_MapaTaller.pdf`, '_blank');
        }
    }

    /** Suma para totales */
    getTotals = async (tecn, month, year, idTSer) => {
        /** Declarando Variables */
        let totAceite = 0, totRepuesto = 0, totMo = 0, totTercero = 0, sumTec = 0, data3 = [], tecnicos = []

        
        /** Obteniendo la sede */
        const userdata = JSON.parse(localStorage.getItem("userdata"))
        const sede =  userdata[0].usu_sede

        /** Recorriendo la lista de tecnicos para obtener la suma total de ventas */
        for(let x in tecn ){
            /** Petición a la factura por tecnico */
            await this.props.getFacTec({month, year, iduser: tecn[x].usu_id, idTSer, sede})
            const items = this.props.facturaTecnicos.data[0]

            /** Sumando los totales de cada servicio */
            if(!!items.aceite)
                totAceite += parseFloat(items.aceite)
            if(!!items.respuesto)
                totRepuesto += parseFloat(items.respuesto)
            if(!!items.mo)
                totMo += parseFloat(items.mo)
            if(!!items.tercero)
                totTercero += parseFloat(items.tercero)

            /** Verificando que no sean nulos, para hacer la operación */
            if(!!items.aceite && !!items.respuesto && !!items.mo && !! items.tercero)
                sumTec = parseFloat(items.aceite) + parseFloat(items.respuesto) + parseFloat(items.mo) + parseFloat(items.tercero)
            else
                sumTec = 0

            /** Estableciendo el arreglo que se enviará al gráfico */
            data3 = [...data3, sumTec]
            tecnicos = [...tecnicos, tecn[x].per_nombre]

            /** Actualizando estado */
            this.isMount &&
                this.setState({ data3, tecnicos, totAceite, totMo, totRepuesto, totTercero })
        }

        
        this.chartDoughnut("myChart", tecnicos, data3) // gráfico de doughnut
    }
    
    /** Calculos para los gráficos lineales */
    getLinealsCalc = (dataApi) => {
        /** Declarando variables */
        let months = [], data = [], data2 = [],  monthsList = [];

        /** Recorriendo los resultados de las motos y estableciendo el array para el gráfico */
        for(let i = 0; i < dataApi.length; i++){
            months = [...months, moment.monthsShort(parseInt(dataApi[i].mes-1)) ]
            data = [...data, dataApi[i].canxMes ]
            data2 = [...data2, parseFloat(dataApi[i].totalxmes)]
        }

        /** Meses para la lista */
        for (let i = 0; i < moment.months().length; i++){
            monthsList = [...monthsList, {id: i+1, nombre: moment.months(i).toUpperCase() }]
        }

        /** Actualizando estado */
        this.isMount &&
            this.setState({ months, data, data2, monthsList })


        /** Estableciendo gráficos */
        this.chartLine1("myChart3", data, months, "rgba(255, 204, 86, 0.9)") // gráfico lineal 1
        this.chartLine2("myChart2", data2, months, "rgba(254, 99, 131, 0.8)") // gráfico lineal 2
    }

    /**Cargando grafico de barra */
    getBarGraph = (data, label) => {
        let dataBar = [], months = [], barMonto = []

        if(!!data){
            for(let i = 0; i < data.length; i++){
                months = [...months, moment.monthsShort(parseInt(data[i].mes-1)) ]
                dataBar = [...dataBar, data[i].canxMes]
                barMonto = [...barMonto, data[i].totalxmes]
            }
            this.chartbar("myChartBar", dataBar, months, barMonto, label, alternateVariantColor) // gráfico de barras
            this.isMount &&
                this.setState({dataBar})
        }
    }

    /** Chart function - Gráfico lineal 1 */
    chartLine1(id, data, meses, color){
        const canvas = document.getElementById(id)
        const ctx = canvas?.getContext('2d')
        
        if (this.chartL1) {
            this.chartL1.clear();
            this.chartL1.destroy();
         }

        this.chartL1 = new Chart(ctx, {
            type: 'line',
            plugins: [ChartDataLabels],
            data: {
                labels: meses,
                datasets: [{
                    label: "Ingresos del Mes",
                    lineTension: 0,
                    backgroundColor: 'transparent',
                    borderColor: '#7aa0cdaf',
                    pointBorderColor: `#7aa0cd9a`,
                    pointBackgroundColor: `#7aa0cd`,
                    pointBorderWidth: 5,
                    data,
                    fill: false,
                }]
            },
            options: {
                plugins: {
                    datalabels: {
                        anchor: 'start',
                        clamp: true,
                        align: 'top',
                        color: '#575757',
                        clip: false,
                        font: {
                            size: 10,
                            weight: 'bold'
                        },
                        formatter: function (value) {
                            return new Intl.NumberFormat('de-DE').format(value)
                        }
                    },
                },
                scales: {
                    xAxes: [{
                        gridLines: {
                            offsetGridLines: true
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            callback: function (value) {
                                return new Intl.NumberFormat('de-DE').format(value)
                            }
                        }
                    }]
                    }
                }
            })
    }
    /** Chart function - Gráfico lineal 2 */
    chartLine2(id, data, meses, color){
        const canvas = document.getElementById(id)
        const ctx = canvas?.getContext('2d')
        
        if (this.chartL2) {
            this.chartL2.clear();
            this.chartL2.destroy();
        }
        this.chartL2 = new Chart(ctx, {
            type: 'line',
            plugins: [ChartDataLabels],
            data: {
                labels: meses,
                datasets: [{
                    lineTension: 0,
                    label: "Facturación del mes",
                    backgroundColor: 'transparent',
                    borderColor: color,
                    pointBorderColor: color,
                    pointBackgroundColor: color,
                    pointBorderWidth: 7,
                    data,
                    fill: false
                }]
            },
            options: {
                layout: {
                    padding: {
                        right: 100
                    }
                },
                plugins: {
                    datalabels: {
                        anchor: 'start',
                        clamp: false,
                        align: '-45',
                        color: '#575757',
                        font: {
                            size: 10,
                            weight: 'bold'
                        },
                        formatter: function (value) {
                            return '$ ' + new Intl.NumberFormat('de-DE').format(value)
                        }
                    },
                },
                scales: {
                    xAxes: [{
                        gridLines: {
                            offsetGridLines: true
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            callback: function (value) {
                                return '$ ' + new Intl.NumberFormat('de-DE').format(value)
                            }
                        }
                    }]
                    },
                    tooltips:{
                        callbacks : {
                            label: function(tooltipItem, data) {
                                return `${data.datasets[tooltipItem.datasetIndex].label}: $ ${new Intl.NumberFormat('de-DE').format(tooltipItem.yLabel)}`
                            }
                        }
                    }
                }
            })
    }
    /** Chart function - Gráfico de barras */
    chartbar = (id, data, meses, barMonto, label, color) => {
        const canvas = document.getElementById(id)
        const ctx = canvas?.getContext('2d')
        
        if (this.chartBar) {
            this.chartBar.clear();
            this.chartBar.destroy();
         }

        this.chartBar = new Chart(ctx, {
        type: 'bar',
        data: {
            labels: meses,
            datasets: [{
                label: label,
                backgroundColor: color,
                data: data,
            }],
        },
        options: {
            plugins: {
                datalabels: {
                    anchor: 'start',
                    clamp: false,
                    align: '-45',
                    color: '#575757',
                    font: {
                        size: 10,
                        weight: 'bold'
                    },
                    formatter: function (value) {
                        return new Intl.NumberFormat('de-DE').format(value)
                    }
                },
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        offsetGridLines: true
                    }
                }],
                
                yAxes: [{
                    ticks: {
                        min: 0,
                    }
                    
                }],
            },
            tooltips:{
                callbacks : {
                    label: function(tooltipItem, data) {
                        return `cantidad: ${tooltipItem.yLabel} \n monto: $ ${new Intl.NumberFormat('de-DE').format(barMonto[tooltipItem.index])}`
                    }
                }
            }
            },
        })
    }
    /** Chart function - Gráfico de Torta */
    chartDoughnut(id, labels, data){
        const colors =[
            '#36a2eb',
            '#fe6383',
            '#ffcc56',
            '#4cc0c0',
          ]
        const canvas = document.getElementById(id)
        const ctx = canvas?.getContext('2d')
        
        if (this.chartDon) {
            this.chartDon.clear();
            this.chartDon.destroy();
         }
        this.chartDon = new Chart(ctx, {
        type: 'pie',
        data: {
            labels,
            datasets: [{
                label: '# of Votes',
                data,
                backgroundColor: colors,
                borderWidth: 1,
            }]
        },
        options: {
            responsive: true,
            plugins: {
                datalabels: {
                    align: '-45',
                    color: '#575757',
                    font: {
                        size: 10,
                        weight: 'bold'
                    },
                    formatter: function (value) {
                        return new Intl.NumberFormat('de-DE').format(value)
                    },
                    clamp: true,
                    clip: true
                },
            },
            tooltips:{
                callbacks : {
                    label: function(tooltipItem, data) {
                        return `${data.labels[tooltipItem.index]} $ ${new Intl.NumberFormat('de-DE').format(data.datasets[0].data[tooltipItem.index])}`
                    }
                }
            }
        }
        })
    }
    
    /** Funcion render */
    render() {
        return (
           <WokrshopMapV
                onChange={this.onChange}
                onClick={this.handleClick}
                onClickArrow={this.onClickArrow}
                state={this.state}
           />
        )
    }
}

const mapStateToProps = ({ inMotosMensuales, facturaTecnicos, tecnicos, typeServices, valVenTSer, postResponseMT }) => ({ inMotosMensuales, facturaTecnicos, tecnicos, typeServices, valVenTSer, postResponseMT })

export default connect(mapStateToProps, {getInMotMes, getFacTec, getTecnicos, getInMotMesId, getTypeService, getValVenSer, getMapaTallerPdf })(WorkshopMap)