import React, { Fragment } from 'react'
import {Table} from 'react-virtualized'
import 'react-virtualized/styles.css'

import { Tooltip, TooltipText } from '../services/ComponentsForm'
import ModalImprimir from '../../common/ModalImprimir'
import Scrollbars from 'react-custom-scrollbars'
import { Loading } from '../../common/Loading'
import FilterPanel from './FilterPanel'
import { Main, Container, Title, Row, Th, Tr, Td, ButtonIcon, ButtonCloseOrder} from "./Styles"
import { secundaryColor, alternateColor, alternateVariantColor, successColor, primayVariantColor} from "../../../constanst"
import { IconSlope, IconEdit, IconCheck, IconWorkshopMap, IconHourglass, IconCotizacion, IconTec, IconHourglass2 } from '../../../assets/icons'

const widthScreen = window.screen.width

export default function Works ({ state, servicesList, onChange, onClickFindDate, onClickModal, openModal,  onFilter, onKeyPressFilter, onChangeSel, 
    onClickCloseOrder, onClickPopup }) { return <Fragment>
    <Main>
        {state.loading && <Loading />}
        {state.modalImp && <ModalImprimir onClickModal={onClickModal} onCloseModal={openModal} typeUser={state.typeUser} state={state} />}
    

        <Scrollbars>
            <Container>
                <Title>SERVICIOS</Title>

                {/** panel de busqueda */}
                <FilterPanel
                    state={state}
                    onChange={onChange}
                    onClickFindDate={onClickFindDate}
                    onFilter={onFilter}
                    onKeyPressFilter={onKeyPressFilter}
                    onChangeSel={onChangeSel}
                />

                <br />

                <Table
                    style={{ width: '100%'}}
                    autoContainerWidth
                    gridStyle={{width: '100%'}}
                    height={750}
                    headerHeight={40}
                    width={1850}
                    rowHeight={70}
                    containerStyle={{width: '100%'}}
                    rowCount={servicesList.length}
                    rowGetter={({index}) => servicesList[index]}
                    rowStyle={{width: '100%'}}
                    headerRowRenderer={() => 
                        <Tr className="ReactVirtualized__Table__headerRow" bgColor style={{
                            width: (servicesList.length > 8 && window.screen.width > 600) ? '97%' : '100%'
                        }}>
                            {widthScreen > 600 ?
                                <>
                                    <Th elipsis width={10} >#</Th>
                                    <Th elipsis width={10} title="consecutivo">Consecutivo</Th>
                                    <Th elipsis width={15} title="Modelo de Moto">Moto</Th>
                                    <Th elipsis width={15} title="Número de Placa">Placa</Th>
                                    <Th elipsis width={20} title="Trabajo Realizado">Trabajo</Th>
                                    <Th elipsis width={10} title="Estado del Servicio">Estado</Th>
                                    <Th elipsis width={10} title="Fecha del Servicio">Fecha</Th>
                                    <Th elipsis width={10} title="Acciones">Acciones</Th>
                                </>
                                :
                                <>
                                    <Th elipsis width={15} title="consecutivo">Cons</Th>
                                    <Th elipsis width={20} title="Modelo de Moto">Moto</Th>
                                    <Th elipsis width={20} title="Número de Placa">Placa</Th>
                                    <Th elipsis width={30} title="Trabajo Realizado">Trabajo</Th>
                                    <Th elipsis width={15} title="E">Estado</Th>
                                </>
                            }
                        </Tr>
                    }
                    rowRenderer={e => 
                        <Tr className="ReactVirtualized__Table__row" key={e.key} style={e.style} onClick={onClickPopup}>
                            {widthScreen > 600 && <Td elipsis width={10} >{e.index + 1}</Td>}
                            <Td elipsis width={widthScreen > 600 ? 10 : 15 } title={e.rowData.ser_consecutivo}>{e.rowData.ser_consecutivo}</Td>
                            <Td elipsis width={widthScreen > 600 ? 15: 20} title={e.rowData.rm_nombremodelo}>{e.rowData.rm_nombremodelo}</Td>
                            <Td elipsis width={widthScreen > 600 ? 15 : 20} title={e.rowData.ds_placa}>{e.rowData.ds_placa}</Td>
                            <Td elipsis width={widthScreen > 600 ? 20 : 30} title={!!e.rowData.ds_otrostrabajos ? e.rowData.ds_otrostrabajos : e.rowData.tg_nombre}>{!!e.rowData.ds_otrostrabajos ? e.rowData.ds_otrostrabajos : e.rowData.tg_nombre}</Td>
                            <Td width={widthScreen > 600 ? 10 : 15} elipsis={false}>                                
                                {e.rowData.ser_estado === "0" &&
                                    <TooltipContainer color={alternateColor} title='Pendiente' icon={<IconSlope width="20px" height="20px" color={alternateColor}/>} />
                                }{e.rowData.ser_estado === "2" &&                                
                                    <TooltipContainer color={successColor} title='Confirmado' icon={<IconCheck width="20px" height="20px" color={successColor} /> } />
                                }{e.rowData.ser_estado === "3" &&
                                    <TooltipContainer color='#3f51b5' title='En taller' icon={<IconTec width="20px" height="20px" color='#3f51b5' /> }  />
                                }{e.rowData.ser_estado === "4" &&
                                    <TooltipContainer color={primayVariantColor} title='Suspendido' icon={<IconHourglass2 width="20px" height="20px" color={primayVariantColor} /> }  />
                                }{e.rowData.ser_estado === "5" &&
                                    <TooltipContainer color={successColor} title='Finalizado' icon={<IconCheck width="20px" height="20px" color={successColor} /> }/>
                                }
                            </Td>
                            {widthScreen > 600 && <Td elipsis width={10} title={e.rowData.fecha}>{e.rowData.fecha}</Td>}
                            <Td width={10} elipsis={false} movil={widthScreen < 600} activePopup={state.menuPopup}>
                                <Row justify="center">
                                    <TooltipContainer color={alternateVariantColor} title='Editar' link={`/trabajos/orden/${e.rowData.ser_id}`}
                                        icon={<IconEdit width="30px" height="20px" color={secundaryColor} bgColor={alternateVariantColor} />} />
                                    {e.rowData.ser_estado !== '5' &&
                                    <TooltipContainer color={primayVariantColor} title='Suspender Servicio' link={`/trabajos/supender/${e.rowData.ser_id}`}
                                            icon={<IconHourglass width="30px" height="20px" color={primayVariantColor} bgColor={secundaryColor} />} />
                                    }
                                </Row>
                                    <Row justify="center">
                                    {e.rowData.ser_estado !== '5' && <>
                                        <Tooltip className="tooltip">
                                            <ButtonCloseOrder type="button" onClick={event => onClickCloseOrder(event, e.rowData.ser_id)}>
                                                <IconWorkshopMap width="30px" height="20px" color={secundaryColor} bgColor={successColor} />
                                            </ButtonCloseOrder>
                                            <TooltipText className="tooltiptext" bgColor={successColor}>Cerrar Orden</TooltipText>
                                        </Tooltip>
                                        <TooltipContainer color='#3f51b5' title='Cotizar' link={`/trabajos/cotizar/${e.rowData.ser_id}`}
                                            icon={<IconCotizacion width="30px" height="20px" color={secundaryColor} bgColor='#3f51b5' />} />
                                    </>}
                                    </Row>
                            </Td>
                        </Tr>
                    }
                    >
                </Table>
            </Container>
        </Scrollbars>
    </Main>
</Fragment>
}


const TooltipContainer = ({color, title, icon, link}) => 
<Tooltip className="tooltip">
    { !!link ? 
        <ButtonIcon type="button" to={link}>
            {icon}
        </ButtonIcon>
    : icon }
    <TooltipText className="tooltiptext" bgColor={color}>{title}</TooltipText>
</Tooltip>