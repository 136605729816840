import { url_base } from "./urls"
import axios from 'axios'

/** Obteniendo información de servicios para trabajos por id */
export const apiGetWorksId = data => () => axios.get(`${url_base}/trabajos/listatrabajo/${data.idSer}/${data.idUser}/${data.idTypeUser}`).then(v => v.data).catch(e => ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))
/** Obteniendo trabajos por id */
export const apiGetOneWorks = data => () => axios.get(`${url_base}/trabajos/listar/one/${data.idSer}/${data.idUser}/${data.idTypeUser}`).then(v => v.data).catch(e => ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))
/** Obteniendo suspensión de trabajos */
export const apiGetOneWorksSusp = data => () => axios.get(`${url_base}/trabajos/suspendidos/${data.idSer}/${data.idUser}/${data.idTypeUser}`).then(v => v.data).catch(e => ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))
/** Registrando trabajos */
export const apiPostRegisterWork = data => () => axios.post(`${url_base}/trabajos/registrar`, data).then(v => v.data).catch(e => ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))
/** Registrando suspensión de trabajos */
export const apiPostWorkSusp = data => () => axios.post(`${url_base}/trabajos/suspender`, data).then(v => v.data).catch(e => ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))
/** Cierre de estado de servicio o trabajo por parte del técnico */
export const apiSetCloseOrden = data => () => axios.get(`${url_base}/servicios/actualizarestado/${data}`).then(v => v.data).catch(e => ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))