import React from 'react'
import styled, { css } from 'styled-components'
import Scrollbars from 'react-custom-scrollbars'

import { secundaryColor, primayColor, iconColor, primayVariantColor, blackColor } from '../../../constanst'
import ModuleInput from '../../common/ModuleInput'
import ButtonSubmit from '../../common/ButtonSubmit'
import Button from '../../common/Button'
import { ContainerButton, Form, FormContent } from "./../../components/services/ComponentsForm"
import { IconArrowLeft } from '../../../assets/icons'
import CustomSelect from '../../common/CustomSelect'
import { MenuItem } from '@material-ui/core'
import { Loading } from '../../common/Loading'

export default ({ state, param, onChange, onChangeSelect, onSubmit }) => 
    <Main>
         {state.loading && <Loading /> }
        <Scrollbars>
            <Container>
                <ButtonContainer >
                    <Button label="REGRESAR" icon={<IconArrowLeft width="15px" height="15px" color={blackColor} />}></Button>
                </ButtonContainer>

                <Title>{!!param ? 'EDICIÓN DE ' : 'REGISTRO DE ' }USUARIOS</Title>
                <Form onSubmit={onSubmit} method="POST">
                    <br />
                    <FormContent>
                    {/** Contendor izquierdo */}
                        <ModuleContainer>
                            <CustomSelect label="TIPO DOCUMENTO" inputw="72%" name="tipodocumento" onChange={onChangeSelect} value={state.userForm.tipodocumento || ''}>
                                {state.typeDocuments &&
                                    state.typeDocuments.map( x => (
                                        <MenuItem key={x.id} value={x.id}>{x.nombre}</MenuItem>
                                    ))
                                }
                            </CustomSelect>
                            <ModuleInput 
                                label="N° DE DOCUMENTO" type="number" name="identificacion" 
                                backgroundC={secundaryColor} borderC={iconColor} width="70%" inputw="100%"
                                onChange={e => onChange(e, true, false, true, true, 5, 20)} value={state.userForm.identificacion || ''}
                            />
                            <ModuleInput label="NOMBRES" type="text" name="nombre" 
                                backgroundC={secundaryColor} borderC={iconColor} width="70%" inputw="100%"
                                onChange={e => onChange(e, true, false, false, true, 2, 20)} value={state.userForm.nombre || ''}
                            />
                            <ModuleInput label="APELLIDOS" type="text" name="apellido" 
                                backgroundC={secundaryColor} borderC={iconColor} width="70%" inputw="100%"
                                onChange={e => onChange(e, true, false, false, true,  2, 20)} value={state.userForm.apellido || ''}
                            />
                            <ModuleInput label="DIRECCIÓN" type="text" name="direccion" 
                                backgroundC={secundaryColor} borderC={iconColor} width="70%" inputw="100%"
                                onChange={e => onChange(e, true, false, false, true, 2, 50)} value={state.userForm.direccion || ''}
                            />
                            <ModuleInput label="E-MAIL" type="text" name="email" 
                                backgroundC={secundaryColor} borderC={iconColor} width="70%" inputw="100%"
                                onChange={e => onChange(e, true, false, false, false, false)} value={state.userForm.email || ''}
                            />
                        </ModuleContainer>

                        {/** Contenedor Derecho */}
                        <ModuleContainer borderLeft={true}>
                            <ModuleInput label="TELÉFONO" type="number" name="telefono"
                                backgroundC={secundaryColor} borderC={iconColor} width="70%" inputw="100%" 
                                onChange={e => onChange(e, false, false, true, true, 2, 15)} value={state.userForm.telefono || ''}
                            />
                            <LineRed />
                            <ModuleInput label="NOMBRE DE USUARIO" name="usuario" type="text" 
                                backgroundC={secundaryColor} borderC={iconColor} width="70%" inputw="100%" 
                                onChange={e => onChange(e, true, false, false, true, 2, 50, false, true)} value={state.userForm.usuario || ''}
                            />
                            <ModuleInput label={!!param ? 'NUEVA CONTRASEÑA (opcional) ' : 'CONTRASEÑA '} type="password" name="password"  ignore={true}
                                backgroundC={secundaryColor} borderC={iconColor} width="70%" inputw="100%" 
                                onChange={e => onChange(e, true, false, false, true, 2, 20, false, true)} value={state.userForm.password || ''}
                            />
                            <CustomSelect label="TIPO DE USUARIO" inputw="72%" name="idtipousuario" onChange={onChangeSelect} value={state.userForm.idtipousuario || ''}>
                                {state.typeUsersList &&
                                    state.typeUsersList.map( x => (
                                        <MenuItem key={x.id} value={x.id}>{x.nombre}</MenuItem>
                                    ))
                                }
                            </CustomSelect>
                            <CustomSelect label="SEDE" inputw="72%" name="idsede" onChange={onChangeSelect} value={state.userForm.idsede || ''}>
                                {state.sedesList &&
                                    state.sedesList.map( x => (
                                        <MenuItem key={x.sed_id} value={x.sed_id}>{x.sed_nombre}</MenuItem>
                                    ))
                                }
                            </CustomSelect>
                        </ModuleContainer>

                    </FormContent>

                    {/* button */}
                    <ContainerButton>
                        <ButtonSubmit width="30%" label="Siguiente" bgColor={primayColor} color={secundaryColor} type="submit" />
                    </ContainerButton>
                </Form>
            </Container>
        </Scrollbars>
    </Main>

const Main = styled.main`
    flex: 1;
    display: flex;
    overflow: hidden;
    box-sizing: border-box;
`
const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: ${secundaryColor};
    width: 100%;
    margin-bottom: 30px;
    margin: 0px 2%;
    /* border-radius: 15px; */
    box-shadow: 0px 0px 6px 1px rgba(0,0,0,.4);
    @media (min-width: 1000px){
        width: 98%;
        margin-left: 1%;
    }
 `
const Title = styled.h3`
    color: ${secundaryColor};
    padding: 5px;
    margin: 0px;
    text-align: center;
    background-color: ${primayVariantColor};
`
 const ModuleContainer = styled.div`
    flex: .5;
    padding: 0 10px 0px 20px;
    display: inline-block;
    
    ${({ borderLeft }) => borderLeft && css`border-left: 1px solid ${primayColor};`}
`
const LineRed = styled.hr`border: 1px solid ${primayColor}; margin-bottom: 20px;`
/** NEW BUTTON */
const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
`