import { handleActions } from 'redux-actions'
import { USER_LOGIN, GET_TECNICOS, GET_USERS, DELETE_USERS, POST_USERS, GET_TYPE_USERS, GET_TYPE_DOCUMENT, GET_USER_ID, UPDATE_USERS, GET_CLIENTE } from '../types'

export const users = handleActions({
    [USER_LOGIN]: (state, action) => action.payload,
    [GET_USERS]: (state, action) => action.payload,
    [GET_USER_ID]: (state, action) => action.payload,
    [DELETE_USERS]: (state, action) => {

        state.map( (x, i) =>  {
            if (x !== undefined)
                if(x.usu_id === action.payload.id) {
                    state.splice(i, 1)
                }
            return true
            })

        return state
    },
}, [])

export const tecnicos = handleActions({
    [GET_TECNICOS]: (state, action) => action.payload,
}, [])

/** Clientes */
export const clients = handleActions({
    [GET_CLIENTE]: (state, action) => action.payload,
}, [])

/** Tipos de usuarios */
export const typeUsers = handleActions({
    [GET_TYPE_USERS]: (state, action) => action.payload 
}, [])

/** Tipos de documento */
export const typeDocument = handleActions({
    [GET_TYPE_DOCUMENT]: (state, action) => action.payload 
}, [])

/** Respuesta a las peticiones post de user */
export const postResponseUsers = handleActions({
    [POST_USERS]: (state, action) => action.payload,
    [UPDATE_USERS]: (state, action) => action.payload
}, [])