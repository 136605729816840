import React from 'react'
import styled from 'styled-components'
import { bgColor, blackColor, errorColor } from '../../constanst'

export default ({onChange, onKeyPress, placeholder, value, type, name, icon, width, borderC, backgroundC, textColor, ignore = false, 
    placeholderColor, onBlur, onFocus, disabled, inputW, autoCom, errorM, margin, onSearch = () => console.log("")}) => 
<InputContainer width={width} margin={margin}>
    <IconContainer onClick={e => onSearch(e, name, value)}>
        {icon}
    </IconContainer>
    <InputLogin placeholder={placeholder} type={type} name={name} data-ignore={!!ignore} disabled={disabled}
        onChange={onChange} value={value || ''} onKeyPress={onKeyPress} onBlur={onBlur} onFocus={onFocus} autoComplete="off"
        icon={!!icon} borderC={borderC} backgroundC={backgroundC} textColor={textColor} placeholderColor={placeholderColor} inputW={inputW} />
    {autoCom ? (errorM && <Error></Error> )        
             : <Error></Error>
    }
</InputContainer>



/** Estilos con styled component */
const InputContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: 'center';
    width: ${({ width }) => width ? width : "100%"};
    margin: ${({ margin }) => margin ? margin : "10px 0px"};
`
const IconContainer = styled.div`
    position: absolute;
    left: 10px;
    top: 5px;
`

const InputLogin = styled.input`
    width: ${({inputW}) => inputW ? inputW : '100%'};
    border: 1px solid ${({ borderC }) => borderC ? borderC : "transparent"};
    color: ${({ textColor }) => textColor ? textColor : blackColor };
    font-size: 14px;
    background-color: ${({ backgroundC }) => backgroundC ? backgroundC : "transparent"};
    border-radius: 25px;
    outline: 0;
    padding: ${({type}) => type==="date" ? '3px 8px' : '6px 8px'};
    padding-left: ${({ icon }) => icon ? "30px" : "8px"};

    &::placeholder {
        color: ${({ placeholderColor }) => placeholderColor ? placeholderColor : bgColor};
    }
    &:focus {border: 1px solid ${blackColor}};
    &:disabled {color: #7a7a7a; background-color: #efefef; &:hover {cursor: no-drop;} }
`
const Error = styled.span`
    padding-right: 10px;
    align-self: flex-end;
    color: ${errorColor};
    font-size: 10px;
`