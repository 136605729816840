import React, { Component } from 'react'
import { connect } from 'react-redux'
import toastr from 'toastr'
import WorkOrderV from '../../components/trabajos/WorkOrder'
import {getAllSedesId} from '../../../redux/actions/Parameterizations'
import {getWorksId, postRegisterWork, getOneWorks} from '../../../redux/actions/works'
import {getRequestServiceId, upRegisterGesTaller} from '../../../redux/actions/service-orders'
import { errorColor, validations } from '../../../constanst'

class WorkOrder extends Component {

    constructor(props){
        super(props)
        this.state = {
            formsede: [{}],
            form: {},
            solicitudform: { clienteSolicitud: [], clientellamadas: [] }, 
            clienteSol: {},
            errorChange: {},
            loading: false
        }
        this.isMount = false
    }

    async componentDidMount(){
        this.isMount = true
        this.setState({loading: true})
        const {id} = this.props.match.params
        const userdata = JSON.parse(localStorage.getItem("userdata"))

        /** Peticiones */
        await this.props.getWorksId({idSer: id, idUser: userdata[0].idusuario, idTypeUser: userdata[0].idtipousuario})
        await this.props.getOneWorks({idSer: id, idUser: userdata[0].idusuario, idTypeUser: userdata[0].idtipousuario})
        await this.props.getAllSedesId(userdata[0].usu_sede)
        await this.props.getRequestServiceId(id)
        
        !this.props.worksList.success && toastr.warning(this.props.worksList.message)

         /** Resultados de las solicitudes y solucion de servicios */
        const worksOne = this.props.worksList.dataOne

        if(this.isMount && !!this.props.worksList.successOne)
            this.setState({
                form: {
                    dg_s: worksOne.dt_diagnostico,
                    h_i: worksOne.dt_hora_ingreso,
                    h_s: worksOne.dt_hora_salida,
                    dt_id: worksOne.dt_id,
                    rec_t: worksOne.dt_sugerencias,
                    v_st: worksOne.dt_valor_sugerido,
                    vt_mo: worksOne.dt_valor_ten_mo
                }
            })

        this.isMount &&
            this.setState({ loading: false, formsede: this.props.sedes.data, idSer: id, idUser: userdata[0].idusuario, solicitudform: { ...this.state.solicitudform, clienteSolicitud: this.props.servicesRequestList.data}})
    }

    componentWillUnmount() {
        this.isMount = false
    }
    
    /** Metodo on Change */
    handleChange = async (e, isNull, isLetter, isNumber, isRange, minRange, maxRange) => {
        /** Asignando Constantes y Variables */
        const {name, value} = e.target

        /** Validando las entradas */
        const error = validations(e, isNull, isLetter, isNumber, isRange, minRange, maxRange)
        this.setState({ errorChange: { ...this.state.errorChange, [name]: error }, form: {...this.state.form, [name]: value} })
    }

    /** Método OnChange Multiple */
    handleChangeMultiple = (e, isNull, isLetter, isNumber, isRange, minRange, maxRange) => {
        const {name, value} = e.target
        const error = validations(e, isNull, isLetter, isNumber, isRange, minRange, maxRange)
        
        this.setState({ clienteSol: { ...this.state.clienteSol, [name]: value.toUpperCase() }, error })
      
    }

    /** Guardar o editar un archivo */
    handleClickButton = e => {
        e.preventDefault()
        let elem
        const {clienteSol, solicitudform } = this.state
       
        /** Función para validar que los campos no estén vacíos*/
        const validation = () => {
            if(!clienteSol.formsolicitudes) {
                elem = document.getElementsByName("formsolicitudes")
                elem[0].style.border = `1px solid ${errorColor}`
                elem[0].nextSibling.innerHTML = "El campo no puede estar vació"
                return false
            }
            return true
        }

        /** Validando que ambos campos sean llenados, ya que uno depende de otro */
        this.setState(ps => {
            const clienteSolicitud = ps.solicitudform.clienteSolicitud.map( x => {
                if(x.idobs === ps.clienteSol.idobs)
                    return {formsolicitudes: clienteSol.formsolicitudes, formsolucion: clienteSol.formsolucion, idobs: ps.clienteSol.idobs }
                return x
            })
            return {solicitudform: {...solicitudform, clienteSolicitud}}
        })

        const res = validation()
        if(!res)
            return false

        this.setState({ clienteSol: {formsolicitudes: '', formsolucion: '', idobs: ''}, editSol: false })
    }
    


    /** Actualizar servicio localmente */
    handleUpdateSolucions = (e, idServ) => {
        e.preventDefault()
        
        const x = this.state.solicitudform.clienteSolicitud.find( e => e.idobs === idServ)
        this.setState({ clienteSol: {formsolicitudes: x.formsolicitudes, formsolucion: x.formsolucion, idobs: idServ}, editSol: true })
    }
    /** Cancelar la modificacion */
    handleCancelUpdate = e => {
        e.preventDefault()
        this.setState({ clienteSol: {formsolicitudes: '', formsolucion: '', idobs: ''}, editSol: false })
    }


    /** Submit de la función */
    handleSubmit = async e => { 
        /** Cancelando la acción por defecto */
        e.preventDefault();

        /** Variables */
        const inputs = e.target.elements
        this.errorSubmit = false
        const {errorChange, idSer, idUser, form, solicitudform, clienteSol } = this.state

        if(!!clienteSol.formsolucion)
            return toastr.warning('Antes de continuar, presione el botón actualizar para registrar los cambios en la solución dada. Sino presione cancelar.')

        /** formulario que recorre los inputs del formulario */
        for (let i = 0; i < inputs.length; i++){
            
            /** Varificando que los campos tengan algún valor */
            if(!!inputs[i].value === false && inputs[i].type !== "submit" && inputs[i].type !== "button"){
                
                /** Validando inputs texts */
                if(!!inputs[i].dataset.ignore){
                    this.errorSubmit=true
                    inputs[i].style.border = `1px solid ${errorColor}`
                    inputs[i].nextSibling.innerHTML = "El campo no puede estar vació"
                }
            } else {
                if(errorChange[inputs[i].name])
                    this.errorSubmit = true;
            }
        }

        /** Verificando si existe un error */
        if(this.state.error || this.errorSubmit){
            toastr.warning("Verifiqué que los campos estén correctos")
            return false
        }
        
        this.setState({loading: true})
        /** Guardando trabajo */
        await this.props.postRegisterWork({idSer, idUser, ...form})

        /** guardar datos de el cliente solicita, solución y solicitud */
        if(solicitudform.clienteSolicitud.length > 0){
            solicitudform.clienteSolicitud.map( async x => {
                await this.props.upRegisterGesTaller({ servicio: x.formsolicitudes, solucion : x.formsolucion, idobs: x.idobs })
            })
        }

        /** Respuesta del servidor */
        if(this.props.worksList.success){
            toastr.success(this.props.worksList.message)
            return this.props.history.push('/trabajos/seguimiento')
        } else
            toastr.warning(this.props.worksList.message)
        
        this.isMount &&
        this.setState({loading: false})
    }

    render() {
        return (
            <WorkOrderV
                state={this.state}
                onDel={this.handleDeleteSolucions}
                onUpdate={this.handleUpdateSolucions}
                onCanUp={this.handleCancelUpdate}
                onChange={this.handleChange}
                onClickButton={this.handleClickButton}
                onChangeMultiple={this.handleChangeMultiple}
                onSubmit={this.handleSubmit}
                onGoBack={() => this.props.history.push('/trabajos/seguimiento')}
            />
        )
    }
}

const mapStateToProps = ({sedes, worksList, servicesRequestList}) => ({sedes, worksList, servicesRequestList})

export default connect(mapStateToProps, {getAllSedesId, getWorksId, getRequestServiceId, postRegisterWork, getOneWorks, upRegisterGesTaller})(WorkOrder)