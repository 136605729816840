import React from 'react'
import styled, { css } from 'styled-components'

import { primayColor, iconColor, secundaryColor, successColor, alternateColor, errorColor } from '../../../constanst'
import { TableContainer, Table, Tr, Th, Td, Subtitle, FormContent } from './ComponentsForm'
import InputText from '../../common/InputText'
import CustomChecks from '../../common/CustomChecks'

export default  ({ stateOrder, state: {inventarioform, inventario, comments, conditions}, onChange, params }) => {
    let inventario1 = [], inventario2 = [] 
    if(!!inventario){
        inventario1 = inventario.filter( (x, i) => (i < (inventario.length / 2) && x ))
        inventario2 = inventario.filter( (x, i) => (i >= (inventario.length / 2) && x ))
    }

    return(
        <FormContent>
            <ModuleContainer>
                <Subtitle>INVENTARIO</Subtitle>

                <Row>
                    <TableContainer>
                        <Table>
                            <thead>
                                <Tr>
                                    <Th width="30%">Detalle</Th>
                                    <Th textAlign="center">B</Th>
                                    <Th textAlign="center">R</Th>
                                    <Th textAlign="center">M</Th>
                                    <Th width="60%">OBSERVACIÓN</Th>
                                </Tr>
                            </thead>
                            <tbody>
                                {!!inventario &&
                                    inventario1.map( (x, i) => (
                                        <Tr style={{ transition: '1s' }} key={x.invo_id}>
                                            <Td>{x.invo_nombre}</Td>
                                            <Td><CustomChecks name={`${x.invo_id}`} value="1"  checked={inventarioform[x.invo_id] === "1"} onChange={e => onChange(e, 5)} ignore={false} color={successColor} /></Td>
                                            <Td><CustomChecks name={`${x.invo_id}`} value="2"  checked={inventarioform[x.invo_id] === "2"} onChange={e => onChange(e, 5)} ignore={false} color={alternateColor} /></Td>
                                            <Td><CustomChecks name={`${x.invo_id}`} value="3"  checked={inventarioform[x.invo_id] === "3"} onChange={e => onChange(e, 5)} ignore={false} color={errorColor} /></Td>
                                            {/* <Td><input type="radio" name={`${x.invo_id}`} value="2" disabled={params.id ? true : false} checked={inventarioform[x.invo_id] === "2"} onChange={e => onChange(e, 5)} data-ignore={false} /></Td>
                                            <Td><input type="radio" name={`${x.invo_id}`} value="3" disabled={params.id ? true : false} checked={inventarioform[x.invo_id] === "3"} onChange={e => onChange(e, 5)} data-ignore={false} /></Td> */}
                                            <Td>
                                                <InputText width="100%" name={`obs${x.invo_id}`} ignore={true} type="text" disabled={(params.id && stateOrder !== '6') ? true : false}
                                                    onChange={e => onChange(e, 5, false, false, false, true, 3, 200)}
                                                    value={comments[`obs${x.invo_id}`] || ''} 
                                                    borderC={iconColor} backgroundC={secundaryColor} />
                                            </Td>
                                        </Tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </TableContainer>
                    <TableContainer>
                        <Table>
                            <thead>
                                <Tr>
                                    <Th width="30%">Detalle</Th>
                                    <Th textAlign="center">B</Th>
                                    <Th textAlign="center">R</Th>
                                    <Th textAlign="center">M</Th>
                                    <Th width="60%">OBSERVACIÓN</Th>
                                </Tr>
                            </thead>
                            <tbody>
                                {!!inventario &&
                                    inventario2.map( (x, i) => (
                                        <Tr style={{ transition: '1s' }} key={x.invo_id}>
                                            <Td>{x.invo_nombre}</Td>
                                            <Td><CustomChecks name={`${x.invo_id}`} value="1"  checked={inventarioform[x.invo_id] === "1"} onChange={e => onChange(e, 5)} ignore={false} color={successColor} /></Td>
                                            <Td><CustomChecks name={`${x.invo_id}`} value="2"  checked={inventarioform[x.invo_id] === "2"} onChange={e => onChange(e, 5)} ignore={false} color={alternateColor} /></Td>
                                            <Td><CustomChecks name={`${x.invo_id}`} value="3"  checked={inventarioform[x.invo_id] === "3"} onChange={e => onChange(e, 5)} ignore={false} color={errorColor} /></Td>
                                            <Td>
                                                <InputText width="100%" name={`obs${x.invo_id}`} ignore={true} type="text" disabled={(params.id && stateOrder !== '6') ? true : false}
                                                    onChange={e => onChange(e, 5, false, false, false, true, 3, 200)}
                                                    value={comments[`obs${x.invo_id}`] || ''} 
                                                    borderC={iconColor} backgroundC={secundaryColor} />
                                            </Td>
                                        </Tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </TableContainer>
                </Row>
                <TableContainer>
                    <Table>
                        <thead>
                            <Tr><Th colSpan="8" textAlign="center">Condiciones en las cuales entra la moto</Th></Tr>
                        </thead>
                        <tbody>
                            <Tr style={{ transition: '1s' }}>
                                <Th>Encendida</Th>
                                <Td><CustomChecks name="formconditionOne" value="1"  checked={conditions.formconditionOne === "1"} onChange={e => onChange(e, 7)} ignore={false} color={successColor} /></Td>
                                <Th>Apagada</Th>
                                <Td><CustomChecks name="formconditionOne" value="2"  checked={conditions.formconditionOne === "2"} onChange={e => onChange(e, 7)} ignore={false} color={errorColor} /></Td>
                                <Th>Ensamblada</Th>
                                <Td><CustomChecks name="formconditionSec" value="1"  checked={conditions.formconditionSec === "1"} onChange={e => onChange(e, 7)} ignore={false} color={successColor} /></Td>
                                <Th>No Ensamblada</Th>
                                <Td><CustomChecks name="formconditionSec" value="2"  checked={conditions.formconditionSec === "2"} onChange={e => onChange(e, 7)} ignore={false} color={errorColor} /></Td>
                            </Tr>
                        </tbody>
                    </Table>
                </TableContainer>
            
            </ModuleContainer>
            
        </FormContent>

    )
}


const ModuleContainer = styled.div`
    flex: 1;
    padding: 0 10px 0px 20px;
    display: inline-block;
    
    ${({ borderLeft }) => borderLeft && css`border-left: 1px solid ${primayColor};`}
`
const Row = styled.div`
    display: flex;
    flex-direction: row;
`