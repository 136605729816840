import React from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { blackColor, iconColor, secundaryColor, errorColor } from '../../../constanst'
import ButtonSubmit from '../../common/ButtonSubmit'
import { Loading } from '../../common/Loading'
import ModuleInput from '../../common/ModuleInput'
import { ButtonsContainer } from '../../common/Table'
import TextArea from '../../common/TextArea'
import Header from './Header'
import IdenMoto from './IdenMoto'
import { Main, Container, Title, Box, ModuleContainer } from './Styles'


export default ({ state, onChange, onGoBack, onSubmit }) =>
    <Main>
        {state.loading && <Loading />}
        <Scrollbars>
            <Container>
                <Header docTitle="ORDEN DE TRABAJO Y DIAGNÓSTICO" sedeUser={state.formsede} />

                {/** Cuerpo de la función */}
                <IdenMoto />

                {/** Detalles del servicio */}
                <form onSubmit={onSubmit} id="formDetails">
                    <ModuleContainer>
                        <br />
                        <Title bgColor="transparent" color={blackColor}>DETALLES DE LA SUSPENCIÓN</Title>
                        <br />
                        <Box justify="space-between" direction={window.screen.width > 600 ? "row" : "column"}>
                            <ModuleContainer>
                                <Box justify="space-between" direction="row" width="100%">
                                    <ModuleInput label="HORA DE SUSPENSIÓN" type="time" name="h_s" onChange={onChange} disabled={!!state.form.ss_id}
                                        backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="50%" value={state.form.h_s || ''}
                                    />
                                    <ModuleInput label="FECHA DE SUSPENCIÓN" type="date" name="f_s" onChange={onChange} disabled={!!state.form.ss_id}
                                        backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="50%" value={state.form.f_s || ''}
                                    />
                                </Box>
                                {/* Retorno de la suspeción */}
                                <Box justify="space-between" direction="row" width="100%">
                                    <ModuleInput label="HORA DE REINICIO" type="time" name="h_r" onChange={onChange} disabled={!state.form.ss_id}
                                        backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="50%" value={state.form.h_r || ''}
                                    />
                                    <ModuleInput label="FECHA DE REINICIO" type="date" name="f_r" onChange={onChange} disabled={!state.form.ss_id}
                                        backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="50%" value={state.form.f_r || ''}
                                    />
                                </Box>
                            </ModuleContainer>

                            <Box width={window.screen.width > 600 ? "50%" : "100%"}>
                                <TextArea label="OBSERVACIONES DE IMPREVISTOS" name="obs" onChange={onChange}
                                    backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="100%"
                                    value={state.form.obs || ''} rows="5"
                                />
                            </Box>
                        </Box>
                    </ModuleContainer>
                </form>

                <br />
                <br />
                <br />
                <ButtonsContainer justify="space-between">
                    <ButtonSubmit width={window.screen.width > 600 ? "200px" : "40%"} label='Volver' id="btnSolicitud" bgColor={blackColor} color={secundaryColor} type="button" onClick={onGoBack} />
                    <ButtonSubmit width={window.screen.width > 600 ? "200px" : "40%"} label="Aceptar" id="btnSolicitud" bgColor={errorColor} color={secundaryColor} type="submit" form="formDetails" />
                </ButtonsContainer>
            </Container>
        </Scrollbars>
    </Main>
