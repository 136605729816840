import { url_base, url_ocrend } from "./urls"
import Axios from "axios"

export const apiLogin = ({user, password}) => () => 
    fetch(`${url_base}/users/login/${user}/${password}`)
    .then( x => x.json())
    .then(v =>{
        
        if(v.length > 0){
            localStorage.setItem("userdata",JSON.stringify(v))
            return {success: 1, message: "Datos Correctos"}
        }
        else 
            return {success: 0, message: "Datos Incorrectos"}
    })

/** Obteniendo la lista de Usuarios */
export const apiGetUsers = () => () => fetch(`${url_base}/usuario/usuarios`).then( x => x.json()).then(v =>{    
    if(v.length > 0)
        return v
    else 
        return {success: 0, message: "Ha ocurrido un error interno"}
})
/** Obteniendo Usuario por id */
export const apiGetUserId = (data) => () => Axios.get(`${url_ocrend}/usuarios/${data}`).then(v => {
    const dataApi = v.data.data

    const data = {
        apellido: dataApi.per_apellido,
        direccion: dataApi.per_direccion,
        email: dataApi.per_email,
        identificacion: dataApi.per_identificacion,
        idtipousuario: dataApi.usu_tipousuario,
        nombre: dataApi.per_nombre,
        password: '',
        idusuario: dataApi.usu_id,
        idpersona: dataApi.per_id,
        telefono: dataApi.per_telefono,
        tipodocumento: dataApi.per_tipodocumento,
        usuario: dataApi.usu_nombreusuario,
        idsede: dataApi.usu_sede,
    }
    return {success: v.data.success, data}
    
}).catch(err => ({success: 0, message: 'Error interno'}))

/** Registrando nuevos usuarios */
export const apiPostUsers = (data) => () => {

    let form = new FormData()
    form.append('apellido', data.apellido)
    form.append('direccion', data.direccion)
    form.append('email', data.email)
    form.append('identificacion', data.identificacion)
    form.append('idtipousuario', data.idtipousuario)
    form.append('nombre', data.nombre)
    form.append('password', data.password)
    form.append('telefono', data.telefono)
    form.append('tipodocumento', data.tipodocumento)
    form.append('usuario', data.usuario)
    form.append('idsede', data.idsede)

    return Axios.post(`${url_ocrend}/usuarios/registrar`, form).then(v => {    

        if(v.data.success === 1)
            return v.data
        else 
            return {success: 0, message: "Ha ocurrido un error interno"}
    })
}
/** Actualizando usuarios */
export const apiUpUsers = (data) => () => {

    const form = new FormData()
    form.append('apellido', data.apellido)
    form.append('direccion', data.direccion)
    form.append('email', data.email)
    form.append('identificacion', data.identificacion)
    form.append('idtipousuario', data.idtipousuario)
    form.append('nombre', data.nombre)
    form.append('password', data.password)
    form.append('telefono', data.telefono)
    form.append('tipodocumento', data.tipodocumento)
    form.append('usuario', data.usuario)
    form.append('idsede', data.idsede)
    form.append('idpersona', data.idpersona)
    form.append('idusuario', data.idusuario)
    
    return Axios.post(`${url_ocrend}/usuarios/actualizar`, form).then(v => {
    if(v.data.success === 1)
        return v.data
    else 
        return {success: 0, message: "Ha ocurrido un error interno"}
    })
}
/** Eliminando usuarios */
export const apiDelUsers = (data) => () => Axios.get(`${url_base}/usuario/eliminar/${data}`).then(v =>{
    if(v.data === 1)
        return {success: 1, message: "Operación exitosa", id: data}
    else 
        return {success: 0, message: "Ha ocurrido un error interno"}
})

/** Obteniendo la lista de técnicos del taller */
export const apiGetTecnicos = () => () => fetch(`${url_base}/usuario/usuariotaller`).then( x => x.json()).then(v =>{    
    if(v.length > 0)
        return v
    else 
        return {success: 0, message: "Ha ocurrido un error interno"}
})

/** Obteniendo la lista de tipos de usuarios */
export const apiGetTypeUsers = () => () => Axios.get(`${url_base}/tipousuario/tipousuario`).then(v =>{
    if(v.data.length > 0)
        return {success: 1, data: v.data}
    else 
        return {success: 0, message: "Ha ocurrido un error interno"}
}).catch( v => ({success: 0, message: "Ha ocurrido un error interno"}) )

/** Obteniendo la lista de tipos de documentos */
export const apiGetTypeDoc = () => () => Axios.get(`${url_base}/tipodocumento/tipodedocumento`).then(v =>{
    if(v.data.length > 0)
        return {success: 1, data: v.data}
    else 
        return {success: 0, message: "Ha ocurrido un error interno"}
}).catch( v => ({success: 0, message: "Ha ocurrido un error interno"}) )




/** Obtener información del cliente */
export const apiGetClient = (data) => () => Axios.get(`${url_base}/usuario/cliente/${data}`).then(v => {
    if(v.data.length > 0 )
        return { success: 1, data: v.data}
    else
        return {success: 0, message:"No se encontraron datos"}
}).catch(e => ({success: 0, message:"No se ha establecido conexión con el servidor"}))