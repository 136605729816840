import React, { Component } from 'react'
import { connect } from 'react-redux'
import toastr from 'toastr'

import Form from '../../components/users/Form'
import { validations, errorColor, blackColor } from '../../../constanst'
import { postUsers, getTypeUsers, getTypeDoc, getUserId, upUsers } from "../../../redux/actions/userActions"
import { getSedes } from "../../../redux/actions/service-orders"
import { findAllByDisplayValue } from '@testing-library/react'

class New extends Component {

    constructor(props){
        super(props)
        this.state = {
            userForm: {},
            errorChange: {},
            typeUsersList: [], 
            typeDocuments: [],
            sedesList: [],
            loading: false
        }
        this.errorSubmit = false
        this.isMount = findAllByDisplayValue
    }

    async componentDidMount() {
        this.isMount = true
        this.setState({ loading: true })

        await this.props.getTypeUsers()
        await this.props.getTypeDoc()
        await this.props.getSedes()
        
        if(this.props.match.params.id)
            await this.props.getUserId(this.props.match.params.id)
        
        if(this.isMount === true){
            /** Cargando estados de las listas */
            if(this.props.typeUsers.success === 1 && this.props.typeDocument.success === 1)
                this.setState({ typeUsersList: this.props.typeUsers.data, typeDocuments: this.props.typeDocument.data, sedesList: this.props.sedes })

            /** Actualizando estados de usuario */
            if(this.props.users.success === 1)
                this.setState({ userForm: this.props.users.data })
        }


        this.setState({ loading: false })
    }
    /** Desmontaje  */
    componentWillUnmount() {
        this.isMount = false
    }
    

    /** Función que detecta un cambio en los inputs */
    handleChange = (e, isNull, isLetter, isNumber, isRange, minRange, maxRange, isEmail, notUpCase ) => {
        /** Declarando variables */
        const {name, value} = e.target

        /** Validando campos */
        const error = validations(e, isNull, isLetter, isNumber, isRange, minRange, maxRange, isEmail)
        this.setState({ 
            errorChange: { ...this.state.errorChange, [name]: error },
            userForm: { ...this.state.userForm, [name]: notUpCase ? value : value.toUpperCase() } 
        })
        
    }

    /** handleChange Select */
    handleChangeSel = (e) => {

        this.setState({ userForm: { ...this.state.userForm, [e.target.name]: e.target.value } })
        
        /** Añadiendo estilos */
        const input = document.getElementsByName(e.target.name)

        if(input[0].parentElement.nextSibling){
            input[0].parentElement.style.border = `1px solid ${blackColor}`
            input[0].parentElement.nextSibling.innerHTML = ""
        }
    }

    /** Submit de formulario */
    handleSubmit = async e => {
        e.preventDefault()
        
        this.setState({ loading: true })
        
        const inputs = e.target.elements
        /** formulario que recorre los inputs del formulario */
        for (let i = 0; i < inputs.length; i++){
            
            /** Varificando que los campos tengan algún valor */
            if(!!inputs[i].value === false && inputs[i].type !== "submit" && inputs[i].type !== "button"){
                
                /** Validando inputs texts */
                if(inputs[i].dataset.ignore === "false"){
                    this.errorSubmit=true
                    inputs[i].style.border = `1px solid ${errorColor}`
                    inputs[i].nextSibling.innerHTML = "El campo no puede estar vació"
                }
                
                /** Validando selects */
                if(inputs[i].dataset.ignore === undefined){
                    this.errorSubmit=true
                    inputs[i].parentElement.style.border=`1px solid ${errorColor}`
                    let node = document.createElement("SPAN")
                    node.classList.add("error")
                    node.style.color = errorColor
                    inputs[i].parentElement.parentNode.appendChild(node)
                    inputs[i].parentElement.nextSibling.innerHTML = "Selecciona una opción"
                }
                
            } else {
                if(this.state.errorChange[inputs[i].name])
                    this.errorSubmit = true;
            }
        }

        if(!this.errorSubmit){
            if(this.props.match.params.id)
                await this.props.upUsers(this.state.userForm)
            else
                await this.props.postUsers(this.state.userForm)

            this.setState({ loading: false })

            if(this.props.postResponseUsers.success === 1){
                this.props.history.push('/usuarios')
                toastr.success("Operación exitosa")
            }
            else 
                toastr.warning("Ha ocurrido un error interno")
        }
        
    }

    render() {
        return (
           <Form 
                state={this.state}
                param={this.props.match.params.id}
                onChange={this.handleChange}
                onChangeSelect={ this.handleChangeSel }
                onSubmit={this.handleSubmit}
           />
        )
    }
}

const mapStateToProps = ({ postResponseUsers, typeUsers, typeDocument, sedes, users }) => ({ postResponseUsers, typeUsers, typeDocument, sedes, users })

export default connect(mapStateToProps, { postUsers, getTypeUsers, getTypeDoc, getSedes, getUserId, upUsers })(New)