import React, { Component } from 'react'
import { connect } from 'react-redux'
import toastr from 'toastr'
import moment from 'moment'
import "toastr/build/toastr.css"

import ListV from '../../components/curriculumVitae/List'
import { getSedesId, getSedes } from "../../../redux/actions/service-orders"
import { getCurriculumVitae } from "../../../redux/actions/curriculumVitae"

class List extends Component {

    constructor(props){
        super(props)
        this.state = {
            sedesList: [],
            curriculumList: [],
            userdata: {},
            resSede: {},
            loading: false,
        }
        this.isMount = false
    }
    async componentDidMount(){
        const userdata = JSON.parse(localStorage.getItem("userdata"))
        this.isMount = true
        
        this.setState({ loading: true })
        await this.props.getSedesId(userdata[0].idusuario)
        await this.props.getSedes()
        await this.props.getCurriculumVitae()

        if(this.isMount){
            const nameSede = this.props.sedes[0].sed_nombre

            this.setState({
                loading: false,
                sedesList: this.props.sedes,
                curriculumList: this.props.curriculumVitae?.data || [],
                typeUser:  userdata[0].idtipousuario,
                userdata: {
                    idSede: userdata[0].usu_sede,
                    typeUser: userdata[0].idtipousuario,
                    idUser: userdata[0].idusuario,
                    nameSede,
                    dateF: 0,
                    selTser: 0,
                    selTserW: 0
                }
            })

        }

        
     
    }
    componentWillUnmount(){
        this.isMount = false
    }

       /** Filtrando resultados */
       onChange = async (e) => {
        const { name, value } = e.target

        this.setState({ [name]: value})
    }
    
    onKeyPressFilter = e =>{
        e.charCode === 13 && this.onFilter(e, e.target.name, e.target.value)
    } 
    /**
     * funcion de busqueda
     * @param {*} e 
     * @param {*} name 
     * @param {*} value 
     */
    onFilter = async (e, name, value) => {
        e.preventDefault()
        this.setState({ loading: true })
        const {idUser, typeUser} = this.state.userdata

        await this.props.getCurriculumVitae({ idUser, typeUser, name, value })
        this.setState({ loading: false, curriculumList: this.props.curriculumVitae?.data || [], })
    }

    /** Filtrar por fecha */
    onClickFindDate = () => {
        let values = []

        if(!this.state.dateStart || !this.state.dateFinal){
            toastr.warning("Seleccione un rango de fechas")
            return false
        }
        this.props.curriculumVitae.data.map( x => {
            if(moment(x.fecha) >= moment(this.state.dateStart) && moment(x.fecha) <= moment(this.state.dateFinal))
                values = [...values, x]
            return this.setState({curriculumList: values})
        })
    }


    render() {
        return (
            <ListV
                onChange={this.onChange}
                onChangeSel={this.onChangeSel}
                onChangeDate={e => this.setState({ [e.target.name]: e.target.value })}
                onClickFindDate={this.onClickFindDate}
                onFilter={this.onFilter}
                onKeyPressFilter={this.onKeyPressFilter}
                state={this.state}
                onDelete={this.handleDelete}
            />
        )
    }
}

const mapStateToProps = ({ sedes, postResponse, curriculumVitae }) => ({ sedes, postResponse, curriculumVitae })

export default connect(mapStateToProps, {
    getCurriculumVitae, getSedesId, getSedes })(List)