import React, {Fragment} from 'react'
import styled from 'styled-components'
import { MenuItem } from '@material-ui/core'

import { secundaryColor, iconColor, blackColor } from '../../../constanst'
import { Flex, LabelOption, Box } from "./ComponentsForm"
import ModuleInput from '../../common/ModuleInput'
import CustomSelect from '../../common/CustomSelect'
import ButtonSubmit from '../../common/ButtonSubmit'


export default  ({ state: {vehicleform, vehiclesList, serviceform, vehicleFilterList, menuA}, onChange, onBlur, onChangeSelect, onClickSelect}) => (
        <Fragment>
        {/** Contendor izquierdo */}
            <ModuleContainer>
            <Title bgcolor="transparent" color={blackColor}>IDENTIFICACIÓN DE LA MOTO</Title>
                <Box justify="space-between" direction="row">
                    <Box direction="column" position="relative" width="40%">
                        <ModuleInput label="N° DE MOTOR" type="text" name="formmotor" onBlur={onBlur}
                            backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="100%" 
                            onChange={e => onChange(e, 2, true, false, false, true, 1, 100)} value={vehicleform.formmotor || ''}
                        />
                        {menuA &&
                        <Box position="absolute" direction="column" top="80px" bgColor="#FFFFFF" left="15px" width="auto" shadow="1px 1px 5px 2px rgba(0,0,0,.5)" zIndex="100" radius="4px" padding="5px" overflow="auto" mHeight="300px">
                            {!!vehicleFilterList &&
                                vehicleFilterList.map( x => (
                                    <ButtonSubmit color={blackColor} key={x.hvv_id} textAlign='left' hColor="#cdcdcd" radius="none" type="button" bgColor="#ffffff"
                                    label={x.hvv_nmotor} onClick={e => onClickSelect(e, x.hvv_id, 2)} />
                                ))
                            }
                        </Box>
                        }
                    </Box>

                    <CustomSelect label="TIPO MOTO" inputw="60%" name="formvehiculo" onChange={e => onChangeSelect(e, 2)} value={vehicleform.formvehiculo || ''}>
                        {(vehiclesList !== undefined) &&
                            vehiclesList.map( x => 
                                <MenuItem key={x.idvalaores} value={x.rm_id}>{x.rm_nombremodelo}</MenuItem>
                        )}
                    </CustomSelect>
                </Box>
                <Box justify="space-between" direction="row">
                    <ModuleInput label="PLACA" type="text" name="formplaca" onFocus={e => onChange(e, 1, true)}
                        backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="50%" onBlur={e => onChange(e, 1, true)}
                        onChange={e => onChange(e, 2, true, false, false, true, 1, 50)} value={vehicleform.formplaca || ''}
                    />
                    <ModuleInput label="COLOR" type="text" name="formcolor" onFocus={e => onChange(e, 1, true)}
                        backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="50%" onBlur={e => onChange(e, 1, true)}
                        onChange={e => onChange(e, 2, true, false, false, true, 1, 50)} value={vehicleform.formcolor || ''}
                    />
                </Box>
                <Box justify="space-between" direction="row">
                    <ModuleInput label="N° IDENTIFICACION PARA ALISTAMIENTO INCOLMOTOS" type="number" name="formincolmotos"
                        backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="80%" ignore={!(serviceform.formTrabajogarantia >= 8 && serviceform.formTrabajogarantia <= 14)}
                        onChange={e => onChange(e, 2, !(serviceform.formTrabajogarantia >= 8 && serviceform.formTrabajogarantia <= 14), false, false, true, 1, 100)} value={vehicleform.formincolmotos || ''}
                    />
                    {/* <ModuleInput label="SERIE" type="text" name="formserie"
                        backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="33%" 
                        onChange={e => onChange(e, 2, true, false, false, true, 1, 100)} value={vehicleform.formserie || ''}
                    /> */}
                </Box>
                <Box justify="space-between" direction="row">
                    <ModuleInput label="KM" type="number" name="formkm" 
                        backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="33%" 
                        onChange={e => onChange(e, 2, true, false, false, true, 1, 100)} value={vehicleform.formkm || ''}
                    />
                    <CustomSelect label="GASOLINA" inputw="33%" value={vehicleform.formgasolina  || ''} name="formgasolina" onChange={e => onChangeSelect(e, 2)}>
                        <MenuItem value="FULL">FULL</MenuItem>
                        <MenuItem value="MEDIO">MEDIO</MenuItem>
                        <MenuItem value="BAJO">RESERVA</MenuItem>
                    </CustomSelect>
                </Box>
                <InputContainer>
                    <Label>CUSTODIA DE PAPELES</Label>
                    <Flex>
                        <LabelOption>SI</LabelOption> <input type="radio" name="custodia" value="1" checked={vehicleform.custodia === '1'} onChange={e => onChange(e, 2)} data-ignore={false} />
                        <LabelOption>NO</LabelOption> <input type="radio" name="custodia" value="2" checked={vehicleform.custodia === '2'} onChange={e => onChange(e, 2)} data-ignore={false} />
                    </Flex>
                </InputContainer>
                
            </ModuleContainer>
        </Fragment>
)


const ModuleContainer = styled.div`
    flex: 1;
    padding: 0 10px 0px 20px;
    
`
const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 5px 10px;
    border-radius: 15px;
    margin: 10px 0px;
    transition: .5s;
`
const Label = styled.label`
    color: ${blackColor};
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif;
    font-size: 12px;
    font-weight: 500;
`
const Title = styled.h3`
    color: ${({color}) => color ? color : secundaryColor};
    padding: 5px;
    margin-top: 0px;
    text-align: center;
`