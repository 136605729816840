import React from 'react'
import styled from 'styled-components'
import SignatureCanvas from 'react-signature-canvas'
import { primayColor, blackColor, secundaryColor } from '../../constanst'

export default ({ sigPad, clearPad, trimPad }) => {
    let height = 200, width = 100
    const a = document.getElementById("main")
    
    if(a !== null){
        height = a.offsetHeight - 150;
        width = a.offsetWidth - 50;
    }
    return(
        <ModalContainer id="modalFirma">
            <ModalContent>
                {/** Leyendo la enrada de la firma */}
                <SignatureCanvas penColor={blackColor}
                    canvasProps={{width: width, height: height}}
                    ref={ ref => {sigPad(ref)} }    
                />
            </ModalContent>
            <FooterModal>
                <ButtonModal bgColor={blackColor} onClick={() => onCloseModal("modalFirma")}>Cerrar</ButtonModal>
                <div>
                    <ButtonModal bgColor={primayColor} onClick={clearPad}>Limpiar</ButtonModal>
                    <ButtonModal bgColor={primayColor} onClick={trimPad}>Guardar</ButtonModal>
                </div>
            </FooterModal>
        </ModalContainer>
    )


}



export const openModal = (id) => {
    document.getElementById(id).style.display = "flex"
}


export const onCloseModal = (id) => {
    document.getElementById(id).style.display = "none"
}


const ModalContainer = styled.div`
    display: none;
    flex: 1;
    position: absolute;
    z-index: 999;
    top: 0px;
    left: 0px;
    background-color: #efefefef;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
`
const ModalContent = styled.div`
    flex: .7;
    width: 97%;
    height: 80%;
    border: 1px solid ${blackColor};
`
const FooterModal = styled.div`
    flex: .2;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 10px;
    width: 100%;
`
const ButtonModal = styled.button`
    background-color: ${({ bgColor }) => bgColor};
    color: ${secundaryColor};
    font-size: 16px;
    width: 120px;
    border-radius: 10px;
    padding: 8px 20px;
    border: none;
    margin: 5px 15px;
    outline: 0;

    &:hover {cursor: pointer;}
`
