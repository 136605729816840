import React from 'react'
import styled, { css } from 'styled-components'

export default ({onClick, onClickCapture, bgColor, hColor, width, label, color, type, id, textAlign, radius, form, style }) => 
<ButtonSubmit 
    id={id}
    onClick={onClick}
    bgColor={bgColor} 
    width={width}
    radius={radius}
    color={color}
    hColor={hColor}
    type={type}
    onClickCapture={onClickCapture}
    textAlign={textAlign}
    form={form}
    style={style}
    >{label}
</ButtonSubmit>



const ButtonSubmit = styled.button`
    color: ${({color}) => color };
    font-size: 10px;
    border: none;
    border-radius: ${({radius}) => radius ? radius : '25px'};
    outline: 0;
    padding: 6px;
    text-align: ${({textAlign}) => textAlign ? textAlign : 'center'};

    &:hover {
        cursor: pointer; 
        background-color: ${({hColor}) => hColor ? hColor : 'inerith' };
    }

    ${({ bgColor }) => bgColor && css`
        background-color: ${bgColor};
    `}
    ${({ width }) => width && css`
        width: ${width}
    `}
`