import React, {Fragment} from 'react'
import styled from 'styled-components'  

import { secundaryColor, iconColor, blackColor } from '../../../constanst'
import { Box } from "./ComponentsForm"
import ModuleInput from '../../common/ModuleInput'


export default  ({ state: { vehicleidentity } }) => (
        <Fragment>
        {/** Contendor izquierdo */}
            <ModuleContainer>
            <Title bgcolor="transparent" color={blackColor}>IDENTIFICACIÓN DE LA MOTO</Title>
                <Box justify="space-between" direction="row">
                    <Box direction="column" position="relative" width="50%">
                        <ModuleInput label="N° DE MOTOR" type="text" name="hvv_nmotor" disabled backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="100%" value={vehicleidentity.hvv_nmotor || ''}/>
                    </Box>
                    <ModuleInput label="MODELO" type="text" name="rm_nombremodelo" disabled backgroundC={secundaryColor} borderC={iconColor} inputw="50%" value={vehicleidentity.rm_nombremodelo || ''}/>
                    
                    {/* <CustomSelect label="TIPO MOTO" inputw="60%" name="formvehiculo" onChange={e => onChangeSelect(e, 2)} value={vehicleform.formvehiculo || ''}>
                        {(vehiclesList !== undefined) &&
                            vehiclesList.map( x => 
                                <MenuItem key={x.idvalaores} value={x.rm_id}>{x.rm_nombremodelo}</MenuItem>
                        )}
                    </CustomSelect> */}
                </Box>
                <Box justify="space-between" direction="row">
                    <ModuleInput label="PLACA" type="text" name="hvv_placa" disabled backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="50%" value={vehicleidentity.hvv_placa || ''} />
                    <ModuleInput label="COLOR" type="text" name="hvv_color" disabled backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="50%" value={vehicleidentity.hvv_color || ''}/>
                </Box>                              
            </ModuleContainer>
        </Fragment>
)


const ModuleContainer = styled.div`
    flex: 1;
    padding: 0 10px 0px 20px;
    
`
const Title = styled.h3`
    color: ${({color}) => color ? color : secundaryColor};
    padding: 5px;
    margin-top: 0px;
    text-align: center;
`