import React, { Component } from 'react'
import { connect } from 'react-redux'
import toastr from 'toastr'
import "toastr/build/toastr.css"

import { getHistoryCrm, upRememberCall, sendEmailCrm, confirmAppointment } from '../../../redux/actions/service-orders'
import { getClients } from '../../../redux/actions/userActions'
import CRMhistoryV from '../../components/services/CRMhistory'
import {openModal, onCloseModal} from '../../common/Modal'


class CRMhistory extends Component {
    constructor(props){
        super(props)
        this.state = {
            loading: false,
            formAgend: {},
            schedulingList: [],
            user: []
        }
        this.isMount = false
    }

    async componentDidMount() {
        this.isMount = true
        this.setState({loading: true})

        if(!!this.props.match.params.id){
            await this.props.getHistoryCrm(this.props.match.params.id)
            await this.props.getClients(this.props.match.params.id)
            
            
            if(this.isMount && this.props.schedulingList.success === 1 && this.props.clients.success === 1)
                this.setState({schedulingList: this.props.schedulingList.data, user: this.props.clients.data[0]})

        }

        
        

        this.isMount && this.setState({loading: false})
    }

    /** Opening modal */
    handleModal = (e, id) => {
        e.preventDefault()

        const ordenSel = this.state.schedulingList.find(x => x.agen_id === id)
        this.setState({formAgend: {id_agen: id, date: ordenSel.agen_recordatorio, obs: ordenSel.agen_observacion}})
        
        openModal("modalAgen")
    }
    closeModal = () => {
        onCloseModal("modalAgen")
        this.isMount &&
            this.setState({ formAgend: {} })
    }

    /** Actualizar recordatorio */
    handleSubmit = async e => {
        e.preventDefault()
        this.setState({loading: true})

        await this.props.upRememberCall(this.state.formAgend)

        if(this.props.postResponse.success === 1)
            toastr.success("Actualización exitosa")
        else 
            toastr.success("Ha ocurrido un error")

        
        this.closeModal()
        
        await this.props.getHistoryCrm(this.props.match.params.id)
        if(this.isMount && this.props.schedulingList.success === 1)
            this.setState({schedulingList: this.props.schedulingList.data, loading: false})
    }

    /** Enviar el correo */
    handleClickEmail = async (e, idTg, email, idAgen, name, idSer) => {
        e.preventDefault()
        const userdata = JSON.parse(localStorage.getItem('userdata'))
        this.setState({loading: true})

        await this.props.sendEmailCrm({idSede: userdata[0].usu_sede, idTg, email, idAgen, name, idSer})
            if(!!this.props.postResponse.success) {
                await this.props.getHistoryCrm(this.props.match.params.id)
    
                this.setState({schedulingList: this.props.schedulingList.data})
                toastr.success(this.props.postResponse.message)
            } else  
            toastr.warning(this.props.postResponse.message)

        !!this.isMount &&
            this.setState({loading: false})
    }

    /** Confirmar agendamiento */
    handleClickConfirm = async (e, idSer) => {
        e.preventDefault()

        this.setState({loading: true})
        await this.props.confirmAppointment(idSer)

        if (!!this.props.postResponse.success) {
            toastr.success(this.props.postResponse.message) 
            await this.props.getHistoryCrm(this.props.match.params.id)
    
            this.setState({schedulingList: this.props.schedulingList.data})
        } else 
            toastr.warning(this.props.postResponse.message)
        
        this.setState({loading: false})
    }

    render() {
        return (
            <CRMhistoryV
                state={this.state}
                openModal={this.handleModal}
                closeModal={this.closeModal}
                onChange={e => this.setState({ formAgend: {...this.state.formAgend, [e.target.name]: e.target.value}  })}
                handleClickEmail={this.handleClickEmail}
                handleClickConfirm={this.handleClickConfirm}
                onSubmit={this.handleSubmit}
            />
        )
    }
}

const mapStateToProps = ({ schedulingList, clients, postResponse }) => ({ schedulingList, clients, postResponse })

export default connect(mapStateToProps, { getHistoryCrm, getClients, upRememberCall, sendEmailCrm, confirmAppointment })(CRMhistory)