import React from 'react'
import styled, { css } from 'styled-components'

import { secundaryColor, primayColor, iconColor, blackColor } from '../../../constanst'
import ModuleInput from '../../common/ModuleInput'
import { Title, Box } from "./ComponentsForm"

export default ({ state, equal, onChange, onChangeCheck }) => (
        <ModuleContainer>
            <Title bgcolor="transparent" color={blackColor}>IDENTIFICACIÓN DEL QUIÉN SOLICITA EL SERVICIO</Title>
                <Box direction="row" padding="5px 10px">
                    <input id="prop" type="checkbox" name="equal" value={state.equal} checked={state.equal} onChange={onChangeCheck} data-ignore={true} />
                    <Label htmlFor="prop">EL PROPIETARIO ES QUIEN SOLICITA EL SERVICIO</Label>
                </Box>
                <Box justify="space-between" direction="row">
                    <ModuleInput label="NOMBRE COMPLETO" name="formpersonaservicio" type="text" onBlur={e => onChange(e, 1, true)}
                        backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="20%" onFocus={e => onChange(e, 1, true)}
                        onChange={e => onChange(e, 1, true, true, false, true, 2, 50)} value={state.formpersonaservicio || ''}
                    />
                    <ModuleInput label="CEDULA" type="text" name="formcedulaservicio" onBlur={e => onChange(e, 1, true)}
                        backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="20%" onFocus={e => onChange(e, 1, true)}
                        onChange={e => onChange(e, 1, true, false, true, true, 2, 20)} value={state.formcedulaservicio || ''}
                    />
                    <ModuleInput label="E-MAIL" name="formpersonaemail" type="text" onBlur={e => onChange(e, 1, true)}
                        backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="20%" onFocus={e => onChange(e, 1, true)}
                        onChange={e => onChange(e, 1, true, false, false, false, false, false, true)} value={state.formpersonaemail || ''}
                    />
                    <ModuleInput label="CELULAR" type="text" name="formtelefonocelular" onBlur={e => onChange(e, 1, true)}
                        backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="20%" onFocus={e => onChange(e, 1, true)}
                        onChange={e => onChange(e, 1, false, false, true, true, 2, 15)} value={state.formtelefonocelular || ''}
                    />
                    <ModuleInput label="TELÉFONO" type="text" name="formpersonatelefono" onBlur={e => onChange(e, 1, true)}
                        backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="20%" onFocus={e => onChange(e, 1, true)}
                        onChange={e => onChange(e, 1, true, false, true, true, 2, 20)} value={state.formpersonatelefono || ''}
                    />
                </Box>
        </ModuleContainer>
)

const ModuleContainer = styled.div`
    flex: 1;
    padding: 0 10px 0px 20px;
    display: flex;
    flex-direction: column;
    
    ${({ borderLeft }) => borderLeft && css`border-left: 1px solid ${primayColor};`}
`
const Label = styled.label`
    color: ${blackColor};
    padding: 0 8px;
    ::selection { background-color: transparent; }
    font-size: 12px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
`