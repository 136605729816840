import React from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { IconEdit } from '../../../assets/icons'
import { blackColor, iconColor, secundaryColor, errorColor, alternateVariantColor } from '../../../constanst'
import ButtonSubmit from '../../common/ButtonSubmit'
import { Loading } from '../../common/Loading'
import ModuleInput from '../../common/ModuleInput'
import { ButtonsContainer } from '../../common/Table'
import TextArea from '../../common/TextArea'
import { TableContainer, Table, Th, Tr, Td, ContainerButton } from '../services/ComponentsForm'
import Header from './Header'
import IdenMoto from './IdenMoto'
import { Main, Container, Title, ModuleContainer, Subtitle, Button, BoxIdent } from './Styles'


export default ({ state, onChange, onChangeMultiple, onUpdate, onClickButton, onCanUp, onGoBack, onSubmit }) =>
    <Main>
        {state.loading && <Loading />}
        <Scrollbars>
            <Container>
                <Header docTitle="ORDEN DE TRABAJO Y DIAGNÓSTICO" sedeUser={state.formsede} />

                {/** Cuerpo de la función */}
                <IdenMoto />

                {/** Detalles del servicio */}
                <form onSubmit={onSubmit} id="formDetails">
                    <ModuleContainer>
                        <br />
                        <Title bgColor="transparent" color={blackColor}>DETALLES DEL SERVICIO</Title>
                        <br />
                        <BoxIdent>
                            <ModuleInput label="HORA DE INGRESO AL TALLER" type="time" name="h_i" onChange={e => onChange(e, true)}
                                backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw={window.screen.width > 600 ? "25%" : "100%"} value={state.form.h_i || ''}
                            />
                            <ModuleInput label="HORA APROXIMADA DE ENTREGA" type="time" name="h_s" onChange={e => onChange(e, true)}
                                backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw={window.screen.width > 600 ? "30%" : "100%"}
                                value={state.form.h_s || ''}
                            />
                            <ModuleInput label="VALOR TENTATIVO DE MANO DE OBRA" name="vt_mo" onChange={e => onChange(e, true, false, true, true, 2, 500)}
                                backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw={window.screen.width > 600 ? "33%" : "100%"}
                                value={state.form.vt_mo || ''}
                            />
                            <ModuleInput label="VALOR SUGERIDO POR EL TÉCNICO" name="v_st" onChange={e => onChange(e, true, false, true, true, 2, 500)}
                                backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw={window.screen.width > 600 ? "33%" : "100%"}
                                value={state.form.v_st || ''}
                            />
                        </BoxIdent>
                        <BoxIdent>
                            <TextArea label="CONCEPTO DEL TÉCNICO (DIAGNÓSTICO)" name="dg_s" onChange={e => onChange(e, true, false, false, true, 2, 500)}
                                backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw={window.screen.width > 600 ? "50%" : "100%"} ignore="false"
                                value={state.form.dg_s || ''} rows="5"
                            />
                            <TextArea label="RECOMENDACIONES ESPECIALES" name="rec_t" onChange={e => onChange(e, true, false, false, true, 2, 500)}
                                backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw={window.screen.width > 600 ? "50%" : "100%"} ignore="false"
                                value={state.form.rec_t || ''} rows="5"
                            />
                        </BoxIdent>
                    </ModuleContainer>
                </form>

                <br />
                <ModuleContainer>
                    <Subtitle>EL CLIENTE SOLICITA</Subtitle>
                    <BoxIdent>
                        <TextArea label="SOLICITUD DEL CLIENTE" name="formsolicitudes" rows="5" disabled
                            backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw={window.screen.width > 600 ? "50%" : "100%"}
                            onChange={e => onChangeMultiple(e, false, false, false, true, 2, 500)} value={state.clienteSol.formsolicitudes || ''}
                        />
                        <TextArea label="SOLUCIÓN DADA" name="formsolucion" rows="5"
                            backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw={window.screen.width > 600 ? "50%" : "100%"}
                            onChange={e => onChangeMultiple(e, false, false, false, true, 2, 500)} value={state.clienteSol.formsolucion || ''}
                        />
                    </BoxIdent>

                    <ContainerButton>
                        {state.editSol && <>
                            <ButtonSubmit width="200px" label='Actualizar' id="btnSolicitud" bgColor={blackColor} color={secundaryColor} type="button" onClick={onClickButton} />
                            {/* <ButtonSubmit width="200px" label="Cancelar" id="btnSolicitud" bgColor={errorColor} color={secundaryColor} type="button" onClick={onCanUp} /> */}
                        </>}
                    </ContainerButton>

                    <TableContainer>
                        <Subtitle>LISTA DE SOLICITUDES</Subtitle>
                        <Table>
                            <thead>
                                <Tr>
                                    {window.screen.width > 600 && <Th>#</Th>}
                                    <Th>Solicitud</Th>
                                    <Th>Solución</Th>
                                    <Th></Th>
                                </Tr>
                            </thead>
                            <tbody>
                                {state.solicitudform.clienteSolicitud.length > 0 ?
                                    state.solicitudform.clienteSolicitud.map((element, index) => (
                                        <Tr key={index}>
                                            {window.screen.width > 600 && <Td>{index + 1}</Td>}
                                            <Td style={{ padding: 5 }}>{element.formsolicitudes}</Td>
                                            <Td style={{ padding: 5 }}>{element.formsolucion}</Td>
                                            <Td style={{ textAlign: 'center', padding: 0 }}>
                                                {element.idobs &&
                                                    <Button onClick={e => onUpdate(e, element.idobs)}>
                                                        <IconEdit width="25px" height="25px" color={alternateVariantColor} bgColor="transparent" />
                                                    </Button>}
                                            </Td>
                                        </Tr>
                                    ))
                                    : <Tr><Td textAlign="center" colSpan="4">No hay solicitudes para mostrar</Td></Tr>
                                }
                            </tbody>
                        </Table>
                    </TableContainer>
                </ModuleContainer>
                <br />
                <br />
                <br />
                <ButtonsContainer justify="space-between">
                    <ButtonSubmit width={window.screen.width > 600 ? "200px" : "40%"} label='Volver' id="btnSolicitud" bgColor={blackColor} color={secundaryColor} type="button" onClick={onGoBack} />
                    <ButtonSubmit width={window.screen.width > 600 ? "200px" : "40%"} label="Aceptar" id="btnSolicitud" bgColor={errorColor} color={secundaryColor} type="submit" form="formDetails" />
                </ButtonsContainer>
            </Container>
        </Scrollbars>
    </Main>
