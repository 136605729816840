import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Table } from 'react-virtualized'
import styled from 'styled-components'
import { bgColor, primayVariantColor, secundaryColor, successColor } from '../../constanst'
import { Th, Tr, Td } from '../components/trabajos/Styles'
import { Loading } from '../common/Loading'
import { IconCheck, IconDelete, IconPlus } from '../../assets/icons'
import Modal from '../common/ModalC'
import { onCloseModal, openModal } from '../common/Modal'
import ClientForm from '../components/services/ClientFormCRM'
import { useFormTools } from 'form-tool'
import { ButtonsContainer } from '../common/Table'
import ButtonBack from '../common/Button'
import axios from 'axios'
import toastr from 'toastr'
import { url_base } from '../../redux/api/urls'
import { Link } from 'react-router-dom'

const SchedulingList = ({ state, handleList, handleScheduling, schedulingConfirm, onClickVisited, handleNewScheduling }) => {
    // States and Variables
    const [loading, setLoading] = useState(false)
    const [userData] = useState(() => {
        try { return JSON.parse(localStorage.getItem('userdata'))
        } catch (_err) { return [] }
    })
    // Form Tools
    const [onChange, onSubmit, { dataForm, errorForm, setForcedData }] = useFormTools()

    /**
     * Función para registrar
     * @param {object} e Objeto del evento
     * @returns {void}
     */
    const handleSubmit = e => onSubmit({
        event: e,
        action: () => {
            setLoading(true)
            return axios.post(`${url_base}/servicio/registerPartialService`, { ...dataForm, idusuario: userData[0]?.idusuario, formsede: userData[0]?.usu_sede })
        },
        actionOfSuccess: res => {
            if (res?.data?.success === 1) {
                toastr.success(res.data.message)
                setLoading(false)
                onCloseModal('schedulingClient')
                setForcedData({})
                handleScheduling()
                return
            }
            
            toastr.warning(res?.data?.message || 'Ha ocurrido un error')
        },
        actionOnError: () => toastr.warning('Ha ocurrido un error')
    })

    return (<>
        {loading && <Loading />}
        <BoxPanel active={state.active} onClick={handleList} />
        <Modal title='NUEVO AGENDAMIENTO' id='schedulingClient'>
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <ClientForm
                    leftMode
                    onChange={onChange}
                    dataForm={dataForm}
                    errorForm={errorForm}
                    clientList={state.clientList} 
                    menuActive={state.menuActive}
                    // onBlur={onBlur}
                    // onClickSelect={onClickSelect}
                    autoCom={state.autoCom}
                    // onChangeSelect={onChangeSelect} 
                />
                 <ButtonsContainer>
                    <ButtonBack label="Cerrar" type="button" width="40%" bgColor={'transparent'} color={primayVariantColor} borderC={primayVariantColor} onClick={() => onCloseModal('schedulingClient')} />
                    <ButtonSubmit label="Guardar" type="submit" width="40%" bgColor={primayVariantColor} color={secundaryColor}>Guardar</ButtonSubmit>
                </ButtonsContainer>
            </form>
        </Modal>
        <Main active={state.active}>
            {state.loading && <Loading />}

            <Button onClick={() => openModal('schedulingClient')} title='Nuevo Agendamiento'><IconPlus color={bgColor} width='20px' height='20px' /></Button>
            <TitleBox onClick={handleList}>
                <Title>AGENDAMIENTOS DIARIOS</Title>
            </TitleBox>
            <>
                <Table
                    style={{ width: 400 }}
                    autoContainerWidth
                    gridStyle={{ width: '100%' }}
                    height={400}
                    headerHeight={40}
                    width={1850}
                    rowHeight={50}
                    containerStyle={{ width: '100%' }}
                    rowCount={schedulingConfirm.data ? schedulingConfirm.data.length : 0}
                    rowGetter={({ index }) => schedulingConfirm.data[index]}

                    rowStyle={{ width: '100%' }}
                    headerRowRenderer={() =>
                        <Tr className="ReactVirtualized__Table__headerRow" bgColor style={{ width: schedulingConfirm.data && schedulingConfirm.data.length > 8 ? '97%' : '100%' || '100%' }}>
                            <Th elipsis width={5} fontSize="10px">#</Th>
                            <Th elipsis width={24} fontSize="10px" title="MOTO">MOTO</Th>
                            <Th elipsis width={24} fontSize="10px" title="TRABAJO">TRABAJO</Th>
                            <Th elipsis width={24} fontSize="10px" title="CLIENTE">CLIENTE</Th>
                            <Th elipsis width={24} fontSize="10px" title="TELEFONO">TELEFONO</Th>
                            <Th elipsis width={24} fontSize="10px" title="TELEFONO">Aceptar</Th>
                            <Th elipsis width={24} fontSize="10px" title="TELEFONO">Eliminar</Th>
                        </Tr>
                    }
                    rowRenderer={e =>
                        <Link key={e.key} to={`/servicios/orden/${ e.rowData.ds_idservicio }`}>
                            <Tr className="ReactVirtualized__Table__row" style={e.style} >
                                <Td elipsis width={5} fontSize="9px" >{e.index + 1}</Td>
                                <Td elipsis width={24} fontSize="9px" title={e.rowData.rm_nombremodelo}>{e.rowData.rm_nombremodelo}</Td>
                                <Td elipsis width={24} fontSize="9px" title={e.rowData.tg_nombre}>{e.rowData.tg_nombre}</Td>
                                <Td elipsis width={24} fontSize="9px" title={`${e.rowData.clit_nombre} ${e.rowData.clit_apellido}`}>{e.rowData.clit_nombre}</Td>
                                <Td elipsis width={24} fontSize="9px" title={e.rowData.clit_telefono}>{e.rowData.clit_telefono}</Td>
                                <Td elipsis width={24} fontSize="9px" onClick={() => onClickVisited(e.rowData.agen_id, 1)}><IconCheck width='20px' height='' color={ successColor }/> </Td>
                                <Td elipsis width={24} fontSize="9px" onClick={() => onClickVisited(e.rowData.agen_id, 0)}><IconDelete color={ primayVariantColor } width='30px' bgColor={ secundaryColor } /></Td>
                            </Tr>
                        </Link>
                    }
                    noRowsRenderer={() =>
                        <Tr className="ReactVirtualized__Table__row">
                            <Td elipsis width={100} fontSize="9px">No se encontraron agendamientos pendientes para hoy</Td>
                        </Tr>
                    }
                >
                </Table>
            </>
        </Main>
    </>)
}


const Main = styled.div`
    position: fixed;
    bottom: ${({ active }) => active ? 0 : -390}px;
    background-color: #ffffff;
    left: 0;
    z-index: 99;
    transition: .5s;
    box-shadow: 0 0 5px 1px #00000055;
    border-radius: 8px 8px 0 0;
    width: 100%;

    @media (min-width: 600px){
        left: 10px;
        width: auto;
    }
`
const TitleBox = styled.div`
    background-color: ${primayVariantColor};
    padding: 5px;
    border-radius: 8px 8px 0 0;
    text-align: center;
    cursor: pointer;
`
const Title = styled.h5`
    color: ${secundaryColor};
    margin: 0;
    padding: 0; 
`
const BoxPanel = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: ${({ active }) => active ? 90 : -1};
    background-color: #77777755;
`
const Button = styled.button`
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    border-radius: 50%;
    background-color: ${ successColor };
    width: 40px;
    height: 40px;
    cursor: pointer;
    z-index: 99;

    :hover {
        box-shadow: 0 0 5px 2px #00000055;
    }
`
const ButtonSubmit = styled.button`
    position: relative;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    background-color: #C1272D;
    width: 40%;
    border-radius: 15px;
    border: none;
    outline: 0px;
    padding: 8px 10px;
    margin: 0px 10px;
    cursor: pointer;
    color: #ffff;
` 

const mapStateToProps = ({ schedulingConfirm, agen }) => ({ schedulingConfirm, agen })
export default connect(mapStateToProps, null)(SchedulingList)