import React from 'react'
import InputText from './InputText'
import styled from 'styled-components'
import { blackColor } from '../../constanst'

export default ({ label, type, name, onChange, value, backgroundC, borderC, width, inputw, errorMessage, ignore, onKeyPress, onBlur, onFocus, errorM, autoCom, disabled, tAlign }) => (
    <InputContainer inputw={inputw}>
        <Label tAlign={tAlign}>{label}</Label>
        <InputText type={type} name={name} onChange={onChange} onBlur={onBlur} value={value} backgroundC={backgroundC} borderC={borderC} width={width} errorMessage={errorMessage} 
        ignore={ignore} onKeyPress={onKeyPress} onFocus={onFocus} errorM={errorM} autoCom={autoCom} disabled={disabled} />
    </InputContainer>
)

/** estilos para el module container */
const InputContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: ${({inputw}) => inputw };
    padding: 0 10px;
`
const Label = styled.label`
    text-align: ${ props => props.tAlign ? props.tAlign : 'center' };
    color: ${blackColor};
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif;
    font-size: 12px;
    font-weight: 500;
`
