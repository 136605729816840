import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

/** Layout */
import Layout from './app/Layout'
import PrivateRoute from './app/common/PrivateRoute'

/** Login and home */
import Login from './app/pages/Login'
import Home from './app/pages/Home'

/** Servicios */
import OrdenServicio from './app/pages/services/Orden'
import SeguimientoServicio from './app/pages/services/Seguimiento'
import CRM from './app/pages/services/CRM'
import CRMHistory from './app/pages/services/CRMhistory'
import MapaTallerForm from './app/pages/services/MapaTallerForm'
import WorkshopMap from './app/pages/services/WorkshopMap'

/** Mecánicos - Técnicos */
import WorksTracing from "./app/pages/trabajos"
import WorkOrder from "./app/pages/trabajos/WorkOrder"
import Layoff from "./app/pages/trabajos/Layoff"
import Quote from "./app/pages/trabajos/Quote"

/** Usuarios */
import UserList from './app/pages/users'
import UserForm from './app/pages/users/Form'

/** Parametrizaciones */
import Values from './app/pages/parameterization/Values'
import Services from './app/pages/parameterization/Services'
import Motorcyles from './app/pages/parameterization/Motorcycle'
import FormMotorcycle from './app/pages/parameterization/FormMotorcycle'
import ValuesVehicles from './app/pages/parameterization/ValuesVehicles'
import CurriculumVitae from './app/pages/curriculumVitae'
import ListCotizations from './app/pages/cotizations/listcotizations'
import ListCurriculumVitae from './app/pages/curriculumVitae/List'

/** Usuario libre */
import Public from './app/pages/public'
import ConfirmAppointment from './app/pages/public/ConfirmAppointment'
import { InitConfigInput } from 'form-tool'

InitConfigInput.configStyles({
  boxInput: {
    styles: {
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      padding: '0 5px',
    }
  },
  input: {
    color: '#353b41',
    borderRadius: '25px',
    border: '1px solid #b4b4b4',
    minHeight: '30px'
  },
  label: {    
    fontSize: '12px',
    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif',
    styles: { fontWeight: '600', paddingTop: '0px', paddingBottom: '8px', margin: '0', color: '#353b41', }
  },
  tooltip: {
    styles: {
      top: '15px',
      right: '31px',
      bottom: 'auto',
      left: 'auto',
      boxShadow: 'none',
      border: 'none',
    }
  }
})

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={Login} />
        <Route exact path='/login' component={Login} />
        <Route exact path='/public/:token' component={Public} />
        <Route exact path='/public/cita/:token' component={ConfirmAppointment} />
        <Layout>
          <PrivateRoute exact path="/home" component={ Home } />
          {/* servicios  */}
          <PrivateRoute exact path="/servicios/orden/:id?" component={ OrdenServicio } />
          <PrivateRoute exact path="/servicios/seguimiento" component={ SeguimientoServicio } />
          <PrivateRoute exact path="/servicios/seguimiento/crm" component={ CRM } />
          <PrivateRoute exact path="/servicios/seguimiento/crm-historia/:id" component={ CRMHistory } />
          <PrivateRoute exact path="/servicios/mapataller/form/:id" component={ MapaTallerForm } />
          <PrivateRoute exact path="/servicios/mapataller/" component={ WorkshopMap } />
          {/** Mecánicos - trabajos */}
          <PrivateRoute exact path="/trabajos/seguimiento" component={ WorksTracing } />
          <PrivateRoute exact path="/trabajos/orden/:id" component={ WorkOrder } />
          <PrivateRoute exact path="/trabajos/supender/:id" component={ Layoff } />
          <PrivateRoute exact path="/trabajos/cotizar/:id" component={ Quote } />
          {/* usuarios */}
          <PrivateRoute exact path="/usuarios/" component={ UserList } />
          <PrivateRoute exact path="/usuarios/reguser/:id?" component={ UserForm } />
          {/* Parametrizaciones */}
          <PrivateRoute exact path="/parametrizacion/valores" component={ Values } />
          <PrivateRoute exact path="/parametrizacion/servicios" component={ Services } />
          <PrivateRoute exact path="/parametrizacion/motos" component={ Motorcyles } />
          <PrivateRoute exact path="/parametrizacion/motos/formulario/:id?" component={ FormMotorcycle } />
          <PrivateRoute exact path="/parametrizacion/motos/valores" component={ ValuesVehicles } />
          <PrivateRoute exact path="/cotizaciones" component={ ListCotizations }/>
          {/* Vehículos */}
          <PrivateRoute exact path="/motos/historia" component={ ListCurriculumVitae } />
          <PrivateRoute exact path="/motos/historia/:id" component={ CurriculumVitae } />
        </Layout>
      </Switch>
    </BrowserRouter>
  )
}

export default App;
