import React, { Component } from 'react'
import { connect } from 'react-redux'
import toastr from 'toastr'
import "toastr/build/toastr.css"

import Motorcycles from '../../components/parameterization/Motorcycles'
import { getMotorcycles, delMotorcycles } from "../../../redux/actions/Parameterizations"

class Motorcycle extends Component {

    constructor(props){
        super(props)

        this.state = { motorcycles: [], loading: false}
        this.isMount = false
    }

    async componentDidMount() {
        /** Parametros de config */
        this.setState({ loading: true })
        this.isMount = true

        await this.props.getMotorcycles()

        if(this.isMount && this.props.motorcycles.success === 1)
            this.setState({ motorcycles: this.props.motorcycles.data })

        
        /** actualizando parametros */
        this.setState({ loading: false })
        
    }
    componentWillUnmount(){
        this.isMount = false
    }
    
    /** On Delete, función para eliminar usuarios */
    handleDelete = async (e, id) => {
        this.setState({loading: true})

        await this.props.delMotorcycles(id)
        if(this.isMount && this.props.motorcycles.success === 1){
            this.setState({ motorcycles: this.props.motorcycles.data })
            toastr.success("Operación exitosa")
        } else
            toastr.success("Ha ocurrido un error interno")

        this.setState({loading: false})

    }

    /** Cambio de pagina, ir a registrar o ediar */
    handleClickPage = (e, id) => id ? this.props.history.push(`/parametrizacion/motos/formulario/${id}`) : this.props.history.push('/parametrizacion/motos/formulario')
    

    render() {
        return (
            <Motorcycles
                state={this.state}
                onClickPage={this.handleClickPage}
                onDelete={this.handleDelete}
            />
        )
    }
}

const mapStateToProps = ({ motorcycles }) => ({ motorcycles })

export default connect(mapStateToProps, { getMotorcycles, delMotorcycles })(Motorcycle)