import React from 'react'
import styled from 'styled-components'
import { blackColor, errorColor, iconColor } from '../../constanst'

export default ({ inputw, name, value, ignore, rows, label, onChange, disabled }) => 
<InputContainer inputw={inputw}>
    <Label>{label}</Label>
    <TextArea name={name} data-ignore={ignore} rows={rows} value={value} disabled={disabled}
        onChange={onChange}
    />
    <Error />
</InputContainer>


const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: ${({inputw}) => inputw };
    padding: 0 10px;
`
const Label = styled.label`
    color: ${blackColor};
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif;
    font-size: 12px;
    font-weight: 500;
`
const TextArea = styled.textarea`
    color: ${blackColor};
    padding: 10px;
    border-radius: 5px;
    border: 1px solid ${iconColor};
    margin: 10px 0px;
    resize: none;
    outline: 0;

    :focus {border: 1px solid ${blackColor}};
`
const Error = styled.span`
    padding-right: 10px;
    align-self: flex-end;
    color: ${errorColor};
    font-size: 10px;
`