import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'
import {Table} from 'react-virtualized'
import 'react-virtualized/styles.css'

import { bgVariantColor, secundaryColor, primayVariantColor, iconColor, iconVariantColor, blackColor, alternateVariantColor, successColor} from "../../../constanst"
import InputText from '../../common/InputText'
import ButtonSubmit from '../../common/ButtonSubmit'
import { IconSearch, IconSee } from '../../../assets/icons'
import Scrollbars from 'react-custom-scrollbars'
import { Loading } from '../../common/Loading'
import { Tooltip, TooltipText } from '../services/ComponentsForm'
import { Link } from 'react-router-dom'

export default ({ state, onChange, onChangeDate, onClickFindDate, onFilter, onKeyPressFilter }) => 
    
    <Fragment>        
    <Main>        
        {state.loading && <Loading />}
        <Scrollbars>
            <Container>
                <Title>HOJAS DE VIDA</Title>
                {/** panel de busqueda */}
                <NavSearch>
                    <DivisorContainer borderC={iconColor} direction="column">
                        <Row>
                            <InputContainer>
                                <InputText icon={ <IconSearch width="20px" height="20px" color={blackColor} /> } 
                                    placeholder="N° de placa" name="hvv_placa" placeholderColor={iconColor}borderC={iconColor}
                                    backgroundC={secundaryColor} textColor={iconColor} 
                                    onChange={onChange} onSearch={onFilter} onKeyPress={onKeyPressFilter} value={state.hvv_placa}
                                />
                            </InputContainer>
                        </Row>
                    </DivisorContainer>
                    <DivisorContainer>
                        <InputContainer>
                            <InputText 
                                type="date" name="dateStart" placeholderColor={iconColor} onChange={onChangeDate}
                                backgroundC={secundaryColor} textColor={iconColor} borderC={iconColor} value={state.dateStart}
                            />
                        </InputContainer>
                        <InputContainer>
                            <InputText
                                type="date" name="dateFinal" placeholderColor={iconColor} onChange={onChangeDate}
                                backgroundC={secundaryColor} textColor={iconColor} borderC={iconColor} value={state.dateFinal}
                            />
                        </InputContainer>
                        <InputContainer align="flex-end">
                            <ButtonSubmit label="Buscar por fecha" bgColor={successColor} color={secundaryColor} onClick={onClickFindDate} type="button" />
                        </InputContainer>
                    </DivisorContainer>
                </NavSearch>
                <Table
                    style={{ width: '100%'}}
                    autoContainerWidth
                    gridStyle={{width: '100%'}}
                    height={600}
                    headerHeight={40}
                    width={1850}
                    rowHeight={30}
                    containerStyle={{width: '100%'}}
                    rowCount={state.curriculumList.length}
                    rowGetter={({index}) => state.curriculumList[index]}
                    rowStyle={{width: '103%'}}
                    headerRowRenderer={() => 
                        <Tr className="ReactVirtualized__Table__headerRow" bgColor style={{width: state.curriculumList.length > 8 ? '100%' : '100%'}}>
                            <Th elipsis width={20}  >#</Th>
                            <Th elipsis width={20} title="Modelo de Moto">Moto</Th>
                            <Th elipsis width={20} title="Número de Placa">Placa</Th>
                            <Th elipsis width={20} title="Fecha del Servicio">Fecha</Th>
                            <Th elipsis width={20}  title="Acciones">Acciones</Th>
                        </Tr>
                    }
                    rowRenderer={e => 
                        <Tr className="ReactVirtualized__Table__row" key={e.key} style={e.style} >
                            <Td elipsis width={20} >{e.index + 1}</Td>
                            <Td elipsis width={20} title={e.rowData.rm_nombremodelo}>{e.rowData.rm_nombremodelo}</Td>
                            <Td elipsis width={20} title={e.rowData.hvv_placa}>{e.rowData.hvv_placa}</Td>
                            <Td elipsis width={20} title={e.rowData.fecha}>{e.rowData.fecha}</Td>
                            <Td width={20} elipsis={false}>{
                                <>
                                    <Row>
                                        <Tooltip className="tooltip">
                                            <Link to={`/motos/historia/${e.rowData.hvv_id}`}>
                                                <IconSee width="30px" height="20px" color={alternateVariantColor} bgColor={alternateVariantColor} />
                                                </Link>
                                                <TooltipText className="tooltiptext" bgColor={alternateVariantColor}>Editar</TooltipText>
                                        </Tooltip>
                                    </Row>
                                </>
                                }</Td>
                            </Tr>
                        }
                    >
                </Table>
            </Container>
        </Scrollbars>
    </Main>
</Fragment>

const Main = styled.main`
    flex: 1;
    display: flex;
    overflow: hidden;
    padding: 0px;
    position: relative;
`
const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: ${bgVariantColor};
    width: 100%;
    margin-bottom: 30px;
    /* border-radius: 15px; */
    box-shadow: 0px 0px 6px 1px rgba(0,0,0,.4);
    @media (min-width: 1000px){
        width: 98%;
        margin-left: 1%;
    }
 `
const Title = styled.h3`
    color: white;
    padding: 5px;
    margin: 0px;
    text-align: center;
    background-color: ${primayVariantColor};
`
const NavSearch = styled.nav`
    position: relative;
    background-color: ${iconVariantColor};
    display: flex;
    justify-content: center;   
`
const DivisorContainer = styled.div`
    width: 50%;
    display: flex;
    height: 100%;
    justify-content: ${({justify}) => justify ? justify : 'center'};
    align-items: center;
    ${({ borderC }) => borderC && css`border-right: 1px solid ${borderC};`}
    padding: 0 5px;
    ${({direction}) => direction && css`flex-direction: ${direction};` }
`
const InputContainer = styled.div`
    width: 33.3%;
    margin: 0px 5px;
    /* margin-bottom: 20px; */
    display: flex;
    ${({align}) => align && css`justify-content: ${align};`}
`
const Row = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 100%;
    align-items: center;
`

/** Constants export for use from other components */
const Tr = styled.div`
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    ${({ bgColor }) => bgColor && css`background-color: ${primayVariantColor}50;`}
    border-bottom: 1px solid ${iconColor}50;
`
const Th = styled.div`
    font-weight: 500;
    font-size: 12px;
    color: ${primayVariantColor};
    padding: 5px;
    text-align: center;
    border-left: 1px solid ${blackColor}80;
    ${({ width }) => width && css`width: ${width}%;`}
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:first-child {border-left: 0px;}
`
const Td = styled.div`
    text-align: center;
    font-size: 12px;
    color: ${blackColor};
    padding: 5px;
    ${({elipsis}) => elipsis && css`
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    `}
    ${({ width }) => width && css`width: ${width}%;`}
`