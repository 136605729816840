import React from 'react'
import styled, { css } from 'styled-components'
import { secundaryColor, blackColor, bgVariantColor, primayColor, primayVariantColor } from '../../../constanst'
import Logo from '../../../assets/imagenes/logomotoexplorer.jpg'
import { ContainerButton, Box as Bbox } from '../services/ComponentsForm'
import ButtonSubmit from '../../common/ButtonSubmit'
//  Vistas 
import IdentidficacionVehiculo from '../services/IdentidficacionVehiculo'
import HistorialVehiculo from '../services/HistorialVehiculo'
import HistorialRevisiones from '../services/HistorialRevisiones'
import RevisionesServicios from '../services/RevisionesServicios'
import { Loading } from '../../common/Loading'
import Scrollbars from 'react-custom-scrollbars'

export default ({state, onChangeInput, handleSubmit, onCheckClick}) =>
    <Scrollbars>
        <Container>
            {/** Cabecera */}
            { state.loading && <Loading />}
            <CabeceraLogo>
                    <ContainerLogo bgColor="white">
                        <Box>
                            <ImgLogo src={Logo} alt="membrete" />
                            <Parr marginTop="0">www.motoexplorer.wowdesarrollos.com</Parr>
                        </Box>
                    </ContainerLogo>
                    <ContainerLogo>
                    <Div>
                        <Parr textAlign="right" weight="bold"fontSize="18px">ORDEN DE SOLICITUD</Parr>
                        <Parr marginTop="0" padding="0 8px 0 0">Sede: {state.sede?.sed_nombre}</Parr>
                        <Parr marginTop="0" padding="0 8px 0 0">Direccion: {state.sede?.sed_direccion}</Parr>
                        <Parr marginTop="0" padding="0 8px 0 0">Tlf: {state.sede?.sed_telefono}</Parr>                    
                    </Div>
                    </ContainerLogo> 
                </CabeceraLogo>


                {/** Titulo */}
                <Title>HOJA DE VIDA DEL VEHICULO</Title>
                
                {/** Formulario */}
                <FormContainer>
                    <Bbox justify="space-between" direction="row" flex=".5" align="none">
                        <IdentidficacionVehiculo state={state}/>
                        <LineRed/>
                        <HistorialVehiculo state={state} onChange={onChangeInput} onCheckClick={onCheckClick}/>
                    </Bbox>
                        <LineRed/>
                    <Bbox justify="space-between" direction="row" flex=".5" align="none">
                        <HistorialRevisiones state={state}  />
                        <LineRed/>
                        <RevisionesServicios state={state} />                           
                    </Bbox>
                        
                </FormContainer>
                {/* button */}
                <ContainerButton>
                    <ButtonSubmit width="30%" label="Guardar" bgColor={primayColor} color={secundaryColor} type="submit" onClick={handleSubmit} />
                    <ButtonSubmit width="30%" label="Atrás" bgColor={blackColor} color={secundaryColor} type="buttton" />
                </ContainerButton>
        </Container>
    </Scrollbars>

const Container = styled.div`
    background-color: ${bgVariantColor};
    margin: 0px 3%;
    height: 100;
    box-shadow: 0px 0px 8px 2px rgba(0,0,0,.4);
    margin-bottom: 40px;
 `
/* Cabecera */
const CabeceraLogo = styled.div`
    display: flex;
    align-items: center;
    padding: 0px 15px;
`
const ContainerLogo = styled.div`
    width: 50%; 
    float: left;
`
const Parr = styled.p`
    font-size:${({fontSize}) => fontSize ? fontSize : '12px'}; 
    font-weight: ${({weight}) => weight ? weight : '600'};
    margin-bottom: 0px; 
    color: #545454;
    ${({marginTop}) => marginTop && css`margin-top: ${marginTop};`}
    ${({textAlign}) => textAlign && css`text-align: ${textAlign};`}
    ${({padding}) => padding && css`padding: ${padding};`}
`
const Div = styled.div`
    text-align: right;
`
const ImgLogo = styled.img`
    height: 100px; 
    width:300px;
`
const Box = styled.div`
    background-color: white;
    text-align: center;
    width: 300px;
    margin-top: 15px;
    padding: 0px 10px;
`
// const Title = styled.h3`
//     color: ${({color}) => color ? color : blackColor};
//     padding: 5px;
//     text-align: center;
// `
/** Formulario Orden de Servicio */
const Title = styled.h3`
    color: ${secundaryColor};
    padding: 5px;
    margin: 0px;
    text-align: center;
    background-color: ${primayVariantColor};
`
const FormContainer = styled.div`
    padding: 0px 30px;
    margin-bottom: 30px;
`
// const FormTitle = styled.p`
//     width: 100%;
//     text-align: center;
//     color: ${primayVariantColor};
//     font-size: 18px;
//     font-weight: 600;
// `
const LineRed = styled.hr`
    border: 1px solid ${primayColor}; 
    margin-bottom: 20px;
`
