import { createAction } from 'redux-actions'
import { USER_LOGIN, GET_TECNICOS, GET_USERS, DELETE_USERS, POST_USERS, GET_TYPE_USERS, GET_TYPE_DOCUMENT, GET_USER_ID, UPDATE_USERS, GET_CLIENTE } from '../types'
import { apiLogin, apiGetTecnicos, apiGetUsers, apiDelUsers, apiPostUsers, apiGetTypeUsers, apiGetTypeDoc, apiGetUserId, apiUpUsers, apiGetClient } from '../api/users'

/** Login -- Inicio de sesión */
export const loginUser = createAction(USER_LOGIN,
    (data) => apiLogin(data)())

/** Lista de usuarios */
export const getusers = createAction(GET_USERS,
    () => apiGetUsers()())

/** Usuario por id */
export const getUserId = createAction(GET_USER_ID,
    (data) => apiGetUserId(data)())

/** Registro de usuarios */
export const postUsers = createAction(POST_USERS,
    (data) => apiPostUsers(data)())

/** Actualización de usuarios */
export const upUsers = createAction(UPDATE_USERS,
    (data) => apiUpUsers(data)())

/** Eliminando usuarios */
export const delUsers = createAction(DELETE_USERS,
    (data) => apiDelUsers(data)())

/** Lista de técnicos */
export const getTecnicos = createAction(GET_TECNICOS,
    () => apiGetTecnicos()())

/** Lista de tipo de usuarios */
export const getTypeUsers = createAction(GET_TYPE_USERS,
    () => apiGetTypeUsers()())

/** Lista de tipos de documento */
export const getTypeDoc = createAction(GET_TYPE_DOCUMENT,
    () => apiGetTypeDoc()())

/** lISTAR INFO DEL CLIENTE */
export const getClients = createAction(GET_CLIENTE,
    (data) => apiGetClient(data)())