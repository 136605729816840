import { createAction } from 'redux-actions'
import { 
    GET_ULTIMO_CONSECUTIVO, GET_VEHICLE, GET_TYPE_SERVICE, GET_TYPE_SERVICE_WORK, GET_SEDES, GET_INVENTARIO, POST_REGISTER_SERVICE,
    POST_REGISTER_GESTION_TALLER, POST_REGISTRAR_LLAMADAS, POST_REGISTRAR_INVENTARIO, POST_REGISTRAR_AUTORIZACION, GET_GENERAR_PDF_CLIENTE, 
    GET_GENERAR_PDF_TECNICO, GET_SERVICES_LIST, GET_ORDEN_SERVICIO_ID, GET_REQUEST_SERVICE_ID, GET_CALLS_SERVICE_ID, GET_INVENTARIO_SERVICE_ID,
    GET_AUTH_ID, UPDATE_SERVICE, GET_PLANILLA_DIARIA, GET_SEDES_ID, GET_MAPA_TALLER, POST_REGISTRAR_MAPA_TALLER, GET_SERVICES_MAP, UPDATE_MAPA_TALLER, 
    POST_CERRAR_ORDEN, DELETE_SERVICE, DELETE_SOLUCION, DELETE_LLAMADAS, GET_GENERAR_PDF_FECHA, UPDATE_REGISTER_GESTION_TALLER, GET_SERVICES_PENDING_LIST, 
    GET_GENERAR_PDF_SERVICIO, GET_CLIENT_CEDULA, GET_VEHICLE_MOTOR, GET_CALL_SERVICES_LIST, UPDATE_REMEMBER_CALL, UPDATE_TECNICO_ORDEN, GET_TOKEN_SERVICE, 
    SEND_EMAIL, GET_AGENDAMIENTO_CRM, GET_CRM_IDCLIENTE, GET_PLANILLA_INCOLMOTOS, SEND_EMAIL_CRM, GET_AGENDAMIENTO_CONFIRMADOS, SET_SCHEDULING_CONFIRM,  GET_SCHEDULING_STATE
} from '../types'
import { 
    apiGetUltimoConsecutivo, apiGetVehicles, apiGetTypeService, apiGetTypeServiceWork, apiGetInventario, apiGetSedes, 
    apiPostRegisterService, apiPostRegisterGesTaller, apiPostRegistrarLlamadas, apiPostRegistrarInventario, apiPostRegistrarAutorizacion,
    apiGetGenerarPdfCliente, apiGetGenerarPdfTecnico, apiGetServicesList, apiGetOrdenServicioId, apiGetRequestServiceId, apiGetCallsServiceId,
    apiGetIventarioList, apiGetAuth, apiUpdateServiceOrder, apiGetPlanillaDiaria, apiGetSedesId, apiGetMapaTaller, apiPostMapaTaller, apiGetServicesMap, 
    apiUpdateMapaTaller, apiPostCerrarOrden, apiDeleteService, apiDeleteSol, apiDeleteLlamSer, apiGetPdfFecha, apiUpRegisterGesTaller, apiGetServicesPList, 
    apiGetGenerarPdfService, apiGetClientCed, apiGetVehicleMotor, apiGetCallsServices, apiUpRememberCall, apiUpTecnicoOrden, apiGetToken, apiSendEmail, apiGetCrm, 
    apiGetHistoryCrm, apiGetPlanillaIncol, apiSendEmailCrm, apiGetSchedulingConfirm, apiConfirmAppointment, apiGetSchedulingState
} from '../api/service-order'

/** Obetener el último numero consecutivo */
export const getUltimoConsecutivo = createAction(GET_ULTIMO_CONSECUTIVO,
    () => apiGetUltimoConsecutivo()())

/** Obetener lista de servicios */
export const getServicesList = createAction(GET_SERVICES_LIST,
    (data) => apiGetServicesList(data)())

/** Obetener lista de llamadas en servicios */
export const getCallsServices = createAction(GET_CALL_SERVICES_LIST,
    (data) => apiGetCallsServices(data)())

/** Obetener lista de servicios pendientes */
export const getServicesPList = createAction(GET_SERVICES_PENDING_LIST,
    (data) => apiGetServicesPList(data)())

/** Obetener lista de orden de servicios por id */
export const getOrdenServicioId = createAction(GET_ORDEN_SERVICIO_ID,
    (id) => apiGetOrdenServicioId(id)())

/** Obetener lista de solicitudes de servicios por id */
export const getRequestServiceId = createAction(GET_REQUEST_SERVICE_ID,
    (id) => apiGetRequestServiceId(id)())

/** Obetener lista de llamadas de servicios por id */
export const getCallsServiceId = createAction(GET_CALLS_SERVICE_ID,
    (id) => apiGetCallsServiceId(id)())

/** Obetener lista de inventario por id */
export const getIventarioList = createAction(GET_INVENTARIO_SERVICE_ID,
    (id) => apiGetIventarioList(id)())

/** Obetener lista de autorización por id */
export const getAuthId = createAction(GET_AUTH_ID,
    (id) => apiGetAuth(id)())

/** Obteniendo la lista de vehículos */
export const getVehicle = createAction(GET_VEHICLE,
    () => apiGetVehicles()())

/** obteniendo los tipos de servicios */
export const getTypeService = createAction(GET_TYPE_SERVICE,
    () => apiGetTypeService()())

/** obteniendo los tipos de trabajo por id de servicio */
export const getTypeServiceWork = createAction(GET_TYPE_SERVICE_WORK,
    (id) => apiGetTypeServiceWork(id)())

/** obteniendo las sedes */
export const getSedes = createAction(GET_SEDES,
    () => apiGetSedes()())

/** obteniendo las sedes por id de usuario */
export const getSedesId = createAction(GET_SEDES_ID,
    (data) => apiGetSedesId(data)())

/** obteniendo en inventario */
export const getInventario = createAction(GET_INVENTARIO,
    () => apiGetInventario()())

/** Generando planilla diaria */
export const getPlanillaDiaria = createAction(GET_PLANILLA_DIARIA,
    (data) => apiGetPlanillaDiaria(data)())

/** Generando planilla incolmotos */
export const getPlanillaIncol = createAction(GET_PLANILLA_INCOLMOTOS,
    (data) => apiGetPlanillaIncol(data)())

/** onteniendo datos de mapa de taller */
export const getMapaTaller = createAction(GET_MAPA_TALLER,
    (data) => apiGetMapaTaller(data)())

/** onteniendo datos de servicios para mapa de taller */
export const getServicesMap = createAction(GET_SERVICES_MAP,
    (data) => apiGetServicesMap(data)())

/** onteniendo datos de clientes por cedula */
export const getClientCed = createAction(GET_CLIENT_CEDULA,
    (data) => apiGetClientCed(data)())

/** onteniendo datos de motos por número de motor */
export const getVehicleMotor = createAction(GET_VEHICLE_MOTOR,
    (data) => apiGetVehicleMotor(data)())

/** Registro de los datos personales, de vehículo y servicio (los primeros 3 formularios) */
export const postRegisterService = createAction(POST_REGISTER_SERVICE,
    (data) => apiPostRegisterService(data)())

/** Registro de Gestión de taller */
export const postRegisterGesTaller = createAction(POST_REGISTER_GESTION_TALLER,
    (data) => apiPostRegisterGesTaller(data)())

/** Actualizar de Gestión de taller */
export const upRegisterGesTaller = createAction(UPDATE_REGISTER_GESTION_TALLER,
    (data) => apiUpRegisterGesTaller(data)())

/** registro de llamadas */
export const postRegistrarLlamadas = createAction(POST_REGISTRAR_LLAMADAS,
    (data) => apiPostRegistrarLlamadas(data)())

/** Registro de inventario */
export const postRegistrarInventario = createAction(POST_REGISTRAR_INVENTARIO,
    (data) => apiPostRegistrarInventario(data)())

/** Registro de las autorizaciones */
export const postRegistrarAutorizacion = createAction(POST_REGISTRAR_AUTORIZACION,
    (data) => apiPostRegistrarAutorizacion(data)())

/** Registro del mapa de taller */
export const postMapaTaller = createAction(POST_REGISTRAR_MAPA_TALLER,
    (data) => apiPostMapaTaller(data)())

/** Cerrar orden */
export const postCerrarOrden = createAction(POST_CERRAR_ORDEN,
    (data) => apiPostCerrarOrden(data)())

/** Generar PDF cliente */
export const getGenerarPdfCliente = createAction(GET_GENERAR_PDF_CLIENTE,
    (data) => apiGetGenerarPdfCliente(data)())

/** Generar PDF de servicio */
export const getGenerarPdfService = createAction(GET_GENERAR_PDF_SERVICIO,
    (data) => apiGetGenerarPdfService(data)())

/** Generar PDF Tecnico */
export const getGenerarPdfTecnico = createAction(GET_GENERAR_PDF_TECNICO,
    (data) => apiGetGenerarPdfTecnico(data)())

/** Actualizando la orden de servicio completa */
export const updateServiceOrder = createAction(UPDATE_SERVICE,
    (data) => apiUpdateServiceOrder(data)())

/** Actualizando mapa de taller */
export const updateMapaTaller = createAction(UPDATE_MAPA_TALLER,
    (data) => apiUpdateMapaTaller(data)())

/** Eliminando servicios */
export const deleteService = createAction(DELETE_SERVICE,
    (data) => apiDeleteService(data)())

/** Eliminamdo solución de servicios */
export const deleteSol = createAction(DELETE_SOLUCION,
    (data) => apiDeleteSol(data)())

/** Eliminando llamadas de servicios */
export const deleteLlamSer = createAction(DELETE_LLAMADAS,
    (data) => apiDeleteLlamSer(data)())

/** Imprimir pdf por fecha */
export const getPdfFecha = createAction(GET_GENERAR_PDF_FECHA,
    (data) => apiGetPdfFecha(data)())

/** Actualizar recordatorio de Llamadas */
export const upRememberCall = createAction(UPDATE_REMEMBER_CALL,
    (data) => apiUpRememberCall(data)())

/** Actualizar tecnico orden */
export const upTecnicoOrden = createAction(UPDATE_TECNICO_ORDEN,
    (data) => apiUpTecnicoOrden(data)())

/** Token de servicio */
export const getTokenService = createAction(GET_TOKEN_SERVICE,
    (data) => apiGetToken(data)())

/** Send Email */
export const sendEmail = createAction(SEND_EMAIL,
    (data) => apiSendEmail(data)())
    
/** Send Email */
export const sendEmailCrm = createAction(SEND_EMAIL_CRM,
    (data) => apiSendEmailCrm(data)())
    
/** Obtener CRM o agendamiento */
export const getCrm = createAction(GET_AGENDAMIENTO_CRM,
    (data) => apiGetCrm(data)())
    
/** Obtener agendamiento del día, (only confirm) */
export const getSchedulingConfirm = createAction(GET_AGENDAMIENTO_CONFIRMADOS,
    (data) => apiGetSchedulingConfirm(data)())

/** Obtener historial de CRM */
export const getHistoryCrm = createAction(GET_CRM_IDCLIENTE,
    (data) => apiGetHistoryCrm(data)())

/** Obtener historial de CRM */
export const confirmAppointment = createAction(SET_SCHEDULING_CONFIRM,
    (data) => apiConfirmAppointment(data)())

/** Obtener agendamiento del día, (only confirm) */
export const getSchedulingState = createAction(GET_SCHEDULING_STATE,
    (data) => apiGetSchedulingState(data)())
