import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import toastr from 'toastr'
import LayoffV from '../../components/trabajos/Layoff'
import { getWorksId, postWorkSusp, getOneWorksSusp } from '../../../redux/actions/works'

class Layoff extends Component {

    constructor(props){
        super(props)
        this.state = {
            form: {
                h_s: moment().format("HH:mm"),
                f_s: moment().format("YYYY-MM-DD"),
                obs: ''
            },
            loading: false
        }
        this.isMount = false
    }

    async componentDidMount() {
        this.isMount = true
        this.setState({loading: true})
        const {id} = this.props.match.params
        
        const userdata = JSON.parse(localStorage.getItem("userdata"))


        /** Peticiones */
        await this.props.getWorksId({idSer: id, idUser: userdata[0].idusuario, idTypeUser: userdata[0].idtipousuario})
        await this.props.getOneWorksSusp({idSer: id, idUser: userdata[0].idusuario, idTypeUser: userdata[0].idtipousuario})
        
        if(!!this.props.worksList.successOne){
            const worksList = this.props.worksList.dataOne
            this.setState({ 
                form: {
                    h_s: worksList.ss_hora_inicio,
                    f_s: worksList.ss_fecha_inicio,
                    h_r: worksList.ss_hora_reinicio,
                    f_r: worksList.ss_fecha_reinicio,
                    obs: worksList.ss_observaciones,
                    ss_id: worksList.ss_id
                },
             })
        }

        this.isMount &&
            this.setState({ loading: false, idSer: id, idUser: userdata[0].idusuario })
    }

    /** Metodo on Change */
    handleChange = async e => this.setState({ form: {...this.state.form, [e.target.name]: e.target.value} })
    
    

    handleSubmit = async e => {
        e.preventDefault()
        const {form, idSer, idUser} = this.state
        
        this.setState({loading: true})
        await this.props.postWorkSusp({...form, idSer, idUser})

        /** Respuesta del servidor */
         if(!!this.props.worksList.successPost){
            toastr.success(this.props.worksList.message)
            return this.props.history.push('/trabajos/seguimiento')
        } else
            toastr.warning(this.props.worksList.message)
        
        this.isMount &&
            this.setState({loading: false})
    }
    

    render() {
        return (
            <LayoffV
                state={this.state}
                onSubmit={this.handleSubmit}
                onChange={this.handleChange}
                onGoBack={() => this.props.history.push('/trabajos/seguimiento')}
            />
        )
    }
}

const mapStateToProps = ({worksList}) => ({worksList})
export default connect(mapStateToProps, {getWorksId, postWorkSusp, getOneWorksSusp})(Layoff)