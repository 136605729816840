import React from 'react'
import Scrollbars from 'react-custom-scrollbars'
import styled from 'styled-components'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { secundaryColor, primayVariantColor, successColor, alternateVariantColor, errorColor, iconColor } from '../../../constanst'
import { Tooltip, TooltipText } from '../../components/services/ComponentsForm'
import Table, { Tr, Td, Th, ButtonsContainer, ButtonIcon } from '../../common/Table'
import { IconEdit, IconDelete, IconPlus, IconSee } from '../../../assets/icons'
import { Loading } from '../../common/Loading'
import Button from '../../common/Button'
import ModalC from '../../common/ModalC'
import ModuleInput from '../../common/ModuleInput'
import CustomSelect from '../../common/CustomSelect'
import { MenuItem } from '@material-ui/core'
import { openModal } from '../../common/Modal'

const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
    ]
  }
  
const formats = [
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent'
]

export default ({ state, onSubmit, onDelete, onClickParams, onChangeSelect, onChangeList, onChange, onClickCancel, onChangeQuill }) => 
<Main>
{state.loading && <Loading /> }
<ModalC title={state.listActive ? "NUEVO SERVICIO" : "NUEVO TRABAJO"} id="modalUpdate" height="auto" onClickSave={onSubmit} onClickBack={onClickCancel}>
    {!state.listActive && 
        <CustomSelect label="TIPO DE SERVICIO" inputw="60%" name="idTSer" onChange={onChangeSelect} value={state.idTSer || ''}>
            {state.services.length > 0 &&
                state.services.map( x => 
                    <MenuItem key={x.tser_id} value={x.tser_id}>{x.tser_nombre}</MenuItem>
        )}
    </CustomSelect>
    }
    <ModuleInput label="NOMBRE" type="text" name="nombre" 
        backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="60%"
        onChange={e => onChange(e, true, false, false, true, 2, 15)} value={state.nombre || ''}
        ignore={true}
    />
    {!state.listActive && 
        <>
            <ModuleInput label="Meses para el recordatorio" type="text" name="daysRemember"
                backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="60%"
                onChange={e => onChange(e, true, false, false, true, 1, 15)} value={state.daysRemember || ''}
                ignore={true}
            />
             <Column>
                <Label>TEXTO EMAIL</Label>
                <ReactQuill theme="snow" value={state.emailText} onChange={onChangeQuill} style={{height: '150px'}} modules={modules} formats={formats} />
                {/* <Textarea value={state.emailText || ''} name="emailText" onChange={onChange} rows="5"></Textarea> */}
            </Column>
        </>
    }
</ModalC>
<Scrollbars autoHide>
    <Container>
        <Title>{state.listActive ? "SERVICIOS" : "TRABAJOS"}</Title>
        <Table
            minWidth="1000px"
            renderButtons={
                <ButtonsContainer>
                    <Button 
                        label={state.listActive ? "Ver lista de trabajos" : "Ver lista de servicios"} 
                        type="button" 
                        bgColor={ alternateVariantColor}
                        color={secundaryColor}
                        icon={<IconSee width="15px" height="15px" color={secundaryColor} />}
                        onClick={onChangeList} 
                    />
                    <Button 
                        label={state.listActive ? "Nuevo Servicio" : "Nuevo Trabajo "} 
                        type="button" 
                        bgColor={successColor} 
                        color={secundaryColor} 
                        icon={<IconPlus width="15px" height="15px" color={secundaryColor} />} 
                        onClick={() => openModal('modalUpdate')} />
                </ButtonsContainer>
            }
            renderHead={
                state.listActive ? 
                <Tr bgColor={true}>
                    <Th>#</Th>
                    <Th>SERVICIO</Th>
                    <Th>ACCIONES</Th>
                </Tr>
                :
                <Tr bgColor={true}>
                    <Th>#</Th>
                    <Th>SERVICIO</Th>
                    <Th>TRABAJO</Th>
                    <Th>TEXTO DE EMAIL</Th>
                    <Th>RECORDATORIO</Th>
                    <Th>ACCIONES</Th>
                </Tr>
            }
            renderBody={
            state.services.length > 0 && state.listActive ?
                state.services.map( (x, index) => (
                    <Tr key={x.tser_id}>
                        <Td>{index + 1}</Td>
                        <Td align="left">{x.tser_nombre}</Td>
                        <Td>
                            <Tooltip className="tooltip">
                                <ButtonIcon type="button" onClick={e => onClickParams(e, x.tser_id, "modalUpdate")} >
                                    <IconEdit width="30px" height="20px" color={secundaryColor} bgColor={alternateVariantColor} />
                                </ButtonIcon>
                                <TooltipText className="tooltiptext" bgColor={alternateVariantColor}>Editar</TooltipText>
                            </Tooltip>
                            <Tooltip className="tooltip">
                                <ButtonIcon type="button" onClick={e => onDelete(e, x.tser_id)} >
                                    <IconDelete width="30px" height="20px" color={secundaryColor} bgColor={errorColor} />
                                </ButtonIcon>
                                <TooltipText className="tooltiptext" bgColor={errorColor}>Eliminar</TooltipText>
                            </Tooltip>
                        </Td>
                    </Tr>
                )) : (!!state.servicesWorks.length && !state.listActive ? 
                        state.servicesWorks.map( (x, index) =>
                        <Tr key={x.tg_id}>
                            <Td>{index + 1}</Td>
                            <Td align="left">{x.tser_nombre}</Td>
                            <Td align="left">{x.tg_nombre}</Td>
                            <Td align="left" style={{maxWidth: '500px', textAlign: 'justify'}}><div dangerouslySetInnerHTML={createMarkup(x.tg_mensaje)}></div></Td>
                            <Td>{x.tg_tiemporecordatorio} {x.tg_tiemporecordatorio ? 'meses' : 'No establecido'}</Td>
                            <Td>
                                <Tooltip className="tooltip">
                                    <ButtonIcon type="button" onClick={e => onClickParams(e, x.tg_id, "modalUpdate")} >
                                        <IconEdit width="30px" height="20px" color={secundaryColor} bgColor={alternateVariantColor} />
                                    </ButtonIcon>
                                    <TooltipText className="tooltiptext" bgColor={alternateVariantColor}>Editar</TooltipText>
                                </Tooltip>
                                <Tooltip className="tooltip">
                                    <ButtonIcon type="button" onClick={e => onDelete(e, x.tg_id)} >
                                        <IconDelete width="30px" height="20px" color={secundaryColor} bgColor={errorColor} />
                                    </ButtonIcon>
                                    <TooltipText className="tooltiptext" bgColor={errorColor}>Eliminar</TooltipText>
                                </Tooltip>
                            </Td>
                        </Tr>)
                :
                <Tr>
                    <Td colSpan="6">No se encontraron elementos para mostrar</Td>
                </Tr>
                )
            }
        >
        </Table>
    </Container>
    <br/>
    <br/>
</Scrollbars>
</Main>


const createMarkup = html => {
    return {__html: html};
  }



const Main = styled.main`
    flex: 1;
    display: flex;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
`
const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: ${secundaryColor};
    width: 100%;
    margin-bottom: 40px;
    margin: 0px 2%;
    /* border-radius: 15px; */
    box-shadow: 0px 0px 6px 1px rgba(0,0,0,.4);
    @media (min-width: 1000px){
        width: 98%;
        margin-left: 1%;
    }
 `
 const Title = styled.h3`
    color: ${secundaryColor};
    padding: 5px;
    margin: 0px;
    text-align: center;
    background-color: ${primayVariantColor};
`
/**
 * textarea
 */
const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
`
// const Textarea = styled.textarea`
//     border-radius: 5px;
//     border: 1px solid ${iconColor};
//     width: 100%;
//     padding: 2px 15px;
//     font-size: 12px;
//     background: #ffffff;
//     width: 100%;
//     resize: none;
// `
const Label = styled.div`
    font-size: 10px;
    margin-bottom: 2px;
    padding-left: 5px;
`
