import { createAction } from 'redux-actions'
import { GET_INGRESO_MOTO_MENSUAL, GET_FACTURA_TECNICO, GET_INGRESO_MOTO_MENSUAL_ID, GET_VALOR_VENTA_TYPE_SERV, GET_MAPATALLER_PDF } from '../types'
import { apiGetInMotMes, apiGetFacTec, apiGetInMotMesId, apiGetValVenSer, apiGetMapaTallerPdf } from '../api/mapa-taller'

/** Ingreso de motos mensuales */
export const getInMotMes = createAction(GET_INGRESO_MOTO_MENSUAL,
    (data) => apiGetInMotMes(data)())

/** Factura por SEDE (el programador anterior confunfió los nombres ) */
export const getFacTec = createAction(GET_FACTURA_TECNICO,
    (data) => apiGetFacTec(data)())

/** Factura por id de técnico */
export const getInMotMesId = createAction(GET_INGRESO_MOTO_MENSUAL_ID,
    (data) => apiGetInMotMesId(data)())

/** Obtener valor por venta de tipo de servicio */
export const getValVenSer = createAction(GET_VALOR_VENTA_TYPE_SERV,
    (data) => apiGetValVenSer(data)())

/** Generar pdf mapa taller */
export const getMapaTallerPdf = createAction(GET_MAPATALLER_PDF,
    (data) => apiGetMapaTallerPdf(data)())
