import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import toastr from 'toastr'

import CRMV from '../../components/services/CRM'
import { getCrm } from '../../../redux/actions/service-orders'
import { getSedes } from '../../../redux/actions/service-orders'
import { url_base } from '../../../redux/api/urls'
import Axios from 'axios'



class CRM extends Component {
    constructor(props){
        super(props)

        this.state = {
            ds_sedes: 0,
            schedulingList: [],
            sedesList: [],
            loading: false,
            visible: false,
            valuesForm: {}

        }
        this.isMount = false
    }

    async componentDidMount() {
        this.isMount = true
        this.setState({loading: true})
        const userdata = JSON.parse(localStorage.getItem('userdata'))

        await this.props.getCrm({idUser: userdata[0].idusuario, idTypeUser: userdata[0].idtipousuario, name: 'ds_sedes', value: `${userdata[0].usu_sede}` })

        await this.props.getSedes()
        if(this.isMount && this.props.schedulingList.success === 1) {
            this.setState({schedulingList: this.props.schedulingList.data})

            this.setState({
                loading: false,
                sedesList: this.props.sedes,
                typeUser:  userdata[0].idtipousuario,
                plIncol: [],
                userdata: {
                    idSede: userdata[0].usu_sede,
                    typeUser: userdata[0].idtipousuario,
                    idUser: userdata[0].idusuario,
                    dateF: 0,
                    selTser: 0,
                    selTserW: 0
                }
            })
        }

        console.log(this.props.schedulingList)
        this.isMount && this.setState({loading: false})
    }
    handleChangeSelect = e => {
        const { name, value } = e.target
        this.setState({ [name]: value })
        if (value === 0)
            return this.onFilter(e, name, "")
            
        this.onFilter(e, name, value)
    }
    /** Filtrando resultados */
    onChange = async (e) => {
        const { name, value } = e.target

        this.setState({ [name]: value})
    }
    
    onKeyPressFilter = e =>{
        e.charCode === 13 && this.onFilter(e, e.target.name, e.target.value)
    } 
    /**
     * funcion de busqueda
     * @param {*} e 
     * @param {*} name 
     * @param {*} value 
     */
    onFilter = async (e, name, value) => {
        e.preventDefault()
        this.setState({ loading: true })
        const {idUser, typeUser} = this.state.userdata

        const params = {
            idUser,
            idTypeUser: typeUser,
            name,
            value
        }

        const res = await Axios.post(`${url_base}/servicio/agendamiento`, params).then(v => v.data).catch(() => ({success: 0, message: 'Error en la conexión', data: []}))
        this.setState({ loading: false, schedulingList: res.data })
    }

    
    /** Filtrar por fecha */
    onClickFindDate = () => {
        let values = []

        if(!this.state.dateStart || !this.state.dateFinal){
            toastr.warning("Seleccione un rango de fechas")
            return false
        }
        this.props.schedulingList.data.map( x => {
            if(moment(x.fecha) >= moment(this.state.dateStart) && moment(x.fecha) <= moment(this.state.dateFinal))
                values = [...values, x]
            return this.setState({schedulingList: values})
        })
    }

    
    render() {
        return (
            <CRMV state={this.state}
                onChange={this.onChange}
                onChangeDate={e => this.setState({ [e.target.name]: e.target.value })}
                onKeyPressFilter={this.onKeyPressFilter}
                onSearch={this.onFilter} 
                handleMenu={type => this.setState({ menuActive: type === this.state.menuActive ? 0 : type })}
                onChangeSelect={this.handleChangeSelect}
                onClickFindDate={this.onClickFindDate}
            />
        )
    }
}

const mapStateToProps = ({ schedulingList, sedes }) => ({ schedulingList, sedes })

export default connect(mapStateToProps, { getCrm, getSedes })(CRM)