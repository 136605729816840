import { url_base, url_ocrend } from "./urls"
import axios from 'axios'

export const apiGetUltimoConsecutivo = () => () => fetch(`${url_base}/servicio/ultimoconsecutivo`).then( x => x.json()).then(v => v )
export const apiGetVehicles = () => () => fetch(`${url_base}/vehiculos/listavehiculos`).then( x => x.json()).then(v => {
    if(v.length>0)
        return v
    else
        return {"succes": "0", "message":"ha ocurrido un error"}
})
export const apiGetTypeService = () => () => fetch(`${url_base}/servicio/tiposervicio`).then( x => x.json()).then(v => {
    if(v.length>0)
        return v
    else
        return {"succes": "0", "message":"ha ocurrido un error"}
})
/** Obteniendo la lista de servicios pendientes */
export const apiGetServicesPList = (data) => () => fetch(`${url_base}/servicio/listaserviciospendientes/${data.iduser}/${data.idTypeUser}`).then( x => x.json()).then(v => {
    
    if(v.length>0)
        return { success: 1, data: v}
    else
        return { success: 0, message:"No se encontraron elementos"}
})
/** Obteniendo la lista de servicios */
export const apiGetServicesList = (data) => () => fetch(`${url_base}/servicio/listaservicios/${data.iduser}/${data.idTypeUser}`).then( x => x.json()).then(v => {
    
    if(v.length>0)
        return { success: 1, data: v}
    else
        return { success: 0, message:"No se encontraron elementos"}
})
/** Obteniendo la lista de llamadas de servicios */
export const apiGetCallsServices = (data) => () => fetch(`${url_base}/servicio/listallamadas/${data.iduser}/${data.idTypeUser}/${data.limit}`).then( x => x.json()).then(v => {
    
    if(v.length>0)
        return { success: 1, data: v}
    else
        return { success: 0, message:"No se encontraron elementos"}
})
/** Obteniendo la lista de solicitudes por usuario */
export const apiGetRequestServiceId = (id) => () => fetch(`${url_base}/servicio/listasolicitudes/${id}`).then( x => x.json()).then(v => {
    const clienteSolicitud = v.map( x => {
       const res = {
            formsolicitudes: x.obs_clientesolicita,
            formsolucion: x.obs_solucionservcio,
            idobs: x.obs_id,
       }
       return res
    })
    
    if(v.length>=0)
        return { success: 1, data: clienteSolicitud}
    else
        return { success: 0, message:"No se encontraron elementos"}
})
/** Obteniendo la lista de llamadas de servicios por usuario */
export const apiGetCallsServiceId = (id) => () => fetch(`${url_base}/servicio/listallamadas/${id}`).then( x => x.json()).then(v => {
    
    if(v.length>0){
        const clienteLlamadas = v.map( x => {
           const res = {
                llamid: x.llam_id,
                llamstate: x.llam_estado,
                formfecha: x.llam_fecha,
                time2: x.llam_hora,
                formcomentario: x.llam_comentario
           }
           return res
        })
        return { success: 1, data: clienteLlamadas}
    } else
        return { success: 0, message:"No se encontraron elementos"}
})
/** Obteniendo la lista de llamadas de servicios por usuario */
export const apiGetIventarioList = (id) => () => fetch(`${url_base}/servicio/listarinventario/${id}`).then( x => x.json()).then(v => {
    let inventarioform = {}, comments = {}
    v.map( x => {
        inventarioform = {
            ...inventarioform,
            [x.invv_idinvdetvehiculo]: x.invv_tipo,
       }
       comments = {
           ...comments,
           [`obs${x.invv_idinvdetvehiculo}`]: x.invv_observacion
       }

       return inventarioform
    })

    
    if(v.length>0)
        return { success: 1, data: {inventarioform, comments} }
    else
        return { success: 0, message:"No se encontraron elementos"}
})
/** Obteniendo la información de la orden de servicios */
export const apiGetOrdenServicioId = (id) => () => fetch(`${url_base}/servicio/listaservicioidservicio/${id}`).then( x => x.json()).then(v => {
    const data = v[0]
    
    if(v.length>0){
    /** Cliente Formulario */
        const clientform = {
            formidservicio: data.ds_idservicio,
            formidcliente: data.clo_idcliente,
            formiddetalle: data.ds_id,
            formpersonaencar: data.pers_id,
            formcosn: data.ser_consecutivo,
            formcedula: data.clit_identificacion,
            formnombre: data.clit_nombre,
            formapellido: data.clit_apellido,
            formemail: data.clit_email,
            formdireccion: data.clit_direccion,
            formprofesion: data.clit_profesion,
            formtelefono: data.clit_telefono,
            formcelular: data.clit_celular,
            formrs: data.clit_rs,
            formtipors: data.clit_tipors,
            formpersonaservicio: data.pers_nombre,
            formcedulaservicio: data.pers_cedula,
            formtelefonocelular: data.pers_telefono,
            firmacliente: data.ds_firmacliente,
            formpersonaemail: data.pers_email,
            formpersonatelefono: data.pers_tlffijo
        }
        /** Formulario de vehículo */
        const vehicleform = {
            formplaca: data.ds_placa,
            formvehiculo: data.ds_idvehiculo,
            formvehiculonombre: data.rm_nombremodelo,
            formcolor: data.ds_color,
            formmotor: data.ds_numeromotor,
            formincolmotos: data.ds_incolmotos,
            formkm: data.ds_km,
            formserie: data.ds_serie,
            formgasolina: data.ds_gasolina,
            custodia: data.ds_custodiapapeles,  
        }
        /** Formulario de servicios */
        const serviceform = {
            formtiposervicio: data.tg_idgarantia,
            formTrabajogarantia: data.tg_id,
            formsede: data.ds_sedes,
            formtecnico: data.ds_idusuariotecnico,
            formentrega: data.ds_diaentrega,
            formhoraentrada: data.ds_horallegada,
            formhorasalida: data.ds_horasalida,
            formvalorabono: data.ds_valorabono,
            formfactura: data.ser_factura,
            formordeninterna: data.ser_ordeninterna,
            formotrotrabajogarantia: data.ds_otrostrabajos,
            tg_nombre: data.tg_nombre,
            tser_nombre: data.tser_nombre,
            sed_nombre: data.sed_nombre,
            ds_mrAssessor: data.ds_mrAssessor,
            ds_mrObs: data.ds_mrObs,
            isReadiness: data.ds_mrAssessor ? true : false,
            per_nombre: `${data.per_nombre} ${data.per_apellido}`
        }
        const conditions = {
            formconditionOne: data.ds_pcondicion,
            formconditionSec: data.ds_scondicion
        }

        return { success: 1, data: {
            ser_estado: data.ser_estado,
            clientform,
            vehicleform,
            serviceform,
            conditions
        }}
    }
    else
        return { success: 0, message:"No se encontraron elementos"}
})
/** Obteniendo información del trabajo por id de servicio */
export const apiGetTypeServiceWork = (id) => () => fetch(`${url_base}/servicio/trabajogarantia/${id}`).then( x => x.json()).then(v => {
    if(v.length>0)
        return v
    else
        return {"succes": "0", "message":"ha ocurrido un error"}
})
/** Obteniendo Todas las sedes */
export const apiGetSedes = () => () => fetch(`${url_base}/servicio/sedes`).then( x => x.json()).then(v => {
    if(v.length>0)
        return v
    else
        return {"succes": "0", "message":"ha ocurrido un error"}
})
/** Obteniendo sede por id de usuario */
export const apiGetSedesId = (id) => () => fetch(`${url_base}/servicio/validarsede/${id}`).then( x => x.json()).then(v => {
    if(v.length>0)
        return v
    else
        return {success: 0, message:"No se encontraron datos"}
})
/** Obteniendo el inventario */
export const apiGetInventario = () => () => fetch(`${url_base}/servicio/listainventatario`).then( x => x.json()).then(v => {
    if(v.length>0)
        return v
    else
        return {"succes": "0", "message":"ha ocurrido un error"}
})
/** Obteniendo los servicios de mapa de taller */
export const apiGetServicesMap = () => () => fetch(`${url_base}/servicio/listartiposerviciomapa`).then( x => x.json()).then(v => {    
    if(v.length>0)
        return { success: 1, data: v }
    else
        return {"succes": "0", "message":"ha ocurrido un error"}
})
/** Obteniendo la autorización de terminos y condiciones */
export const apiGetAuth = (id) => () => fetch(`${url_base}/servicio/listautorizacion/${id}`).then( x => x.json()).then(v => {
    
    
    if(v.length>0){
        const termsform = {
            oneItem: (v[0].ruta === "checked") ? "1" : "2",
            secItem: (v[0].llamada === "checked") ? "1" : "2",
            terItem: (v[0].repuesto === "checked") ? "1" : "2"
        }
        return { success: 1, data: termsform}
    }
    else
        return {success: 0, message:"ha ocurrido un error"}
})
/** Obteniendo lista de clientes por numero de cedula */
export const apiGetClientCed = (data) => () => axios.get(`${url_base}/servicio/clientecedula/${data}`).then(v => {
    if(v.data.length>0){
        return { success: 1, data: v.data}
    }
    else
        return {success: 0, message:"ha ocurrido un error"}
})
/** Obteniendo lista de clientes por numero de cedula */
export const apiGetVehicleMotor = (data) => () => axios.get(`${url_base}/servicio/vehiclemotor/${data}`).then(v => {
    if(v.data.length>0){
        return { success: 1, data: v.data}
    }
    else
        return {success: 0, message:"ha ocurrido un error"}
})
/** Obteniendo la autorización de mapa de taller */
export const apiGetMapaTaller = (id) => () => fetch(`${url_base}/servicio/registronapataller/${id}`).then( x => x.json()).then(v => {
    const mapataller = {
        formidmapa: v[0].map_id,
        formtiposervicioorden: parseInt(v[0].map_idtiposervicio),
        formaceite: parseInt(v[0].obs_aceite),
        formrepuesto: parseInt(v[0].obs_respuesto),
        formmo: parseInt(v[0].obs_mo),
        formtercero: parseInt(v[0].obs_tercero),
        formfacturatotalmapa: parseInt(v[0].map_totalfactura),
        formidservicio: v[0].map_idservicio,
        formcomentarios: v[0].obs_comentario,
        formtecnico: v[0].ds_idusuariotecnico,
        formcierre: v[0].obs_fechaCierre,
        ds_id: v[0].ds_id
    }

    if(v.length>0)
        return { success: 1, data: mapataller}
    else
        return {success: 0, message:"No se encontraron datos"}
}).catch(e => ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))

/** Regsitrando el servicio  */
export const apiPostRegisterService = (data) => () => axios.post(`${url_base}/servicio/registrar`, data).then(v => {    
    if(!isNaN(v.data)){
        return { success: 1, data: v.data }
    }
    else
        return {success: 0, message:"ha ocurrido un error, intentelo más tarde"}
})

/** Actualizar tecnico en mapa de taller  */
export const apiUpTecnicoOrden = (data) => () => axios.get(`${url_base}/servicio/actualizartecnicoorden/${data.ds_id}/${data.formtecnico}`).then(v => {
    if(v.data === 1 || v.data === '1'){
        return { success: 1, message: "Actualización exitosa"}
    }
    else
        return {success: 0, message:"ha ocurrido un error, intentelo más tarde"}
})

/** Regsitrando el servicio  */
export const apiPostRegisterGesTaller = (data) => () => {
    let form = new FormData()
    form.append('servicio', data.servicio)
    form.append('solucion', data.solucion)
    form.append('idservicio', data.idservicio)
    
    return axios.post(`${url_ocrend}/servicio/gestiontaller/registrar`, form).then(v => {    
        if(v.length>0){
            return v;
        }
        else
            return {"succes": "0", "message":"ha ocurrido un error"}
    }).catch( x => ({success: 0, message: "No se ha establecido conexión con el servidor"}))
}

/** Actualizar el servicio  */
export const apiUpRegisterGesTaller = (data) => () => {
    let form = new FormData()
    form.append('servicio', data.servicio)
    form.append('solucion', data.solucion)
    form.append('idobs', data.idobs)
    
    return axios.post(`${url_ocrend}/servicio/gestiontaller/actualizar`, form).then(v => {
        if(v.length>0){
            return v;
        }
        else
            return {"succes": "0", "message":"ha ocurrido un error"}
    }).catch( x => ({success: 0, message: "No se ha establecido conexión con el servidor"}))
}

/** Regsitrando las llamadas  */
export const apiPostRegistrarLlamadas = (data) => () => {
    let form = new FormData()
    form.append('hora', data.hora)
    form.append('fecha', data.fecha)
    form.append('comentario', data.comentario)
    form.append('idservicio', data.idservicio)

    return axios.post(`${url_ocrend}/servicio/llamadas/registrar`, form).then(v => {    
        if(v.length>0){
            return v;
        }
        else
            return {"success": "0", "message":"ha ocurrido un error"}
    }).catch( x => ({success: 0, message: "No se ha establecido conexión con el servidor"}))
}

/** Regsitrando el inventario  */
export const apiPostRegistrarInventario = (data) => () => {
    let form = new FormData()
    form.append('tipo', data.type)
    form.append('idinv', data.idinv)
    form.append('descripcion', data.comment)
    form.append('idusuario', data.iduser)
    form.append('idservicio', `${data.idservice}`)

    return axios.post(`${url_ocrend}/servicio/inventario/registrar`, form).then(v => {
        if(v.data.success === 1 || v.data.success === 1){
            return v;
        }
        else
            return {success: 0, message:"ha ocurrido un error"}
    }).catch( x => ({ success: 0, message: "Ha ocurrido un error en la petición" }))
}

/** Regsitrando permisos de terminos y condiciones  */
export const apiPostRegistrarAutorizacion = (data) => () => ( axios.post(`${url_base}/servicio/autorizacionorden`, data).then(v => {    
        if(v.length>0){
            return v;
        }
        else
            return {success: 0, message:"Ha ocurrido un error al intentar guardar la información"}
    }).catch( x => ({success: 0, message:"Ha ocurrido un error en la petición"}))
)

/** Regsitrando el mapa de taller */
export const apiPostMapaTaller = (data) => () => (axios.post(`${url_base}/servicio/registronapatallerinsert`, data).then(v => { 
        if(v.data === 1){
            return  {success: 1, message:"Operación exitosa"};
        }
        else
            return {success: 0, message:"Ha ocurrido un error al intentar guardar la información"}
    }).catch( x => ({success: 0, message:"Ha ocurrido un error en la petición"}))
)

/** Cerrar orden */
export const apiPostCerrarOrden = (data) => () => (axios.post(`${url_base}/servicio/cerrarorden`, data).then(v => { 
        if(v.data === 1){
            return  {success: 1, message:"Operación exitosa"};
        }
        else
            return {success: 0, message:"Ha ocurrido un error al intentar guardar la información"}
    }).catch( x => ({success: 0, message:"Ha ocurrido un error en la petición"}))
)

/** Generar pdf cliente  */
export const apiGetGenerarPdfCliente = (data) => () => ( axios.get(`${url_base}/servicio/pdfcliente/${data}`).then(v => {
    if(v.data === 1){
        return {success: 1, message:"Operación Exitosa"}
    }
    else
        return {success: 0, message:"ha ocurrido un error"}
    }).catch( x => ({success: 0, message:"ha ocurrido un error"}))
)
/** Generar pdf orden de servicio  */
export const apiGetGenerarPdfService = (data) => () => ( axios.get(`${url_base}/servicio/pdfOrdenServicio/${data}`).then(v => {
    if(v.data === 1){
        return {success: 1, message:"Operación Exitosa"}
    }
    else
        return {success: 0, message:"ha ocurrido un error"}
    }).catch( x => ({success: 0, message:"ha ocurrido un error"}))
)

/** Generar pdf tecnico  */
export const apiGetGenerarPdfTecnico = (data) => () => ( axios.get(`${url_base}/servicio/pdfEmpresa/${data}`).then(v => {
        if(v.status === 200){
            return {success: 1, message:"Operación Exitosa"}
        }
        else
            return {success: 0, message:"ha ocurrido un error"}
    }).catch( x => ({success: 0, message:"ha ocurrido un error"}))
)
/** Generar pdf planilla diaria  */
export const apiGetPlanillaDiaria = (data) => () => {
    return axios.post(`${url_base}/servicio/planilladiaria`, JSON.stringify (data)).then(v => {
    if(v.data === 1){
            return { success: 1 , message: 'Planilla generada correctamente'}
        }
        else
            return {success: 0, message:"ha ocurrido un error en la consulta"}
    }).catch(e => {
        return {success: 0, message:"ha ocurrido un error en la consulta"}
    }
)}
/** Generar pdf planilla incolmotos  */
export const apiGetPlanillaIncol = (data) => () => ( axios.get(`${url_base}/servicio/planillaincolmotos/${data.date}/${data.dateF}`).then(v => {
    if(v.data.length > 0)
            return { success: 1 , data: v.data}
        else
            return {success: 0, message:"No se econtraron resultados"}
    }).catch( x => ({success: 0, message:"ha ocurrido un error en la consulta"}))
)

/** Actualizar servicio  */
export const apiUpdateServiceOrder = (data) => () => ( axios.post(`${url_base}/servicio/actualizarservicio/`, data).then(v => {
    if(v.length>0){
        return v;
    }
    else
        return { success: 0, message:"ha ocurrido un error"}
    }).catch( x => ({success: 0, message: "No se ha establecido conexión con el servidor"}))
)

/** Actualizar Mapa de taller  */
export const apiUpdateMapaTaller = (data) => () => ( axios.post(`${url_base}/servicio/actualizarmapataller`, data).then(v => {
    if(v.data === 1){
        return  {success: 1, message:"Operación exitosa"};
    }
    else
        return {success: 0, message:"Ha ocurrido un error al intentar guardar la información"}
    }).catch( x => ({success: 0, message:"Ha ocurrido un error en la petición"}))
)

/** Eliminar Servicios  */
export const apiDeleteService = (data) => () => ( axios.get(`${url_base}/servicio/eliminar/${data}`).then(v => {    
    if(v.data === 1){
        return  {success: 1, message:"Operación exitosa", idServicio: data};
    }
    else
        return {success: 0, message:"Ha ocurrido un error al intentar guardar la información"}
    }).catch( x => ({success: 0, message:"Ha ocurrido un error en la petición"}))
)
/** Eliminar Soluciones del cliente solicita  */
export const apiDeleteSol = (data) => () => ( !!data && axios.get(`${url_base}/servicio/eliminarsolser/${data}`).then(v => {    
    if(v.data === 1){
        return  {success: 1, message:"Operación exitosa", idSol: data};
    }
    else
        return {success: 0, message:"Ha ocurrido un error al intentar guardar la información"}
    }).catch( x => ({success: 0, message:"Ha ocurrido un error en la petición"}))
)
/** Eliminar llamadas del servicio  */
export const apiDeleteLlamSer = (data) => () => ( !!data && axios.get(`${url_base}/servicio/eliminarllamser/${data}`).then(v => {    
    if(v.data === 1){
        return  {success: 1, message:"Operación exitosa", idLlam: data};
    }
    else
        return {success: 0, message:"Ha ocurrido un error al intentar guardar la información"}
    }).catch( x => ({success: 0, message:"Ha ocurrido un error en la petición"}))
)

/** Imprimir por filtro de fecha */
export const apiGetPdfFecha = (data) => () => axios.get(`${url_base}/servicio/pdflistafecha/${data.dateStart}/${data.dateFinal}/${data.name}`).then(v => {
    if(v.data.length>0)
        return { success: 1, data: v.data}
    else
        return {success: 0, message:"No se encontraron datos"}
}).catch(e => ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))

/** Actualizar recordatorio de llamada */
export const apiUpRememberCall = (data) => () => axios.post(`${url_base}/servicio/updateremembermes`, data).then(v => {
    if(v.data === 1 || v.data === '1')
        return { success: 1, data: v.data}
    else
        return {success: 0, message:"No se encontraron datos"}
}).catch(e => ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))

/** Get Token */
export const apiGetToken = (data) => () => axios.get(`${url_base}/get_id_token/${data.token}`).then(v => {
    if(v.data.length > 0 )
        return { success: 1, data: v.data}
    else
        return {success: 0, message:"No se encontraron datos"}
}).catch(e => ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))

/** SEND EMAIL */
export const apiSendEmail = (data) => () => {
    
    
    let form = new FormData()
    form.append('idSer', data.idSer)
    form.append('email', data.email)
    form.append('tlf', data.tlf)
    form.append('dir', data.dir)
    form.append('sede', data.sede)


    return axios.post(`${url_ocrend}/sendemail`, form).then(v => {
        
    if(v.data.success === 1 || v.data.success === "1" )
        return { success: 1, data: v.data}
    else
        return {success: 0, message:"Se ha enviado un email al correo del cliente"}
    }).catch(e => ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))
}

/** SEND EMAIL */
export const apiSendEmailCrm = (data) => () => {
    
    
    let form = new FormData()
    form.append('idTg', data.idTg)
    form.append('idSede', data.idSede)
    form.append('email', data.email)
    form.append('idAgen', data.idAgen)
    form.append('name', data.name)
    form.append('idSer', data.idSer)

    return axios.post(`${url_ocrend}/sendemail/crm`, form)
    .then(v => ({ success: v.data.success, message: v.data.message}))
    .catch(e => ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))
}



/** MODULO CRM O AGENDAMIENTO */
/** Obtener lista de agendamientos pendientes */
export const apiGetCrm = (data) => () => axios.post(`${url_base}/servicio/agendamiento`, data).then(v => v.data).catch(e => ({success: 0, message:"No se ha establecido conexión con el servidor"}))

/** Obtener historial de agendamiento */
export const apiGetHistoryCrm = (data) => () => axios.get(`${url_base}/servicio/agendamiento/historial/${data}`).then(v => {
    if(v.data.length > 0 )
        return { success: 1, data: v.data}
    else
        return {success: 0, message:"No se encontraron datos"}
}).catch(e => ({success: 0, message:"No se ha establecido conexión con el servidor"}))

/** Confirmar la cita para el agendamiento */
export const apiConfirmAppointment = (data) => () => axios.get(`${url_base}/servicio/confirmaragendamiento/${data}`).then(v => v.data)
.catch(e => ({success: 0, message:"No se ha establecido conexión con el servidor"}))

/** Obtener agendamiento por día */
export const apiGetSchedulingConfirm = (data) => () => axios.get(`${url_base}/servicio/agendamiento/confirmados/${data.idUser}/${data.idTypeUser}`).then(v => v.data)
.catch(e => ({success: 0, message:"No se ha establecido conexión con el servidor"}))

/** Obtener agendamiento por día */
export const apiGetSchedulingState = (data) => () => axios.get(`${url_base}/servicio/agendamiento/visited/${data.id}/${data.state}`).then(v => v.data)
.catch(e => ({success: 0, message:"No se ha establecido conexión con el servidor"}))
