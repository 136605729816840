import React from 'react'
import Scrollbars from 'react-custom-scrollbars'
import styled, { css, keyframes } from 'styled-components'
import { secundaryColor,primayColor, bgVariantColor, blackColor  } from '../../../constanst'
import ButtonSubmit from '../../common/ButtonSubmit'

export default ({ visible, state = {}, onClickAllNotify}) => {
    // const [activeFunctions, setActiveFunctions] = useState(0)
    // const [isMount, setIsMount] = useState(false)
    
    // useEffect(() => {
    //     setIsMount(true)
    // }, [])

return (
    <Container visible={visible} >
        <Text size='12px' padding='0 0 20px'>NOTIFICACIONES</Text>
        <Box>
            <Text weight='600' padding='0 0 5px'>NEW</Text>
            <ButtonSubmit onClick={onClickAllNotify } padding-right='50px' width="30%" label="VER TODO" bgColor={primayColor} color={secundaryColor} type="submit"/>
        </Box>
        <Scrollbars autoHeight autoHide autoHeightMin='125px' autoHeightMax='300px'>
            <BoxNew>
                <Row>
                    <Span>herminiriligildo de la hoz</Span>
                </Row>
                <Row>
                    <Span>Descripción</Span>
                </Row>
                <Row>
                    <DateText style={{color: '#333'}}>{state.date}</DateText>
                </Row>             
            </BoxNew>
            <BoxNew>
                <Row>
                    <Span>herminiriligildo de la hoz</Span>
                </Row>
                <Row>
                    <Span>Descripción</Span>
                </Row>
                <Row>
                    <DateText style={{color: '#333'}}>{state.date}</DateText>
                </Row>             
            </BoxNew>
            <BoxNew>
                <Row>
                    <Span>herminiriligildo de la hoz</Span>
                </Row>
                <Row>
                    <Span>Descripción</Span>
                </Row>
                <Row>
                    <DateText style={{color: '#333'}}>{state.date}</DateText>
                </Row>             
            </BoxNew>
            <BoxNew>
                <Row>
                    <Span>herminiriligildo de la hoz</Span>
                </Row>
                <Row>
                    <Span>Descripción</Span>
                </Row>
                <Row>
                    <DateText style={{color: '#333'}}>{state.date}</DateText>
                </Row>             
            </BoxNew>
            <BoxNew>
                <Row>
                    <Span>herminiriligildo de la hoz</Span>
                </Row>
                <Row>
                    <Span>Descripción</Span>
                </Row>
                <Row>
                    <DateText style={{color: '#333'}}>{state.date}</DateText>
                </Row>             
            </BoxNew>
            <BoxNew>
                <Row>
                    <Span>herminiriligildo de la hoz</Span>
                </Row>
                <Row>
                    <Span>Descripción</Span>
                </Row>
                <Row>
                    <DateText style={{color: '#333'}}>{state.date}</DateText>
                </Row>             
            </BoxNew>
            <Text weight='600' padding='5px 0 '>EARLIER</Text>
            <BoxNew>
                <Row>
                    <Span>Anacleto Hernandez</Span>
                </Row>
                <Row>
                    <Span>Descripción</Span>
                </Row>
                <Row>
                    <DateText style={{color: '#333'}}>{state.date}</DateText>
                </Row>             
            </BoxNew>    
        </Scrollbars>
    </Container>
)}

const FadeInAnimation = keyframes`  
    from { opacity: 0; z-index: -1;}
    to { opacity: 1; z-index: 999; }
`
const FadeOutAnimation = keyframes`
    from { opacity: 1; z-index: 999;}
    to { opacity: 0; z-index: -1; }
`
const Container = styled.div`
    position: absolute;
    max-height: 400px;
    right: 80%;
    top: 40px;
    background-color: ${ secundaryColor };
    z-index: -1;
    opacity: ${({ visible }) => visible ? '1' : '0'};
    animation-name: ${ ({ visible }) => visible ? FadeInAnimation : (visible === false && FadeOutAnimation) };
    animation-duration: .5s;  
    animation-fill-mode: forwards;
    padding: 20px;
    border-radius: 5px;
    @media (max-width:500px) {
        width: 250px;
        height: 250px;
    }

`
const BoxNew = styled.div`
    padding: 10px;
    border: 1px solid #F0F0F0;
    width: 300px;
    background-color: ${ bgVariantColor }; 
   
`
const Row = styled.div`
    display: flex;
    align-items: left;
    justify-content: left;  
` 
const Span = styled.span`
    padding: 3px; 
`
const DateText = styled.span`
    color: ${secundaryColor};
    font-size: 12px;
    font-weight: 100;
`
const Text = styled.span`
    display: block;
    color: ${ ({ color }) => color || blackColor };
    font-size: ${ ({ size }) => size || '10px' };
    font-weight: ${ ({ weight }) => weight || 'bold' };
    ${ ({ padding }) => padding && css`padding: ${padding};` }
`
const Box = styled.div`
    display: flex;
    justify-content: space-between;
    padding:5px;
`
