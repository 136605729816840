import React from 'react'

/** Icon Alert */
export const IconAlert = ({ style = { }, color, bgColor, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 150 150" style={ style } xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
    <circle fill={bgColor} cx="75" cy="75" r="72"/>
<g>
	<path fill={color} d="M47.1,30c12.6,0,25.3,0,37.9,0c0,0,0.1,0.1,0.1,0.1c3.6,0.5,6.5,3.6,6.7,7.2c0,0.2,0.1,0.4,0.2,0.5
		c11-2.4,20.5,0.2,27.7,8.9c6.8,8.2,8.3,17.8,3.8,27.5c-6.2,13.3-17.2,17.8-31.5,15.3c0,0.3,0,0.7,0,1c0,8.4,0,16.8,0,25.2
		c0,4.9-3.3,8.4-8.1,8.4c-11.9,0-23.7,0-35.6,0c-1.8,0-3.4-0.5-4.9-1.6c-2.3-1.7-3.2-4-3.2-6.8c0-7.8,0-15.6,0-23.4
		c0-18,0-35.9,0-53.9c0-2.2,0.5-4.2,2.1-5.9C43.5,31.2,45.2,30.5,47.1,30z M49.9,41.4c0,19.5,0,38.9,0,58.4c10.7,0,21.3,0,31.9,0
		c0-4.7,0-9.4,0-14.1c-1.8,0.2-3.6,0.4-5.4,0.6C74,86.7,71.5,87,69,87.3c-1.9,0.2-2.9-1.4-2.2-3.2c0.1-0.2,0.2-0.4,0.3-0.7
		c1.9-4.1,3.9-8.2,5.8-12.4c0.2-0.4,0.2-1,0.2-1.4c-2-10,0.6-18.6,8-25.6c0.9-0.8,0.9-1.6,0.7-2.6C71.1,41.4,60.5,41.4,49.9,41.4z
		 M72.5,82.4c3.8-0.5,7.5-0.9,11.2-1.4c0.9-0.1,1.6,0.1,2.3,0.5c1.2,0.8,2.5,1.6,3.8,2.2c6.1,2.8,12.3,2.9,18.4,0.1
		c7.3-3.4,11.7-9.1,12.8-17.1c1.1-7.5-1.1-14-6.6-19.2c-6-5.7-13.2-7.5-21.2-5.4c-6.7,1.8-11.4,6-14.4,12.2
		c-2.3,4.9-2.6,10.1-1.2,15.3c0.1,0.5,0.1,1.3-0.1,1.8c-1.5,3.3-3.1,6.6-4.6,9.9C72.8,81.7,72.7,82,72.5,82.4z M60.3,112.3
		c0,3.2,2.5,5.7,5.7,5.8c3.1,0.1,5.8-2.5,5.8-5.7c0-3.2-2.5-5.8-5.7-5.8C62.9,106.5,60.3,109.1,60.3,112.3z"/>
	<path fill={bgColor} d="M49.9,41.4c10.7,0,21.2,0,31.9,0c0.2,1,0.2,1.8-0.7,2.6c-7.4,7-10,15.6-8,25.6c0.1,0.4,0,1-0.2,1.4
		c-1.9,4.1-3.9,8.3-5.8,12.4c-0.1,0.2-0.2,0.4-0.3,0.7c-0.7,1.8,0.3,3.3,2.2,3.2c2.5-0.2,4.9-0.6,7.4-0.8c1.8-0.2,3.5-0.4,5.4-0.6
		c0,4.7,0,9.4,0,14.1c-10.6,0-21.2,0-31.9,0C49.9,80.4,49.9,61,49.9,41.4z"/>
	<path fill={bgColor} d="M72.5,82.4c0.2-0.4,0.3-0.7,0.4-1c1.6-3.3,3.1-6.6,4.6-9.9c0.2-0.5,0.2-1.2,0.1-1.8
		c-1.4-5.3-1.1-10.4,1.2-15.3c2.9-6.2,7.7-10.4,14.4-12.2c8-2.1,15.2-0.3,21.2,5.4c5.5,5.2,7.7,11.7,6.6,19.2
		c-1.2,8-5.6,13.7-12.8,17.1c-6.1,2.8-12.3,2.7-18.4-0.1c-1.3-0.6-2.6-1.4-3.8-2.2c-0.7-0.5-1.5-0.6-2.3-0.5
		C80,81.5,76.3,82,72.5,82.4z M102.4,49.2c-2.4,0-4.7,0-7,0c0,5.7,0,11.3,0,16.9c2.4,0,4.7,0,7,0C102.4,60.4,102.4,54.8,102.4,49.2z
		 M102.4,73.6c0-1.9-1.6-3.5-3.5-3.5c-2,0-3.6,1.6-3.6,3.5c0,1.9,1.6,3.5,3.5,3.5C100.8,77.1,102.5,75.5,102.4,73.6z"/>
	<path fill={bgColor} d="M60.3,112.3c0-3.2,2.6-5.7,5.7-5.7c3.2,0,5.8,2.7,5.7,5.8c0,3.1-2.7,5.7-5.8,5.7
		C62.8,118,60.3,115.4,60.3,112.3z M67.3,112.3c0-0.7-0.6-1.4-1.4-1.3c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.5,1.3,1.3,1.3
		C66.7,113.7,67.3,113.1,67.3,112.3z"/>
	<path fill={color} d="M102.4,49.2c0,5.6,0,11.2,0,16.9c-2.3,0-4.6,0-7,0c0-5.6,0-11.2,0-16.9C97.7,49.2,100.1,49.2,102.4,49.2z"/>
	<path fill={color} d="M102.4,73.6c0,2-1.6,3.6-3.6,3.6c-1.9,0-3.5-1.6-3.5-3.5c0-2,1.6-3.6,3.6-3.5
		C100.9,70.1,102.4,71.6,102.4,73.6z"/>
	<path fill={color} d="M67.3,112.3c0,0.7-0.6,1.3-1.4,1.3c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3
		C66.7,111,67.3,111.6,67.3,112.3z"/>
</g>
<line fill="none" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="43" y1="19" x2="44.5" y2="27.5"/>
<line fill="none" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="31.5" y1="26.5" x2="39.5" y2="31.5"/>
<line fill="none" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="26.5" y1="39.5" x2="35.5" y2="37.5"/>
  </svg>
)

/** Icon Arrow */
export const IconArrow = ({ style = { }, color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 42 50" style={ style } xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
    <g>
      <polyline fill="none" points="4.6,19.7 20.7,3 37.4,20.4 " stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" />
      <polyline fill="none" points="37.4,31.3 21.3,48 4.6,30.6 " stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"/>
    </g>
  </svg>
)

/** Icon Arrow Left */
export const IconArrowLeft = ({ style = { }, color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 25 21" style={ style } xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
    <g>
    <path fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" d="M12.9,2l-9,7.8l9,8.7"/>
    <path fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" d="M21.2,2l-9,7.8l9,8.7"/>
    </g>
  </svg>
)

/** Icon Arrow Down */
export const IconArrowDown = ({ style = { }, color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 512.011 512.011" style={ style } xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
    <g>
      <path fill={color} d="M505.755,123.592c-8.341-8.341-21.824-8.341-30.165,0L256.005,343.176L36.421,123.592c-8.341-8.341-21.824-8.341-30.165,0
        s-8.341,21.824,0,30.165l234.667,234.667c4.16,4.16,9.621,6.251,15.083,6.251c5.462,0,10.923-2.091,15.083-6.251l234.667-234.667
        C514.096,145.416,514.096,131.933,505.755,123.592z"/>
    </g>
  </svg>
)

/** Icon Arrow Right */
export const IconArrowRight = ({ style = { }, color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 512.005 512.005" style={ style } xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
    <g>
      <path fill={color} d="M388.418,240.923L153.751,6.256c-8.341-8.341-21.824-8.341-30.165,0s-8.341,21.824,0,30.165L343.17,256.005
			L123.586,475.589c-8.341,8.341-8.341,21.824,0,30.165c4.16,4.16,9.621,6.251,15.083,6.251c5.461,0,10.923-2.091,15.083-6.251
			l234.667-234.667C396.759,262.747,396.759,249.264,388.418,240.923z"/>
    </g>
  </svg>
)

/** Icon Asesor */
export const IconAdvisor  = ({ style = { }, color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 100 100" style={ style } xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
    <g>
      <path fill={color} d="M49.21,3.02c2.37-0.1,4.89,0.14,7.29,0.93c6.47,2.14,9.31,7.09,10.22,13.48c0.48,3.4,0.12,6.93,1.54,10.21
        c0.22,0.51,0.22,1.26,0.03,1.79c-2.1,5.78-3.93,11.75-8.88,15.9c-7.75,6.51-17.34,5.07-23.49-3.38c-2.59-3.56-3.92-7.7-5.67-11.65
        c-0.27-0.6-0.48-1.51-0.22-2.01c1.76-3.32,1.16-6.96,1.52-10.45C32.68,7.18,39.5,2.84,49.21,3.02z M49.38,46.54
        c1.89-0.26,3.92-0.6,5.76-1.68c5.94-3.51,7.71-9.73,10.14-15.53c0.25-0.6,0.04-1.59-0.51-1.51c-2.64,0.38-4.58-0.72-6.35-2.47
        c-0.17-0.16-0.67,0.01-1.01,0.03c-5.23,0.33-10.38-0.32-15.44-1.52c-2.05-0.49-3.35-0.22-3.98,1.92c-0.38,1.3-1.18,1.9-2.69,1.85
        c-2.27-0.07-2.77,0.77-1.86,2.9c1.05,2.46,2.08,4.96,3.38,7.29C39.52,42.68,43.34,46.09,49.38,46.54z"/>
      <path fill={color} d="M49.81,90.53c-4.85,0-9.7-0.07-14.55,0.03c-2.15,0.05-2.92-0.78-2.88-2.9c0.09-5.02,0.08-10.05,0.01-15.07
        c-0.03-2.11,0.81-2.94,2.92-2.93c9.79,0.06,19.58,0.05,29.37,0c2.02-0.01,2.74,0.8,2.71,2.8c-0.09,5.11-0.09,10.22,0,15.33
        c0.03,2.02-0.74,2.78-2.75,2.75C59.69,90.48,54.75,90.53,49.81,90.53z"/>
      <path fill={color} d="M28.18,78.72c0,3.04-0.06,6.07,0.02,9.11c0.06,2.03-0.81,2.82-2.8,2.72c-1.82-0.1-3.64,0.03-5.46-0.04
        c-4.05-0.17-6.13-2.27-5.88-6.3c0.31-4.8,2.37-9.21,3.63-13.79c1.7-6.18,3.84-12.25,5.67-18.39c0.97-3.26,2.94-5.05,6.31-5.72
        c7.04-1.41,7.04-1.52,10.51,4.69c2.2,3.94,4.36,7.89,6.51,11.86c1.34,2.47,1.23,2.65-1.68,2.67c-4.08,0.02-8.15-0.02-12.23,0.02
        c-3.23,0.03-4.56,1.36-4.6,4.6C28.16,73,28.19,75.86,28.18,78.72C28.19,78.72,28.19,78.72,28.18,78.72z"/>
      <path fill={color} d="M60.31,65.52c-2.51,0-5.03-0.02-7.54,0.01c-1.71,0.02-1.95-0.68-1.16-2.08c3.14-5.59,6.28-11.18,9.38-16.79
        c0.63-1.14,1.45-1.68,2.75-1.31c0.08,0.02,0.17,0.04,0.25,0.06c10.5,1.99,9.69,1.43,12.57,10.7c2.7,8.69,5.36,17.39,7.98,26.1
        c1.43,4.77-1.2,8.31-6.23,8.32c-7.39,0.01-6.37,0.76-6.43-6.3c-0.04-4.59,0.01-9.19-0.01-13.78c-0.02-3.65-1.21-4.87-4.79-4.9
        C64.81,65.51,62.56,65.53,60.31,65.52C60.31,65.53,60.31,65.53,60.31,65.52z"/>
      <path fill={color} d="M49.28,92.44c7.79,0,15.59,0.01,23.38-0.01c1.73-0.01,3.05,0.14,3.03,2.44c-0.02,2.14-1.22,2.36-2.87,2.35
        c-15.67-0.01-31.35-0.01-47.02-0.01c-1.44,0-2.79,0.01-2.89-2.05c-0.1-2.04,0.72-2.76,2.73-2.74
		    C33.52,92.48,41.4,92.44,49.28,92.44z"/>
    </g>
  </svg>
)

/** Icon Check */
export const IconCheck = ({ style = { }, color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 150 150" style={ style } xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
    <g>
      <path fill={color} d="M45.3,63.6c0.3,0,0.4,0.2,0.6,0.3c3,3.1,6,6.1,9,9.2c2.8,2.9,5.6,5.7,8.3,8.6c0.4,0.5,0.7,0.3,1.1,0
        c4.9-4.8,9.7-9.6,14.6-14.4c8.1-7.9,16.1-15.8,24.2-23.8c0.7-0.7,0.7-0.7,1.5,0c3.5,3.6,7.1,7.2,10.6,10.8c0.4,0.4,0.5,0.7,0,1.1
        c-5.7,5.6-11.4,11.2-17,16.8C89,81.2,79.9,90.1,70.8,99.1c-2.2,2.2-4.5,4.4-6.7,6.6c-0.7,0.7-0.7,0.7-1.4,0
        C53.3,96,44,86.4,34.6,76.8c-0.3-0.3-0.6-0.7-1-1c-0.4-0.4-0.4-0.7,0-1.1c3.7-3.6,7.4-7.2,11.1-10.8C45,63.8,45.1,63.7,45.3,63.6z"
        />
    </g>
    <circle fill="none" stroke={color} strokeWidth="10" strokeMiterlimit="10" cx="74.5" cy="74.5" r="64.5"/>
  </svg>
)

/** Icono cotización */
export const IconCotizacion = ({ style = { }, bgColor, color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 512 512" style={ style } xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
    <g>
    <path fill={bgColor} d="M314.5,512h-117C88.9,512,0,423.1,0,314.5v-117C0,88.9,88.9,0,197.5,0h117C423.1,0,512,88.9,512,197.5v117
      C512,423.1,423.1,512,314.5,512z"/>
    <path fill={color} d="M369.2,90h-61.4c-16.6,0-30,13.9-30,31.1v9.2c-1.6-1.4-3.3-2.7-5.1-3.7L220.4,96c-14.5-8.4-32.8-3.2-40.9,11.8
        c-8.7,16-112.3,206.1-118,216.6c-8.2,15-3.1,33.9,11.4,42.3l52.3,30.5c14.5,8.4,32.8,3.2,40.9-11.8c4.3-8,95.1-174.6,108.2-198.5
        c0,0,0,0,0,0c0,0,0,0,0,0l3.5-6.5v189.3c0,17.1,13.5,31.1,30,31.1h61.4c16.6,0,30-13.9,30-31.1V121.1
        C399.3,103.9,385.8,90,369.2,90L369.2,90z M135,379.2l-52.3-30.5c-4.8-2.8-6.5-9.1-3.8-14.1l4.9-9l8.7,5.1c4.8,2.8,10.9,1,13.6-3.9
        c2.7-5,1-11.3-3.8-14.1l-8.7-5.1l9.8-18l8.7,5.1c4.8,2.8,10.9,1,13.6-3.9c2.7-5,1-11.3-3.8-14.1l-8.7-5.1l9.8-18l8.7,5.1
        c4.8,2.8,10.9,1,13.6-3.9c2.7-5,1-11.3-3.8-14.1l-8.7-5.1l9.8-18l8.7,5.1c4.8,2.8,10.9,1,13.6-3.9c2.7-5,1-11.3-3.8-14.1l-8.7-5.1
        l9.8-18l8.7,5.1c4.8,2.8,10.9,1,13.6-3.9c2.7-5,1-11.3-3.8-14.1l-8.7-5.1l9.8-18l8.7,5.1c4.8,2.8,10.9,1,13.6-3.9
        c2.7-5,1-11.3-3.8-14.1L192,127l4.9-9c2.7-5,8.8-6.7,13.6-3.9l52.3,30.5c4.8,2.8,6.5,9.1,3.8,14.1l-4.9,9l-8.7-5.1
        c-4.8-2.8-10.9-1-13.6,3.9c-2.7,5-1,11.3,3.8,14.1l8.7,5.1l-9.8,18l-8.7-5.1c-4.8-2.8-10.9-1.1-13.6,3.9c-2.7,5-1,11.3,3.8,14.1
        l8.7,5.1l-9.8,18l-8.7-5.1c-4.8-2.8-10.9-1.1-13.6,3.9c-2.7,5-1,11.3,3.8,14.1l8.7,5.1l-9.8,18l-8.7-5.1
        c-4.8-2.8-10.9-1.1-13.6,3.9c-2.7,5-1,11.3,3.8,14.1l8.7,5.1l-9.8,18l-8.7-5.1c-4.8-2.8-10.9-1.1-13.6,3.9c-2.7,5-1,11.3,3.8,14.1
        l8.7,5.1l-9.8,18l-8.7-5.1c-4.8-2.8-10.9-1.1-13.6,3.9c-2.7,5-1,11.3,3.8,14.1l8.7,5.1l-4.9,9C145.9,380.3,139.8,382,135,379.2
        L135,379.2z M369.2,380.1h-61.4c-5.5,0-10-4.6-10-10.4v-10.4h10c5.5,0,10-4.6,10-10.4c0-5.7-4.5-10.4-10-10.4h-10v-20.7h10
        c5.5,0,10-4.6,10-10.4c0-5.7-4.5-10.4-10-10.4h-10v-20.7h10c5.5,0,10-4.6,10-10.4s-4.5-10.4-10-10.4h-10V235h10
        c5.5,0,10-4.6,10-10.4c0-5.7-4.5-10.4-10-10.4h-10v-20.7h10c5.5,0,10-4.6,10-10.4s-4.5-10.4-10-10.4h-10v-20.7h10
        c5.5,0,10-4.6,10-10.4c0-5.7-4.5-10.4-10-10.4h-10v-10.4c0-5.7,4.5-10.4,10-10.4h61.4c5.5,0,10,4.6,10,10.4v10.4h-10
        c-5.5,0-10,4.6-10,10.4c0,5.7,4.5,10.4,10,10.4h10v20.7h-10c-5.5,0-10,4.6-10,10.4s4.5,10.4,10,10.4h10v20.7h-10
        c-5.5,0-10,4.6-10,10.4c0,5.7,4.5,10.4,10,10.4h10v20.7h-10c-5.5,0-10,4.6-10,10.4s4.5,10.4,10,10.4h10v20.7h-10
        c-5.5,0-10,4.6-10,10.4c0,5.7,4.5,10.4,10,10.4h10v20.7h-10c-5.5,0-10,4.6-10,10.4c0,5.7,4.5,10.4,10,10.4h10v10.4
        C379.2,375.4,374.7,380.1,369.2,380.1z"/>
    </g>
  </svg>
)

/** Icon Client */
export const IconClient = ({ style = { }, color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 143 150" style={ style } xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
    <g>
    <path fill={color} d="M73.1,147.8c-5.1,0-8.8,0-12.6-0.1c-2.7-0.1-5.4-0.4-8.1-0.5c-3.5-0.1-7-0.6-10.5-1c-4.5-0.5-9-1.2-13.5-2.2
      c-4.5-1-9-2.2-13.3-4c-3.1-1.4-6-3-8.2-5.7c-1.5-1.9-2.2-4.2-2.4-6.5c-0.2-2.2,0.2-4.3,0.9-6.4c1.1-3.2,2.2-6.4,3.7-9.5
      c1.4-2.8,3.1-5.5,5.2-8c3.7-4.4,8.3-7.3,14.1-8.4c3.4-0.7,6.9-1,10.4-1.2c1.9-0.1,3.9-0.2,5.8-0.5c0.8-0.1,1,0,1.4,0.8
      c0.7,1.4,1.8,2.5,3.1,3.3c0.7,0.4,1.2,1,2,1.4c2.9,1.5,5.9,2.5,9,3.2c4.4,0.9,8.9,1.3,13.5,1.2c5.6-0.2,11.1-1,16.3-3.2
      c2.3-0.9,4.4-2.1,6.2-3.9c0.7-0.7,1.2-1.4,1.6-2.2c0.2-0.6,0.5-0.8,1.2-0.6c2.1,0.4,4.2,0.4,6.4,0.6c4.5,0.3,9,0.7,13.3,2.1
      c3.1,1,5.8,2.8,8.2,5c2.9,2.8,5.2,6,7.1,9.6c2.1,3.9,3.7,8,4.7,12.3c1,4,0.4,7.8-2.2,11.1c-1.5,1.9-3.5,3.2-5.6,4.3
      c-4.3,2.3-8.9,3.6-13.6,4.8c-4,1-8,1.6-12,2.2c-3.4,0.5-6.7,0.8-10.1,1.1c-1.3,0.1-2.6,0.2-4,0.3c-3.4,0.3-6.8,0.4-10.1,0.5
      C77.9,147.9,74.8,147.8,73.1,147.8z"/>
    <path fill={color} d="M71.7,3.2c4.7-0.1,9,0.5,13.3,2c2.5,0.9,4.9,2,7.2,3.5c4.4,3,7.7,6.9,10.1,11.6c1.9,3.9,3,8,3.6,12.3
      c0.4,3.1,0.5,6.1,0.3,9.2c0,0.5,0.1,0.7,0.5,1.1c1.2,0.9,1.5,2.4,1.8,3.7c0.5,2.4,0.3,4.7-0.1,7.1c-0.5,2.8-1.3,5.5-2.9,7.9
      c-0.6,0.8-1.2,1.6-2.1,2c-0.3,0.1-0.5,0.4-0.6,0.7c-1.9,5-4.4,9.8-7.6,14.1c-2.2,3-4.7,5.8-7.6,8.2c-2.4,2-5,3.7-8,4.9
      c-4.7,1.9-9.6,2.2-14.5,0.7c-3.9-1.2-7.4-3.3-10.5-6c-3.4-3-6.3-6.4-8.7-10.2c-2.3-3.6-4.2-7.3-5.8-11.3c-0.2-0.5-0.4-0.8-1-1.1
      c-1.4-0.6-2.1-1.8-2.8-3.1c-1.8-3.3-2.5-7-2.7-10.7c-0.1-1.7,0.1-3.4,0.7-5c0.4-1,0.8-2,1.9-2.4c0.3-0.1,0.3-0.3,0.3-0.5
      c0.4-5.3,1.3-10.5,2.2-15.8c0.9-5.1,3.3-9.5,6.9-13.1c5-5.1,11.2-7.9,18.2-9.2C66.6,3.4,69.2,3.1,71.7,3.2z M40.3,52.5
      c-0.2,1.4,0.1,3,0.7,4.7c0.4,1.2,1,2.4,1.9,3.3c0.6,0.6,1.2,0.9,2.1,0.5c0.4-0.2,0.4,0.1,0.5,0.3c0.4,1.4,0.9,2.7,1.5,4
      c1.6,3.7,3.6,7.2,6.1,10.4c2.9,3.8,6.3,7,10.6,9c2.5,1.2,5.2,1.9,8,1.8c3.1-0.1,5.9-0.9,8.6-2.4c4.5-2.5,8-6.2,10.9-10.4
      c2.6-3.7,4.6-7.7,6-12c0.2-0.5,0.3-0.9,1-0.6c0.4,0.2,0.9,0,1.3-0.2c0.5-0.3,0.9-0.8,1.1-1.3c1.9-3.5,2.7-7.1,1.7-11
      c-0.4-1.5-1.1-1.9-2.5-1.4c-0.4,0.1-0.4-0.1-0.5-0.3c-0.3-0.9-0.5-1.8-0.8-2.8c-1.1-3.2-2.4-6.3-4.6-9c-0.3-0.4-0.7-0.7-1.1-0.9
      c-0.2,0-0.3-0.2-0.5-0.1c-0.1,0.1,0,0.3,0.1,0.4c0.3,0.8,0.6,1.6,0.9,2.4c0.1,0.2,0.1,0.4,0,0.6c-0.2,0.2-0.4,0-0.5-0.1
      c-0.3-0.1-0.6-0.3-0.9-0.4c-2.4-1.3-4.8-2.7-7.7-2.9c-1.7-0.2-3.5-0.4-5.2,0.2c-2.1,0.7-4.2,1-6.4,1.1c-3.6,0.2-7.2-0.1-10.8-1
      c-2-0.5-4-1.2-6.2-1c-3.3,0.3-6.1,1.6-8.2,4.2c-2.1,2.6-3.3,5.6-3.8,8.8c-0.1,0.6-0.2,0.8-0.8,0.5c-0.5-0.3-1.1-0.2-1.5,0.3
      c-0.4,0.5-0.6,1-0.7,1.6C40.3,50,40.3,51.1,40.3,52.5z"/>
    </g>
  </svg>
)

/** Icon Delete */
export const IconDelete = ({ style = { cursor: 'pointer' }, bgColor, color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 33 31" style={ style } xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
    <path fill={bgColor} d="M21.92,30.61H11.37c-5.54,0-10.08-4.54-10.08-10.08v-9.84c0-5.54,4.54-10.08,10.08-10.08h10.55
      C27.46,0.61,32,5.15,32,10.69v9.84C32,26.07,27.46,30.61,21.92,30.61z"/>
    <circle fill="none" stroke={color} strokeWidth="0.75" strokeMiterlimit="10" cx="17" cy="16" r="9.5"/>
    <g>
      <path fill={color} d="M11.83,20.65l3.51-4.74l-3.37-4.47h3.15l1.73,2.53l1.82-2.53h3.03l-3.3,4.36L22,20.65h-3.17l-1.98-2.85
        l-2,2.85H11.83z"/>
    </g>
  </svg>
)

/** Icon Edit */
export const IconEdit = ({ style = { cursor: 'pointer' }, bgColor, color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 33 31" style={ style } xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
    <path fill={bgColor} d="M21.92,30.61H11.37c-5.54,0-10.08-4.54-10.08-10.08v-9.84c0-5.54,4.54-10.08,10.08-10.08h10.55
      C27.46,0.61,32,5.15,32,10.69v9.84C32,26.07,27.46,30.61,21.92,30.61z"/>
    <circle fill="none" stroke={color} strokeWidth="0.75" strokeMiterlimit="10" cx="17" cy="16" r="9.5"/>
    <g>
      <path fill={color} d="M15.14,20c-0.14-0.1-0.27-0.17-0.37-0.28c-0.58-0.62-1.16-1.25-1.74-1.86c-0.25-0.26-0.22-0.48,0.03-0.72
        c1.71-1.6,3.42-3.21,5.13-4.81c0.27-0.26,0.49-0.19,0.72,0.06c0.56,0.61,1.12,1.21,1.69,1.8c0.25,0.26,0.33,0.49,0.02,0.78
        c-1.72,1.6-3.42,3.21-5.13,4.81C15.39,19.86,15.27,19.92,15.14,20z"/>
      <path fill={color} d="M23,12.38c0.01,0.38-0.21,0.57-0.41,0.76c-1.49,1.38-1.03,1.44-2.45-0.05c-0.21-0.22-0.39-0.49-0.63-0.68
        c-0.6-0.48-0.61-0.84,0-1.38c1.32-1.15,1.29-1.19,2.48,0.07C22.37,11.51,22.82,11.87,23,12.38z"/>
      <path fill={color} d="M11.77,21.18c-0.35,0.07-0.43-0.11-0.34-0.4c0.24-0.81,0.48-1.63,0.73-2.44c0.13-0.42,0.34-0.38,0.6-0.1
        c0.55,0.58,1.1,1.15,1.65,1.73c0.22,0.23,0.37,0.5-0.09,0.61C13.47,20.8,12.62,20.98,11.77,21.18z"/>
    </g>
  </svg>
)

/** Icon Hourglass */
export const IconHourglass = ({ style = { cursor: 'pointer' }, bgColor, color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 533 488.1" style={ style } xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
      <path fill={color} d="M333.2,488.1H128.3c-71.1,0-128.8-66.6-128.8-148.7V148.7C-0.5,66.6,57.1,0,128.3,0h204.9
	C404.4,0,462,66.6,462,148.7v190.8C462,421.5,404.4,488.1,333.2,488.1z"/>
      <path fill={bgColor} d="M331.3,172.4v-22.3h4.1c9.9,0,18-6.5,18.2-14.6v-31.7c-0.2-8.1-8.2-14.6-18.2-14.6H118.5
	c-9.9,0-18,6.5-18.2,14.6v31.6c0.2,8.1,8.2,14.6,18.2,14.6h4.1v22.4c0,10.6,5.8,20.6,16,27.2l72.7,47.5L140,292.3
	c-10.4,6.6-16.5,16.7-16.5,27.6v24.6h-5c-9.9,0-18,6.5-18.2,14.6v31.6c0.2,8.1,8.2,14.6,18.2,14.6h216.9c9.9,0,18-6.5,18.2-14.6
	v-31.6c-0.2-8.1-8.2-14.6-18.2-14.6h-3.1V320c-0.1-10.9-6.3-21.1-16.9-27.7l-72.7-45.2l72.6-47.5C325.6,193,331.4,183,331.3,172.4z
	 M119.7,104.9h214.6v29.3H119.7V104.9L119.7,104.9z M334.3,389.5H119.7v-29.3h11.8c0.6,0,1,0.2,1.7,0.2c0.6,0,1.1-0.2,1.7-0.2h186
	c0.6,0,1,0.2,1.7,0.2c0.6,0,1.1-0.2,1.7-0.2h10.1L334.3,389.5L334.3,389.5z M313,320.1v24.5H142.8V320c0-6,3.3-11.5,9.1-15
	l75.1-47.6l76.7,47.7C309.4,308.5,312.9,314,313,320.1z M303.2,187.2L227,237.1l-76.2-49.9c-5.5-3.6-8.8-9.1-8.8-14.9v-22.4h170.1
	v22.4C312,178.2,308.8,183.7,303.2,187.2z"/>
  </svg>
)

/** Icon Hourglass 2 */
export const IconHourglass2 = ({ style = { cursor: 'pointer' }, bgColor, color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0  457.728 457.728" style={ style } xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
  <g>
		<polygon fill={color} points="73.728,0 73.728,39.936 96.768,39.936 360.96,39.936 384,39.936 384,0 		"/>
	</g>
	<g>
		<polygon fill={color} points="360.96,417.792 96.768,417.792 73.728,417.792 73.728,457.728 384,457.728 384,417.792 		"/>
	</g>
	<g>
		<path fill={color} d="M360.96,132.096v-71.68H96.768v71.68c0,5.632,2.56,11.264,6.656,14.848l94.72,81.92l-94.208,81.92
			c-4.096,3.584-6.656,9.216-6.656,14.848v71.68h263.68v-71.68c0-5.632-2.56-11.264-6.656-14.848l-95.232-81.92l94.72-81.92
			C357.888,143.36,360.448,137.728,360.96,132.096z"/>
	</g>
  </svg>
)


/** Icon Lock */
export const IconLock = ({width, height, color}) =>
<svg x="0px" y="0px" width={width} height={height}  viewBox="0 0 150 150">
<g>
	<path fill={color} d="M105.6,149.53H43.97c-11.67,0-21.17-9.5-21.17-21.17V77.09c0-11.67,9.5-21.17,21.17-21.17h61.63
		c11.67,0,21.17,9.5,21.17,21.17v51.28C126.77,140.03,117.27,149.53,105.6,149.53z M43.97,74c-1.71,0-3.09,1.39-3.09,3.09v51.28
		c0,1.69,1.38,3.09,3.09,3.09h61.63c1.71,0,3.09-1.4,3.09-3.09V77.09c0-1.7-1.38-3.09-3.09-3.09H43.97z"/>
	<path fill={color} d="M48.43,64.96H30.35v-19.5C30.35,20.95,50.28,1,74.79,1s44.44,19.95,44.44,44.46h-18.08
		c0-14.55-11.83-26.38-26.36-26.38S48.43,30.91,48.43,45.46V64.96z"/>
</g>
</svg>

/** Icon Logout */
export const IconLogout = ({width, height, color}) =>
<svg x="0px" y="0px" width={width} height={height}  viewBox="0 0 469 469.33333">
  <g>
    <path fill={color} d="m234.832031 0c122.410157 0 222.804688 93.738281 233.601563 213.332031h-190.933594v-64c0-8.617187-5.207031-16.402343-13.164062-19.710937-7.976563-3.304688-17.152344-1.472656-23.25 4.628906l-85.335938 85.335938c-8.339844 8.339843-8.339844 21.820312 0 30.164062l85.335938 85.332031c6.097656 6.101563 15.273437 7.9375 23.25 4.628907 7.957031-3.304688 13.164062-11.09375 13.164062-19.710938v-64h190.933594c-10.796875 119.59375-111.191406 213.332031-233.601563 213.332031-129.597656 0-234.664062-105.066406-234.664062-234.664062 0-129.601563 105.066406-234.667969 234.664062-234.667969zm0 0"/>
  </g>
</svg>



/** Icon Notification */
export const IconNotification = ({ style = {}, color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 100 100" style={ style } xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
   <g>
      <path fill={color} d="M49.23,83.13C39.7,83,29.95,82.3,20.42,79.72c-3.22-0.87-6.41-1.88-9.08-4c-3.09-2.46-3.08-5.22,0.12-7.47
        c10.78-7.6,14.42-18.54,13.94-31.03c-0.36-9.44,4.77-18.91,13.87-23.41c1.85-0.91,2.67-1.92,2.78-4.04
        c0.24-4.64,3.37-7.68,7.53-7.76c4.25-0.08,7.51,2.99,7.8,7.78c0.13,2.13,0.98,3.11,2.81,4.03c9.37,4.67,14.23,14.01,13.72,24.32
        c-0.52,10.53,3.11,19.81,10.35,27.55c0.82,0.88,2,1.43,3.03,2.11c4.04,2.66,4.08,5.76,0.05,8.49c-2.56,1.73-5.46,2.67-8.41,3.46
        C69.29,82.31,59.45,83.04,49.23,83.13z"/>
  </g>
  <path fill={color} d="M13.03,87.8c0,0,41.02,23.18,70.12-0.34C83.15,87.46,59.82,99.82,13.03,87.8z"/>
    
  </svg>
)

/** Icon Menu */
export const IconMenu = ({ style = {}, color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 512 512" style={ style } xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
   <g>
      <path fill={color} d="m464.883 64.267h-417.766c-25.98 0-47.117 21.136-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h417.766c25.98 0 47.117-21.137 47.117-47.117 0-26.013-21.137-47.149-47.117-47.149z"/>
      <path fill={color} d="m464.883 208.867h-417.766c-25.98 0-47.117 21.136-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h417.766c25.98 0 47.117-21.137 47.117-47.117 0-26.013-21.137-47.149-47.117-47.149z"/>
      <path fill={color} d="m464.883 353.467h-417.766c-25.98 0-47.117 21.137-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h417.766c25.98 0 47.117-21.137 47.117-47.117 0-26.012-21.137-47.149-47.117-47.149z"/>
  </g>
  <path fill={color} d="M13.03,87.8c0,0,41.02,23.18,70.12-0.34C83.15,87.46,59.82,99.82,13.03,87.8z"/>
    
  </svg>
)

/** Icon Search */
export const IconSearch = ({ style = {}, color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 150 129" style={ style } xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
   <g>
        <path fill={color} d="M13.76,52.67c0-3.08,0-6.16,0-9.24c1.4-8.93,4.62-17.05,10.6-23.96C31.65,11.05,40.95,6.3,51.79,4.32
          c3.98,0,7.97,0,11.95,0c0.35,0.1,0.69,0.23,1.04,0.3c32,5.56,47.49,40.59,30.03,67.92c-0.58,0.91-1.84,1.85-0.56,3.06
          c1.2,1.13,2.62,2.26,4.35,1.47c2.41-1.1,4.34-0.28,5.91,1.33c10.12,10.35,20.18,20.77,30.23,31.18c0.5,0.51,0.84,1.18,1.25,1.77
          c0,1.09,0,2.17,0,3.26c-3.09,4.88-7.39,8.55-11.95,11.95c-1.27,0-2.54,0-3.8,0c-1.23-0.61-2.26-1.48-3.21-2.46
          c-5.21-5.4-10.44-10.79-15.65-16.19c-4.09-4.23-8.2-8.43-12.24-12.7c-1.92-2.03-3.45-4.15-1.98-7.26c0.73-1.55-0.19-3.07-1.43-4.07
          c-1.15-0.93-1.99,0.29-2.83,0.86c-18.64,12.61-43.26,9.57-58.11-7.17C18.46,70.44,15.06,62.03,13.76,52.67z M58.02,18.44
          C41.3,18.38,28.11,31.37,28,48.02c-0.12,16.79,12.99,30.05,29.77,30.13C74.5,78.22,87.9,64.91,87.84,48.28
          C87.79,31.63,74.65,18.49,58.02,18.44z"/>
    </g>
  </svg>
)


/** Icon Person */
export const IconPerson = ({width, height, color }) =>
<svg x="0px" y="0px" width={width} height={height} viewBox="0 0 5669.29 5669.29">
<g>
	<path fill={color} d="M1970.24,3378.72h62.5l364.39-198.09l59.32-232c-86.87-87.93-175.85-236.23-258.47-418.42
	c-6.36,1.05-13.76,2.13-20.14,2.13c-96.39,0-191.72-104.87-212.91-234.1c-16.96-100.62,16.96-187.49,78.39-220.32
	c-81.57-415.24-135.59-1052.94,579.43-1210.76c429-94.28,582.61,236.23,600.62,273.29c259.53-146.18,424.77,73.09,467.16,188.54
	c76.29,208.68,51.92,597.44,12.71,769.05c43.42,41.31,64.61,115.47,49.79,199.15c-21.19,127.11-113.35,230.93-208.68,234.1
	c-76.29,168.44-179.03,311.42-282.82,416.31l59.33,232h5.28l353.81,198.1h67.79c467.16,0,849.54,368.62,849.54,815.66v661H1119.62
	v-661c0-449.14,382.41-815.66,849.54-815.66L1970.24,3378.72z"/>
</g>
</svg>

/** Icon Plus */
export const IconPlus = ({width, height, color }) =>
<svg x="0px" y="0px" width={width} height={height} viewBox="0 0 150 150">
<g>
	<path fill={color} d="M56.2,150V94.2H0V55.8h56.2V0h37.5v55.8H150v38.5H93.7V150H56.2z"/>
</g>
</svg>

/** Icon Print */
export const IconPrint = ({ style = { cursor: 'pointer' }, width, height, color, bgColor }) =>
<svg x="0px" y="0px" width={width} height={height} style={style} viewBox="0 0 33 31">
  <path fill={bgColor} d="M21.9,30.6H11.4c-5.5,0-10.1-4.5-10.1-10.1v-9.8c0-5.5,4.5-10.1,10.1-10.1h10.6C27.5,0.6,32,5.2,32,10.7v9.8
    C32,26.1,27.5,30.6,21.9,30.6z"/>
  <g>
    <path fill={color} d="M16.2,6.2c1.6,0,3.1,0,4.7,0c0.8,0,1,0.3,1,1c0,1.1,0,2.2,0,3.3c0,0.5,0.2,0.6,0.6,0.6c0.3,0,0.5,0,0.8,0
      c1.4,0.1,2.4,1,2.4,2.4c0,2,0,3.9,0,5.9c0,1.3-1,2.3-2.4,2.3c-0.1,0-0.2,0-0.3,0c-1.3,0-1.2-0.2-1.2,1.2c0,0.4,0,0.8,0,1.2
      c0,0.6-0.3,0.9-0.9,0.9c-3.2,0-6.5,0-9.7,0c-0.5,0-0.8-0.3-0.8-0.8c0-0.6,0-1.2,0-1.8c0-0.5-0.1-0.7-0.6-0.7c-0.4,0-0.9,0-1.3-0.1
      c-1.1-0.2-1.8-1.1-1.9-2.3c0-2,0-3.9,0-5.9c0-1.4,0.9-2.3,2.3-2.4c0.3,0,0.7,0,1,0c0.4,0,0.5-0.1,0.5-0.5c0-1.1,0-2.2,0-3.3
      c0-0.8,0.2-1,1-1C13.1,6.2,14.6,6.2,16.2,6.2z M16.1,12.6c-2.3,0-4.6,0-6.8,0c-0.9,0-1.2,0.3-1.2,1.2c0,1.7,0,3.5,0,5.2
      c0,0.9,0.3,1.2,1.2,1.2c0.2,0,0.5,0,0.7,0c0.4,0,0.5-0.1,0.5-0.5c0-0.9,0-1.9,0-2.8c0-0.7,0.2-0.9,1-1c3.1,0,6.3,0,9.4,0
      c0.7,0,1,0.3,1,1c0,0.7,0,1.4,0,2.1c0,1.4-0.2,1.2,1.2,1.2c0.8,0,1.1-0.3,1.1-1.1c0-1.8,0-3.5,0-5.3c0-0.9-0.3-1.2-1.2-1.2
      C20.7,12.6,18.4,12.6,16.1,12.6z M16.2,23.5c1.2,0,2.4,0,3.7,0c0.4,0,0.5-0.1,0.5-0.5c0-1.7,0-3.4,0-5c0-0.4-0.1-0.6-0.5-0.5
      c-2.4,0-4.9,0-7.3,0c-0.4,0-0.6,0.1-0.6,0.5c0,1.7,0,3.3,0,5c0,0.4,0.1,0.6,0.6,0.6C13.7,23.5,15,23.5,16.2,23.5z M16.2,11.1
      c1.2,0,2.4,0,3.6,0c0.4,0,0.6-0.1,0.5-0.6c0-0.8,0-1.6,0-2.3c0-0.4-0.1-0.5-0.5-0.5c-2.5,0-5,0-7.5,0c-0.3,0-0.5,0.1-0.5,0.5
      c0,0.8,0,1.6,0,2.3c0,0.5,0.2,0.6,0.6,0.6C13.8,11.1,15,11.1,16.2,11.1z"/>
    <path fill={color} d="M12.5,13.6c0.4,0,0.7,0.4,0.7,0.7c0,0.4-0.4,0.7-0.7,0.7c-0.4,0-0.7-0.4-0.7-0.7
      C11.8,13.9,12.1,13.6,12.5,13.6z"/>
    <path fill={color} d="M11.2,14.3c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.4-0.7-0.8c0-0.4,0.3-0.7,0.7-0.7
      C10.9,13.6,11.2,13.9,11.2,14.3z"/>
    <path fill={color} d="M16.2,21c0.8,0,1.7,0,2.5,0c0.6,0,0.9,0.3,0.9,0.7c0,0.4-0.3,0.8-0.9,0.8c-1.7,0-3.4,0-5.1,0
      c-0.6,0-0.9-0.3-0.9-0.8c0-0.4,0.4-0.7,0.9-0.7C14.5,21,15.3,21,16.2,21z"/>
    <path fill={color} d="M16.2,20.1c-0.9,0-1.7,0-2.6,0c-0.6,0-0.9-0.3-0.9-0.7c0-0.4,0.3-0.7,0.9-0.7c1.7,0,3.5,0,5.2,0
      c0.5,0,0.9,0.3,0.9,0.7c0,0.4-0.3,0.7-0.9,0.7C17.9,20.1,17,20.1,16.2,20.1z"/>
  </g>
</svg>

/** Icon Print2 */
export const IconPrint2 = ({ style = { cursor: 'pointer' }, width, height, color }) =>
<svg x="0px" y="0px" width={width} height={height} style={style} viewBox="0 0 150 150">
  <g>
    <path fill={color} d="M73.7,8c11,0,21.9,0,32.9,0c5.3,0,7.2,1.8,7.3,7.2c0.1,7.7,0.1,15.5,0,23.2c-0.1,3.3,1.1,4.6,4.4,4.3
      c1.9-0.2,3.7-0.1,5.6,0c9.9,0.4,16.8,7,17,16.8c0.3,13.8,0.2,27.7,0,41.5c-0.2,9.5-7.3,16.3-16.8,16.6c-0.6,0-1.2,0-1.9,0
      c-9.3,0.1-8.2-1.2-8.3,8.5c0,2.9,0.1,5.7-0.1,8.6c-0.2,4-2.1,6.1-6,6.1c-22.8,0.1-45.6,0.1-68.4,0c-3.8,0-5.7-2-5.8-5.8
      c-0.1-4.2-0.2-8.5,0-12.7c0.2-3.5-0.9-5-4.6-4.7c-3.1,0.2-6.2,0.3-9.3-0.4c-7.9-1.8-13.1-7.8-13.1-15.9c-0.1-13.8-0.1-27.7,0-41.5
      c0-9.7,6.5-16.5,16.2-17c2.4-0.1,4.7-0.2,7.1,0c2.8,0.1,3.7-1,3.7-3.7c-0.1-7.9-0.1-15.7,0-23.6c0-5.8,1.6-7.4,7.3-7.4
      C51.8,8,62.7,8,73.7,8z M73.4,53.4c-16.1,0-32.2,0-48.3,0c-6.2,0-8.3,2.1-8.3,8.3c0,12.3,0,24.7,0,37c0,6.2,2.1,8.2,8.4,8.2
      c1.6,0,3.2-0.1,4.9,0c2.6,0.2,3.5-1,3.4-3.5c-0.1-6.6-0.1-13.2,0-19.8c0-5,1.7-6.7,6.7-6.7c22.2,0,44.4,0,66.6,0c5.1,0,6.9,1.8,7,7
      c0.1,4.9,0,9.7,0,14.6c0.1,9.6-1.2,8.4,8.7,8.4c5.7,0,7.5-1.9,7.6-7.7c0-12.5,0-24.9,0-37.4c0-6.6-1.9-8.4-8.5-8.4
      C105.6,53.4,89.5,53.4,73.4,53.4z M73.7,130.2c8.6,0,17.2-0.1,25.8,0c2.7,0,3.7-0.9,3.7-3.7c-0.1-11.9-0.1-23.7,0-35.6
      c0-2.9-0.9-3.9-3.9-3.9c-17.2,0.1-34.4,0.1-51.7,0c-2.9,0-4,0.9-3.9,3.9c0.1,11.7,0.1,23.5,0,35.2c0,3.1,1,4.2,4.1,4.1
      C56.5,130.1,65.1,130.2,73.7,130.2z M73.6,42.7c8.6,0,17.2-0.1,25.8,0c2.9,0,4-1.1,3.9-4c-0.2-5.5-0.1-11,0-16.4
      c0-2.5-0.8-3.6-3.4-3.6c-17.6,0.1-35.1,0-52.7,0c-2.4,0-3.3,1-3.3,3.4c0.1,5.5,0.2,11,0,16.4c-0.1,3.4,1.2,4.3,4.4,4.2
      C56.6,42.6,65.1,42.7,73.6,42.7z"/>
    <path fill={color} d="M47.9,60c2.8,0,5.3,2.5,5.2,5.2c0,2.8-2.5,5.3-5.3,5.3c-2.7,0-5.2-2.5-5.2-5.3C42.7,62.5,45.2,60,47.9,60z"/>
    <path fill={color} d="M38.5,65.4c0,2.9-2.4,5.2-5.2,5.1c-2.8-0.1-5-2.5-5-5.4c0.1-2.8,2.4-5.1,5.3-5.1C36.4,60,38.6,62.4,38.5,65.4z
      "/>
    <path fill={color} d="M73.7,112.9c6,0,12,0,18,0c4,0,6.5,2.1,6.6,5.1c0.1,3.2-2.5,5.5-6.6,5.5c-12.1,0.1-24.2,0.1-36.3,0
      c-3.9,0-6.4-2.3-6.4-5.3c0-3.1,2.5-5.2,6.4-5.3C61.5,112.8,67.6,112.9,73.7,112.9z"/>
    <path fill={color} d="M73.6,105.9c-6.1,0-12.2,0-18.3,0c-4,0-6.4-2.1-6.3-5.2c0.1-3,2.5-5,6.2-5c12.3,0,24.6,0,36.9,0
      c3.8,0,6.1,2.1,6.1,5.1c0,3.1-2.3,5.1-6,5.1C86,105.9,79.8,105.9,73.6,105.9z"/>
  </g>
</svg>

/** Icon Report */
export const IconReport = ({ style = { cursor: 'pointer' }, width, height, color }) =>
<svg x="0px" y="0px" width={width} height={height} style={style} viewBox="0 0 512 512">
  <g>
    <path fill={color} d="m122 480h268v-364h-268zm12-352h244v340h-244z"/>
    <path fill={color} d="m160 180h112v12h-112z"/><path fill={color} d="m160 212h80v12h-80z"/>
    <path fill={color} d="m160 260h192v12h-192z"/><path fill={color} d="m160 292h192v12h-192z"/>
    <path fill={color} d="m160 356h192v12h-192z"/><path fill={color} d="m192 388h128v12h-128z"/>
    <path fill={color} d="m160 324h192v12h-192z"/>
    <path fill={color} d="m326 68v-36h-32v-32h-76v32h-32v36h-96v444h332v-444zm-128-24h32v-32h52v32h32v40h-116zm212 456h-308v-420h84v16h140v-16h84z"/>
  </g>
</svg>


/** Icon Slope */
export const IconSlope = ({ style = { }, color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 150 150" style={ style } xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
    <g>
      <circle fill="none" stroke={color} strokeWidth="10" strokeMiterlimit="10" cx="74.5" cy="74.5" r="64.5"/>
      <line fill="none" stroke={color} strokeWidth="13" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" x1="29.4" y1="74.5" x2="118.4" y2="74.5"/>
    </g>
  </svg>
)

/** Icon See */
export const IconSee = ({ style = { }, color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 150 150" style={ style } xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
    <g>
      <path fill={color} d="M74.7,34.2c31.2,1.4,54.7,14.8,70.5,41.4c1.1,1.9,1.2,3.4,0.1,5.3c-15.8,26.5-39,41-70,41.1
        c-31.1,0.1-54.4-14.4-70.4-41c-1.2-2-1.1-3.6,0.1-5.6C20.8,48.9,44.4,35.6,74.7,34.2z M75,49.7c-16.1,0-28.7,12.6-28.7,28.6
        C46.4,94.4,58.9,107,75,107c16.1,0,28.7-12.6,28.7-28.6C103.6,62.3,91.1,49.7,75,49.7z"/>
      <path fill={color} d="M75,63.6c8.2,0,14.7,6.5,14.7,14.7c0,8.4-6.5,14.9-15,14.8c-8.2-0.1-14.6-6.6-14.5-14.9
        C60.3,70,66.8,63.6,75,63.6z"/>
    </g>
  </svg>
)

/** Icon Tecnico */
export const IconTec = ({ style = { }, color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 143 150" style={ style } xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
    <g>
      <path fill={color} d="M38.6,148.5c-2.9-0.9-5.7-2-7.7-4.4c-0.6-0.7-1.1-1.3-1.5-2c-0.8-1.2-0.5-1.8,1-1.9c3.1,0,6.3,0,9.4,0
        c2.1,0,2.3-0.2,2.4-2.2c0.1-2.2,0.2-4.4-0.1-6.6c-0.1-1.3-0.7-1.8-2-1.7c-3.2,0.1-6.4,0-9.6,0c-0.6,0-1.2,0.1-1.5-0.5
        c-0.3-0.6,0.1-1.1,0.4-1.6c5.4-8.6,18.5-8.2,23.3,0.8c0.6,1.1,1.3,1.5,2.5,1.5c9.4-0.1,18.8,0,28.1,0c1.3,0,2-0.4,2.7-1.6
        c4.8-8.9,17.6-9.4,23.2-1c0.3,0.5,1,1.1,0.5,1.8c-0.4,0.7-1.2,0.5-1.8,0.5c-3.1,0-6.2,0.1-9.3,0c-1.2,0-1.9,0.4-2,1.6
        c-0.2,2.5-0.2,4.9,0,7.4c0.1,1.1,0.7,1.5,1.8,1.5c3.2-0.1,6.5,0,9.7,0c0.6,0,1.3-0.1,1.6,0.6c0.3,0.6-0.2,1.1-0.5,1.6
        c-1.9,2.9-4.5,4.8-7.8,5.7c-0.5,0.1-0.9,0.3-1.3,0.5c-1.5,0-3,0-4.6,0c-0.1-0.1-0.3-0.2-0.4-0.2c-4.4-1-7.5-3.5-9.6-7.4
        c-0.4-0.8-1-0.9-1.7-0.9c-9.7,0-19.5,0-29.2,0c-0.9,0-1.4,0.3-1.8,1.1c-0.4,1-1,1.8-1.7,2.6c-2.2,2.6-5,4.1-8.2,4.9
        C41.7,148.5,40.2,148.5,38.6,148.5z"/>
      <path fill={color} d="M69.7,96.9c1.3-3.4,2.6-6.5,3.7-9.7c0.6-1.7,1.6-2.6,3.4-3c3.7-0.9,7-2.5,9.9-4.8c1.4-1.1,2.7-1.2,4.3-0.3
        c8.1,4.4,16.2,8.9,24.3,13.4c5.2,2.9,8.4,7.3,10.6,12.7c2.2,5.6,3.5,11.4,4.6,17.2c0.2,1.2-0.1,2.3-0.8,3.4c-2.8,4.2-6.7,7-11,9.3
        c-0.6,0.3-1.2,0.6-1.8,0.9c-2.4,1.2-2.8,1.1-4.7-0.7c-0.4-0.4-0.2-0.6,0.1-0.9c4.1-3.3,4.5-6.5,1.3-10.8c-3.8-5.1-8.9-7.8-15.2-7.9
        c-6.1-0.1-11.3,2.3-15.2,7.1c-0.8,1-1.5,1.4-2.8,1.3c-7.3-0.1-14.6,0-21.9,0c-1.2,0-1.9-0.3-2.7-1.3c-8.1-10.3-24.1-9.5-31.3,1.9
        c-2.3,3.6-1.6,7,1.8,9.5c0.6,0.5,0.8,0.5,0.1,1.3c-1.6,1.6-3,1.2-4.7,0.3c-4.6-2.3-8.8-5.1-12-9.3c-1.2-1.5-1.6-3.1-1.2-5.1
        c1.3-6.4,2.9-12.6,5.5-18.6c2.1-4.8,5.5-8.3,10.1-10.9c8-4.4,15.9-8.9,23.9-13.1c1.3-0.7,2.5-0.7,3.7,0.2c3.2,2.5,6.9,4.3,10.9,5.2
        c1.3,0.3,2.1,1.1,2.6,2.4c1,2.7,2,5.4,3,8C68.8,95.4,68.9,96.1,69.7,96.9z"/>
      <path fill={color} d="M68.9,2c6.4,0,12,0.6,17.4,2.7c8.4,3.4,13,9.9,14.6,18.6c1.7,9.2,2.5,18.5,0.6,27.7c-3.1,15-12,25.2-26.5,30.2
        c-4.2,1.5-8.4,1.1-12.4-0.5c-14.5-5.8-23-16.5-25.3-31.9c-1.3-8.4-0.7-16.7,0.8-25C40.2,12.2,47.3,5.2,59,2.9
        C62.5,2.3,66,1.9,68.9,2z M97.4,36.5c-0.7,0.9-1.1,1.5-1.7,2.1c-1.8,2-3,2.1-5.1,0.4c-0.5-0.4-1-0.9-1.5-1.3
        c-8.9-8.2-22-10-32.8-4.5c-2.9,1.5-5.3,3.7-7.8,5.8c-2,1.7-3.2,1.6-5-0.3c-0.6-0.6-1.1-1.3-1.8-2.2c-0.2,4.9-0.2,9.5,0.8,14
        c2.8,12.6,10.2,21.4,22.5,25.8c2,0.7,4.1,1.2,6.3,0.7c7.9-1.6,14.1-6,18.9-12.2C96.6,56.6,98,47,97.4,36.5z M69.5,15
        c-3.2,0.1-6.4,0.6-9.5,1.5c-0.9,0.2-1,0.6-0.7,1.4c0.5,1.3,1.1,2.5,1.5,3.8c0.2,0.7,0.6,0.9,1.3,0.7c4.9-1.3,9.8-1.3,14.7,0
        c0.7,0.2,1.1,0,1.3-0.7c0.5-1.3,1-2.6,1.5-3.8c0.3-0.8,0.2-1.2-0.7-1.4C75.9,15.6,72.8,15.1,69.5,15z"/>
    </g>
  </svg>
)


/** Icon User */
export const IconUser = ({ style = {}, color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" style={ style }>
   <g>
        <path fill={color} d="M8.15,10.39c-2.87,0-5.2-2.33-5.2-5.2S5.28,0,8.15,0s5.2,2.33,5.2,5.2S11.01,10.39,8.15,10.39z M8.15,1.81
		    c-1.87,0-3.39,1.52-3.39,3.39s1.52,3.39,3.39,3.39s3.39-1.52,3.39-3.39S10.02,1.81,8.15,1.81z"/>
	    <path fill={color} d="M14.65,16H1.64v-0.9c0-3.59,2.92-6.51,6.51-6.51s6.51,2.92,6.51,6.51V16z M3.53,14.19h9.23
		    c-0.42-2.16-2.33-3.8-4.61-3.8S3.96,12.03,3.53,14.19z"/>
    </g>
  </svg>
)

/** Icon User */
export const IconWorkshopMap = ({ style = {}, color, width, height, bgColor }) => (
  <svg width={width} height={height} viewBox="0 0 33 31" style={ style }>
   <g>
    <path fill={bgColor} d="M21.9,30.6H11.4c-5.5,0-10.1-4.5-10.1-10.1v-9.8c0-5.5,4.5-10.1,10.1-10.1H22c5.5,0,10,4.6,10,10.1v9.8
      C32,26.1,27.5,30.6,21.9,30.6z"/>
    <path fill={color} d="M23.5,4.4H9.8C8.3,4.4,7.2,5.6,7.2,7v17.2c0,1.4,1.1,2.5,2.5,2.5h13.8c1.4,0,2.5-1.2,2.5-2.5V7
      C26.1,5.6,25,4.4,23.5,4.4z M23.8,21.9c0,1.4-1.2,2.5-2.5,2.5h-9.1c-1.4,0-2.6-1.1-2.6-2.5V9.3c0-1.4,1.2-2.5,2.6-2.5h9.1
      c1.4,0,2.5,1.1,2.5,2.5V21.9z"/>
    <path fill={color} d="M19.9,12.6h-7c-0.6,0-1.1-0.5-1.1-1.1v0c0-0.6,0.5-1.1,1.1-1.1h7c0.6,0,1.1,0.5,1.1,1.1v0
      C21,12.1,20.5,12.6,19.9,12.6z"/>
    </g>
  </svg>
)

/** Icon Sad */
export const IconSad = ({ style = {}, color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 511.99999 511.99999" style={ style }>
   <g>
      <path fill={color} d="m437.019531 74.980469c-48.351562-48.351563-112.640625-74.980469-181.019531-74.980469s-132.667969 26.628906-181.019531 74.980469c-48.351563 48.351562-74.980469 112.640625-74.980469 181.019531s26.628906 132.667969 74.980469 181.019531c48.351562 48.351563 112.640625 74.980469 181.019531 74.980469s132.667969-26.628906 181.019531-74.980469c48.351563-48.351562 74.980469-112.640625 74.980469-181.019531s-26.628906-132.667969-74.980469-181.019531zm-21.234375 340.804687c-42.679687 42.679688-99.425781 66.1875-159.785156 66.1875s-117.105469-23.507812-159.785156-66.1875c-42.679688-42.679687-66.1875-99.425781-66.1875-159.785156s23.507812-117.105469 66.1875-159.785156c42.679687-42.679688 99.425781-66.1875 159.785156-66.1875s117.105469 23.507812 159.785156 66.1875c42.679688 42.679687 66.1875 99.425781 66.1875 159.785156s-23.507812 117.105469-66.1875 159.785156zm0 0"/>
      <path fill={color} d="m199.710938 196.21875c0 8.292969 6.722656 15.011719 15.015624 15.011719 8.292969 0 15.011719-6.71875 15.011719-15.011719 0-33.636719-27.359375-60.996094-60.996093-60.996094-33.632813 0-60.996094 27.359375-60.996094 60.996094 0 8.292969 6.722656 15.011719 15.015625 15.011719s15.015625-6.71875 15.015625-15.011719c0-17.078125 13.890625-30.96875 30.964844-30.96875 17.078124 0 30.96875 13.890625 30.96875 30.96875zm0 0"/>
      <path fill={color} d="m347.949219 135.222656c-33.632813 0-60.996094 27.359375-60.996094 60.996094 0 8.292969 6.722656 15.011719 15.015625 15.011719 8.289062 0 15.011719-6.71875 15.011719-15.011719 0-17.078125 13.894531-30.96875 30.96875-30.96875 17.078125 0 30.96875 13.890625 30.96875 30.96875 0 8.292969 6.722656 15.011719 15.015625 15.011719 8.289062 0 15.011718-6.71875 15.011718-15.011719 0-33.632812-27.359374-60.996094-60.996093-60.996094zm0 0"/>
      <path fill={color} d="m258.691406 258.214844c-57.097656 0-103.546875 46.453125-103.546875 103.550781 0 8.289063 6.722657 15.011719 15.015625 15.011719 8.289063 0 15.011719-6.722656 15.011719-15.011719 0-40.539063 32.980469-73.519531 73.519531-73.519531 40.539063 0 73.519532 32.980468 73.519532 73.519531 0 8.289063 6.722656 15.011719 15.015624 15.011719 8.289063 0 15.011719-6.722656 15.011719-15.011719 0-57.097656-46.449219-103.550781-103.546875-103.550781zm0 0"/>
    </g>
  </svg>
)
