import React, { Component } from 'react'
import { connect } from 'react-redux'
import toastr from 'toastr'
import { getWorksId, setCloseOrden } from '../../../redux/actions/works'
import QuoteV from '../../components/trabajos/Quote'

class Quote extends Component {
    constructor(props){
        super(props)
        this.state = {
            form: {
            },
            loading: false
        }
        this.isMount = false
    }

    
    /** montaje del componente */
    async componentDidMount() {
        this.isMount = true
        this.setState({loading: true})
        const {id} = this.props.match.params
        
        const userdata = JSON.parse(localStorage.getItem("userdata"))

        /** Peticiones */
        await this.props.getWorksId({idSer: id, idUser: userdata[0].idusuario})

        this.isMount && 
            this.setState({ loading: false })
        
    }

    /** Desmontaje del componente */
    componentWillUnmount() {
        this.isMount = false
    }

    /** Metodo on Change */
    handleChange = async e => this.setState({ form: {...this.state.form, [e.target.name]: e.target.value} })
    

    /** Metodo Submit del formulario */
    handleSubmit = async e => {
        e.preventDefault()
        const {form, idSer, idUser} = this.state
        
        if(!form.obs)
            return toastr.warning('Rellene el campo de solicitud antes de continuar')

        this.setState({loading: true})
        await this.props.setCloseOrden({...form, idSer, idUser})

        /** Respuesta del servidor */
         if(!!this.props.worksList.successPost){
            toastr.success(this.props.worksList.message)
            return this.props.history.push('/trabajos/seguimiento')
        } else
            toastr.warning(this.props.worksList.message)
        
        this.isMount &&
            this.setState({loading: false})
    }
    


    /** Metodo render */
    render() {
        return (
            <QuoteV
                state={this.state}
                onChange={this.handleChange}
                onSubmit={this.handleSubmit}
                onGoBack={() => this.props.history.push('/trabajos/seguimiento')}
            />
        )
    }
}

export default connect(({ worksList }) => ({ worksList }), {getWorksId, setCloseOrden})(Quote)