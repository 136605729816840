import React from 'react'
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer'
import { blackColor, primayVariantColor } from '../../constanst'
import Logo from '../../assets/imagenes/logomotoexplorer.jpg'

export default ({ title, sede, phone, direction, consecutivo }) => (
    <>
        <View style={[styles.box, {width: '100%'}]}>
            <View style={styles.section}>
                <View>
                    <Image src={Logo} style={{width: 180, height: 40}} />
                    <Text style={[styles.label, {paddingLeft: 10}]}>www.motoexplorer.wowdesarrollos.com</Text>
                </View>
            </View>
            <View style={styles.section}>
                <View style={{display: 'flex', alignItems: 'flex-end'}}>
                    <Text style={[styles.label, {fontSize: 12}]}>{title}</Text>
                    <Text style={styles.label}>{sede}</Text>
                    <Text style={styles.label}>{direction}</Text>
                    <Text style={styles.label}>Tlf: {phone}</Text>
                </View>
            </View>
        </View>
        <View style={{backgroundColor: primayVariantColor}}>
          {!!consecutivo && <Text style={[styles.title, {color: '#ffffff'}]}>N° - {consecutivo}</Text>}
        </View>
    </>
)

// Create styles
const styles = StyleSheet.create({
  section: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    marginVertical: 3,
  },
  title: {
    fontSize: 10,
    color: blackColor,
    padding: 5,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  label: {
    fontSize: 8,
    fontWeight: 'bold',
    color: blackColor
  }
})
