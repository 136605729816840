import React from 'react'
import styled from 'styled-components'
import { primayVariantColor, secundaryColor } from '../../constanst'
import { ButtonsContainer } from './Table'
// import { onCloseModal } from './Modal'
import Button from './Button'

const ModalC = ({ title, children, id, onClickSave, onClickBack, height, footer }) => 
    <Container id={id}>
        <Content height={height}>
            <Header>
                <Title>{title}</Title>
            </Header>
            <Body>
                {children}
            </Body>
            {footer &&
                <FooterModal>
                    <ButtonsContainer>
                        <Button label="Cerrar" type="button" width="40%" bgColor={'transparent'} color={primayVariantColor} borderC={primayVariantColor} onClick={onClickBack} />
                        <Button label="Guardar" type="button" width="40%" bgColor={primayVariantColor} color={secundaryColor} onClick={onClickSave} />
                    </ButtonsContainer>
                        {/* <ButtonModal bgColor={blackColor} onClick={() => onCloseModal(id)}>Cerrar</ButtonModal> */}
                        {/* <ButtonModal bgColor={primayColor} onClick={onClickSave}>Guardar</ButtonModal> */}
                </FooterModal>
            }
        </Content>
    </Container>

export default ModalC

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 100;

    background-color: rgba(0,0,0,.25);
`

const Content = styled.div`
    width: 60%;
    max-width: 600px;
    /* max-height: 300px; */
    height: ${({height}) => height ? height : '60%' };
    background-color: ${secundaryColor};
    border-radius: 15px;
`

const Header = styled.div`
    padding: 10px 20px;
    border-radius: 15px 15px 0 0;
    text-align: center;
    background-color: ${primayVariantColor};
`

const Body = styled.div`
    padding: 20px 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
const Title = styled.h4`
    color: ${secundaryColor};
    font-size: 16px;
    margin: 0px;
    font-weight: 600;
`
const FooterModal = styled.div`
    flex: .2;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 10px;
    width: 100%;
`
// const ButtonModal = styled.button`
//     background-color: ${({ bgColor }) => bgColor};
//     color: ${secundaryColor};
//     font-size: 16px;
//     width: 120px;
//     border-radius: 10px;
//     padding: 8px 20px;
//     border: none;
//     margin: 5px 15px;
//     outline: 0;

//     &:hover {cursor: pointer;}
// `