import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import moment from 'moment'
// import Modal from './components/services/Modal'
// import ModalAllNotificaciones from './components/services/ModalAllNotificaciones'
import { primayColor, bgColor, blackColor, secundaryColor, iconColor, transparentColor, primayVariantColor } from '../constanst'
import logotipo from '../assets/imagenes/logoYamaha.svg'
import { IconAdvisor, IconLogout, IconMenu, IconNotification, IconPerson } from '../assets/icons'
import { withRouter } from 'react-router-dom'
import SchedulingList from './pages/SchedulingList'
import { connect } from 'react-redux'
import { getSchedulingConfirm, getSchedulingState } from '../redux/actions/service-orders'
import MenuMovil from './components/MenuMovil'
import toastr from 'toastr'
class Layout extends Component {
    constructor(props){
        super(props);

        this.state = { menuMovil: undefined, vertodo: false, visible: false, active: false, userdata: {}, servicesList: [], loading: false }
        this.isMount = false
    }

    async componentDidMount(){
        this.isMount = true

        const date = moment().format('D/M/YY')
        const hour = moment().format('h:mm A')
        let userdata = JSON.parse(localStorage.getItem("userdata"))

        this.setState({date, hour})
        
        if(!!userdata === false)
            this.props.history.push('/')
        
        if(!!userdata)
            this.setState({userdata: userdata[0] })

        /** Cargando la lista de agendamiento pendientes para el día */
        this.setState({loading: true})
        await this.props.getSchedulingConfirm({idUser: userdata[0].idusuario, idTypeUser: userdata[0].idtipousuario})
        this.setState({loading: false})
    }

    intervalHour = setInterval(() => {
        const date = moment().format('D/M/YY')
        const hour = moment().format('h:mm A')
        this.setState({date, hour})
    }, 18000)
    
    handleScheduling = async () => {
        let userdata = JSON.parse(localStorage.getItem("userdata"))
        this.setState({loading: true})
        await this.props.getSchedulingConfirm({idUser: userdata[0].idusuario, idTypeUser: userdata[0].idtipousuario})
        this.setState({loading: false})
    }

    componentWillUnmount(){
        this.isMount = false
        clearInterval(this.intervalHour)
    }

    logout = () => {
        localStorage.removeItem("userdata")
        this.props.history.push('/login')
    }
    handleMenu = e => {
        e.preventDefault()
        this.setState({ menuMovil: !this.state.menuMovil })
    }

    onClickNotify = () => {
        this.setState({ visible: !this.state.visible })
    }

    onClickAllNotify = () => {
        this.setState({ vertodo: !this.state.vertodo })
    }
    onClickVisited = async (id, state) => {
        await this.props.getSchedulingState({ id, state })
        if (this.props.agen.success) {
            let userdata = JSON.parse(localStorage.getItem("userdata"))
             /** Cargando la lista de agendamiento pendientes para el día */
            this.setState({loading: true})
            await this.props.getSchedulingConfirm({idUser: userdata[0].idusuario, idTypeUser: userdata[0].idtipousuario})
            this.setState({loading: false})
            toastr.success(this.props.agen.message)
        }
        else toastr.success('Ha Ocurrido un error.')
    }


    render() {
        return (
            <Main>

                {/** Header */}
                <Header>
                   {/** Logo */}
                   <Logo src={logotipo} />

                    {/** Date and Info */}
                    <InfoDate>
                        <Small>DIGITAL FOR &nbsp;</Small>
                        <Normal>MOTOEXPLORER</Normal>
                        <DateContainer>
                            <DateText>{this.state.hour}</DateText>
                            <DateText>{this.state.date}</DateText>
                        </DateContainer>
                    </InfoDate>
                    <DateContainer movil={true}>
                        <DateText>{this.state.hour}</DateText>
                        <DateText>{this.state.date}</DateText>
                    </DateContainer>
                </Header>
                <Banner>
                    <InfoDate>
                        <IconAdvisor width={30} height={30} color={secundaryColor} />
                        <MenuItem marginLeft={"10px"} onClick={() => this.props.history.push("/home")} >HOME</MenuItem>
                        {/** cotizaciones */}
                        {/* <MenuItem className="menu-parent">COTIZACIONES
                            <MenuChildren className="menu-child">
                                {(this.state.userdata.idtipousuario === "1" || this.state.userdata.idtipousuario === "5") &&
                                <>
                                    <Option onClick={() => this.props.history.push("/cotizaciones")}>LISTA</Option>
                                </>}
                            </MenuChildren>
                        </MenuItem> */}
                        <MenuItem className="menu-parent">SERVICIOS
                            <MenuChildren className="menu-child">
                                {/* Recepcionista  */}
                                {(this.state.userdata.idtipousuario === "1" || this.state.userdata.idtipousuario === "5") &&
                                <>
                                    <Option onClick={() => this.props.history.push("/servicios/orden")}>ORDEN DE SERVICIO</Option>
                                    <Option onClick={() => this.props.history.push("/servicios/seguimiento")}>SEGUIMIENTO</Option>
                                    <Option onClick={() => this.props.history.push("/servicios/seguimiento/crm")}>CRM</Option>
                                    <Option onClick={() => this.props.history.push("/servicios/mapataller")}>MAPA DE TALLER</Option>
                                </>}
                                {/*  Tecnico mecánico */}
                                {(this.state.userdata.idtipousuario === "1" || this.state.userdata.idtipousuario === "4" || this.state.userdata.idtipousuario === "5") &&
                                    <Option onClick={() => this.props.history.push("/trabajos/seguimiento")}>DIAGNOSTICO Y TRABAJOS</Option>
                                }
                            </MenuChildren>
                        </MenuItem>
                        {/** Motos */}
                        <MenuItem className="menu-parent">MOTOS
                            <MenuChildren className="menu-child">
                                {/* Recepcionista  */}
                                {(this.state.userdata.idtipousuario === "1" || this.state.userdata.idtipousuario === "5") &&
                                <>
                                    <Option onClick={() => this.props.history.push("/parametrizacion/motos")}>LISTADO</Option>
                                    <Option onClick={() => this.props.history.push("/parametrizacion/motos/valores")}>VALORES</Option>
                                    <Option onClick={() => this.props.history.push("/motos/historia")}>HOJA DE VIDA</Option>
                                </>}
                            </MenuChildren>
                        </MenuItem>
                        {/** usuarios  */}
                        { this.state.userdata.idtipousuario === "1" &&
                            <MenuItem onClick={() => this.props.history.push("/usuarios")} >USUARIOS</MenuItem>}
                        {/* Parametrización */}
                        {(this.state.userdata.idtipousuario === "1" || this.state.userdata.idtipousuario === "5" ) &&
                        <MenuItem className="menu-parent">PARAMETRIZACIÓN
                            <MenuChildren className="menu-child">
                            { this.state.userdata.idtipousuario === "1" && 
                            <>
                                <Option onClick={() => this.props.history.push("/parametrizacion/servicios")}>SERVICIOS</Option>
                                <Option onClick={() => this.props.history.push("/parametrizacion/valores")}>VALORES</Option>
                            </>}
                            </MenuChildren>
                        </MenuItem>}
                    </InfoDate>

                    <RigthContainer>
                        {/* <Modal visible={this.state.visible} state={this.state} onClickAllNotify={this.onClickAllNotify } onClick={this.onClickNotify}/>                       */}
                        <Box onClick={this.onClickNotify }><IconNotification  width={25} height={25} color={secundaryColor} /></Box>                        
                        <DateContainer className="menu-parent">
                            <DateText >Bienvenido </DateText>
                            <DateText>{this.state.userdata.nombres}</DateText>
                            <MenuChildren className="menu-child">
                                <Option onClick={this.logout}>Cerrar Sesisón</Option>
                            </MenuChildren>
                        </DateContainer>
                        <UserContainer>
                            <IconPerson width={25} height={25} color={blackColor} />
                        </UserContainer>
                    </RigthContainer>
                    <NavMovil>
                        <Button onClick={this.handleMenu}>
                            <IconMenu width={25} height={25} color={secundaryColor} />
                        </Button>
                        <div>
                            <IconNotification width={25} height={25} color={secundaryColor} style={{marginRight: 10}} />
                            <Button onClick={() => this.props.history.goBack()}>
                                <IconLogout width={25} height={25} color={secundaryColor} />
                            </Button>
                        </div>
                    </NavMovil>
                </Banner>
                {this.props.children}
                
                {this.isMount === true &&
                    <MenuMovil active={this.state.menuMovil} state={this.state} logout={this.logout} onCloseMenu={this.handleMenu} />
                }
                <SchedulingList onClickVisited={this.onClickVisited} handleScheduling={this.handleScheduling} state={this.state} handleList={e => this.setState({ active: !this.state.active })} />
                {/* <ModalAllNotificaciones state={this.state} onClickAllNotify={this.onClickAllNotify}/> */}
            </Main>
        )
    }
}


const Main = styled.div`
    display: flex;
    height: 100vh;
    flex-direction: column;
    background-color: ${bgColor};
`
const Header = styled.header`
    background-color: ${primayColor};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
`
const Logo = styled.img`
    width: 120px;
    height: 100%;
`
const InfoDate = styled.div`
    display: none;
    @media (min-width: 600px){
        display: flex;
        align-items: center;
    }

`
const Small = styled.span`
    font-size: 20px;
    font-weight: 100;
    color: ${secundaryColor};
`
const Normal = styled.span`
    font-size: 20px;
    color: ${secundaryColor};
`

const DateContainer = styled.div`
    padding-left: 30px;
    ${({ movil }) => movil && css`
        @media (min-width: 600px){
            display: none;
        }
    `}
`
const DateText = styled.span`
    color: ${secundaryColor};
    font-size: 12px;
    font-weight: 100;
    display: block;
    text-align: right;
`
const NavMovil = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (min-width: 600px){
        display: none;
    }
`
const Button = styled.button`
    background-color: transparent;
    border: none;
`
/** banner */
const Banner = styled.nav`
    background-color: ${blackColor};
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const MenuItem = styled.div`
    position: relative;
    display: inline-block;
    background-color: ${transparentColor};
    border: none;
    outline: none;
    color: ${iconColor};
    padding: 5px 10px;
    font-weight: 300;
    font-size: 14px;
    border-left: 1px solid ${iconColor};
    margin-left: ${props => props.marginLeft && props.marginLeft };

    &:hover {
        cursor: pointer;
        color: ${secundaryColor};
    }
`
const MenuChildren = styled.div`
    position: absolute;
    display: none;
    top: 100%;
    left: 0px;
    min-width: 250px;
    background-color: ${secundaryColor};
    border-radius: 5px;
    box-shadow: 1px 1px 5px 1px rgba(225, 225, 225, .7);
    z-index: 999;
`
const Option = styled.a`
    padding: 10px 20px;
    display: block;
    color: ${blackColor};
    font-size: 16px;

    :hover {
        background-color: ${primayVariantColor};
        color: ${secundaryColor};
        cursor: pointer;
    } 
`

/* Banner rigth */
const RigthContainer = styled.div`
    float: right;
    display: none;
    align-items: center;
    position: relative;
    @media(min-width: 600px){
        display: flex;
    }
`
const UserContainer = styled.div`
    background-color: ${secundaryColor};
    border-radius: 100%;
    width: 25px;
    height: 25px;
    margin-left: 30px;
`
const Box = styled.div`
`
export default withRouter(connect(({ agen }) => ({ agen }), {getSchedulingConfirm, getSchedulingState})(Layout))