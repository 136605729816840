import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getTokenService, confirmAppointment } from '../../../redux/actions/service-orders'
import ConfirmAppointmentV from '../../components/public/ConfirmAppointment'

class ConfirmAppointment extends Component {

    constructor(props){
        super(props)
        this.state = {
            loading: false,
            success: 0
        }
        this.isMount = false
    }

    async componentDidMount() {
        const token = this.props.match.params.token
        this.isMount = true
        
        
        this.setState({loading: true})
        await  this.props.getTokenService({token})
        if(this.props.serviceToken.success === 1){
            await this.props.confirmAppointment(this.props.serviceToken.data[0].ser_id)
            this.setState({success: this.props.postResponse.success})
        }
        
        
        
        this.isMount &&
           this.setState({loading: false})
    }
    

    componentWillUnmount() {
        this.isMount = false
    }
    

    render() {
        return <ConfirmAppointmentV state={this.state} />
    }
}


const mapStateToProps = ({serviceToken, postResponse}) => ({serviceToken, postResponse})

export default connect(mapStateToProps, {getTokenService, confirmAppointment})(ConfirmAppointment)