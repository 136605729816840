import React, { Component } from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import { connect } from 'react-redux'
import toastr from 'toastr'
import "toastr/build/toastr.css"

import PublicV from '../../print/CustomerTracking'
import { getServicesList, getPlanillaDiaria, getSedesId, getGenerarPdfCliente, getGenerarPdfTecnico, deleteService, getPdfFecha,
    getServicesPList, getSedes, getGenerarPdfService, getOrdenServicioId, getTypeServiceWork, getRequestServiceId, getCallsServiceId,
    getIventarioList, getAuthId, getInventario, getTokenService } from "../../../redux/actions/service-orders"
import { getTecnicos } from '../../../redux/actions/userActions'


class PublicPrint extends Component {

    constructor(props){
        super(props)
        this.state = {
            clif: [],
            vf: [],
            sf: [],
            solicitudform: [],
            clientellamadas: [],
            inventarioform: [],
            comments: [],
            termsform: [],
            servicesList: [],
            cond: [],
            sedesList: [],
            inventario: [],
            resSede: {},
            ready: false
        }
    }

    async componentDidMount() {

        let  idServicio = undefined;
        /** Obteniendo el parametro de la url */
        const token = this.props.match.params.token
        await  this.props.getTokenService({token})
        if(this.props.serviceToken.success === 1)
            idServicio = this.props.serviceToken.data[0].ser_id


        /** Resultados de la petición para la información personal, vehiculo y servicio */
        if(this.props.serviceToken.success === 1)
            await this.props.getOrdenServicioId(idServicio)
        else {
            toastr.warning("Verifique el enlace, no se ha podido cargar la información")
            return false
        }

        /** Acc */
        await this.props.getSedes()
        await this.props.getInventario()
        
        this.setState({
            servicesList: this.props.servicesList.data,
            sedesList: this.props.sedes,
            inventario: this.props.inventario,
        })


        /** Prev */
        if(this.props.servicesOrden.success === 1) {
            const {data: {clientform: clif, vehicleform: vf, serviceform: sf, conditions: cond}} = this.props.servicesOrden

            /** Buscar la información de la sede */
            const resSede = this.props.sedes.find(x => x.sed_id === sf.formsede)

            /** Obteniendo datos del técnico */
            await this.props.getTecnicos()
            const tecn = this.props.tecnicos.length > 1 && this.props.tecnicos.filter(x => x.usu_id === sf.formtecnico)
            
            await this.props.getTypeServiceWork(sf.formtiposervicio)
            // this.setState({ typeServicesWork: this.props.typeServicesWork })
            this.setState({ typeServicesWork: this.props.typeServicesWork, clif, vf, sf, cond, tecn: tecn[0].per_nombre, resSede })

            /** Resultados de las solicitudes y solucion de servicios */
            await this.props.getRequestServiceId(idServicio)
            
            if(this.props.servicesRequestList.success === 1)
                this.setState({ solicitudform: { ...this.state.solicitudform, clienteSolicitud: this.props.servicesRequestList.data} })
            else {
                toastr.error("Ha ocurrido un error cargando la información")
                //this.props.history.push('/servicios/seguimiento')
            }
            
            /** Resultados de las llamadas de servicios */
            await this.props.getCallsServiceId(idServicio)

            if(this.props.servicesCallsList.success === 1)
                this.setState({ solicitudform: { ...this.state.solicitudform, clientellamadas: this.props.servicesCallsList.data} })

            /** Obteniendo la lista de inventario */
            await this.props.getIventarioList(idServicio)
            
            if(this.props.inventarioList.success === 1){
                const { inventarioform, comments } = this.props.inventarioList.data
                this.setState({ inventarioform, comments })
            }

            /** Obteniendo información de la lista de las autorizaciones */
            await this.props.getAuthId(idServicio)

            if(this.props.authId.success === 1)
            this.setState({ termsform: this.props.authId.data })

            this.setState({ ready: true })
        }

    }
    

    render() {
        return (
            this.state.ready ?
            <div style={{ display: 'flex', height: '100vh', width: '100%' }}>
                <PDFViewer style={{width: '100%', height: '100%'}}>
                    <PublicV state={this.state} />
                </PDFViewer> 
            </div>
                // <div>{console.log(this.state)}</div>
            : <div></div>
        )
    }
}


const mapStateToProps = ({ servicesList, servicesOrden, sedes, postResponse, typeServicesWork, servicesRequestList, servicesCallsList, inventarioList, authId, tecnicos, inventario, serviceToken }) => ({ 
    servicesList, sedes, postResponse, servicesOrden, typeServicesWork, servicesRequestList, servicesCallsList, inventarioList, authId, tecnicos, inventario, serviceToken })

export default connect(mapStateToProps, {
    getServicesList, getPlanillaDiaria, getSedesId, getSedes, getGenerarPdfCliente, getGenerarPdfTecnico, deleteService, getPdfFecha, getTecnicos, getTokenService,
    getServicesPList, getGenerarPdfService, getOrdenServicioId, getTypeServiceWork, getRequestServiceId, getCallsServiceId, getIventarioList, getAuthId, getInventario
}   )(PublicPrint)