import React, { Component } from 'react'
import { connect } from 'react-redux'
import toastr from 'toastr'
import TrabajosV from "../../components/trabajos"
import {getServicesPList, getServicesList, getSedes} from "../../../redux/actions/service-orders"
import {setCloseOrden} from "../../../redux/actions/works"
import Axios from 'axios'
import { url_base } from '../../../redux/api/urls'

class Trabajos extends Component {
    constructor(props){
        super(props)
        this.state = {
            servicesList: [],
            sedesList: [],
            // inventario: [],
            userdata: {},
            resSede: {},
            loading: false,
            ser_estado: '0',
            selectDisabled: true,
            menuPopup: null
        }
        this.isMount = false
    }

    async componentDidMount(){
        this.isMount = true
        this.setState({loading: true})
      

        /** Cargando datos de localStorage */
        const userdata = JSON.parse(localStorage.getItem("userdata"))
        await this.props.getServicesPList({iduser: userdata[0].idusuario, idTypeUser: userdata[0].idtipousuario})
        await this.props.getSedes()

        this.isMount &&
            this.setState({ loading: false, servicesList: this.props.servicesList.data || [],
                sedesList: this.props.sedes || [],
                userdata: {
                    idSede: userdata[0].usu_sede,
                    typeUser: userdata[0].idtipousuario,
                    idUser: userdata[0].idusuario,
                    dateF: 0,
                    selTser: 0,
                    selTserW: 0
                }
            })

        await this.props.getServicesList({iduser: userdata[0].idusuario, idTypeUser: userdata[0].idtipousuario})
        if(this.isMount)
            this.setState({selectDisabled: false})
    }


    /** Métodos de cambio onChange's */
        /** Filtrando resultados */
        onChange = async (e) => {
            const { name, value } = e.target
    
            this.setState({ [name]: value})
        }
        /** Filtrando resultados */
        onChangeSel = async (e) => {
            let values = []
            const { name, value } = e.target
            this.setState({ [name]: value })
            if(value === 'all') 
                this.setState({ servicesList: this.props.servicesList.data })
            else
                this.props.servicesList.data.map( x => {
                    if(x[name].indexOf(value) !== -1) {
                        values = [...values, x]   
                    }
                    return this.setState({servicesList: values})
                })
        }

        onKeyPressFilter = e => e.charCode === 13 && this.onFilter(e, e.target.name, e.target.value)
    
        onFilter = async (e, name, value) => {
            e.preventDefault()
            this.setState({ loading: true })
            const {idUser, typeUser} = this.state.userdata
            
            const res = await Axios.get(`${url_base}/servicio/listaservicios/filtro/${idUser}/${typeUser}/${name}/${value || 0}`).then(v => v.data).catch(() => ({success: 0, message: 'Error en la conexión', data: []}))
            this.setState({ loading: false, servicesList: res.data })
        }
        
    /** Filtrar por fecha */
    onClickFindDate = () => {
        const dateStart = new Date(`${this.state.dateStart}`).getTime()
        const dateFinal = new Date(`${this.state.dateFinal}`).getTime()
        let values = []

        if(!this.state.dateStart || !this.state.dateFinal)
            return toastr.warning("Seleccione un rango de fecha")
            
        this.props.servicesList.data.map( x => {
            const date = new Date(x.fecha).getTime()

            if(date >= dateStart && date <= dateFinal)
                values = [...values, x]
            
            return this.setState({servicesList: values})
        })
    }

    /** Cierre de orden técnico */
    handleOrder = async(e, id) => {
        e.preventDefault()
        await this.props.setCloseOrden(id)
        if(!!this.props.worksList.successPost){
            toastr.success(this.props.worksList.message)        
            const userdata = JSON.parse(localStorage.getItem("userdata"))
            this.setState({loading: true})
            await this.props.getServicesPList({iduser: userdata[0].idusuario, idTypeUser: userdata[0].idtipousuario})
            this.setState({loading: false, servicesList: this.props.servicesList.data || []})
        }
        else
            toastr.warning(this.props.worksList.message)
    }

    /** Click para sacar el menu emergente del mecanico */
    onClickPopup = e => {
        e.preventDefault()
        this.setState({ menuPopup: !this.state.menuPopup })
    }

    render() {
        return (
            <TrabajosV
                servicesList={this.state.servicesList}
                onChange={this.onChange}
                onChangeSel={this.onChangeSel}
                onClickFindDate={this.onClickFindDate}
                onClickCloseOrder={this.handleOrder}
                onFilter={this.onFilter}
                onKeyPressFilter={this.onKeyPressFilter}
                state={this.state}
                onClickPopup={this.onClickPopup}
            />
        )
    }
}

const mapStateToProps = ({servicesList, worksList, sedes}) => ({servicesList, worksList, sedes})
export default connect(mapStateToProps, { getServicesPList, getServicesList, setCloseOrden, getSedes })(Trabajos)