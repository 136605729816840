import React from 'react'
import styled, { keyframes, css } from 'styled-components'
import { primayColor, iconColor, bgColor, primayVariantColor, secundaryColor } from '../../constanst/index'
import Scrollbars from 'react-custom-scrollbars'
import { Link } from 'react-router-dom'


export default ({ state, active, onCloseMenu, logout }) =>
    <>
        <Panel onClick={onCloseMenu} active={active} />
        <Menu active={active}>
            <Row flex=".2">
                <Logo width="100%" height="70px" src={require('../../assets/imagenes/logo.jpg')} />
            </Row>
            <br />
            <Options>
                <Scrollbars autoHide style={{ flex: 1 }} autoHeight autoHeightMax="100%" autoHeightMin={0}>
                    <List>
                        <Li onClick={onCloseMenu}><Enlace to="/">Inicio</Enlace></Li>
                        {(state.userdata.idtipousuario === "1" || state.userdata.idtipousuario === "4") &&
                            <Li onClick={onCloseMenu}><Enlace to="/trabajos/seguimiento">Diagnóstico y Trabajo</Enlace></Li>
                        }
                        <Li onClick={onCloseMenu}><Enlace to="#">Notificaciones</Enlace></Li>
                    </List>
                </Scrollbars>
            </Options>
            <Footer>
                <Button onClick={logout}>Cerrar Sesión</Button>
                <DateContainer movil>
                    <DateText>{state.hour}</DateText>
                    <DateText>{state.date}</DateText>
                </DateContainer>
            </Footer>
        </Menu>
    </>

/** Animaciones */
const SlideRight = keyframes`
    from {left: -100%;}
    to {left: 0;}
`
const SlideLeft = keyframes`
    from {left: 0%;}
    to {left: ineriht;}
`
const fadeIn = keyframes`
    from {background-color: #00000000; z-index: -1;}
    to {background-color: #00000055; z-index: 9998;}
`
const fadeOut = keyframes`
    from {background-color: #00000055; z-index: 9998;}
    to {background-color: #00000000; z-index: -1;}
`


/** Menú de categorías */
const Panel = styled.div`
    position: fixed;
    background-color: transparent;
    width: 100%;
    height: 100%;
    z-index: -1;
    animation: ${({ active }) => active ? fadeIn : (active === false && fadeOut)} .3s linear;
    animation-fill-mode: forwards;
`
const Menu = styled.div`
    /* margin-left: -100%; */
    position: fixed;
    top: 0;
    left: -100%;
    width: 60%;
    height: 100%;
    z-index: 9999;
    background-color:${bgColor};
    display: flex;
    flex-direction: column;
    animation: ${({ active }) => active ? SlideRight : (active === false && SlideLeft)} .2s linear;
    animation-fill-mode: forwards;
    background-image: linear-gradient(125deg , ${primayVariantColor} 50%,  ${primayColor});



`
const Options = styled.div`
    overflow: hidden;
    height: 80%;
`

const List = styled.ul`
    list-style-type: none;
    padding: 10px;

    @media(max-width: 1400px){
        padding: 10px;
    }
`
const Li = styled.li`
    cursor: pointer;
    color: ${bgColor};
    text-decoration:none;
    transition: .4s;

    font-size: 12px;
    padding: 5px;
    padding-left: ${({ padding }) => padding ? '0' : '10px'};
`
const Footer = styled.div`
    border-top: 1px solid ${iconColor};
    padding: 10px;
    
`
const Enlace = styled(Link)`
    padding: 2px;
    display: inline-block;
    font-size: 14px;
    text-decoration: none;
    width: 100%;
    color:${bgColor};
    font-size: 14px;
    width: 100%;
    &:hover {
        color: ${primayColor};
    }
`

const Row = styled.div`
    flex: ${({ flex }) => flex};
    padding: 10px;
    background-color: #ffffff;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${({ justify }) => justify ? justify : 'flex-start'};

    @media only screen and (max-width: 1700px){
        ${({ resp }) => !!resp && css`display: none;`}
    }
`
const Logo = styled.img`
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    position: relative;
    
`

const DateContainer = styled.div`
    padding-left: 30px;
    ${({ movil }) => movil && css`
        @media (min-width: 600px;){
            display: none;
        }
    `}
`
const DateText = styled.span`
    color: ${secundaryColor};
    font-size: 12px;
    font-weight: 100;
    display: block;
    text-align: right;
`
const Button = styled.button`
    border: none;
    outline: 0;
    background-color: transparent;
    display: inline-block; padding: 2px;
    display: inline-block;
    font-size: 14px;
    text-decoration: none;
    color:${bgColor};
    font-size: 14px;
`