import React from 'react'
import Scrollbars from 'react-custom-scrollbars'
import styled from 'styled-components'

import { secundaryColor, primayVariantColor, successColor, alternateVariantColor, errorColor, iconColor } from '../../../constanst'
import { Tooltip, TooltipText } from '../../components/services/ComponentsForm'
import Table, { Tr, Td, Th, ButtonsContainer, ButtonIcon } from '../../common/Table'
import { IconEdit, IconDelete, IconPlus } from '../../../assets/icons'
import { Loading } from '../../common/Loading'
import Button from '../../common/Button'
import ModalC from '../../common/ModalC'
import ModuleInput from '../../common/ModuleInput'
import CustomSelect from '../../common/CustomSelect'
import { MenuItem } from '@material-ui/core'
import { openModal } from '../../common/Modal'


export default ({ state, onSubmit, onDelete, onClickParams, onChangeSelect, onChange, onClickCancel }) => 
<Main>
{state.loading && <Loading /> }
<ModalC title="NUEVO VALOR" id="modalVehicle" onClickBack={onClickCancel} onClickSave={onSubmit} >
    <CustomSelect label="VEHICULO" inputw="60%" name="formvehiculo" onChange={onChangeSelect} value={state.formvehiculo || ''}>
        {state.vehiclesList.length > 0 &&
            state.vehiclesList.map( x => 
                <MenuItem key={x.id} value={x.id}>{x.nombre}</MenuItem>
        )}
    </CustomSelect>
    <ModuleInput label="VALOR" type="text" name="formvalor" 
        backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="60%"
        onChange={e => onChange(e, true, false, true, true, 2, 15)} value={state.formvalor || ''}
        ignore={true}
    />
    {/* <ButtonsContainer>
        <Button label="Cerrar" type="button" width="40%" bgColor={'transparent'} color={primayVariantColor} borderC={primayVariantColor} />
        <Button label="Guardar" type="button" width="40%" bgColor={primayVariantColor} color={secundaryColor} onClick={onSubmit} />
    </ButtonsContainer> */}
</ModalC>
<Scrollbars>
    <Container>
        <Title>VALORES DE VEHÍCULO</Title>
        <Table
            minWidth="1000px"
            renderButtons={
                <ButtonsContainer>
                    <Button label="Nuevo Valor" type="button" bgColor={successColor} color={secundaryColor} icon={<IconPlus width="15px" height="15px" color={secundaryColor} />} onClick={() => openModal('modalVehicle')} />
                </ButtonsContainer>
            }
            renderHead={
                <Tr bgColor={true}>
                    <Th>#</Th>
                    <Th>VEHÍCULO</Th>
                    <Th>VALOR</Th>
                    <Th>FECHA DE REGISTRO</Th>
                    <Th>ACCIONES</Th>
                </Tr>
            }
            renderBody={
            state.motorcyclesVal.length > 0 ?
                state.motorcyclesVal.map( (x, index) => (
                    <Tr key={x.idvalaores}>
                        <Td>{index + 1}</Td>
                        <Td>{x.rm_nombremodelo}</Td>
                        <Td>{x.val_valor}</Td>
                        <Td>{x.val_fechaSistema}</Td>
                        <Td>
                            <Tooltip className="tooltip">
                                <ButtonIcon type="button" onClick={e => onClickParams(e, x.idvalaores, "modalVehicle")} >
                                    <IconEdit width="30px" height="20px" color={secundaryColor} bgColor={alternateVariantColor} />
                                </ButtonIcon>
                                <TooltipText className="tooltiptext" bgColor={alternateVariantColor}>Editar</TooltipText>
                            </Tooltip>
                            <Tooltip className="tooltip">
                                <ButtonIcon type="button" onClick={e => onDelete(e, x.idvalaores)} >
                                    <IconDelete width="30px" height="20px" color={secundaryColor} bgColor={errorColor} />
                                </ButtonIcon>
                                <TooltipText className="tooltiptext" bgColor={errorColor}>Eliminar</TooltipText>
                            </Tooltip>
                        </Td>
                    </Tr>
                )) : 
                <Tr>
                    <Td colSpan="6">No se encontraron elementos para mostrar</Td>
                </Tr>
            }
        >
        </Table>
    </Container>
        <br/>
        <br/>
</Scrollbars>
</Main>


const Main = styled.main`
    flex: 1;
    display: flex;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
`
const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: ${secundaryColor};
    width: 100%;
    margin-bottom: 30px;
    margin: 0px 2%;
    /* border-radius: 15px; */
    box-shadow: 0px 0px 6px 1px rgba(0,0,0,.4);
    @media (min-width: 1000px){
        width: 98%;
        margin-left: 1%;
    }
 `
 const Title = styled.h3`
    color: ${secundaryColor};
    padding: 5px;
    margin: 0px;
    text-align: center;
    background-color: ${primayVariantColor};
`