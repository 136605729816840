import { handleActions } from 'redux-actions'
import { GET_INGRESO_MOTO_MENSUAL, GET_FACTURA_TECNICO, GET_INGRESO_MOTO_MENSUAL_ID, GET_VALOR_VENTA_TYPE_SERV, GET_MAPATALLER_PDF } from '../types'

/** ingresos de motos mensuales o ventas de motos mensuales  */
export const inMotosMensuales = handleActions({ 
    [GET_INGRESO_MOTO_MENSUAL]: (state, action) => action.payload,
    [GET_INGRESO_MOTO_MENSUAL_ID]: (state, action) => action.payload
}, [])
export const facturaTecnicos = handleActions({ [GET_FACTURA_TECNICO]: (state, action) => action.payload }, [])
export const valVenTSer = handleActions({ [GET_VALOR_VENTA_TYPE_SERV]: (state, action) => action.payload }, [])
export const postResponseMT = handleActions({ [GET_MAPATALLER_PDF]: (state, action) => action.payload }, [])