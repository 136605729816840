import React, {Fragment} from 'react'
import styled from 'styled-components'
import { secundaryColor, iconColor, blackColor } from '../../../constanst'
import { Box } from "./ComponentsForm"
import ModuleInput from '../../common/ModuleInput'
import { Radio } from '@material-ui/core'


export default  ({ state: {historyform}, onChange, onCheckClick }) => (
        <Fragment>
        {/** Contendor izquierdo */}
            <Form method='post' id='formHistory'>
                <Title bgcolor="transparent" color={blackColor}>HISTORIA DE LA MOTO</Title>
                <Box justify="space-between" direction="row">
                    <Box direction="column" position="relative" width="50%">
                        <ModuleInput label="VEHICULO COMPRADO POR:" type="text" name="hvvh_buyer"
                            backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="100%" 
                            onChange={e => onChange(e, true, true, false, true, 5, 100)} value={historyform.hvvh_buyer || ''}
                        />
                    </Box>

                    <Box direction="column" position="relative" width="50%">
                        <ModuleInput label="FECHA DE COMPRA DEL VEHICULO" type="date" name="hvvh_dateBuy"
                            onChange={e => onChange(e, true, false, false, true, 5, 100)} value={historyform.hvvh_dateBuy || ''}
                            backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="100%"
                        />  
                    </Box> 
                </Box>
                <Box justify="space-between" direction="row">
                    <ModuleInput label="NUMERO DE FACTURA" type="text" name="hvvh_invoice"
                        backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="50%"
                        onChange={e => onChange(e, true, false, true, true, 5, 100)} value={historyform.hvvh_invoice || ''}
                    />
                    <ModuleInput label="FECHA DE PRIMER ALISTAMIENTO" type="date" name="hvvh_firsAlis"
                            onChange={e => onChange(e, true, false, false, true, 5, 100)} value={historyform.hvvh_firsAlis || ''}
                            backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="50%"
                    />
                </Box>               
                <Box justify="space-between" direction="row">
                    <ModuleInput label="VEHICULO VENDIDO POR" type="text" name="hvvh_seller" 
                        backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="50%" 
                        onChange={e => onChange(e, true, true, false, true, 5, 100)} value={historyform.hvvh_seller || ''}
                    />
                    <InputContainer>
                        <Label>MOTIVO DE SOLICITUD:</Label>
                        <Label><Radio onChange={() => onCheckClick('1')} checked={ historyform.hvvh_type_soli === '1' ? true : false}/>Por Promocion</Label>
                        <Label><Radio checked={historyform.hvvh_type_soli === '2' ? true : false}  onChange={() => onCheckClick('2')}/>Por Venta de vehiculo</Label>
                        <Label><Radio checked={historyform.hvvh_type_soli === '3' ? true : false}  onChange={() => onCheckClick('3')}/>Otro</Label>
                    </InputContainer>
                     
                </Box>
                
            </Form>
        </Fragment>
)


const Form = styled.form`
    flex: 1;
    padding: 0 10px 0px 20px;
    
`
const InputContainer = styled.div`
    width: 50%;
    font-size: 12px;
`
const Label = styled.label`
    display: block;
    color: ${blackColor};
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif;
    font-size: 12px;
    font-weight: 500;
`
const Title = styled.h3`
    color: ${({color}) => color ? color : secundaryColor};
    padding: 5px;
    margin-top: 0px;
    text-align: center;
`