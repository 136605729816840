import React, { Component } from 'react'
import { connect } from 'react-redux'
import toastr from 'toastr'
import "toastr/build/toastr.css"

import FormMotorcycleV from '../../components/parameterization/FormMotorcycle'
import { getInventario } from "../../../redux/actions/service-orders"
import { getSoatList, postMotorcycles, postInvMotorcycles, getMotorcyclesId, upMotorcycles, upInvMotorcycles } from "../../../redux/actions/Parameterizations"
import { validations, errorColor } from '../../../constanst'

class FormMotorcycle extends Component {

    constructor(props){
        super(props)

        this.state = {
            loading: false,
            inventarioList: [],
            inventarioform: [],
            motform: {},
            invData: [],
            errorChange:{}
        }
        this.isMount = false
        this.error = false
        this.check = false
    }

    async componentDidMount() {
        this.isMount = true
        this.setState({ loading: true })

        await this.props.getInventario()
        await this.props.getSoatList()

        
        if(this.props.match.params.id){
            await this.props.getMotorcyclesId(this.props.match.params.id)

            if(this.props.motorcycles.success === 1 && this.isMount){
                const { inventarioform, motform, invData } = this.props.motorcycles.data

                this.setState({ inventarioform, motform, invData })
            }
        }

        if(this.isMount)
            this.setState({ inventarioList: this.props.inventario, loading: false })

    }
    
    componentWillUnmount(){
        this.isMount = false
    }

    /** On Change method */
    handleChange = (event, isNull, isLetter, isNumber, isRange, minRange, maxRange, isEmail) => {
        const target = event.target
        const value = target.type === 'checkbox' ? (target.checked ? target.name : 0) : target.value
        const name = target.name
        
        /** Validando las entradas */
        if(target.type === 'checkbox'){
            this.setState( ps => ({  inventarioform: {...ps.inventarioform, [name]: value} }))
        } else {
            const error = validations(event, isNull, isLetter, isNumber, isRange, minRange, maxRange, isEmail)
            this.setState({ errorChange: { ...this.state.errorChange, [name]: error }, motform: { ...this.state.motform, [name]: value.toUpperCase()} })
        }

    }

    /** Handle Change marcar todos lo inputs */
    handleCheckAll = e => {
        let checks = {}

        const inputs = document.forms.formMot.elements
        for(let i in inputs){
            if(inputs[i].type === "checkbox" && inputs[i].name !== 'checkAlls'){
                    inputs[i].checked = inputs[i].checked ? false : true
                    checks = {
                        ...this.state.inventarioform,
                        ...checks,
                        [inputs[i].name]: this.check ? 0 : inputs[i].name,
                        estado: this.check ? 1 : 0,
                    }
                }
            }
        

        this.check = this.check ? false : true
        this.setState({ ...this.state.inventarioform, inventarioform: checks })
    }

    /** Enviando formulario */
    handleSubmit = async e => {
        e.preventDefault()
        this.error = false
        this.setState({ loading: true })

        const errorChange = this.state.errorChange
        const inputs = document.forms.formMot.elements
        let idmoto = ""

        /** Validando que no haya error en los campos */
        for(let x in this.state.errorChange){
            errorChange[x] === true && (this.error = true)
        }

        /** Agregando estilos a los inputs TEXT con error */
        for(let i in inputs){
            if(inputs[i].type === "text"){
                if(!!inputs[i].value === false){
                    this.error = true
                    inputs[i].style.border = `1px solid ${errorColor}`
                } 
                
            }
        }

        /** Validando que haya minimo un elemento seleccionado */
        if(this.state.inventarioform.length <= 0){
            this.error = true
            toastr.warning("Seleccione al menos un elemento del inventario")
        }
  
        /** Si hay error no continúa */
        if(this.error){
            this.setState({ loading: false })
            return false
        }

        /** Verificando el tipo de soat que se aplica */
        await this.verifySoat(this.state.motform.cilindraje)
        
        /** Verificando si debe actualizar o registrar */
        if(this.props.match.params.id)
            await this.props.upMotorcycles(this.state.motform) // actualizar moto
        else
            await this.props.postMotorcycles(this.state.motform) // Registrar moto
        
        if(this.props.match.params.id)
            idmoto = this.props.match.params.id
        else
            idmoto = this.props.postResponseParam.data // Id retornado de la moto
            
        /** Si la respuesta es correcta  */
       if(this.props.postResponseParam.success === 1){
            for(let i in this.state.inventarioform){
                /** Creando el formato que recibe la api */
                const val = {
                    cant: 0,
                    idmot: idmoto,
                    iditem: i,
                    idestado: this.state.inventarioform[i] === 0 ? 1 : 0,
                }
                
                /** Guardando o actualizando el inventario de la moto */
                if(this.props.match.params.id)                
                    await this.props.upInvMotorcycles(val) // actualizar inventario
                else
                    await this.props.postInvMotorcycles(val) // Registrar inventario
            }
        }
        
        /** Mensaje de alerta para el tipo de respuesta */
       if(this.props.postResponseParam.success === 1)
            toastr.success('Operación exitosa')
        else
            toastr.warning("Ha ocurrido un error")
        
        /** Ocultando el loading */
        this.isMount &&
            this.setState({ loading: false })
        
        /** Enviando a la pagina de la lista */
        this.props.history.push('/parametrizacion/motos')
    }

    /** Función que verifica el tipo de soat que se le aplica dependiendo el tipo de cilindraje */
    verifySoat = async val => {
        let aux = 0
        !!this.props.soatList &&
            this.props.soatList.data.map( x => {
                if (val >= aux && val < x.trs_hastacilindraje ){
                    this.setState({ motform: {...this.state.motform, idsoat: x.trs_id} })
                } else if (val > aux )
                    this.setState({ motform: {...this.state.motform, idsoat: x.trs_id} })
    
                aux = x.trs_hastacilindraje

                return aux
            })

    }

    render() {
        return (
            <FormMotorcycleV
                state={this.state}
                onChange={this.handleChange}
                onCheckAll={this.handleCheckAll}
                onSubmit={this.handleSubmit}
                onGoBack={() => this.props.history.goBack()}
            />
        )
    }
}

const mapStateToProps = ({inventario, soatList, postResponseParam, motorcycles}) => ({inventario, soatList, postResponseParam, motorcycles})

export default connect(mapStateToProps, { getInventario, getSoatList, postMotorcycles, postInvMotorcycles, getMotorcyclesId, upMotorcycles, upInvMotorcycles })(FormMotorcycle)