import { url_base } from "./urls"
import axios from 'axios'

/** Ingreso de motos mensuales */
export const apiGetInMotMes = (data) => () => axios.get(`${url_base}/mapataller/listadoinxmes/${data}`).then(v => {
    if(v.data.length>0)
        return {success: 1, data: v.data}
    else
        return {success: 0, message:"ha ocurrido un error"}
})

/** Ingreso por id de técnico mensual */
export const apiGetInMotMesId = (data) => () => axios.get(`${url_base}/mapataller/listadoinxmesxuser/${data}`).then(v => {

    if(v.data.length>0)
        return {success: 1, data: v.data}
    else
        return {success: 0, message:"ha ocurrido un error"}
})

/** Factura por id tecnico */
export const apiGetFacTec = (data) => () => axios.get(`${url_base}/mapataller/acumuladotecnico/${data.month}/${data.year}/${data.iduser}/${data.idTSer}/${data.sede}`).then(v => {

    if(v.data.length>0)
        return {success: 1, data: v.data}
    else
        return {success: 0, message:"ha ocurrido un error"}
})

/** Obtener datos de ventas para el tipo de servicio -- TODOS --  */
export const apiGetValVenSer = (data) => () => axios.get(`${url_base}/mapataller/valxvenxtser/${data.typeServ}/${data.IDsede}`).then(v => {
    
    if(v.data.length>0)
        return {success: 1, data: v.data}
    else
        return {success: 0, message:"ha ocurrido un error"}
})

/** GENERAR PDF MAPA TALLER  */
export const apiGetMapaTallerPdf = (data) => () => axios.get(`${url_base}/mapataller/planillaMapaTaller/${data.month}/${data.year}/${data.idSede}`).then(v => {
    if(v.data === 1)
        return {success: 1, data: v.data}
    else
        return {success: 0, message:"ha ocurrido un error"}
})
