import { Link } from 'react-router-dom'
import styled, {css} from 'styled-components'
import { iconColor, iconVariantColor, secundaryColor, blackColor, bgVariantColor, primayVariantColor } from '../../../constanst'


export const NavSearch = styled.nav`
    position: relative;
    background-color: ${iconVariantColor};
    display: flex;
    justify-content: center;
    flex-direction: column;
    ${({ border }) => border && css`border-bottom: 1px solid rgb(180, 180, 180);`}
    
    & > div {width: 100%;}
    @media (min-width: 600px){
        flex-direction: row;
        & > div {width: 50%; padding: 0px 5px;}
    }
`
export const DivisorContainer = styled.div`
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: ${({justify}) => justify ? justify : 'center'};
    align-items: center;
    /* ${({ borderC }) => borderC && css`border-right: 0px solid ${borderC};`} */
    ${({direction}) => direction && css`flex-direction: ${direction};` }
    @media(min-width: 600px){
        padding: 10px 5px 20px 18px;
    }
`

export const InputContainer = styled.div`
    display: flex;
    width: 33.3%;
    ${({align}) => align && css`justify-content: ${align};`}
    @media(min-width: 600px){
        margin-bottom: 20px;
    }
`
export const Arrow = styled.button`
    position: absolute;
    z-index: 1;
    bottom: -10px;
    border: 1px solid ${iconColor};
    outline: none;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${secundaryColor};

    &:hover {cursor: pointer;}
`
export const Button = styled.button`
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;

    &:hover {cursor: pointer;}
`

/** Planilla diaria, menu oculto */
export const PlanillaDiaria = styled.div`
    background-color: ${secundaryColor};
    height: 0px;
    position: absolute;
    z-index: 1;
    bottom: 0px;
    width: 100%;
    overflow: hidden;
    transition: .5s;
    border-bottom: 1px solid ${iconColor};
    border-top: 1px solid ${iconColor};
    display: flex;
    justify-content: space-between;
    align-items: center;

    box-shadow: 1px 5px 8px 1px rgba(0,0,0,.25);
`
export const Label = styled.label`
    color: ${blackColor};
    font-size: 14px;
    font-weight: 500;
    display: block;
    ${({alignSelf}) => alignSelf && css`align-self: ${alignSelf};`}
    ::selection { background-color: transparent; }
`

export const Row = styled.div`
    display: flex;
    width: 100%;
    justify-content: ${({justify}) => justify ? justify : 'flex-start'};
    align-items: center;
`

/** Seguimiento Trabajos estilos */

export const Main = styled.main`
    flex: 1;
    display: flex;
    overflow: hidden;
    padding: 0px;
    position: relative;
`
export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: ${bgVariantColor};
    width: 100%;
    margin-bottom: 30px;
    /* border-radius: 15px; */
    box-shadow: 0px 0px 6px 1px rgba(0,0,0,.4);
    @media (min-width: 1000px){
        width: 98%;
        margin-left: 1%;
    }
 `
export const Title = styled.h3`
    color: ${({color}) => color ? color : secundaryColor};
    padding: 5px;
    margin: 0px;
    text-align: center;
    background-color: ${({bgColor}) => bgColor ? bgColor : primayVariantColor};
    font-size: 14px;

    @media(min-width: 600px){
        font-size: 18px;
    }
`
export const Subtitle = styled.h4`
    color: ${blackColor};
    padding: 0 10px;
    padding-bottom: 10px;
    margin: 0px;
    @media(min-width: 600px){
    }
`
export const ButtonCloseOrder = styled.button`
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
`

/** Modal con las opciones */
export const ModalOptions = styled.div`
    display: flex;
    flex: 1;
    position: absolute;
    z-index: 999;
    top: 0px;
    left: 0px;
    background-color: #efefefef;
    width: 100%;
    height: 100%;
    padding: 5%;
    align-items: center;
    justify-content: center;
    flex-direction: row;
`
export const ButtonClose = styled.button`
    font-size: 16px;
    position: absolute;
    color: ${iconColor};
    border: 1px solid ${iconColor};
    border-radius: 5px;
    background-color: ${iconVariantColor};
    padding: 5px 15px;
    top: 15px;
    right: 25px;
    cursor: pointer;
`
export const BoxOption = styled.button`
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid ${iconColor};
    border-radius: 8px;
    padding: 8px;
    margin-right: 15px;
    width: 250px;
    cursor: pointer;
`
export const LabelOption = styled.label`
    background-color: ${primayVariantColor};
    width: 85%;
    text-align: center;
    border-radius: 15px;
    color: ${secundaryColor};
    padding: 5px;
`

/** export Constants export for use from other components */
export const Tr = styled.div`
    display: flex;
    ${({ bgColor }) => bgColor && css`background-color: ${primayVariantColor}50;`}
    border-bottom: 1px solid ${iconColor}50;
`
export const Th = styled.div`
    font-weight: 500;
    font-size: ${({fontSize}) => fontSize ? fontSize : '12px' };
    color: ${primayVariantColor};
    padding: 5px;
    text-align: center;
    border-left: 1px solid ${blackColor}80;
    ${({ width }) => width && css`width: ${width}%;`}
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:first-child {border-left: 0px;}
`
export const Td = styled.div`
    text-align: center;
    font-size: 10px;
    color: ${blackColor};
    cursor: pointer;
    padding: 5px;
    ${({elipsis}) => elipsis && css`
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    `}
    ${({ width }) => width && css`width: ${width}%;`}
    @media (min-width: 600px){
        font-size: ${({fontSize}) => fontSize ? fontSize : '12px' };
    }
    
    ${({ movil }) => movil && css`
        position: absolute;
        right: ${({activePopup}) => activePopup ? '0' : '-150px' };
        background-color: #fff;
        width: 100px;
        border-radius: 8px 0 0 8px;
        box-shadow: -3px 0px 5px 1px #99999999;
        transition: .4s;
    `}
`


/** Estilos para la orden de trabajo */
export const ModuleContainer = styled.div`
    flex: 1;
    padding: 0;
    @media(min-width: 600px){
        padding: 0 10px 0px 20px;
    }
    
`
export const IpContainer = styled.div`
    display: flex;
    width: 100%;
    margin: 10px 0px;
    flex-direction: column;
    padding: 5px 10px;
    border-radius: 15px;
    transition: .5s;
`
export const ButtonIcon = styled(Link)`
    text-decoration: none;
    border: none;
    outline: 0;
    background-color: transparent;
`
// const Label = styled.label`
//     color: ${blackColor};
//     font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif;
//     font-size: 12px;
//     font-weight: 500;
// `
// const Title = styled.h3`
//     color: ${({color}) => color ? color : secundaryColor};
//     padding: 5px;
//     margin-top: 0px;
//     text-align: center;
// `


/** Styles especial */
export const Box = styled.div`
    display: flex;
    ${({flex}) => css`flex: ${flex};`}
    ${({width}) => css`width: ${width};`}
    ${({mHeight}) => css`max-height: ${mHeight};`}
    ${({direction}) => css`flex-direction: ${direction};`}
    align-items: ${({align}) => align ? align : 'flex-start'};
    ${({justify}) => css`justify-content: ${justify};`}
    ${({position}) => css`position: ${position};`}
    ${({top}) => css`top: ${top};`}
    ${({bottom}) => css`bottom: ${bottom};`}
    ${({left}) => css`left: ${left};`}
    ${({bgColor}) => css`background-color: ${bgColor};`}
    ${({shadow}) => css`box-shadow: ${shadow};`}
    ${({zIndex}) => css`z-index: ${zIndex};`}
    ${({radius}) => css`border-radius: ${radius};`}
    ${({padding}) => css`padding: ${padding};`}
    ${({overflow}) => css`overflow-y: ${overflow};`}
`
export const BoxIdent = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    @media(min-width: 600px){
        flex-wrap: nowrap;
    }
`