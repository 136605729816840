import { createAction } from 'redux-actions'
import { apiPostCurriculumVitae, apiGetIdentityVitae, apiGetWarrantyVitae, apiGetReviewsServices, apiGetCurriculumVitae } from '../api/curriculumVitae'
import { POST_CURRICULUM_VITAE, GET_INDENTITY_VITAE, GET_WARRANTY_VITAE, GET_REVIEWSSERVICES_VITAE, GET_LIST_HV } from '../types'


/**  HISTORIAL DE LA MOTO*/
export const getCurriculumVitae = createAction(GET_LIST_HV,
    data => apiGetCurriculumVitae(data)())
    
/**  HISTORIAL DE LA MOTO*/
export const postCurriculumVitae = createAction(POST_CURRICULUM_VITAE,
    data => apiPostCurriculumVitae(data)())

/**  INDENTIDAD DE LA MOTO*/

export const getIdentityVitae = createAction(GET_INDENTITY_VITAE,
    data => apiGetIdentityVitae(data)())

/**  GARANTIA DE LA MOTO*/
export const getwarrantyVitae = createAction(GET_WARRANTY_VITAE,
    data => apiGetWarrantyVitae(data)())

/**  REVICION DE LA MOTO*/
 export const getReviewsServices = createAction(GET_REVIEWSSERVICES_VITAE, 
    data => apiGetReviewsServices(data)()
    )