import React from 'react';
import { Page, View, Document, StyleSheet } from '@react-pdf/renderer'
import { blackColor, primayColor } from '../../constanst'
import Head from './Head'
import { TableChildBorder } from './utils'

export default function RepIncol ({state: {sedesList, selSede, userdata, plIncol}}) {
    let cont = 0
    let limit = 44
    let resSede = {}
    !!selSede ? resSede = sedesList.find(e => e.sed_id === selSede) : resSede = sedesList.find(e => e.sed_id === userdata.idSede)

    return(
      <Document>
          <Page size="A4" style={styles.page}>
              {/** Cabecera */}
              <Head title='REPORTE INCOLMOTOS' sede={resSede.sed_nombre} direction={resSede.sed_direccion} phone={resSede.sed_telefono} />


                {/** Inventario */}
                <View style={[styles.sectionFull, {marginTop: 8}]}>
                  <View style={styles.section}>
                      <TableHeader />

                      {/* Cuerpo de la tabla */}
                      {plIncol.map( (x, i) =>{
                          if(cont <= limit){
                              cont++
                              return <Table key={x.ds_id} x={x} i={i} />
                          } else {
                              cont = 0
                              limit = 46
                              return <React.Fragment key={x.ds_id}><View break /><TableHeader /><Table x={x} i={i} /></React.Fragment>
                          }
                      } )}

                      {/** Totales */}
                      <Totals plIncol={plIncol} />
                  </View>
              </View>
          </Page>
      </Document>
)}

const Table = ({x, i}) => 
    <View style={styles.boxTr} key={x.ds_id}>
        <TableChildBorder value={`${i}`} fontWeight="bold" width="5%" borderLeft />
        <TableChildBorder value={`${x.ds_numeromotor}`} fontWeight="bold" width="15%" />
        <TableChildBorder value={`${x.fecha}`} fontWeight="bold" width="10%" />
        <TableChildBorder value={x.ds_idtiposervicio === '8' ? `N-${x.ds_incolmotos || 0}` : '-'} fontWeight="bold" width="10%" />
        <TableChildBorder value={x.ds_idtiposervicio === '9' ?  `${x.ds_km}` : '-'} fontWeight="bold" width="10%" />
        <TableChildBorder value={x.ds_idtiposervicio === '10' ? `${x.ds_km}` : '-'} fontWeight="bold" width="10%" />
        <TableChildBorder value={x.ds_idtiposervicio === '11' ? `${x.ds_km}` : '-'} fontWeight="bold" width="10%" />
        <TableChildBorder value={x.ds_idtiposervicio === '12' ? `${x.ds_km}` : '-'} fontWeight="bold" width="10%" />
        <TableChildBorder value={x.ds_idtiposervicio === '13' ? `${x.ds_km}` : '-'} fontWeight="bold" width="10%" />
        <TableChildBorder value={x.ds_idtiposervicio === '14' ? `${x.ds_km}` : '-'} fontWeight="bold" width="10%" />
    </View>

const TableHeader = () => 
    <View style={[styles.boxTr, {backgroundColor: '#dedede'}]}>
        <TableChildBorder value="N°" fontWeight="bold" width="5%" />
        <TableChildBorder value="N° motor" fontWeight="bold" width="15%" />
        <TableChildBorder value="Fecha" fontWeight="bold" width="10%" />
        <TableChildBorder value="# Alistamientos" fontWeight="bold" width="10%" />
        {/* <TableChildBorder value="KM actual" fontWeight="bold" width="10%" /> */}
        <TableChildBorder value="KM 1ra revisión" fontWeight="bold" width="10%" />
        <TableChildBorder value="KM 2da revisión" fontWeight="bold" width="10%" />
        <TableChildBorder value="KM 3ra revisión" fontWeight="bold" width="10%" />
        <TableChildBorder value="KM 4ta revisión" fontWeight="bold" width="10%" />
        <TableChildBorder value="KM 5ta revisión" fontWeight="bold" width="10%" />
        <TableChildBorder value="KM 6ta revisión" fontWeight="bold" width="10%" />
    </View>

const Totals = ({ plIncol }) =>
  <View style={styles.boxTr}>
    <TableChildBorder borderLeft value='TOTALES' fontWeight="bold" width="30%" />
    <TableChildBorder value={`${plIncol.filter(element => element.ds_idtiposervicio === '8' ).length}`} fontWeight="bold" width="10%" />
    <TableChildBorder value={`${plIncol.filter(element => element.ds_idtiposervicio === '9' ).length}`} fontWeight="bold" width="10%" />
    <TableChildBorder value={`${plIncol.filter(element => element.ds_idtiposervicio === '10' ).length}`} fontWeight="bold" width="10%" />
    <TableChildBorder value={`${plIncol.filter(element => element.ds_idtiposervicio === '11' ).length}`} width="10%" />
    <TableChildBorder value={`${plIncol.filter(element => element.ds_idtiposervicio === '12' ).length}`} fontWeight="bold" width="10%" />
    <TableChildBorder value={`${plIncol.filter(element => element.ds_idtiposervicio === '13' ).length}`} fontWeight="bold" width="10%" />
    <TableChildBorder value={`${plIncol.filter(element => element.ds_idtiposervicio === '14' ).length}`} fontWeight="bold" width="10%" />
  </View>

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    paddingVertical: 30,
    paddingHorizontal: 20
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  sectionFull: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    marginVertical: 3,
  },
  boxTr: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    fontSize: 10,
    color: blackColor,
    padding: 5,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  label: {
    fontSize: 6,
    color: blackColor,
    fontWeight: 500
  },
  lineRed: {
    borderWidth: .7,
    borderColor: primayColor,
    marginVertical: 5
  },
  tableText: {
    fontSize: 6,
    color: blackColor,
    paddingVertical: 4,
    paddingHorizontal: 6,
    textAlign: 'center'
  },
  check: {
      width: 8,
      height: 8
  },
  checkBox: {
    height: 8,
    width: 8,
    borderWidth: 1,
    borderColor: '#777777',
    borderRadius: 100
  }
});
