import React from 'react'
import styled, { css } from 'styled-components'
import { MenuItem } from '@material-ui/core'

import { secundaryColor, primayColor, iconColor } from '../../../constanst'
import ModuleInput from '../../common/ModuleInput'
import CustomSelect from '../../common/CustomSelect'
import { FormContent, Box } from './ComponentsForm'
import { Label } from '../trabajos/Styles'


const ServicesForm = ({ state: {serviceform, typeServices, typeServicesWork, sedes, tecnicos, newMReadiness}, onChange, onCheckReadiness, onChangeSelect }) => (
    <FormContent>
        {/** Contendor izquierdo */}
        <ModuleContainer>
            <Box justify="space-between" direction="row">
                <CustomSelect label="SEDE" inputw="48%" name="formsede" onChange={e => onChangeSelect(e, 3)} value={serviceform.formsede || ''} disabled>
                    {!!sedes && 
                        sedes.map( x => (
                            <MenuItem key={x.sed_id} value={x.sed_id}>{x.sed_nombre}</MenuItem>
                    ))}
                </CustomSelect>
                <CustomSelect label="TÉCNICO" inputw="48%" name="formtecnico" onChange={e => onChangeSelect(e, 3)} value={serviceform.formtecnico || ''}>
                    {!!tecnicos && 
                        tecnicos.map( x => (
                            <MenuItem key={x.usu_id} value={x.usu_id}>{`${x.per_nombre} ${x.per_apellido}`}</MenuItem>
                        ))
                    }
                </CustomSelect>
            </Box>
            <Box flex=".5" justify="space-between" direction="row">
                <CustomSelect label="SERVICIO" inputw="48%" name="formtiposervicio" onChange={e => onChangeSelect(e, 3, "query")} value={serviceform.formtiposervicio || ''}>
                    {!!typeServices &&
                        typeServices.map(x => (
                            <MenuItem key={x.tser_id} value={x.tser_id}>{x.tser_nombre}</MenuItem>
                        ))
                    }
                </CustomSelect>
                <CustomSelect label="TRABAJO" inputw="48%" name="formTrabajogarantia" onChange={e => onChangeSelect(e, 3)} value={serviceform.formTrabajogarantia || '' }>
                    {!!typeServicesWork?.length &&
                        typeServicesWork?.map(x => (
                            <MenuItem key={x.tg_id} value={x.tg_id}>{x.tg_nombre}</MenuItem>
                        ))
                    }
                </CustomSelect>
            </Box>
            <Box justify="space-between" direction="row">
                {serviceform.formTrabajogarantia === "4" && 
                    <ModuleInput label="ESPECIFÍQUE EL TRABAJO" type="text" name="formotrotrabajogarantia" 
                        onChange={e => onChange(e, 3, true)} value={serviceform.formotrotrabajogarantia || ''}
                        backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="50%" 
                    />
                }
                <ModuleInput label="FECHA TENTATIVA DE ENTREGA" type="date" name="formentrega" 
                    onChange={e => onChange(e, 3, true)} value={serviceform.formentrega || ''}
                    backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="50%" 
                />
            </Box>
            <Box>
                <Box direction="row" padding="5px 10px">
                    <input id="readiness" type="checkbox" name="isReadiness" value={serviceform.isReadiness} checked={serviceform.isReadiness} onChange={onCheckReadiness} data-ignore={true} />
                    <Label htmlFor="readiness">¿ES ALISTAMIENTO MOTO NUEVA?</Label>
                </Box>
            </Box>
            <Box justify="space-between" direction="row">
                {!!serviceform.isReadiness && <> 
                    <ModuleInput label="ASESOR" type="text" name="ds_mrAssessor" 
                        onChange={e => onChange(e, 3, true)} value={serviceform.ds_mrAssessor || ''}
                        backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="50%" 
                    />
                    <ModuleInput label="OBSERVACIONES" type="text" name="ds_mrObs" ignore={true}
                        onChange={e => onChange(e, 3)} value={serviceform.ds_mrObs || ''}
                        backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="50%" 
                    />
                </>}
            </Box>
        </ModuleContainer>

        {/** Contenedor Derecho */}
        <ModuleContainer borderLeft={true}>
            <ContainerHora>
                <ModuleInput label="HORA DE ENTRADA" type="time" name="formhoraentrada" 
                    onChange={e => onChange(e, 3, true)} value={serviceform.formhoraentrada || ''} 
                    backgroundC={secundaryColor} borderC={iconColor} width="90%" inputw="100%"
                />
                <ModuleInput label="HORA DE SALIDA" type="time" name="formhorasalida"
                    onChange={e => onChange(e, 3, true)} value={serviceform.formhorasalida || ''}
                    backgroundC={secundaryColor} borderC={iconColor} width="90%" inputw="100%"
                />
                
                {/* <InputContainer>
                    <Label>Hora de Entrada</Label>
                    <ContainerHora>
                        <InputText value={serviceform.hora} width="40%" backgroundC={secundaryColor} borderC={iconColor} />&nbsp; : &nbsp;
                        <InputText value={serviceform.hora} width="40%" backgroundC={secundaryColor} borderC={iconColor} />
                    </ContainerHora>
                </InputContainer>
                <InputContainer>
                    <Label>Hora de Salida</Label>
                    <ContainerHora>
                        <InputText value={serviceform.hora} width="40%" backgroundC={secundaryColor} borderC={iconColor} />&nbsp; : &nbsp;
                        <InputText value={serviceform.hora} width="40%" backgroundC={secundaryColor} borderC={iconColor} />
                    </ContainerHora>
                </InputContainer> */}
            </ContainerHora> 
            <Box justify="space-between" direction="row">
                <ModuleInput label="VALOR ABONO" type="number" name="formvalorabono"
                    onChange={e => onChange(e, 3, false)} value={serviceform.formvalorabono || ''} 
                    backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="50%" 
                />
                <ModuleInput label="ORDEN INTERNA" type="text" name="formordeninterna" ignore={true}
                    onChange={e => onChange(e, 3, false)} value={serviceform.formordeninterna || ''} 
                    backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="50%" 
                />
            </Box>
            <ModuleInput label="N° DE FACTURA GARANTÍA INCOLMOTOS" type="text" name="formfactura" ignore={true}
                onChange={e => onChange(e, 3, false)} value={serviceform.formfactura || ''} 
                backgroundC={secundaryColor} borderC={iconColor} width="50%" inputw="100%"
            />
        </ModuleContainer>
    </FormContent>
    
)

export default ServicesForm

const ModuleContainer = styled.div`
    flex: .5;
    width: 50%;
    padding: 0 10px 0px 20px;
    display: inline-block;
    
    ${({ borderLeft }) => borderLeft && css`border-left: 1px solid ${primayColor};`}
`
// const InputContainer = styled.div`
//     display: flex;
//     flex-direction: column;
//     width: 50%;
//     padding: 0 10px;
// `
// const Label = styled.label`
//     color: ${blackColor};
//     font-size: 10px;
// `
const ContainerHora = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
`