import React from 'react'
import styled from 'styled-components'
import { blackColor } from '../../constanst'

export default ({ignore, name, checked, disabled, onChange, value, color}) => 
    <Container>
        <Input type="radio" checked={checked} name={name} value={value} data-ignore={ignore} disabled={disabled} onChange={onChange} color={color} />
        <CustomCheck />
    </Container>



const CustomCheck = styled.span`
    position: absolute;
    top: -8px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #ffffff;
    border: 1px solid #777777;
    border-radius: 50%;
`

const Container = styled.label`
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    /*:hover ~ ${CustomCheck} {background-color: #ccc;}*/
`
const Input = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    
    &:focus ~ ${CustomCheck} {border: 1px solid ${blackColor};}
    &:disabled ~ ${CustomCheck} {cursor: no-drop;}
    &:checked ~ ${CustomCheck} {
        background-color: ${({color}) => color};
    }
`
  
//   /* Create the indicator (the dot/circle - hidden when not checked) */
//   .checkmark:after {
//     content: "";
//     position: absolute;
//     display: none;
//   }
  
//   /* Show the indicator (dot/circle) when checked */
//   .container input:checked ~ .checkmark:after {
//     display: block;
//   }
  
