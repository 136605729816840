import React from 'react'
import { Route, Redirect } from 'react-router-dom'

export default ({ typeUser, component: Component, ...rest }) => {
    
    const userdata = JSON.parse(localStorage.getItem("userdata"))

    return !!userdata && !!userdata.length ?  
        <Route
            {...rest}
            render = { props => <Component {...props} /> }
        />
    :
        <Redirect to="/" />
    
}