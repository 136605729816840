import React,{ Component } from 'react'
import { connect } from 'react-redux'
import ListCotizationsV from '../../components/cotizations/listcotizations'
import { getAllCotizations } from '../../../redux/actions/cotizations'

class List extends Component {
    constructor(props){
        super(props)

        this.state = {
            schedulingList: [],
            loading: false
        }                           
        this.isMount = false
    }

    async componentDidMount() {
        this.isMount = true
        this.setState({loading: true})
        // const userdata = JSON.parse(localStorage.getItem('userdata'))
        await this.props.getAllCotizations()

        this.isMount && this.setState({loading: false})
    }
    handleChange = e => {

    }
    render() {

        return <ListCotizationsV state={this.state} onChange={this.handleChange} />
    }
}

export default connect(({ cotizationsList }) => ({ cotizationsList }),
    { getAllCotizations })(List)
