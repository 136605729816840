import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import toastr from 'toastr'
import "toastr/build/toastr.css"

import SeguimientoV from '../../components/services/SeguimientoV'
import { getServicesList, getPlanillaDiaria, getSedesId, getGenerarPdfCliente, getGenerarPdfTecnico, deleteService, getPdfFecha,
    getServicesPList, getSedes, getGenerarPdfService, getOrdenServicioId, getTypeServiceWork, getRequestServiceId, getCallsServiceId,
    getIventarioList, getAuthId, getInventario, getTypeService, getPlanillaIncol } from "../../../redux/actions/service-orders"
import { getTSerWorks } from "../../../redux/actions/Parameterizations"
import { url_base } from '../../../redux/api/urls'
import { getTecnicos } from '../../../redux/actions/userActions'
import { iconColor, primayVariantColor } from '../../../constanst'
import Axios from 'axios'

class Seguimiento extends Component {

    constructor(props){
        super(props)
        this.state = {
            servicesList: [],
            sedesList: [],
            inventario: [],
            tser: [],
            tserWork: [],
            userdata: {},
            resSede: {},
            loading: false,
            clit_estado: '0',
            modalImp: false,
            ser_estado: '0',
            page: 'SERVICIOS',
            selectDisabled: true,
            modalPla: false
        }
        this.isMount = false
        this.activeMenu = false
    }
    async componentDidMount(){
        const userdata = JSON.parse(localStorage.getItem("userdata"))
        this.isMount = true
        
        this.setState({ loading: true })
        await this.props.getServicesPList({iduser: userdata[0].idusuario, idTypeUser: userdata[0].idtipousuario})
        await this.props.getSedesId(userdata[0].idusuario)
        await this.props.getSedes()
        await this.props.getInventario()
        await this.props.getTypeService()
        

        if(this.props.servicesList.success === 1)
            this.setState({ servicesList: this.props.servicesList.data })
        if(this.isMount){
            const nameSede = this.props.sedes[0].sed_nombre

            this.setState({
                loading: false,
                sedesList: this.props.sedes,
                typeUser:  userdata[0].idtipousuario,
                inventario: this.props.inventario,
                tser: this.props.typeServices,
                plIncol: [],
                userdata: {
                    idSede: userdata[0].usu_sede,
                    typeUser: userdata[0].idtipousuario,
                    idUser: userdata[0].idusuario,
                    nameSede,
                    dateF: 0,
                    selTser: 0,
                    selTserW: 0
                }
            })

        }

        
        await this.props.getServicesList({iduser: userdata[0].idusuario, idTypeUser: userdata[0].idtipousuario})
        if(this.isMount)
            this.setState({selectDisabled: false})
        //this.onFilter('clit_estado', '0')
    }
    componentWillUnmount(){
        this.isMount = false
    }

    /** Nagegando entre ventanas */
    handleClickPage = (e, id, mapa) => {
        if(mapa)
            this.props.history.push(`/servicios/mapataller/form/${id}`)
        else if(id)
            this.props.history.push(`/servicios/orden/${id}`)
        else
            this.props.history.push(`/servicios/orden`)
    }

    /** Filtrando resultados */
    onChange = async (e) => {
        const { name, value } = e.target

        this.setState({ [name]: value})
    }
    /** Filtrando resultados */
    onChangeSel = async (e) => {
        let values = []
        const { name, value } = e.target
        this.setState({ [name]: value })
        if(value === 'all') 
            this.setState({ servicesList: this.props.servicesList.data })
        else
            this.props.servicesList.data.map( x => {
                if(x[name].indexOf(value) !== -1) {
                    values = [...values, x]   
                }
                return this.setState({servicesList: values})
            })
    }

    /** Filtros para la planilla diaria metodo onChange */
    handleChangeFilterPD = async (e, query) =>{
        const {value, name} = e.target

        if(query === 'query'){
            await this.props.getTypeServiceWork(value)
            this.setState({tserWork: this.props.typeServicesWork, userdata: {...this.state.userdata, [name]: value}})
            return
        }
        
        const sedeSelected = this.state.sedesList.filter(x => x.sed_id === value)
        
        if(!!value)
        this.setState({
            [name]: value,
            userdata: {
                ...this.state.userdata, 
                nameSede: !!sedeSelected.length ? sedeSelected[0].sed_nombre : this.state.nameSede, 
                idSede: value
            }
        })
    } 
    
    onKeyPressFilter = e => e.charCode === 13 && this.onFilter(e, e.target.name, e.target.value)
    /**
     * funcion de busqueda ....................................................................................................................RTHRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR
     * @param {*} e 
     * @param {*} name 
     * @param {*} value 
     */
    onFilter = async (e, name, value) => {
        e.preventDefault()
        this.setState({ loading: true })
        const {idUser, typeUser} = this.state.userdata

        const res = await Axios.get(`${url_base}/servicio/listaservicios/filtro/${idUser}/${typeUser}/${name}/${value || 0}`).then(v => v.data).catch(() => ({success: 0, message: 'Error en la conexión', data: []}))
        this.setState({ loading: false, servicesList: res.data })
    }

    /** Filtrar por fecha */
    onClickFindDate = () => {
        const dateStart = new Date(`${this.state.userdata.dateStart}`).getTime()
        const dateFinal = new Date(`${this.state.userdata.dateFinal}`).getTime()
        let values = []

        if(!this.state.userdata.dateStart || !this.state.userdata.dateFinal){
            toastr.warning("Seleccione un rango de fecha")
            return false
        }

        this.props.servicesList.data.map( x => {
            const date = new Date(x.fecha).getTime()

            if(date >= dateStart && date <= dateFinal)
                values = [...values, x]
            
            return this.setState({servicesList: values})
        })
    }

    /** On Click arrow */
    onClickArrow = e => {
        e.stopPropagation()

        if(!this.activeMenu){
            document.getElementById("menuOculto").style.height = "200px"
            document.getElementById("menuOculto").style.bottom = "-200px"
        } else {
            document.getElementById("menuOculto").style.height = "0px"
            document.getElementById("menuOculto").style.bottom = "0px"
        } 
        this.activeMenu = this.activeMenu ? false : true   
    }

    /** Generar planilla diaria */
    handlePlanillaDiaria = async (e, indicator) => {
        e.preventDefault()
        /** planilla diaria */

        if(this.state.typeUser === "1" && !this.state.selSede) {
            toastr.warning("Seleccione un filtro para la planilla diaria")
            return false
        }
        if(indicator === 'today'){
            const date = moment().format('YYYY-MM-DD')
            
            this.setState({ userdata: {...this.state.userdata, date} })

            await this.props.getPlanillaDiaria({...this.state.userdata, date})

            if(this.props.postResponse.success === 1){
                toastr.success(this.props.postResponse.message)
                window.open(`${url_base}/uploads/planilladiaria/planilladiaria${date}.pdf`,'_blank')
            }
            else
                toastr.success(this.props.postResponse.message)
                
        }

        /** otras planilla */
        if(indicator === 'other') {

            const date = this.state.userdata.date
            if(date){
                await this.props.getPlanillaDiaria(this.state.userdata)
                window.open(`${url_base}/uploads/planilladiaria/planilladiaria${date}.pdf`,'_blank')
            }
            else
                toastr.warning('Seleccione una fecha')
        }

        this.isMount &&
            this.setState({userdata: {...this.state.userdata, dateF: 0}, date: '' })
    }

        
    /**  Imprimir la planilla desde el modal */
    onClickModal = async (e, param) => {
        const idservicio = this.state.idServicio
        const { target } = e
        
        /** Desabilitando el botón */
        target.disabled = true
        target.style.backgroundColor = iconColor

        if(param === 'cliente'){
            await this.props.getGenerarPdfCliente(idservicio)
            if(this.props.postResponse.success === 1){
                toastr.success(this.props.postResponse.message)
                let ruta = 'orden_' + idservicio + '.pdf';
                window.open(`${url_base}/uploads/uploads_ordenservicio_cliente/${ruta}`, '_blank');
            } else 
                toastr.warning(this.props.postResponse.message)
        }
        if(param === 'tecnico'){
            await this.props.getGenerarPdfTecnico(idservicio)
            if(this.props.postResponse.success === 1){
                toastr.success(this.props.postResponse.message)
                let ruta = 'orden_' + idservicio + '.pdf';
                window.open(`${url_base}/uploads/uploads_ordenservicio_empresa/${ruta}`, '_blank');
            } else
                toastr.warning(this.props.postResponse.message)
        }
        if(param === 'service'){
            await this.props.getGenerarPdfService(idservicio)
            if(this.props.postResponse.success === 1){
                toastr.success(this.props.postResponse.message)
                let ruta = 'orden_' + idservicio + '.pdf';
                window.open(`${url_base}/uploads/uploads_ordenservicio_service/${ruta}`, '_blank');
            } else
                toastr.warning(this.props.postResponse.message)
        }

        /** Habilitando el botón */
        target.disabled = false
        target.style.backgroundColor = primayVariantColor
    }

    /** Borrar servicios */
    handleDelete = async (e, idSer) => {
        e.preventDefault()
        
        await this.props.deleteService(idSer)
        if(this.props.servicesList.success === 1)
            this.setState( ps => { 
                const servicesList = ps.servicesList.filter( x => x.ser_id !== idSer)
                return {servicesList}
             })
    
        if(this.props.postResponse.success === 1){
            toastr.success(this.props.postResponse.message)
        } else
            toastr.warning(this.props.postResponse.message)
    }
    
    /** iMPRIMIR */
    handlePrint = async (e) => {
        e.preventDefault()
        this.setState({ loading: true })
        const {dateStart, dateFinal} = this.state.userdata

        if(!this.state.userdata.dateStart || !this.state.userdata.dateFinal){
            toastr.warning("Seleccione un rango de fecha")
            if(this.isMount)
                this.setState({ loading: false })
            
            return false
        }
        await this.props.getPdfFecha({dateStart, dateFinal, name: 'pdfFiltroFecha'})
        window.open(`${url_base}/uploads/uploads_pdfFiltroFecha/Listado_servicio.pdf`, '_blank');
        
        if(this.isMount)
            this.setState({ loading: false })
    }

    /** Abrir modal y cargar datos */
    openModal = async (e, idServicio) => {
        e.preventDefault()
        this.setState({ loading: true })
        
        /** Resultados de la petición para la información personal, vehiculo y servicio */            
        await this.props.getOrdenServicioId(idServicio)
        const { success } = this.props.servicesOrden

        if(success === 1) {
            const {data: {clientform: clif, vehicleform: vf, serviceform: sf, conditions: cond}} = this.props.servicesOrden

            /** Buscar la información de la sede */
            const resSede = this.props.sedes.find(x => x.sed_id === sf.formsede)

            /** Obteniendo datos del técnico */
            await this.props.getTecnicos()
            const tecn = this.props.tecnicos.length > 1 && this.props.tecnicos.filter(x => x.usu_id === sf.formtecnico)
            
            await this.props.getTypeServiceWork(sf.formtiposervicio)
            // this.setState({ typeServicesWork: this.props.typeServicesWork })
            this.setState({ typeServicesWork: this.props.typeServicesWork, clif, vf, sf, cond, tecn: tecn[0].per_nombre, resSede })

            /** Resultados de las solicitudes y solucion de servicios */
            await this.props.getRequestServiceId(idServicio)
            
            if(this.props.servicesRequestList.success === 1)
                this.setState({ solicitudform: { ...this.state.solicitudform, clienteSolicitud: this.props.servicesRequestList.data} })
            else {
                toastr.error("Ha ocurrido un error cargando la información")
                this.setState({loading: false})
                //this.props.history.push('/servicios/seguimiento')
            }
            
            /** Resultados de las llamadas de servicios */
            await this.props.getCallsServiceId(idServicio)

            if(this.props.servicesCallsList.success === 1)
                this.setState({ solicitudform: { ...this.state.solicitudform, clientellamadas: this.props.servicesCallsList.data} })

            /** Obteniendo la lista de inventario */
            await this.props.getIventarioList(idServicio)
            
            if(this.props.inventarioList.success === 1){
                const { inventarioform, comments } = this.props.inventarioList.data
                this.setState({ inventarioform, comments })
            }

            /** Obteniendo información de la lista de las autorizaciones */
            await this.props.getAuthId(idServicio)

            if(this.props.authId.success === 1)
            this.setState({ termsform: this.props.authId.data })

        }
        
        this.setState({ modalImp: this.state.modalImp ? false : true, idServicio, loading: false })
    }

    /** Generar planilla de reporte para incolmotos */
    handleGeneratePla = async e => {
        e.preventDefault()

        const {date, dateF} = this.state.userdata
        if(!date)
            await this.props.getPlanillaIncol({date: moment().format("YYYY-MM-DD"), dateF})
        else
            await this.props.getPlanillaIncol({date, dateF})

        if(!!this.props.plIncol.success)
            this.setState({ plIncol: this.props.plIncol.data })

        if(this.state.typeUser === "1") 
            !!this.state.selSede ? this.setState({modalPla: true}) : toastr.warning("Seleccione una sede")
        else 
            this.setState({modalPla: true})
  
    }


    render() {
        return (
            <SeguimientoV
                servicesList={this.state.servicesList}
                onClickPage={this.handleClickPage}
                onChange={this.onChange}
                onChangeSel={this.onChangeSel}
                onChangeDate={e => this.setState({ userdata: {...this.state.userdata, [e.target.name]: e.target.value} })}
                onChangeFilterPD={this.handleChangeFilterPD}
                onClickArrow={this.onClickArrow}
                onCloseModal={() => this.setState({ modalPla: false })}
                onGeneratePla={this.handleGeneratePla}
                onClickFindDate={this.onClickFindDate}
                onFilter={this.onFilter}
                onKeyPressFilter={this.onKeyPressFilter}
                state={this.state}
                onClickPlanDiaria={this.handlePlanillaDiaria}
                onClickModal={this.onClickModal}
                onDelete={this.handleDelete}
                onPrint={this.handlePrint}
                openModal={this.openModal}
            />
        )
    }
}

const mapStateToProps = ({ servicesList, servicesOrden, sedes, postResponse, typeServicesWork, servicesRequestList, servicesCallsList, inventarioList, authId, tecnicos, inventario, typeServicesWorks,
    typeServices, plIncol }) => ({ 
    servicesList, sedes, postResponse, servicesOrden, typeServicesWork, servicesRequestList, servicesCallsList, inventarioList, authId, tecnicos, inventario, typeServicesWorks, typeServices,
    plIncol })

export default connect(mapStateToProps, {
    getServicesList, getPlanillaDiaria, getSedesId, getSedes, getGenerarPdfCliente, getGenerarPdfTecnico, deleteService, getPdfFecha, getTecnicos, getTSerWorks, getTypeService,
    getServicesPList, getGenerarPdfService, getOrdenServicioId, getTypeServiceWork, getRequestServiceId, getCallsServiceId, getIventarioList, getAuthId, getInventario, getPlanillaIncol
})(Seguimiento)