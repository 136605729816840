import { url_base } from "./urls"
import axios from 'axios'


export const apiGetCurriculumVitae = params => () => axios.post(`${url_base}/vehiculo/all/hojadevida`, params).then(v => v.data).catch(e => ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))

export const apiPostCurriculumVitae = data => () => axios.post(`${url_base}/vehiculo/hojadevida/registrohistoria`, data).then(v => v.data).catch(e => ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))

export const apiGetIdentityVitae = data => () => axios.get(`${url_base}/vehiculo/hojadevida/${data.hvv_id}`).then(v => v.data).catch(e => ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))

export const apiGetWarrantyVitae = data => () => axios.post(`${url_base}/vehiculo/hojadevida/servicios`, data).then(v => v.data).catch(e => ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))

export const apiGetReviewsServices = data => () => axios.post(`${url_base}/vehiculo/hojadevida/servicios`, data).then(v => v.data).catch(e => ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))



