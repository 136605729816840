/** users */
export const USER_LOGIN = "USER_LOGIN"
export const GET_USERS = "GET_USERS"
export const UPDATE_USERS = "UPDATE_USERS"
export const GET_USER_ID = "GET_USER_ID"
export const POST_USERS = "POST_USERS"
export const DELETE_USERS = "DELETE_USERS"
export const GET_TECNICOS = "GET_TECNICOS"
export const GET_TYPE_USERS = "GET_TYPE_USERS"
export const GET_TYPE_DOCUMENT = "GET_TYPE_DOCUMENT"
export const GET_CLIENTE = "GET_CLIENTE"

// HISTORIAL DE LA MOTO
export const POST_CURRICULUM_VITAE = 'POST_CURRICULUM_VITAE'
/** identificacion de la moto */
export const GET_INDENTITY_VITAE = 'GET_INDENTITY_VITAE'

/** identificacion de la moto */
export const GET_WARRANTY_VITAE = 'GET_WARRANTY_VITAE'
// reviciones y servicios
export const GET_REVIEWSSERVICES_VITAE = 'GET_REVIEWSSERVICES_VITAE'

//Identificación y datos para la hoja de vida de la moto
export const GET_LIST_HV = 'GET_LIST_HV'

/** servicios */
export const GET_SERVICES_LIST = "GET_SERVICES_LIST"
export const GET_CALL_SERVICES_LIST = "GET_CALL_SERVICES_LIST"
export const GET_SERVICES_PENDING_LIST = "GET_SERVICES_PENDING_LIST"
export const GET_ORDEN_SERVICIO_ID = "GET_ORDEN_SERVICIO_ID"
export const GET_REQUEST_SERVICE_ID = "GET_REQUEST_SERVICE_ID"
export const GET_CALLS_SERVICE_ID = "GET_CALLS_SERVICE_ID"
export const GET_INVENTARIO_SERVICE_ID = "GET_INVENTARIO_SERVICE_ID"
export const GET_AUTH_ID = "GET_AUTH_ID"
export const GET_ULTIMO_CONSECUTIVO = "GET_ULTIMO_CONSECUTIVO"
export const GET_TYPE_SERVICE = "GET_TYPE_SERVICE"
export const GET_TYPE_SERVICE_WORK = "GET_TYPE_SERVICE_WORK"
export const GET_INVENTARIO = "GET_INVENTARIO"
export const GET_MAPA_TALLER = "GET_MAPA_TALLER"
export const GET_PLANILLA_DIARIA = "GET_PLANILLA_DIARIA"
export const GET_PLANILLA_INCOLMOTOS = "GET_PLANILLA_INCOLMOTOS"
export const GET_SERVICES_MAP = "GET_SERVICES_MAP"
export const GET_CLIENT_CEDULA = "GET_CLIENT_CEDULA"
export const GET_VEHICLE_MOTOR = "GET_VEHICLE_MOTOR"
export const POST_REGISTER_SERVICE = "POST_REGISTER_SERVICE"
export const POST_REGISTER_GESTION_TALLER = "POST_REGISTER_GESTION_TALLER"
export const POST_REGISTRAR_LLAMADAS = "POST_REGISTRAR_LLAMADAS"
export const POST_REGISTRAR_INVENTARIO = "POST_REGISTRAR_INVENTARIO"
export const POST_REGISTRAR_AUTORIZACION = "POST_REGISTRAR_AUTORIZACION"
export const POST_REGISTRAR_MAPA_TALLER = "POST_REGISTRAR_MAPA_TALLER"
export const POST_CERRAR_ORDEN = "POST_CERRAR_ORDEN"
export const GET_GENERAR_PDF_CLIENTE = "GET_GENERAR_PDF_CLIENTE"
export const GET_GENERAR_PDF_TECNICO = "GET_GENERAR_PDF_TECNICO"
export const UPDATE_SERVICE = "UPDATE_SERVICE"
export const UPDATE_MAPA_TALLER = "UPDATE_MAPA_TALLER"
export const UPDATE_REMEMBER_CALL = "UPDATE_REMEMBER_CALL"
export const UPDATE_REGISTER_GESTION_TALLER = "UPDATE_REGISTER_GESTION_TALLER"
export const UPDATE_TECNICO_ORDEN = "UPDATE_TECNICO_ORDEN"
export const DELETE_SERVICE = "DELETE_SERVICE"
export const DELETE_SOLUCION = "DELETE_SOLUCION"
export const DELETE_LLAMADAS = "DELETE_LLAMADAS"
export const GET_GENERAR_PDF_FECHA = "GET_GENERAR_PDF_FECHA"
export const GET_GENERAR_PDF_SERVICIO = "GET_GENERAR_PDF_SERVICIO"
export const GET_TOKEN_SERVICE = "GET_TOKEN_SERVICE"
export const SEND_EMAIL = "SEND_EMAIL"
export const SEND_EMAIL_CRM = "SEND_EMAIL_CRM"

/** CRM */
export const GET_AGENDAMIENTO_CRM = "GET_AGENDAMIENTO_CRM"
export const GET_AGENDAMIENTO_CONFIRMADOS = "GET_AGENDAMIENTO_CONFIRMADOS"
export const GET_CRM_IDCLIENTE = "GET_CRM_IDCLIENTE"
export const SET_SCHEDULING_CONFIRM = "SET_SCHEDULING_CONFIRM"

 /** Mapa de Taller */
export const GET_INGRESO_MOTO_MENSUAL = 'GET_INGRESO_MOTO_MENSUAL'
export const GET_FACTURA_TECNICO = 'GET_FACTURA_TECNICO'
export const GET_INGRESO_MOTO_MENSUAL_ID = 'GET_INGRESO_MOTO_MENSUAL_ID'
export const GET_VALOR_VENTA_TYPE_SERV = 'GET_VALOR_VENTA_TYPE_SERV'
export const GET_MAPATALLER_PDF = 'GET_MAPATALLER_PDF'

/** Vehicles forms */
export const GET_VEHICLE = "GET_VEHICLE"

/** Parametrización */
export const GET_MOTORCYCLES = "GET_MOTORCYCLES"
export const GET_MOTORCYCLES_ID = "GET_MOTORCYCLES_ID"
export const GET_INVENTARIO_MOTORCYCLES = "GET_INVENTARIO_MOTORCYCLES"
export const GET_VALUES_VEHICLES = "GET_VALUES_VEHICLES"
export const GET_SOAT_LIST = "GET_SOAT_LIST"
export const GET_SERVICES_WORKS_LIST = "GET_SERVICES_WORKS_LIST"
export const GET_VALUES_LIST = "GET_VALUES_LIST"
export const DEL_VAL_MOTORCYCLE = "DEL_VAL_MOTORCYCLE"
export const UPDATE_MOTORCYCLES = "UPDATE_MOTORCYCLES"
export const UPDATE_INV_MOTORCYCLES = "UPDATE_INV_MOTORCYCLES"
export const UPDATE_VAL_VEHICLE = "UPDATE_VAL_VEHICLE"
export const UP_TYPE_SERVICE = "UP_TYPE_SERVICE"
export const UP_TYPE_WORK = "UP_TYPE_WORK"
export const UP_VALUES = "UP_VALUES"
export const POST_MOTORCYCLES = "POST_MOTORCYCLES"
export const POST_INV_MOTORCYCLES = "POST_INV_MOTORCYCLES"
export const POST_VAL_MOTORCYCLES = "POST_VAL_MOTORCYCLES"
export const POST_TYPE_SERVICE = "POST_TYPE_SERVICE"
export const POST_TYPE_WORK = "POST_TYPE_WORK"
export const DEL_TYPE_SERVICE = "DEL_TYPE_SERVICE"
export const DELETE_MOTORCYCLES = "DELETE_MOTORCYCLES"
export const DEL_TYPE_WORKS = "DEL_TYPE_WORKS"

/** Obtener Sedes */
export const GET_SEDES = "GET_SEDES"
export const GET_SEDES_ID = "GET_SEDES_ID"
export const GET_ALL_SEDES_ID = "GET_ALL_SEDES_ID"

/** Works */
export const GET_WORKS_ID = "GET_WORKS_ID"
export const GET_WORKS_ONE = "GET_WORKS_ONE"
export const POST_REGISTER_WORK = "POST_REGISTER_WORK"
export const POST_REGISTER_WORK_SUSPENSION = "POST_REGISTER_WORK_SUSPENSION"
export const GET_ONE_WORK_SUSPENSION = "GET_ONE_WORK_SUSPENSION"
export const SET_WORK_CLOSE_TECN = "SET_WORK_CLOSE_TECN"

/** Cotizaciones */
export const GET_COTIZATIONS_LIST = 'GET_COTIZATIONS_LIST'
/** Cotizaciones */
export const GET_SCHEDULING_STATE = 'GET_SCHEDULING_STATE'