import React, { Component } from 'react'
import { connect } from 'react-redux'

import UsersV from "../../components/users"
import {getusers, delUsers} from '../../../redux/actions/userActions'

class index extends Component {

    constructor(props){
        super(props)

        this.state = { users: [], loading: false}
        this.isMount = false
    }

    async componentDidMount() {
        /** Parametros de config */
        this.setState({ loading: true })
        this.isMount = true

        await this.props.getusers()
        if(this.isMount)
            this.setState({users: this.props.users })

        
        /** actualizando parametros */
        this.setState({ loading: false })
        
    }
    componentWillUnmount(){
        this.isMount = false
    }
    
    /** On Delete, función para eliminar usuarios */
    handleDelete = async (e, id) => {

        await this.props.delUsers(id)
        if(this.isMount)
            this.setState({ users: this.props.users })
    }

    /** Cambio de pagina, ir a registrar o ediar */
    handleClickPage = (e, id) => id ? this.props.history.push(`/usuarios/reguser/${id}`) : this.props.history.push('/usuarios/reguser')

    render() {
        return (
            <UsersV
                state={this.state}
                onClickPage={this.handleClickPage}
                onDelete={this.handleDelete}
            />
        )
    }
}

const mapStateToProps = ({ users }) => ({ users })

export default connect(mapStateToProps, {getusers, delUsers})(index)