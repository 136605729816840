import { createAction } from 'redux-actions'
import { apiGetOneWorks, apiGetOneWorksSusp, apiGetWorksId, apiPostRegisterWork, apiPostWorkSusp, apiSetCloseOrden } from '../api/works'
import { GET_ONE_WORK_SUSPENSION, GET_WORKS_ID, GET_WORKS_ONE, POST_REGISTER_WORK, POST_REGISTER_WORK_SUSPENSION, SET_WORK_CLOSE_TECN } from '../types'

/** Trabajos -- obtener información del servicio por id */
export const getWorksId = createAction(GET_WORKS_ID,
    data => apiGetWorksId(data)())
/** Trabajos -- obtener por id */
export const getOneWorks = createAction(GET_WORKS_ONE,
    data => apiGetOneWorks(data)())
/** Trabajos Suspendidos -- obtener por id */
export const getOneWorksSusp = createAction(GET_ONE_WORK_SUSPENSION,
    data => apiGetOneWorksSusp(data)())
/** Trabajos -- registrar */
export const postRegisterWork = createAction(POST_REGISTER_WORK,
    data => apiPostRegisterWork(data)())
/** Trabajos -- registrar */
export const postWorkSusp = createAction(POST_REGISTER_WORK_SUSPENSION,
    data => apiPostWorkSusp(data)())
/** Trabajos -- cierre de orden tecnico */
export const setCloseOrden = createAction(SET_WORK_CLOSE_TECN,
    data => apiSetCloseOrden(data)())
