import React from 'react'
import styled, { css } from 'styled-components'
import Scrollbars from 'react-custom-scrollbars'

import { secundaryColor, blackColor, bgVariantColor, primayColor, primayVariantColor, iconColor } from '../../../constanst'
// import { IconPlus } from "../../../assets/icons/"
import { Loading } from '../../common/Loading'
import ModuleInput from '../../common/ModuleInput'
import ButtonSubmit from '../../common/ButtonSubmit'
import { ContainerButton, FormContent, Tooltip, TooltipText,Title, Box } from './ComponentsForm'
import { MenuItem } from '@material-ui/core'
import CustomSelect from '../../common/CustomSelect'
import { openModal } from '../../common/Modal'
import Modal from '../../common/Modal'

const MapaTaller = ({ state, onChange, onSubmit, onChangeSelect, onChangeMultiple, onGoBack, onClickSol, onClickButton, sigPad, clearPad, trimPad }) => (
    <Main id="main">
        {state.loading &&
            <Loading />    
        }
        <Modal sigPad={(ref) => sigPad(ref) } clearPad={clearPad} trimPad={trimPad} />

        <Scrollbars className="scroll" style={{flex: 1}}>
            <Container>
                <ButtonContainer>
                    {/* <NewButton>NUEVO&nbsp; <IconPlus width={"10px"} height={"10px"} color={blackColor} /></NewButton>
                    <NewButton>IR AL SEGUIMIENTO&nbsp; <IconPlus width={"10px"} height={"10px"} color={blackColor} /></NewButton> */}
                </ButtonContainer>
                <Title>CIERRE DE ORDEN</Title>

                {/** Formulario */}
                <FormContainer>
        
                    <FormTitle>{state.consecutivo}</FormTitle>
                    <LineRed />
                    
                    <Form onSubmit={onSubmit}>
                        <FormContent>
                            <ModuleContainer>
                                <Box justify="space-between" direction="row" width="100%">
                                    <CustomSelect label="TIPO DE SERVICIO" inputw="48%" name="formtiposervicioorden" onChange={onChangeSelect} value={state.mapataller.formtiposervicioorden}>
                                        <MenuItem value='0'>Seleccione una opción</MenuItem>
                                        { state.listServicesMap &&
                                            state.listServicesMap.map(x => (
                                                <MenuItem key={x.tsem_id} value={x.tsem_id}>{x.tsem_nombre}</MenuItem>
                                            ))
                                        }
                                    </CustomSelect>
                                    <CustomSelect label="TÉCNICO FINAL" inputw="48%" name="formtecnico" onChange={onChangeSelect} value={state.mapataller.formtecnico || ''}>
                                        {!!state.tecnicos && 
                                            state.tecnicos.map( x => (
                                                <MenuItem key={x.usu_id} value={x.usu_id}>{`${x.per_nombre} ${x.per_apellido}`}</MenuItem>
                                            ))
                                        }
                                    </CustomSelect>
                                </Box>
                                <Box justify="space-between" direction="row" width="100%">
                                    <ModuleInput label="FACTURA DE ACEITE" type="text" name="formaceite"
                                        backgroundC={secundaryColor} borderC={iconColor} width="100%" value={format(state.mapataller.formaceite)}
                                        onChange={e => onChange(e, true, false, false, true, 1, 50)} inputw="50%"
                                    />
                                    <ModuleInput label="FACTURA DE REPUESTO" type="text" name="formrepuesto"
                                        backgroundC={secundaryColor} borderC={iconColor} width="100%" value={format(state.mapataller.formrepuesto)}
                                        onChange={e => onChange(e, true, false, false, true, 1, 50)} inputw="50%"
                                    />
                                </Box>

                                <Box justify="space-between" direction="row" width="100%">
                                    <ModuleInput label="FACTURA DE MANO DE OBRA" type="text" name="formmo"
                                        backgroundC={secundaryColor} borderC={iconColor} width="100%" value={format(state.mapataller.formmo)}
                                        onChange={e => onChange(e, true, false, false, true, 1, 50)} inputw="50%"
                                    />
                                    <ModuleInput label="FACTURA DE TERCEROS" type="text" name="formtercero"
                                        backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="50%"
                                        onChange={e => onChange(e, true, false, false, true, 1, 50)}  value={format(state.mapataller.formtercero)}
                                    />
                                </Box>
                                <ModuleInput label="FECHA DE CIERRE" type="date" name="formcierre"
                                    backgroundC={secundaryColor} borderC={iconColor} width="70%" inputw="100%"
                                    onChange={onChangeSelect} value={state.mapataller.formcierre || ''}
                                />
                                <InputContainer>
                                    <Label>Recomendaciones del técnico</Label>
                                    <TextArea name="formcomentarios" value={state.mapataller.formcomentarios} disabled></TextArea>
                                </InputContainer>
                                
                                <Box justify="space-between" direction="row" width="88%">
                                    <InputContainer width="80%">
                                        <Label>Solución dada</Label>
                                        <TextArea name="formsolucion" data-ignore="false" rows="5"
                                            onChange={onChangeMultiple} value={state.clienteSol.formsolucion || ''} 
                                        />
                                    </InputContainer>
                                    <InputContainer width="20%">
                                        <Label>Soluciones</Label>
                                        {!!state.clienteSolicitud && state.clienteSolicitud.map( (x, i) =>
                                            <Tooltip key={x.idobs} className="tooltip">
                                                <ButtonSol type="button" onClick={e => onClickSol(e, x.idobs)}>{i+1}</ButtonSol>
                                                <TooltipText className="tooltiptext" bgColor={primayColor} style={{fontSize: '10px'}}>Solicitud previa: {x.formsolicitudes}</TooltipText>
                                            </Tooltip>
                                        )}
                                    </InputContainer>
                                </Box>
                                <ButtonSubmit width="150px" style={{ marginLeft: '15px' }} label="Actualizar Solución" onClick={onClickButton} bgColor={blackColor} color={secundaryColor} type="button" />
                            
                                <Box style={{ marginTop:  30, flexDirection: 'column', alignItems: 'center' }}>
                                    <Firma type="button" onClick={() => openModal("modalFirma")} id="imgfirma"> 
                                        { state?.firmacliente && <ImagenFirma src={state?.firmacliente} style={{ width: "100%", height:"100%" }} alt="" />}
                                    </Firma>
                                    <Label>AUTORIZO</Label>
                                </Box>
                            </ModuleContainer>

                            <ModuleContainer borderLeft={true}>
                                <LabelTotal>TOTAL: ${format(state.mapataller.formfacturatotalmapa)}</LabelTotal>
                            </ModuleContainer>
                        </FormContent>
                        
                        {/* button */}
                        <ContainerButton>
                            <ButtonSubmit width="30%" label="Cancelar" onClick={onGoBack} bgColor={blackColor} color={secundaryColor} type="button" />
                            <ButtonSubmit width="30%" label="Guardar y Cerrar orden" bgColor={primayColor} color={secundaryColor} type="submit" />
                        </ContainerButton>
                    </Form>
                </FormContainer>

            </Container>
        </Scrollbars>
    </Main>
)

const format = value => new Intl.NumberFormat('en-US').format(value)



const Main = styled.main`
    flex: 1;
    position: relative;
`
const Container = styled.div`
    background-color: ${bgVariantColor};
    margin: 0px 1%;
    height: 100;
    box-shadow: 0px 0px 8px 2px rgba(0,0,0,.4);
 `
 const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
 `
//  const NewButton = styled.button`
//     background-color: transparent;
//     color: ${blackColor};
//     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
//     border: none;
//     padding: 10px;
//     font-size: 12px;
//     font-weight: 500;

//     :hover {cursor: pointer;}
//     :focus {outline: none;}
//  `
/** Formulario Orden de Servicio */
const FormContainer = styled.div`
    padding: 0px 30px;
    margin-bottom: 30px;
`
const FormTitle = styled.p`
    width: 100%;
    text-align: center;
    color: ${primayVariantColor};
    font-size: 18px;
    font-weight: 600;
`
const LineRed = styled.hr`border: 1px solid ${primayColor}; margin-bottom: 20px;`
const Form = styled.form`
    display: flex;
    flex-direction: column;

`
const ModuleContainer = styled.div`
    flex: .5;
    padding: 0 10px 0px 20px;
    display: flex;
    justify-content : center;
    flex-direction: column;
    align-items: ${({center}) => center ? 'center' : 'flex-start'};
    
    ${({ borderLeft }) => borderLeft && css`border-left: 1px solid ${primayColor};`}
`

const LabelTotal = styled.label`
    color: ${primayVariantColor};
    font-size: 36px;
    font-weight: 500;
    align-self: center;
`
const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: ${({width}) => width ? width : '70%'};
    padding: 0 10px;
`
const Label = styled.label`
    color: ${blackColor};
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif;
    font-size: 12px;
    font-weight: 500;
`
const TextArea = styled.textarea`
    color: ${blackColor};
    padding: 10px;
    border-radius: 5px;
    border: 1px solid ${iconColor};
    margin: 10px 0px;
    resize: none;
    outline: 0;

    :focus {border: 1px solid ${blackColor}};
`
const ButtonSol = styled.button`
    background-color: ${primayColor};
    border: none;
    outline: 0;
    color: white;
    font-size: 12px;
    font-weight: 800;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-top: 12px;

    &:hover {
        cursor: pointer;
        opacity: .5;
    }
`
const Firma = styled.button`
    border-radius: 15px;
    border: 1px solid ${iconColor};
    width: 200px;
    height: 140px;
    outline: 0;
    padding: 10px;

    &:disabled {cursor: no-drop;}
`
const ImagenFirma = styled.img`
    border: none;
    border-radius: 15px;
`

export default MapaTaller