import React from 'react'
import styled, { css } from 'styled-components'

import { secundaryColor, primayColor, iconColor, blackColor, errorColor } from '../../../constanst'
import ModuleInput from '../../common/ModuleInput'
import { TableContainer, Table, Tr, Th, Td, Subtitle, ContainerButton, Box } from './ComponentsForm'
import ButtonSubmit from '../../common/ButtonSubmit'
import { IconDelete } from '../../../assets/icons'
import { Button } from '@material-ui/core'


export default  ({ state: {solicitudform, clienteLlam}, onClickButton, onChangeMultiple, onDel }) => (
    <ModuleContainer>
        <Subtitle>LLAMADAS</Subtitle>
        <Box justify="space-between" direction="row">
            <InputContainer inputw="60%">
                <Label>COMENTARIOS</Label>
                <TextArea name="formcomentario" onChange={e => onChangeMultiple(e, false, false, false, true, 1, 500, false, 2)} data-ignore="true" value={clienteLlam.formcomentario || ''} rows="5" />
                <Error></Error>
            </InputContainer>
            <Box flex="1" direction="column" align="flex-end">
                <ModuleInput label="FECHA DE LA LLAMADA" type="date" name="formfecha" ignore={true}
                    onChange={e => onChangeMultiple(e, false, false, false, true, 2, 100, false, 2)} value={clienteLlam.formfecha || ''}
                    backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="80%"
                />
                <ModuleInput label="HORA" type="time" name="time2" ignore={true}
                    onChange={e => onChangeMultiple(e, false, false, false, true, 2, 100, false, 2)} value={clienteLlam.time2 || ''}
                    backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="80%"
                />
                 <ContainerButton width="250px">
                    <ButtonSubmit width="100%" label="Agregar" bgColor={blackColor} color={secundaryColor} type="button" onClick={e => onClickButton(e, 2)} />
                </ContainerButton>
            </Box>
           
        </Box>
        
        <TableContainer>
            <Subtitle>LISTA DE LLAMADAS</Subtitle>
            <Table>
                <thead>
                    <Tr>
                        <Th>#</Th>
                        <Th>Fecha</Th>
                        <Th>Hora</Th>
                        <Th>Decripción</Th>
                        <Th></Th>
                    </Tr>
                </thead>
                <tbody>
                    {solicitudform.clientellamadas.length > 0 ?
                        solicitudform.clientellamadas.map( (element, index) => {
                            if(element.llamstate === "0") {
                                return <Tr key={index}>
                                        <Td>{index + 1}</Td>
                                        <Td>{element.formfecha}</Td>
                                        <Td>{element.time2}</Td>
                                        <Td>{element.formcomentario}</Td>
                                        <Td style={{textAlign: 'center'}}>
                                            <Button onClick={e => onDel(e,index, 2, element.llamid)}>
                                                <IconDelete width="25px" height="25px" color={errorColor} bgColor="transparent" />
                                            </Button>
                                        </Td>
                                    </Tr>
                            }
                            return false
                        })
                        : <Tr><Td textAlign="center" colSpan="5">No hay llamadas para mostrar</Td></Tr>
                    }
                </tbody>
            </Table>
        </TableContainer>

    </ModuleContainer>
)


const ModuleContainer = styled.div`
    flex: 1;
    width: 100%;
    padding: 0 10px 0px 20px;
    display: inline-block;
    
    ${({ borderLeft }) => borderLeft && css`border-left: 1px solid ${primayColor};`}
`
const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: ${({inputw}) => inputw };
    padding: 0 10px;
`
const Label = styled.label`
    color: ${blackColor};
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif;
    font-size: 12px;
    font-weight: 500;
`
const TextArea = styled.textarea`
    color: ${blackColor};
    padding: 10px;
    border-radius: 5px;
    border: 1px solid ${iconColor};
    margin: 10px 0px;
    resize: none;
    outline: 0;

    :focus {border: 1px solid ${blackColor}};
`
const Error = styled.span`
    padding-right: 10px;
    align-self: flex-end;
    color: ${errorColor};
    font-size: 10px;
`