import React from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { blackColor, iconColor, secundaryColor, errorColor } from '../../../constanst'
import ButtonSubmit from '../../common/ButtonSubmit'
import { Loading } from '../../common/Loading'
import { ButtonsContainer } from '../../common/Table'
import TextArea from '../../common/TextArea'
import Header from './Header'
import IdenMoto from './IdenMoto'
import { Main, Container, Title, Box, ModuleContainer } from './Styles'


export default ({ state, onChange, onGoBack, onSubmit }) =>
    <Main>
        {state.loading && <Loading />}
        <Scrollbars>
            <Container>
                <Header docTitle="ORDEN DE TRABAJO Y DIAGNÓSTICO" sedeUser={state.formsede} />

                {/** Cuerpo de la función */}
                <IdenMoto />

                {/** Detalles del servicio */}
                <form onSubmit={onSubmit} id="formDetails">
                    <ModuleContainer>
                        <br />
                        <Title bgColor="transparent" color={blackColor}>SOLICITAR COTIZACIÓN</Title>
                        <br />
                        <Box justify="space-between" direction="row">
                            <TextArea label="DETALLES DE LA COTIZACIÓN DEL SERVICIO" name="obs" onChange={onChange}
                                backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="100%"
                                value={state.form.obs || ''} rows="5"
                            />
                        </Box>
                    </ModuleContainer>
                </form>

                <br />
                <br />
                <br />
                <ButtonsContainer justify="space-between">
                    <ButtonSubmit width={window.screen.width > 600 ? "200px" : "40%"} label='Volver' id="btnSolicitud" bgColor={blackColor} color={secundaryColor} type="button" onClick={onGoBack} />
                    <ButtonSubmit width={window.screen.width > 600 ? "200px" : "40%"} label='Aceptar' id="btnSolicitud" bgColor={errorColor} color={secundaryColor} type="submit" form="formDetails" />
                </ButtonsContainer>
            </Container>
        </Scrollbars>
    </Main>
