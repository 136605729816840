import React from 'react'
import styled from 'styled-components'
import Scrollbars from 'react-custom-scrollbars'

import { secundaryColor, alternateVariantColor, errorColor, successColor, primayVariantColor } from '../../../constanst'
import Table, {Tr,Th, Td, ButtonsContainer, ButtonIcon} from '../../common/Table'
import { IconDelete, IconEdit, IconPlus } from '../../../assets/icons'
import { Tooltip, TooltipText, } from './../../components/services/ComponentsForm'
import Button from '../../common/Button'
import { Loading } from '../../common/Loading'


export default ({ state, onClickPage, onDelete }) => 
    <Main>
        {state.loading && <Loading /> }
        <Scrollbars>
            <Container>
                <Title>USUARIOS</Title>
                <Table
                    minWidth="1000px"
                    renderButtons={
                        <ButtonsContainer>
                            <Button label="Nuevo Usuario" type="button" bgColor={successColor} color={secundaryColor} icon={<IconPlus width="15px" height="15px" color={secundaryColor} />} onClick={onClickPage} />
                        </ButtonsContainer>
                    }
                    renderHead={
                        <Tr bgColor={true}>
                            <Th>#</Th>
                            <Th>Usuario</Th>
                            <Th>TIPO</Th>
                            <Th>CREACIÓN</Th>
                            <Th>ACTUALIZACIÓN</Th>
                            <Th>ACCIONES</Th>
                        </Tr>
                    }
                    renderBody={
                    state.users.length > 0 ?
                        state.users.map( (x, index) => (
                            <Tr key={x.usu_id}>
                                <Td>{index + 1}</Td>
                                <Td>{x.usu_nombreusuario}</Td>
                                <Td>{x.tu_nombre}</Td>
                                <Td>{x.usu_fechaSistema}</Td>
                                <Td>{x.usu_fechaSistemaActualizacion}</Td>
                                <Td>
                                    <Tooltip className="tooltip">
                                        <ButtonIcon type="button" onClick={e => onClickPage(e, x.usu_id)} >
                                            <IconEdit width="30px" height="20px" color={secundaryColor} bgColor={alternateVariantColor} />
                                        </ButtonIcon>
                                        <TooltipText className="tooltiptext" bgColor={alternateVariantColor}>Editar</TooltipText>
                                    </Tooltip>
                                    <Tooltip className="tooltip">
                                        <ButtonIcon type="button" onClick={e => onDelete(e, x.usu_id)} >
                                            <IconDelete width="30px" height="20px" color={secundaryColor} bgColor={errorColor} />
                                        </ButtonIcon>
                                        <TooltipText className="tooltiptext" bgColor={errorColor}>Eliminar</TooltipText>
                                    </Tooltip>
                                </Td>
                            </Tr>
                        )) : 
                        <Tr>
                            <Td colSpan="6">No se encontraron elementos para mostrar</Td>
                        </Tr>
                    }
                >
                </Table>
            </Container>
        </Scrollbars>
    </Main>


const Main = styled.main`
    flex: 1;
    display: flex;
    overflow: hidden;
    box-sizing: border-box;
`
const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: ${secundaryColor};
    width: 100%;
    margin: 0px 2%;
    margin-bottom: 30px;
    /* border-radius: 15px; */
    box-shadow: 0px 0px 6px 1px rgba(0,0,0,.4);
    @media (min-width: 1000px){
        width: 98%;
        margin-left: 1%;
    }
 `
 const Title = styled.h3`
    color: ${secundaryColor};
    padding: 5px;
    margin: 0px;
    text-align: center;
    background-color: ${primayVariantColor};
`