import React from 'react'
import styled, { css } from 'styled-components'

import { secundaryColor, primayColor, blackColor } from '../../../constanst'
import { Box } from "./ComponentsForm"
import CustomSelect from '../../common/CustomSelect'
import { MenuItem } from '@material-ui/core'
import { Input } from 'form-tool'

const ClientFormCRM = ({ dataForm, errorForm, autoCom, clientList, menuActive, onChange, onBlur, onClickSelect, onChangeSelect, leftMode }) => (
        <ModuleContainer width={leftMode ? '100%' : 'auto'}>
            <Title bgcolor="transparent" color={blackColor}>IDENTIFICACIÓN DEL CLIENTE</Title>
                <Box justify="space-between" direction="row">
                    <Input
                        title="CÉDULA" type="number" name="formcedula"
                        onChange={onChange} value={dataForm?.formcedula}
                        error={errorForm?.formcedula} autoComplete='off'
                        required numeric
                    />
                    <Input
                        title="NOMBRE DEL PROPIETARIO (TP)" type="text" name="formnombre"
                        onChange={onChange} value={dataForm?.formnombre}
                        error={errorForm?.formnombre} autoComplete='off'
                        required
                    /> 
                </Box>
                <Box justify="space-between" direction="row">
                    <Input
                        title="APELLIDOS" type="text" name="formapellido"
                        onChange={onChange} value={dataForm?.formapellido}
                        error={errorForm?.formapellido} autoComplete='off'
                        required
                    />
                    <Input
                        title="DIRECCIÓN" type="text" name="formdireccion"
                        onChange={onChange} value={dataForm?.formdireccion}
                        error={errorForm?.formdireccion} autoComplete='off'
                        required
                    />
                </Box>
                <Box justify="space-between" direction="row">
                    <Input
                        title="E-MAIL" type="text" name="formemail"
                        onChange={onChange} value={dataForm?.formemail}
                        error={errorForm?.formemail} autoComplete='off'
                        required email
                    />
                    <Input
                        title="PROFESIÓN" type="text" name="formprofesion"
                        onChange={onChange} value={dataForm?.formprofesion}
                        error={errorForm?.formprofesion} autoComplete='off'
                        required
                    />
                </Box>
                <Box justify="space-between" direction="row">
                    <Input
                        title="CELULAR" type="number" name="formcelular"
                        onChange={onChange} value={dataForm?.formcelular}
                        error={errorForm?.formcelular} autoComplete='off'
                        required numeric
                    />
                    <Input
                        title="TELÉFONO ALTERNATIVO" type="number" name="formtelefono"
                        onChange={onChange} value={dataForm?.formtelefono}
                        error={errorForm?.formtelefono} autoComplete='off'
                        required
                    />
                </Box>
                <Box justify="space-between" direction="row">
                    <CustomSelect label="RED SOCIAL" inputw="40%" name="formtipors" data-ignore={true} onChange={e => onChange({ event: e, name: e.target.name, value: e.target.value })} value={dataForm?.formtipors || ''} > 
                        <MenuItem value="FACEBOOK">FACEBOOK</MenuItem>
                        <MenuItem value="TWITER">TWITTER</MenuItem>
                        <MenuItem value="INSTAGRAM">INSTAGRAM</MenuItem>
                    </CustomSelect>
                    <Input
                        title="USUARIO" type="text" name="formrs"
                        onChange={onChange} value={dataForm?.formrs}
                        error={errorForm?.formrs} autoComplete='off'
                    />
                </Box>
                <Box justify="space-between" direction="row">
                    <Input
                        title="PROGRAMAR CITA PARA LA FECHA" type="date" name="formremember"
                        onChange={onChange} value={dataForm?.formremember}
                        error={errorForm?.formremember} autoComplete='off'
                    />
                </Box>
        </ModuleContainer>
)

export default ClientFormCRM

const ModuleContainer = styled.div`
    flex: 1;
    padding: 0 10px 0px 20px;
    display: flex;
    flex-direction: column;
    
    width: ${ props => props.width };
    ${({ borderLeft }) => borderLeft && css`border-left: 1px solid ${primayColor};`}
`
const Title = styled.h3`
    color: ${({color}) => color ? color : secundaryColor};
    padding: 5px;
    margin-top: 0px;
    text-align: center;
`