import React from 'react'
import styled from 'styled-components'

import ModuleInput from '../../common/ModuleInput'
import { Loading } from '../../common/Loading'
import ModalC from '../../common/ModalC'
import Table, {Tr,Th, Td, ButtonIcon} from '../../common/Table'
import { IconEdit, IconSlope, IconWorkshopMap, IconCheck, IconCotizacion, IconAlert } from '../../../assets/icons'
import { Tooltip, TooltipText } from './ComponentsForm'
import { primayVariantColor, bgVariantColor, secundaryColor, iconColor, blackColor, alternateVariantColor, successColor, alternateColor } from '../../../constanst'

export default ({state, onChange, onSubmit, openModal, closeModal, handleClickEmail, handleClickConfirm}) => 
    <Main>
        {/** Loading */}
       {state.loading && <Loading /> }

        {/* Modal observación */}
        <ModalC id="modalAgen" title="RECORDATORIO" height="auto" onClickSave={onSubmit} onClickBack={closeModal}>
            <ModuleInput label="FECHA DE RECORDATORIO" type="date" name="date" 
                onChange={onChange} value={state.formAgend.date || ''}
                backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="60%" 
            />
            <Column>
                <Label>OBSERVACION</Label>
                <Textarea value={state.formAgend.obs || ''} name="obs" onChange={onChange} rows="5"></Textarea>
            </Column>
        </ModalC>


        <Container>
        {/** Title */}
        <Title>CRM - HISTORIA DEL CLIENTE</Title>
        
        {/** Información personal del cliente */}
        <H6>Información del cliente</H6>
        <ClienteCont>
            <ModuleInfo label="Nombre completo" value={state.user.clit_nombre} />
            <ModuleInfo label="Apellidos" value={state.user.clit_apellido} />
            <ModuleInfo label="Documento de identidad" value={state.user.clit_identificacion} />
            <ModuleInfo label="Celular" value={state.user.clit_celular} />
            <ModuleInfo label="Teléfono" value={state.user.clit_telefono} />
            <ModuleInfo label="Dirección" value={state.user.clit_direccion} />
            <ModuleInfo label="Profesión" value={state.user.clit_profesion} />
            <ModuleInfo label="Email" value={state.user.clit_email} />
            <ModuleInfo label="Red Social" value={state.user.clit_tipors} />
            <ModuleInfo label="Nombre de usuario" value={state.user.clit_rs} />
        </ClienteCont>



        {/* Listando el historial */}
        <br />
        
        <H6>Historial de llamadas</H6>
        <Table
        renderHead={
            <Tr bgColor={true}>
                <Th>#</Th>
                <Th>Cons</Th>
                <Th>Moto</Th>
                <Th>Placa</Th>
                <Th>Trabajo</Th>
                <Th>Ingreso</Th>
                <Th>Recordatorio</Th>
                <Th>Estado</Th>
                <Th>Observación</Th>
                <Th>Acciones</Th>
            </Tr>
        }
        renderBody={
                (state.schedulingList.length > 0) ?
                    state.schedulingList.map( (x, i) => (
                    <Tr key={x.agen_id}>
                        <Td color={primayVariantColor} bold="bold">{i+1}</Td>
                        <Td bold="bold">{x.ser_consecutivo}</Td>
                        <Td>{x.rm_nombremodelo}</Td>
                        <Td>{x.ds_placa}</Td>
                        <Td>{ x.ds_otrostrabajos ? x.ds_otrostrabajos : x.tg_nombre}</Td>
                        <Td>{x.fecha}</Td>
                        <Td>{x.agen_recordatorio}</Td>
                        <Td>{x.agen_estado === "0" ?
                            <Tooltip className="tooltip">
                                <IconAlert width="20px" height="20px" color={secundaryColor} bgColor={primayVariantColor} />
                                <TooltipText className="tooltiptext" bgColor={primayVariantColor}>Email sin enviar</TooltipText>
                            </Tooltip>
                            : x.agen_estado === "1" && x.agen_confirm === '1' ?
                            <Tooltip className="tooltip">
                                <IconCheck width="20px" height="20px" color={successColor}/>
                                <TooltipText className="tooltiptext" bgColor={successColor}>Email enviado y confirmado</TooltipText>
                            </Tooltip>
                            : 
                            <Tooltip className="tooltip">
                                <IconSlope width="20px" height="20px" color={alternateColor}/>
                                <TooltipText className="tooltiptext" bgColor={alternateColor}>Email enviado, aún no confirmado</TooltipText>
                            </Tooltip>
                            
                            
                            }
                        </Td>
                        <Td maxWidth="300px">{x.agen_observacion}</Td>
                        <Td>
                            <Row>
                                <Tooltip className="tooltip">
                                    <ButtonIcon onClick={e => openModal(e, x.agen_id)}>
                                        <IconEdit width="30px" height="20px" color={secundaryColor} bgColor={alternateVariantColor} />
                                    </ButtonIcon>
                                    <TooltipText className="tooltiptext" bgColor={alternateVariantColor}>Editar</TooltipText>
                                </Tooltip>
                                {x.agen_estado === '1' && x.agen_confirm === '0' ?
                                    <Tooltip className="tooltip">
                                        <ButtonIcon onClick={e => handleClickConfirm(e, x.ser_id)}>
                                            <IconCotizacion width="30px" height="20px" color={secundaryColor} bgColor="rgb(63, 81, 181)" />
                                        </ButtonIcon>
                                        <TooltipText className="tooltiptext" bgColor="rgb(63, 81, 181)">Confirmar Cita</TooltipText>
                                    </Tooltip>
                                : x.agen_estado === '0' &&
                                    <Tooltip className="tooltip">
                                        <ButtonIcon onClick={e => handleClickEmail(e, x.tg_id, x.clit_email, x.agen_id, `${x.clit_nombre} ${x.clit_apellido}`, x.ser_id)}>
                                            <IconWorkshopMap width="30px" height="20px" color={secundaryColor} bgColor={successColor} />
                                        </ButtonIcon>
                                        <TooltipText className="tooltiptext" bgColor={successColor}>Enviar Email</TooltipText>
                                    </Tooltip>
                                }
                            </Row>
                        </Td>
                    </Tr>
                ))
                : <Tr>
                    <Td colSpan={11}>No se encontraron ordenes para mostrar</Td>
                </Tr>
            }>
        </Table>
        </Container>
    </Main>

const ModuleInfo = ({label, value}) => 
    <ModuleView>
        <Label>{label}</Label>
        <InputModule>{value || 'N/A'}</InputModule>
    </ModuleView>


const Main = styled.main`
    flex: 1;
    position: relative;
`
const Container = styled.div`
    background-color: ${bgVariantColor};
    margin: 0px 1%;
    height: 100;
    box-shadow: 0px 0px 8px 2px rgba(0,0,0,.4);
 `
const Title = styled.h1`
    font-size: 18px;
    color: ${secundaryColor};
    padding: 5px;
    margin: 0px;
    text-align: center;
    background-color: ${primayVariantColor};
`
const Row = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
`
const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
`
/** Container para la información del cliente */
const ClienteCont = styled.div`
    background-color: ${bgVariantColor};
    border-radius: 0 0 16px 16px;
    border-bottom: 1px solid ${iconColor};
    padding: 5px 10px 15px 5px;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
`
const ModuleView = styled.div`
    width: auto;
    margin: 2px 10px;
`
const Label = styled.div`
    font-size: 10px;
    margin-bottom: 2px;
    padding-left: 5px;
`
const InputModule = styled.div`
    border-radius: 15px;
    border: 1px solid ${iconColor};
    width: 100%;
    padding: 2px 15px;
    font-size: 12px;
    background: #ffffff;
`
const H6 = styled.h5`
    text-align: center;
    margin: 15px 5px 10px 5px;
    color: ${blackColor};
`
const Textarea = styled.textarea`
    border-radius: 5px;
    border: 1px solid ${iconColor};
    width: 100%;
    padding: 2px 15px;
    font-size: 12px;
    background: #ffffff;
    width: 100%;
    resize: none;
`