import { handleActions } from 'redux-actions'

import { GET_MOTORCYCLES, GET_INVENTARIO_MOTORCYCLES, GET_SOAT_LIST, POST_MOTORCYCLES, POST_INV_MOTORCYCLES, DELETE_MOTORCYCLES, GET_MOTORCYCLES_ID, UPDATE_MOTORCYCLES,
    UPDATE_INV_MOTORCYCLES, GET_VALUES_VEHICLES, POST_VAL_MOTORCYCLES, DEL_VAL_MOTORCYCLE, UPDATE_VAL_VEHICLE, DEL_TYPE_SERVICE, UP_TYPE_SERVICE, POST_TYPE_SERVICE, 
    GET_SERVICES_WORKS_LIST, DEL_TYPE_WORKS, UP_TYPE_WORK, POST_TYPE_WORK, GET_VALUES_LIST, UP_VALUES } from '../types'

export const motorcycles = handleActions({
    [GET_MOTORCYCLES]: (state, action) => action.payload,
    [GET_MOTORCYCLES_ID]: (state, action) => action.payload,
    [DELETE_MOTORCYCLES]: (state, action) => {

        state.data.map( (x, i) =>  {
            if (x !== undefined)
                if(x.rm_id === action.payload.id) {
                    state.data.splice(i, 1)
                }
            return true
            })

        return state
    },
}, [])
export const inventarioMoto = handleActions({ [GET_INVENTARIO_MOTORCYCLES]: (state, action) => action.payload }, [])
export const soatList = handleActions({ [GET_SOAT_LIST]: (state, action) => action.payload }, [])
export const valuesVehicles = handleActions({ 
    [GET_VALUES_VEHICLES]: (state, action) => action.payload,
    [DEL_VAL_MOTORCYCLE]: (prevState, action) => {

        const state = prevState.data.filter(x => x.idvalaores !== action.payload.id)
        return {...prevState, data: state}
    },
}, [])
export const typeServicesWorks = handleActions({ 
    [GET_SERVICES_WORKS_LIST]: (state, action) => action.payload,
    [DEL_TYPE_WORKS]: (prevState, action) => {
        
        const state = prevState.data.filter(x => x.tg_id !== action.payload.id)
        return {...prevState, data: state, success: action.payload.success}
    },
}, [])
export const valuesList = handleActions({ 
    [GET_VALUES_LIST]: (state, action) => action.payload,
}, [])

export const postResponseParam = handleActions({ 
    [POST_MOTORCYCLES]: (state, action) => action.payload,
    [POST_INV_MOTORCYCLES]: (state, action) => action.payload,
    [UPDATE_MOTORCYCLES]: (state, action) => action.payload,
    [UPDATE_INV_MOTORCYCLES]: (state, action) => action.payload,
    [UPDATE_VAL_VEHICLE]: (state, action) => action.payload,
    [POST_VAL_MOTORCYCLES]: (state, action) => action.payload,
    [DEL_TYPE_SERVICE]: (state, action) => action.payload,
    [UP_TYPE_SERVICE]: (state, action) => action.payload,
    [POST_TYPE_SERVICE]: (state, action) => action.payload,
    [UP_TYPE_WORK]: (state, action) => action.payload,
    [POST_TYPE_WORK]: (state, action) => action.payload,
    [UP_VALUES]: (state, action) => action.payload,
}, [])