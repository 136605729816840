/** colores */
export const primayColor = "#E22122"
export const primayVariantColor = "#C1272D"
export const secundaryColor = "#FFFFFF"
export const bgColor = "#e0eeff"
export const bgVariantColor = "#f8f9fa"
export const iconColor = "#B4B4B4"
export const iconVariantColor = "#f0f0f0"
export const alternateColor = "#ffc107"
export const alternateVariantColor = "#29ABE2"
export const blackColor = "#353b41"
export const transparentColor = "transparent"
export const errorColor = "#E22122"
export const successColor = "#28a745"




/** Validaciones */


export const isNull = (dato) => {
    if (!dato || dato === '') {
        return true
    } else return false
}

export const isNumeric = (dato) => {
    // const value = dato.replace(/,/g, '');
    if (isNaN(dato) && dato !== '' && dato !== undefined && dato !== null) {
        return true
    } else return false
}

export const onlyLetters = (dato) => {
    let validar = /^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g;
    if (validar.test(dato) === false && dato !== '' && dato !== undefined && dato !== null) {
        return true
    } else return false
}

export const rangeLength = (dato, min, max) => {
    if (dato !== undefined && dato !== '' && dato !== null) {
        if ((dato.length < min) || (dato.length > max)) {
            return true
        } else return false
    } else 
        return false
}

export const Match = (dato1, dato2) => {
    if (dato1 !== dato2) {
        return true
    } else return false
}

export const isEmail = (email) => {
    let validar = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (validar.test(email) === false && email !== '' && email !== undefined && email !== null) {
        return true
    } else return false
}

export const numberFormat = value => {
    value = parseFloat(value.replace(/,/g, ""))
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    return value
}

export const validations = (e, typeNull, typeLetters, typeNumeric, typeRange, minRange, maxRange, typeEmail) => {
    const {value} = e.target
    const sibling = e.target.nextSibling
    if (typeNull) {
        if (isNull(value)){
            e.target.style.border = `1px solid ${errorColor}`
            sibling.innerHTML = "El campo no puede estar vació"
            return true
        } else{ 
            e.target.style.border = `1px solid ${blackColor}`
            sibling.innerHTML = ""
        }
    }
    if (typeNumeric) {
        if (isNumeric(value)){
            e.target.style.border = `1px solid ${errorColor}`
             sibling.innerHTML = "El campo debe ser númerico"
            return true
        } else {
            sibling.innerHTML = ""
            e.target.style.border = `1px solid ${blackColor}`
        }
    }
    if (typeRange) {
        if (rangeLength(value, minRange, maxRange)) {
            e.target.style.border = `1px solid ${errorColor}`
             sibling.innerHTML = `Rango permitido de ${minRange} a ${maxRange} carácteres`
            return true
        } else{
            sibling.innerHTML = ""
            e.target.style.border = `1px solid ${blackColor}`
        }
    }
    if (typeLetters) {
        if (onlyLetters(value)) {
            e.target.style.border = `1px solid ${errorColor}`
            sibling.innerHTML = "El campo debe ser solo letras"
            return true
        } else{
            sibling.innerHTML = ""
            e.target.style.border = `1px solid ${blackColor}`
        }
    }
    if (typeEmail) {
        if (isEmail(value)) {
            e.target.style.border = `1px solid ${errorColor}`
             sibling.innerHTML = "Formato de email incorrecto"
            return true
        } else {
            sibling.innerHTML = ""
            e.target.style.border = `1px solid ${blackColor}`
        }
    }
    return false
}
