import React, { Component } from 'react'
import { connect } from 'react-redux'
import toastr from 'toastr'
import "toastr/build/toastr.css"

import LoginV from '../components/LoginV'
import { loginUser } from "../../redux/actions/userActions"

class Login extends Component {

    constructor(props){
        super(props);
        this.state = {form: {}}
    }
    
    componentDidMount() {
        const userdata = localStorage.getItem("userdata")
        if(userdata)
            this.props.history.push('/home')
    }
    

    handleChange = e => {
        this.setState({ form: {...this.state.form, [e.target.name]:e.target.value }})
    }
    handleClick = async e => {
        e.preventDefault()
        await this.props.loginUser(this.state.form)
        if(this.props.users.success === 1){
           toastr.success(this.props.users.message)
           this.props.history.push('/home')
        } else
            toastr.error(this.props.users.message)
    }

    render() {
        return (
            <LoginV 
                data={this.state}
                onChange={this.handleChange}
                onClick={this.handleClick}
            />
        )
    }
}

const mapStateToProps = ({ users }) => ({ users })
export default connect(mapStateToProps, { loginUser })(Login)