import React from 'react'
import { MenuItem } from '@material-ui/core'
import CustomSelect from '../../common/CustomSelect'
import InputText from '../../common/InputText'
import ButtonSubmit from '../../common/ButtonSubmit'
import { IconSearch } from '../../../assets/icons'
import { NavSearch, DivisorContainer, Row, InputContainer } from "./Styles"
import { secundaryColor, iconColor, blackColor, successColor } from "../../../constanst"
                                                                                        
export default ({ state, onChange, onClickFindDate, onFilter, onKeyPressFilter, onChangeSel }) =>
    <NavSearch border>
        <DivisorContainer borderC={iconColor} direction="column">
            <Row>
                <InputContainer>
                    <CustomSelect inputw="100%" name="usu_sede" onChange={onChangeSel} value={state.usu_sede} title='Sede'>
                        {state.sedesList?.map(x =>  <MenuItem key={x.sed_id} value={x.sed_id}>{x.sed_nombre}</MenuItem>)}
                    </CustomSelect>
                </InputContainer>
                <InputContainer>
                    <InputText icon={<IconSearch width="20px" height="20px" color={blackColor} />}
                        placeholder="Consecutivo" name="ser_consecutivo" placeholderColor={iconColor} borderC={iconColor}
                        backgroundC={secundaryColor} textColor={iconColor}
                        onChange={onChange} onSearch={onFilter} onKeyPress={onKeyPressFilter} value={state.ser_consecutivo}
                    />
                </InputContainer>
                <InputContainer>
                    <InputText icon={<IconSearch width="20px" height="20px" color={blackColor} />}
                        placeholder="Placa" name="ds_placa" placeholderColor={iconColor} borderC={iconColor}
                        backgroundC={secundaryColor} textColor={iconColor}
                        onChange={onChange} onSearch={onFilter} onKeyPress={onKeyPressFilter} value={state.ds_placa}
                    />
                </InputContainer>
                <InputContainer>
                    <CustomSelect inputw="100%" name="ser_estado" onChange={onChangeSel} value={state.ser_estado} disabled={state.selectDisabled} load={state.selectDisabled}>
                        <MenuItem value="all">Todos</MenuItem>
                        <MenuItem value="0">Pendientes</MenuItem>
                        <MenuItem value="2">Cerrados</MenuItem>
                    </CustomSelect>
                </InputContainer>
            </Row>
        </DivisorContainer>
        <DivisorContainer>
            <InputContainer>
                <InputText
                    type="date" name="dateStart" placeholderColor={iconColor} onChange={onChange}
                    backgroundC={secundaryColor} textColor={iconColor} borderC={iconColor} value={state.dateStart}
                />
            </InputContainer>
            <InputContainer>
                <InputText
                    type="date" name="dateFinal" placeholderColor={iconColor} onChange={onChange}
                    backgroundC={secundaryColor} textColor={iconColor} borderC={iconColor} value={state.dateFinal}
                />
            </InputContainer>
            <InputContainer align="flex-end">
                <ButtonSubmit label={window.screen.width > 600 ? "Buscar por fecha" : "Buscar"} bgColor={successColor} color={secundaryColor} onClick={onClickFindDate} type="button" width={window.screen.width > 600 ? 'auto' : '100%'} />
            </InputContainer>
        </DivisorContainer>
    </NavSearch>