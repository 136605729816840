import styled, { css } from "styled-components"
import { iconColor, blackColor } from '../../../constanst'

export const TableContainer = styled.div`
    margin-top: 15px;
    width: 100%;
`
export const Table = styled.table`
    border-collapse: collapse;
    font-size: 12px;
    color: ${blackColor};
    width: 100%;
    text-align: left;
`
export const Tr = styled.tr`
    :nth-child(even){background-color: #eeeeeefe; }
`
export const Th = styled.th`
    background-color: #efefefef;
    padding: 8px 15px;
    border-bottom: 1px solid ${iconColor};

    ${({ textAlign }) => textAlign && css`text-align: ${textAlign};` }
    ${({ width }) => width && css`width: ${width};` }
`
export const Td = styled.td`
    padding: 8px 15px;
    border-bottom: 1px solid ${iconColor};
    ${({ textAlign }) => textAlign && css`text-align: ${textAlign};` }
`
export const Subtitle = styled.h4`
    color: ${blackColor};
    padding-bottom: 10px;
    margin: 0px;
    text-align: center;
`
export const Title = styled.h3`
    color: ${blackColor};
    padding-bottom: 10px;
    margin: 0px;
    text-align: center;
`
export const ContainerButton = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: ${({ justify }) => justify ? justify : 'space-between' } ;
    padding: 20px;
    ${({width}) => width && css`width: ${width};`}
`
export const Form = styled.form`
    display: flex;
    flex-direction: column;
`
export const FormContent = styled.div`
    display: flex;
`
export const LabelOption = styled.label`
    color: ${blackColor};
    padding: 5px 10px;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif;
    font-size: 12px;
    font-weight: 500;
`
export const Flex = styled.div`
    display: flex;
    align-items: center;
    transition: 1s;
    border-radius: 15px;
`
/** Tooltips */
export const Tooltip = styled.div`
    position: relative;
    display: inline-block;
        
    &:hover { cursor: pointer; }
  `
export const Box = styled.div`
    display: flex;
    ${({flex}) => css`flex: ${flex};`}
    ${({width}) => css`width: ${width};`}
    ${({mHeight}) => css`max-height: ${mHeight};`}
    ${({direction}) => css`flex-direction: ${direction};`}
    align-items: ${({align}) => align ? align : 'flex-start'};
    ${({justify}) => css`justify-content: ${justify};`}
    ${({position}) => css`position: ${position};`}
    ${({top}) => css`top: ${top};`}
    ${({bottom}) => css`bottom: ${bottom};`}
    ${({left}) => css`left: ${left};`}
    ${({bgColor}) => css`background-color: ${bgColor};`}
    ${({shadow}) => css`box-shadow: ${shadow};`}
    ${({zIndex}) => css`z-index: ${zIndex};`}
    ${({radius}) => css`border-radius: ${radius};`}
    ${({padding}) => css`padding: ${padding};`}
    ${({overflow}) => css`overflow-y: ${overflow};`}
`
export const TooltipText = styled.div`
    visibility: hidden;
    width: 120px;
    background-color: ${({bgColor}) => bgColor };
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -5px;
    right: ${({ position }) => position ? position : '110%'};

    &:after {
        content: "";
        position: absolute;
        top: 50%;
        ${({arrowPosition}) => arrowPosition === "left" ? css`right: 100%` : css`left: 100%`};
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: ${({arrowPosition, bgColor}) => 
            arrowPosition === "left" ?
            `transparent ${bgColor} transparent transparent;`
            :
            `transparent transparent transparent ${bgColor};`
        }
    }
`
