import React from 'react'
import styled, { css } from 'styled-components'
import { secundaryColor, blackColor, bgVariantColor, iconColor, primayVariantColor,successColor, alternateVariantColor  } from '../../../constanst'
import Scrollbars from 'react-custom-scrollbars'
import { MenuItem } from '@material-ui/core'
import CustomSelect from '../../common/CustomSelect'
import InputText from '../../common/InputText'
import ButtonSubmit from '../../common/ButtonSubmit'
import { IconSearch } from '../../../assets/icons/index'
import { DivisorContainer, Row, InputContainer } from '../../components/trabajos/Styles'

export default({  onFilter, onKeyPressFilter, onChangeSel, onClickFindDate, state, onChange }) => <Main>
<Scrollbars className="scroll" style={{flex: 1}}>
    <Container>
        <DivisorContainer borderC={iconColor} direction="column">
            <Row>
                <InputContainer>
                    <InputText icon={<IconSearch width="20px" height="20px" color={blackColor} />}
                        placeholder="Consecutivo" name="ser_consecutivo" placeholderColor={iconColor} borderC={iconColor}
                        backgroundC={secundaryColor} textColor={iconColor}
                        onChange={onChange} onSearch={onFilter} onKeyPress={onKeyPressFilter} value={state.ser_consecutivo}/>
                </InputContainer> 
                <InputContainer>
                    <InputText icon={<IconSearch width="20px" height="20px" color={blackColor} />}
                        placeholder="Placa" name="ds_placa" placeholderColor={iconColor} borderC={iconColor}
                        backgroundC={secundaryColor} textColor={iconColor}
                        onChange={onChange} onSearch={onFilter} onKeyPress={onKeyPressFilter} value={state.ds_placa}/>
                </InputContainer>
                <InputContainer>
                    <CustomSelect inputw="100%" name="ser_estado" onChange={onChangeSel} value={state.ser_estado} disabled={state.selectDisabled} load={state.selectDisabled}>
                        <MenuItem value="all">Todos</MenuItem>
                        <MenuItem value="0">Pendientes</MenuItem>
                        <MenuItem value="2">Cerrados</MenuItem>
                    </CustomSelect>
                </InputContainer>
            </Row>
        </DivisorContainer>
        <DivisorContainer>
            <InputContainer>
                <InputText
                    type="date" name="dateStart" placeholderColor={iconColor} onChange={onChange}
                    backgroundC={secundaryColor} textColor={iconColor} borderC={iconColor} value={state.dateStart}
                />
            </InputContainer>
            <InputContainer>
                <InputText
                    type="date" name="dateFinal" placeholderColor={iconColor} onChange={onChange}
                    backgroundC={secundaryColor} textColor={iconColor} borderC={iconColor} value={state.dateFinal}
                />
            </InputContainer>
            <InputContainer align="flex-end">
                <ButtonSubmit label={window.screen.width > 600 ? "Buscar por fecha" : "Buscar"} bgColor={successColor} color={secundaryColor} onClick={onClickFindDate} type="button" width={window.screen.width > 600 ? 'auto' : '100%'} />
            </InputContainer>
        </DivisorContainer>
        <Title>Solicitudes Pendientes</Title>
            <TableContainer>
                <Subtitle>HISTORIAL DE SOLICITUDES</Subtitle>
                    <Table>
                        <thead>
                            <Tr>
                                <Th width='10%'>#</Th>
                                <Th width='50%'> Asunto </Th>
                                <Th width='20%'>Nombre del Técnico</Th>
                                <Th width='10%'>Acciones</Th>
                            </Tr>
                        </thead>
                    <tbody>              
                            <Tr >
                                <Td>1</Td>
                                <Td><TextBox>Aquí va la descripción</TextBox> </Td>
                                <Td>Manolo Carlitos Pachecho Molina </Td>
                                <Td>
                                    <Button bgColor={alternateVariantColor}>Aceptar</Button>
                                </Td>                          
                            </Tr>
                    </tbody>
                    </Table>  
            </TableContainer>

            {/** Formulario */}
        </Container>
    </Scrollbars>
</Main>


/** Botones */
const Button = styled.button`
    background-color: ${ ({ bgColor }) => bgColor };
    border-radius: 25px;
    outline: none;
    border: none;
    color: #ffffff;
    padding: 8px;
    cursor: pointer;
`

/** Caja de texto */
const TextBox = styled.div`
    border-radius: 10px;
    border: 1px solid #999;
    text-align: center;
    height: 50px;
    margin: 0px;
    padding: 0px;
    display: flex;
`

/** Contenedores */
const Main = styled.div`
    flex: 1;
    position: relative;
`
const Container = styled.div`
    background-color: ${bgVariantColor};
    margin: 0px 3%;
    height: 100;
    box-shadow: 0px 0px 8px 2px rgba(0,0,0,.4);
    margin-bottom: 40px;
`

/** Titulo */
const Title = styled.h3`
    color: ${({color}) => color ? color : secundaryColor};
    padding: 5px;
    text-align: center;
    background-color: ${({bgcolor}) => bgcolor ? bgcolor : primayVariantColor};
`

/** Tablas */
const TableContainer = styled.div`
    margin-top: 15px;
    width: 100%;
`
const Table = styled.table`
    border-collapse: collapse;
    font-size: 12px;
    color: ${blackColor};
    width: 100%;
    text-align: left;
`
export const Tr = styled.tr`
    :nth-child(even){background-color: #eeeeeefe; }
`
export const Th = styled.th`
    background-color: #efefefef;
    padding: 8px 15px;
    border-bottom: 1px solid ${iconColor};     
`
export const Td = styled.td`
    padding: 13px 10px 15px 20px;
    border-bottom: 0px solid ${iconColor};
    ${({ textAlign }) => textAlign && css`text-align: ${textAlign};` }
`
export const Subtitle = styled.h4`
    color: ${blackColor};
    padding-bottom: 10px;
    margin: 10px;
`