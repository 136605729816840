import React from 'react'
import styled, { css } from 'styled-components'
import { blackColor, primayVariantColor, iconColor, iconVariantColor } from "../../constanst"
import { PDFDownloadLink } from '@react-pdf/renderer'
import { IconClient, IconTec, IconReport } from '../../assets/icons'
import ServiceOrder from '../print/ServiceOrder'
import CustomerTracking from '../print/CustomerTracking'


export default ({onClickModal, onCloseModal, typeUser, state, orderRoad}) => 
    <ContainerModal id="modalimp">
        <ModalContent>
            <HeaderContainer>
                <Title>SELECCIONE PARA IMPRIMIR</Title>
            </HeaderContainer>
            <BodyContainer>
                {/* <Client>
                    <IconClient width={"80px"} height={"80px"} color={iconColor} />
                    <ButtonModal bgColor={primayVariantColor} color={iconVariantColor} onClick={ e => onClickModal(e, 'cliente')} fontSize="12px">Generar PDF</ButtonModal>
                </Client> */}
                <Client centerModal='true'>
                    <IconClient width={"80px"} height={"80px"} color={iconColor} />
                    <ButtonModal bgColor={primayVariantColor} >
                        <PDFDownloadLink style={{ color: '#ffffff', textDecoration: 'none', fontSize: 12, fontWeight: 300 }} document={<CustomerTracking state={state} />} fileName={`constancia de ingreso clientes - ${state.clif.formcosn}.pdf`}>
                            {({ loading }) => (loading ? 'Cargando' : 'Constancia de Ingreso')}
                        </PDFDownloadLink>
                    </ButtonModal>
                </Client>
                {typeUser === "1" &&
                <Client>
                    <IconReport width={"80px"} height={"80px"} color={iconColor} />
                    <ButtonModal bgColor={primayVariantColor} >
                        <PDFDownloadLink style={{ color: '#ffffff', textDecoration: 'none', fontSize: 12, fontWeight: 300 }} document={<ServiceOrder state={state} />} fileName={`orden de servicio - ${state.clif.formcosn}.pdf`}>
                            {({ loading }) => (loading ? 'Cargando' : 'Orden de servicio')}
                        </PDFDownloadLink>
                    </ButtonModal>
                    {/* <ButtonModal bgColor={primayVariantColor} color={iconVariantColor} onClick={ e => onClickModal(e, 'service')} fontSize="12px">Generar PDF</ButtonModal> */}
                </Client>
                }
                {!orderRoad && <Client>
                    <IconTec width={"80px"} height={"80px"} color={iconColor} />
                    <ButtonModal bgColor={primayVariantColor} color={iconVariantColor} onClick={ e => onClickModal(e, 'tecnico')} fontSize="12px">Orden de trabajo</ButtonModal>
                </Client>}
            </BodyContainer>
            <FooterContainer>
                <ButtonModal bgColor={iconVariantColor} onClick={(e) => onCloseModal(e, "modalimp")}>Cerrar</ButtonModal>
            </FooterContainer>
        </ModalContent>
    </ContainerModal>

const ContainerModal = styled.div`
    display: flex;
    flex: 1;
    position: absolute;
    z-index: 999;
    top: 0px;
    left: 0px;
    background-color: rgba(0,0,0,.25);
    width: 100%;
    height: 100%;
    padding-top: 20px;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
`
const ModalContent = styled.div`
    /* flex: min-content; */
    width: 50%;
    height: min-content;
    border-radius: 15px;
    background-color: ${iconVariantColor};
`
const HeaderContainer = styled.div`
    padding: 15px 25px;
    border-bottom: 1px solid ${iconColor};
`
const Title = styled.h4`
    color: ${blackColor};
    margin: 0;
    font-weight: 500;
`
const BodyContainer = styled.div`
    padding: 25px;
    display: flex;
`
const Client = styled.div`
    flex-direction: column;
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    ${ ({ centerModal }) => centerModal && css`margin: auto;` }
`
const FooterContainer = styled.div`
    padding: 8px;
    background-color: ${primayVariantColor};
    display: flex;
    justify-content: flex-end;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
`
const ButtonModal = styled.button`
    background-color: ${({ bgColor }) => bgColor};
    color: ${({ color }) => color ? color : blackColor};
    font-size: ${({ fontSize }) => fontSize ? fontSize : '16px'};
    border-radius: 5px;
    padding: 8px 15px;
    border: none;
    margin: 5px 15px;
    outline: 0;

    &:hover {cursor: pointer;}
`
