import React from 'react'
import styled, { css } from 'styled-components'

import { secundaryColor, primayColor, iconColor, blackColor } from '../../../constanst'
import ModuleInput from '../../common/ModuleInput'
import { Box } from "./ComponentsForm"
import ButtonSubmit from '../../common/ButtonSubmit'
import CustomSelect from '../../common/CustomSelect'
import { MenuItem } from '@material-ui/core'

const ClientForm = ({ state, autoCom, errorM, clientList, menuActive, onChange, onBlur, onClickSelect, onChangeSelect, leftMode }) => (
        <ModuleContainer width={leftMode ? '100%' : 'auto'}>
            <Title bgcolor="transparent" color={blackColor}>IDENTIFICACIÓN DEL CLIENTE</Title>
                <Box justify="space-between" direction="row">
                    <Box direction="column" position="relative" width="40%">
                        <ModuleInput
                            label="CÉDULA" type="number" name="formcedula" tAlign='left'
                            backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="100%"
                            onChange={e => onChange(e, 1, true, false, true, true, 5, 20)} value={state.formcedula || ''}
                            onBlur={onBlur} errorM={errorM} autoCom={autoCom}
                        />
                        {menuActive &&
                        <Box position="absolute" direction="column" top="80px" bgColor="#FFFFFF" left="15px" width="500px" shadow="1px 1px 5px 2px rgba(0,0,0,.5)" zIndex="100" radius="4px" padding="5px" overflow="auto" mHeight="300px">
                            {!!clientList &&
                                clientList.map( x => (
                                    <ButtonSubmit tonSubmit color={blackColor} key={x.clit_id} textAlign='left' hColor="#cdcdcd" radius="none" type="button" bgColor="#ffffff"
                                    label={`${x.clit_identificacion} - ${x.clit_nombre} ${x.clit_apellido}`} onClick={e => onClickSelect(e, x.clit_identificacion, 1)} />
                                ))
                            }
                        </Box>
                        }
                    </Box> 
                    <ModuleInput tAlign='left' label="NOMBRE DEL PROPIETARIO (TP)" type="text" name="formnombre" onBlur={e => onChange(e, 1, true)}
                        backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="60%" onFocus={e => onChange(e, 1, true)}
                        onChange={e => onChange(e, 1, true, false, false, true, 2, 20)} value={state.formnombre || ''}
                    />
                </Box>
                <Box justify="space-between" direction="row">
                    <ModuleInput tAlign='left' label="APELLIDOS" type="text" name="formapellido" onBlur={e => onChange(e, 1, true)}
                        backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="50%" onFocus={e => onChange(e, 1, true)}
                        onChange={e => onChange(e, 1, true, false, false, true,  2, 20)} value={state.formapellido || ''}
                    />
                    <ModuleInput tAlign='left' label="DIRECCIÓN" type="text" name="formdireccion" onBlur={e => onChange(e, 1, true)}
                        backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="50%" onFocus={e => onChange(e, 1, true)}
                        onChange={e => onChange(e, 1, true, false, false, true, 2, 50)} value={state.formdireccion || ''}
                    />
                </Box>
                <Box justify="space-between" direction="row">
                    <ModuleInput tAlign='left' label="E-MAIL" type="text" name="formemail" onBlur={e => onChange(e, 1, true)}
                        backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="100%" onFocus={e => onChange(e, 1, true)}
                        onChange={e => onChange(e, 1, true, false, false, false, false, false, true)} value={state.formemail || ''}
                    />
                    <ModuleInput tAlign='left' label="PROFESIÓN" type="text" name="formprofesion" onBlur={e => onChange(e, 1, true)}
                        backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="100%" onFocus={e => onChange(e, 1, true)}
                        onChange={e => onChange(e, 1, true, false, false, true, 2, 30)} value={state.formprofesion || ''}
                    />
                </Box>
                <Box justify="space-between" direction="row">
                    <ModuleInput tAlign='left' label="CELULAR" type="number" name="formcelular" onBlur={e => onChange(e, 1, true)}
                        backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="100%" onFocus={e => onChange(e, 1, true)}
                        onChange={e => onChange(e, 1, true, false, true, true, 2, 15)} value={state.formcelular || ''}
                    />
                    <ModuleInput tAlign='left' label="TELÉFONO ALTERNATIVO" type="number" name="formtelefono" onBlur={e => onChange(e, 1, true)}
                        backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="100%" onFocus={e => onChange(e, 1, true)}
                        onChange={e => onChange(e, 1, true, false, true, true, 2, 15)} value={state.formtelefono || ''}
                    />
                </Box>
                <Box justify="space-between" direction="row">
                    <CustomSelect label="RED SOCIAL" inputw="40%" name="formtipors" data-ignore={true} onChange={e => onChangeSelect(e, 1)} value={state.formtipors || ''} > 
                        <MenuItem value="FACEBOOK">FACEBOOK</MenuItem>
                        <MenuItem value="TWITER">TWITTER</MenuItem>
                        <MenuItem value="INSTAGRAM">INSTAGRAM</MenuItem>
                    </CustomSelect>
                    <ModuleInput tAlign='left' label="USUARIO" type="text" name="formrs" onBlur={e => onChange(e, 1, true)} ignore={true}
                        backgroundC={secundaryColor} borderC={iconColor} width="100%" inputw="60%" onFocus={e => onChange(e, 1, true)}
                        onChange={e => onChange(e, 1, true, false, false, true, 2, 15)} value={state.formrs || ''}
                    />
                </Box>
        </ModuleContainer>
)

export default ClientForm

const ModuleContainer = styled.div`
    flex: 1;
    padding: 0 10px 0px 20px;
    display: flex;
    flex-direction: column;
    
    width: ${ props => props.width };
    ${({ borderLeft }) => borderLeft && css`border-left: 1px solid ${primayColor};`}
`
const Title = styled.h3`
    color: ${({color}) => color ? color : secundaryColor};
    padding: 5px;
    margin-top: 0px;
    text-align: center;
`