import React from 'react'
import styled, { css } from 'styled-components'
import Scrollbars from 'react-custom-scrollbars'
import { primayVariantColor, iconColor, blackColor } from '../../constanst'

export default ({ renderBody, renderHead, renderButtons, minWidth }) =>
    <TableContainer>
        {renderButtons}
        <Scrollbars autoHeight autoHide autoHeightMin={0} autoHeightMax='100%'>
            <Table minWidth={minWidth}>
                <thead>
                    {renderHead}
                </thead>
                <tbody>
                    {renderBody}
                </tbody>
            </Table>   
        </Scrollbars>
    </TableContainer>


/** Tabla con los servicios detallados */
const TableContainer = styled.div`
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-left: 10px;
`

const Table = styled.table`
    overflow: hidden;
    border-collapse: collapse;
    font-size: 12px;
    width: 99%;
    min-width: ${({minWidth}) => minWidth ? minWidth : 'auto'};
    min-height: 100px;
`

/** Constants export for use from other components */
export const Tr = styled.tr`
    ${({ bgColor }) => bgColor && css`background-color: ${primayVariantColor}50;`}
    border-bottom: 1px solid ${iconColor}50;
`
export const Th = styled.th`
    font-weight: 500;
    color: ${primayVariantColor};
    padding: 5px;
    text-align: center;
    border-left: 1px solid ${blackColor}80;

    &:first-child {border-left: 0px;}
`
export const Td = styled.td`
    text-align: ${({align}) => align ? align : 'center'};
    color: ${blackColor};
    padding: 5px 0px;
    margin: 5px 0px;
    ${({ align }) => align && css`padding-left: 20px;`}
    ${({ maxWidth }) => maxWidth && css`max-width: ${maxWidth};`}
    ${({ bold }) => bold && css`font-weight: ${bold};`}
    ${({ color }) => color && css`color: ${color}aa;`}
`
export const ButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: ${({justify}) => justify ? justify : 'flex-end'};
    padding-bottom: 10px;
    margin-top: 40px;
    padding: 10px;
`
export const ButtonIcon = styled.button`
    border: none;
    outline: 0;
    background-color: transparent;

    &:hover {cursor: pointer;}
`