import React from 'react'
import styled, { css } from 'styled-components'
import { blackColor,  } from "../../constanst"

export default ({ label, type, icon, bgColor, color, width, onClick, marginV, borderC }) =>
    <ButtonContainer bgColor={bgColor} width={width} padding={ icon && true } onClick={onClick} onPointerUp={onPointer} marginV={marginV} borderC={borderC}>
        <IconContainer>
            { icon && icon }
        </IconContainer>
        <Label type={type} color={color}>{label}</Label>
    </ButtonContainer>


const ButtonContainer = styled.button`
    position: relative;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    background-color: ${({ bgColor }) => bgColor ? bgColor : 'transparent'};
    width: ${({ width }) => width ? width : 'auto'};
    border-radius: 15px;
    border: ${({borderC }) => borderC ? `1px solid ${borderC}` : 'none' };
    outline: 0px;
    padding: 8px 10px;
    ${({ padding }) => padding && css`padding-left: 30px;`}
    margin: ${({marginV}) => marginV ? `${marginV} 10px` : '0px 10px'};

    &:hover {cursor: pointer;}
`
const IconContainer = styled.div`
    position: absolute;
    left: 8px;
    top: 8px;
`
const Label = styled.label`
    color: ${({ color }) => color ? color : blackColor};

    &:hover {cursor: pointer; }
`


const onPointer = e => {
    let span = document.createElement('span')
    span.classList.add('buttonEffect')
    e.target.appendChild(span)
   //  x cordinate
   let x =  e.nativeEvent.offsetX
   let y = e.nativeEvent.offsetY
 
   span.style.left = `${x}px`
   span.style.top = `${y}px`
 
   // removing it after some time
   setTimeout(() => {
     span.remove()
   }, 500)
}