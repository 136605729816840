import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import { blackColor } from '../../constanst'

export const InputContainer = ({ width, label, value }) =>
    <View style={[ styles.inputContainer,  {width } ]}>
        <Text style={styles.label}>{label}</Text>
        <Text style={styles.text}>{value}</Text>
    </View>

export const TableChild = ({ value, width, fontWeight, textAlign = 'center' }) => 
    <View style={{ width, borderBottomWidth : .8,  borderBottomColor: '#B4B4B4' }}>
        <Text style={[styles.tableText, {fontWeight, textAlign}]}>{value}</Text>
    </View>

export const TableChildBorder = ({ value, width, fontWeight, children, borderLeft, bgColor }) => 
    <View style={[styles.containerTCB, { width, borderLeftWidth: borderLeft ? 1 : 'none', backgroundColor: bgColor } ]}>
        {value && <Text style={[styles.tableText, {fontWeight, textAlign: 'left'}]}>{value}</Text>}
        {children}
    </View>

const styles = StyleSheet.create({
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    paddingHorizontal: 10
  },
  text: {
    borderRadius: 16,
    borderWidth: 1,
    borderColor: blackColor,
    color: blackColor,
    fontSize: 6,
    paddingVertical: 4,
    marginTop: 1,
    paddingHorizontal: 6
  },
  label: {
    fontSize: 6,
    color: blackColor,
    fontWeight: 500
  },
  containerTCB: {
    borderBottomWidth: .8, 
    borderBottomColor: '#B4B4B4', 
    borderRightWidth: .8, 
    borderRightColor: '#B4B4B4', 
    alignItems: 'center', 
    justifyContent: 'center', 
    borderLeftColor: '#B4B4B4'
  },
  tableText: {
    fontSize: 6,
    color: blackColor,
    paddingVertical: 4,
    paddingHorizontal: 6,
    textAlign: 'center'
  }
})