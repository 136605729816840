import React from 'react'
import { FormControl, Select, MenuItem } from '@material-ui/core'
import { iconColor, blackColor } from '../../constanst'

export default ({disabled, name, key, value, title, onChange, children, margin, inputw}) => {   
const styles = {
    select: {
        borderRadius: 25, backgroundColor: '#ffffff', border: `1px solid ${iconColor}`, outline: 0, fontSize: 12, color: blackColor, paddingLeft: 15, margin: margin ? margin : "10px 0",
    }
}
    return (
    <FormControl style={{width: inputw}}>
        <Select
            multiple
            disabled={disabled}
            displayEmpty
            name={name}
            key={key}
            value={!!children.length ? (value || []) : [0]}
            onChange={onChange}
            style={styles.select}
        >
            <MenuItem value="0">{!!title ? title : "SELECCIONAR"}</MenuItem>
            {children}
        </Select>
    </FormControl>
    )
}
