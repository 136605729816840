import React from 'react'
import styled, { css } from 'styled-components'
import { openModal } from "../../common/Modal"

import { primayColor, iconColor, blackColor } from '../../../constanst'
import { TableContainer, Table, Tr, Th, Td, Subtitle, FormContent } from './ComponentsForm'

export default  ({ state, firmacliente, onChange, params, stateOrder }) => (
    <FormContent>
        <ModuleContainer>
            <Subtitle>TÉRMINOS Y CONDICIONES</Subtitle>
            <Paragraph>
                <b>Términos y condiciones de garantía</b><br />
                Si la reparación y/o reemplazo de pieza(s) relacionados en la presente orden de salida se efectúa por garantía, estos tendrán un término de garantía 
                de 12 meses y/o 20.000 Kilómetros, lo primero que se cumpla, contados a partir de la entrega del vehículo y firma de esta orden y en caso de no ser 
                retirado el mismo en el tiempo que se de para este efecto el término de garantía comenzará a correr el primer día calendario siguiente al vencimiento 
                de este. Para las reparaciones en general no se instalarán repuestos que no sean comprados en INCOLMOTOS y/o MOTOEXPLORER, toda reparación tendrá una
                garantía de cuarenta y ocho (48) horas. Para cualquier reclamo presente su factura de venta con el sello de la caja. Si la reparación, servicio y/o 
                reemplazo de piezas no se efectúa por garantía, estos tendrán un termino de garantía de (48) horas contados a partir de la entrega del vehículo y firma 
                de esta orden, y en caso de no ser retirado el mismo, en el tiempo que se dé para este efecto, el término de garantía comenzará a correr el primer 
                día calendario siguiente al vencimiento de este. Su reconocimiento estará sujeto a que la pieza(s) sea manipulada, ensamblada o instalada en los centros
                de Servicio Técnico directos de Incolmotos o los autorizados por el mismo; o que el servicio se realice en estos, siempre que se cumplan por parte del
                comprador las recomendaciones de uso dadas por el vendedor. Las presentes condiciones se entienden comprendidas y aceptadas irrevocablemente con la 
                firma de esta orden de salida, la cual deberá en todo caso ser presentada para el reconocimiento de la garantía.
            </Paragraph>
            <TableContainer>
                <Table>
                    <thead>
                        <Tr>
                            <Th textAlign="center">SI</Th>
                            <Th textAlign="center">NO</Th>
                            <Th textAlign="center" width="90%" colSpan="2">AUTORIZACIÓN</Th>
                            
                        </Tr>
                    </thead>
                    <tbody>
                        <Tr style={{transition: '1s'}}>
                            <Td><input type="radio" name="oneItem" value="1" checked={state.oneItem === "1"} onChange={e => onChange(e, 6)} data-ignore={false} /></Td>
                            <Td><input type="radio" name="oneItem" value="2" checked={state.oneItem === "2"} onChange={e => onChange(e, 6)} data-ignore={false} /></Td>
                            <Td>Autorizo a que INCOLMOTOS y/o MOTOEXPLORER, realice prueba de ruta a mi motocicleta cuando lo considere necesario y bajo su responsabilidad.</Td>
                            <Td rowSpan="3" textAlign="center">
                                <Firma type="button" onClick={() => openModal("modalFirma")} id="imgfirma" disabled={params.id && stateOrder !== '6' ? true : false}> 
                                    { firmacliente && <ImagenFirma src={firmacliente} style={{ width: "100%", height:"100%" }} alt="" />}
                                </Firma>
                                <Label>AUTORIZO</Label>
                            </Td>
                        </Tr>
                        <Tr style={{transition: '1s'}}>
                            <Td><input type="radio" name="secItem" value="1" checked={state.secItem === "1"} onChange={e => onChange(e, 6)} data-ignore={false} /></Td>
                            <Td><input type="radio" name="secItem" value="2" checked={state.secItem === "2"} onChange={e => onChange(e, 6)} data-ignore={false} /></Td>
                            
                            <Td>Autorizo a que INCOLMOTOS y/o MOTOEXPLORER, me informe telefónicamente y/o envíe correo electrónico o fax informando los cambios en las condiciones
                                 pactadas al momento del ingreso con respecto al valor de la mano de obra, repuestos y fecha de entrega.</Td>
                        </Tr>
                        <Tr style={{transition: '1s'}}>
                            <Td><input type="radio" name="terItem" value="1" checked={state.terItem === "1"} onChange={e => onChange(e, 6)} data-ignore={false} /></Td>
                            <Td><input type="radio" name="terItem" value="2" checked={state.terItem === "2"} onChange={e => onChange(e, 6)} data-ignore={false} /></Td>
                            <Td>
                                Autorizo a que INCOLMOTOS y/o MOTOEXPLORER, disponga de los repuestos usados/cambiados durante la presente reparación.
                            </Td>
                        </Tr>
                    </tbody>
                </Table>
            </TableContainer>

        </ModuleContainer>
        </FormContent>
)



const ModuleContainer = styled.div`
    flex: 1;
    padding: 0 10px 0px 20px;
    display: inline-block;
    
    ${({ borderLeft }) => borderLeft && css`border-left: 1px solid ${primayColor};`}
`
const Paragraph = styled.p`
    color: ${blackColor};
    font-size: 12px;
    text-align: justify;
`
const Firma = styled.button`
    border-radius: 15px;
    border: 1px solid ${iconColor};
    width: 200px;
    height: 140px;
    outline: 0;
    padding: 10px;

    &:disabled {cursor: no-drop;}
`
const Label = styled.label`
    color: ${blackColor};
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    padding-top: 10px;
    width: 100%;
    display: block;
`
const ImagenFirma = styled.img`
    border: none;
    border-radius: 15px;
`