import React from 'react'
import styled, { css } from 'styled-components'

import { secundaryColor, primayColor, iconColor, blackColor, errorColor, alternateVariantColor } from '../../../constanst'
import { TableContainer, Table, Tr, Th, Td, Subtitle, ContainerButton, Box } from './ComponentsForm'
import ButtonSubmit from '../../common/ButtonSubmit'
import { IconDelete, IconEdit } from '../../../assets/icons'
import { Button } from '@material-ui/core'


export default  ({ state: {solicitudform, clienteSol, editSol}, onClickButton, onChangeMultiple, onKeyPress, onDel, onUpdate, onCanUp }) => (
        <ModuleContainer>
            <Subtitle>EL CLIENTE SOLICITA</Subtitle>
            <Box justify="space-between" direction="row">
                <InputContainer inputw="70%">
                    <Label>SOLICITUD DEL CLIENTE</Label>
                    <TextArea name="formsolicitudes" data-ignore="true" rows="5"
                        onChange={e => onChangeMultiple(e, false, false, false, true, 2, 500, false, 1)} value={clienteSol.formsolicitudes || ''}
                        onKeyPress={ e => onKeyPress(e, "btnSolicitud")}
                    />
                    <Error/>
                </InputContainer>
                <InputContainer inputw="70%">
                    <Label>SOLUCIÓN DADA</Label>
                    <TextArea name="formsolucion" data-ignore="true" rows="5"
                        onKeyPress={ e => onKeyPress(e, "btnSolicitud")}
                        onChange={e => onChangeMultiple(e, false, false, false, true, 2, 500, false, 1)} value={clienteSol.formsolucion || ''} 
                    />
                    <Error />
                </InputContainer>
                
            </Box>
            
            <ContainerButton>
                <ButtonSubmit width="200px" label={editSol ? 'Actualizar' : "Agregar"} id="btnSolicitud" bgColor={blackColor} color={secundaryColor} type="button" onClick={e => onClickButton(e, editSol ? 3 : 1)} />
                {editSol && 
                    <ButtonSubmit width="200px" label="Cancelar" id="btnSolicitud" bgColor={errorColor} color={secundaryColor} type="button" onClick={onCanUp} />
                }
            </ContainerButton>

            <TableContainer>
                <Subtitle>LISTA DE SOLICITUDES</Subtitle>
                <Table>
                    <thead>
                        <Tr>
                            <Th>#</Th>
                            <Th>Solicitud</Th>
                            <Th>Solución</Th>
                            <Th></Th>
                        </Tr>
                    </thead>
                    <tbody>
                        {solicitudform.clienteSolicitud.length > 0 ?
                            solicitudform.clienteSolicitud.map( (element, index) => (
                                <Tr key={index}>
                                    <Td>{index + 1}</Td>
                                    <Td>{element.formsolicitudes}</Td>
                                    <Td>{element.formsolucion}</Td>
                                    <Td style={{textAlign: 'center'}}>
                                        {element.idobs &&
                                        <Button onClick={e => onUpdate(e, element.idobs)}>
                                            <IconEdit width="25px" height="25px" color={alternateVariantColor} bgColor="transparent" />
                                        </Button>}
                                        <Button onClick={e => onDel(e,index, 1, element.idobs)}>
                                            <IconDelete width="25px" height="25px" color={errorColor} bgColor="transparent" />
                                        </Button>
                                    </Td>
                                </Tr>
                            ))
                            : <Tr><Td textAlign="center" colSpan="4">No hay solicitudes para mostrar</Td></Tr>
                        }
                    </tbody>
                </Table>
            </TableContainer>

        </ModuleContainer>

)


const ModuleContainer = styled.div`
    flex: 1;
    width: 100%;
    padding: 0 10px 0px 20px;
    display: inline-block;
    
    ${({ borderLeft }) => borderLeft && css`border-left: 1px solid ${primayColor};`}
`
const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: ${({inputw}) => inputw };
    padding: 0 10px;
`
const Label = styled.label`
    color: ${blackColor};
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif;
    font-size: 12px;
    font-weight: 500;
`
const TextArea = styled.textarea`
    color: ${blackColor};
    padding: 10px;
    border-radius: 5px;
    border: 1px solid ${iconColor};
    margin: 10px 0px;
    resize: none;
    outline: 0;

    :focus {border: 1px solid ${blackColor}};
`
const Error = styled.span`
    padding-right: 10px;
    align-self: flex-end;
    color: ${errorColor};
    font-size: 10px;
`