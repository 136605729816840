import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { blackColor, primayColor, primayVariantColor, successColor, errorColor, alternateColor } from '../../constanst'
import CheckImg from '../../assets/imagenes/check.jpg'
// import Logo from '../../assets/imagenes/logomotoexplorer.jpg'
import Head from './Head'
import { InputContainer, TableChild, TableChildBorder } from './utils'

export default ({ state: { clif, vf, sf, tecn, solicitudform, inventario, inventarioform, comments, termsform, cond, resSede}}) => {
    let inventario1 = [], inventario2 = [] 
    if(!!inventarioform){
        inventario1 = inventario.filter( (x, i) => (i < (inventario.length / 2) && x ))
        inventario2 = inventario.filter( (x, i) => (i >= (inventario.length / 2) && x ))
    }

return(
    <Document>
        <Page size="A4" style={styles.page}>
             {/** Cabecera */}
            <Head title='ORDEN DE SERVICIO' sede={resSede.sed_nombre} direction={resSede.sed_direccion} phone={resSede.sed_telefono}/>
            <Text style={[styles.title, {color: primayVariantColor}]}>{clif.formcosn}</Text>
            <View style={styles.box}>
                {/** informaciön del cliente */}
                <View style={styles.section}>
                    <Text style={styles.title}>IDENTIFICACIÓN DEL CLIENTE</Text>
                    <View style={styles.box}>
                        <InputContainer label="NOMBRE DEL PROPIETARIO (TP)" value={clif.formnombre} width="50%" />
                        <InputContainer label="APELLIDOS" value={clif.formapellido} width="50%" />
                    </View>
                    <View style={styles.box}>
                        <InputContainer label="CÉDULA" value={clif.formcedula} width="30%" />
                        <InputContainer label="DIRECCIÓN" value={clif.formdireccion} width="70%" />
                    </View>
                    <View style={styles.box}>
                        <InputContainer label="E-MAIL" value={clif.formemail} width="70%" />
                        <InputContainer label="CELULAR" value={clif.formcelular ? clif.formcelular : 'N/A'} width="30%" />
                    </View>
                    <View style={styles.box}>
                        <InputContainer label="PROFESIÓN" value={clif.formprofesion ? clif.formprofesion : 'N/A'} width="70%" />
                        <InputContainer label="TELÉFONO" value={clif.formtelefono ? clif.formtelefono : 'N/A'} width="30%" />
                    </View>
                </View>
                <View style={styles.lineRed}></View>

                {/** informaciön de la moto */}
                <View style={styles.section}>
                    <Text style={styles.title}>IDENTIFICACIÓN DE LA MOTO</Text>
                    <View style={styles.box}>
                        <InputContainer label="TIPO MOTO" value={vf.formvehiculonombre} width="30%" />
                        <InputContainer label="PLACA" value={vf.formplaca} width="70%" />
                    </View>
                    <View style={styles.box}>
                        <InputContainer label="COLOR" value={vf.formcolor} width="60%" />
                        <InputContainer label="N° DE MOTOR" value={vf.formmotor} width="40%" />
                    </View>
                    <View style={styles.box}>
                        <InputContainer label="N° INCOLMOTOS" value={vf.formincolmotos ? vf.formincolmotos : 'N/A'} width="30%" />
                        <InputContainer label="KM" value={vf.formkm} width="30%" />
                        <InputContainer label="SERIE" value={vf.formserie} width="40%" />
                    </View>
                    <View style={styles.box}>
                        <InputContainer label="GASOLINA" value={vf.formgasolina === "BAJO" ? 'RESERVA' : vf.formgasolina} width="60%" />
                        <InputContainer label="CUSTODIA DE PAPELES" value={vf.custodia === '1' ? 'SI' : 'NO' } width="40%" />
                    </View>
                </View>
            </View>

            {/** informaciön de quiën solicita el servicio */}
            <View style={styles.sectionFull}>
                <Text style={styles.title}>IDENTIFICACIÓN DE QUIEN SOLICITA EL SERVICIO</Text>
                <View style={styles.box}>
                    <InputContainer label="NOMBRE COMPLETO" value={clif.formpersonaservicio} width="30%" />
                    <InputContainer label="CÉDULA" value={clif.formcedulaservicio} width="13%" />
                    <InputContainer label="E-MAIL" value={clif.formpersonaemail} width="30%" />
                    <InputContainer label="CELULAR" value={clif.formtelefonocelular} width="13%" />
                    <InputContainer label="TELÉFONO" value={clif.formpersonatelefono} width="13%" />
                </View>
            </View> 
            <View style={styles.lineRed}></View>
            
            {/** Detalles del servicio */}
            <View style={styles.sectionFull}>
                <Text style={styles.title}>DETALLES DEL SERVICIO</Text>
                <View style={styles.box}>
                    <View style={styles.section}>
                        <View style={styles.box}>
                            <InputContainer label="SEDE" value={sf.sed_nombre} width="50%" />
                            <InputContainer label="TÉCNICO" value={tecn} width="50%" />
                        </View>
                        <InputContainer label="SERVICIO" value={sf.tser_nombre} width="100%" />
                        <View style={styles.box}>
                            <InputContainer label="TRABAJO" value={sf.tg_nombre} width={sf.formTrabajogarantia === '4' ? '30%' : "100%"} />
                            {sf.formTrabajogarantia === '4' &&
                                <InputContainer label="TRABAJO ESPECIFICO" value={sf.formotrotrabajogarantia} width="70%" />
                            }
                        </View>
                    </View>
                    <View style={styles.lineRed}></View>
                    <View style={styles.section}>
                        <View style={styles.box}>
                            <InputContainer label="HORA DE ENTRADA" value={sf.formhoraentrada} width="50%" />
                            <InputContainer label="HORA DE SALIDA" value={sf.formhorasalida} width="50%" />
                        </View>
                        <View style={styles.box}>
                            <InputContainer label="VALOR ABONO" value={sf.formvalorabono} width="50%" />
                            <InputContainer label="N° DE FACTURA" value={sf.formfactura} width="50%" />
                        </View>
                        <View style={styles.box}>
                            <InputContainer label="ORDEN INTERNA" value={sf.formordeninterna} width="50%" />
                            <InputContainer label="FECHA TENTATIVA DE ENTREGA" value={sf.formentrega} width="50%" />
                        </View>
                    </View>
                </View>
            </View>

            <View style={styles.lineRed}></View>

             {/** Lita de solicitudes */}
             <View style={[styles.sectionFull, {marginTop: 8}]}>
                <Text style={[styles.title, {textAlign: 'left'}]}>EL CLIENTE SOLICITA</Text>
                <View style={[styles.box, {backgroundColor: '#dedede'}]}>
                    <TableChild value="#" fontWeight="bold" width="10%" />
                    <TableChild value="SOLICITUD" fontWeight="bold" width="45%" />
                    <TableChild value="SOLUCIÓN" fontWeight="bold" width="45%" />
                </View>
                {!!solicitudform && !!solicitudform.clienteSolicitud ?
                    solicitudform.clienteSolicitud.map((x, i) => (
                        <View key={x.idobs} style={styles.box}>
                            <TableChild value={i+1} width="10%" />
                            <TableChild value={x.formsolicitudes} width="45%" />
                            <TableChild value={x.formsolucion} width="45%" />
                        </View>
                    ))
                    : <Text style={[styles.label, {textAlign: 'center'}]}>No hay llamadas</Text>
                }
            </View>
            
            {/** Lita de llamadas */}
            <View style={[styles.sectionFull, {marginTop: 8}]}>
                <Text style={[styles.title, {textAlign: 'left'}]}>LLAMADAS</Text>
                <View style={[styles.box, {backgroundColor: '#dedede'}]}>
                    <TableChild value="#" fontWeight="bold" width="10%" />
                    <TableChild value="FECHA" fontWeight="bold" width="20%" />
                    <TableChild value="HORA" fontWeight="bold" width="20%" />
                    <TableChild value="COMENTARIOS" fontWeight="bold" width="50%" />
                </View>
                {!!solicitudform && !!solicitudform.clientellamadas ?
                    solicitudform.clientellamadas.map((x, i) => (
                        <View key={i} style={styles.box}>
                            <TableChild value={i+1} width="10%" />
                            <TableChild value={x.formfecha} width="20%" />
                            <TableChild value={x.time2} width="20%" />
                            <TableChild value={x.formcomentario} width="50%" />
                        </View>
                    ))
                : <Text style={[styles.label, {textAlign: 'center'}]}>No hay llamadas</Text>
                }
            </View>
            <View style={styles.lineRed}></View>

            {/** Teminos y Condiciones */}
            <View style={[styles.sectionFull, {marginTop: 8}]}>
                <Text style={[styles.title, {textAlign: 'left'}]}>TÉRMINOS Y CONDICIONES</Text>
                <Text style={[styles.tableText, {textAlign: 'justify'}]}>Si la reparación o reemplazo de piezas relacionados en la presente orden de salida se efectúa por garantía 
                de repuestos o por orden del FABRICANTE:  estos tendrán un término de garantía de 6 meses y/o 10.000 Kilómetros, lo primero que se cumpla, contados a partir de 
                la entrega del vehículo, en caso de no ser retirado en el  tiempo que se dé para este efecto, (24 horas) el término de garantía comenzará a correr el primer día
                calendario siguiente al vencimiento de este ,así el vehículo permanezca dentro de nuestras instalaciones y por cualquier motivo que el cliente exponga. {"\n"}
                Todo servicio por reparación por Mano de obra en nuestros talleres: tendrá una garantía de cuarenta y ocho (48 horas), 
                su reconocimiento estará sujeto a que la piezas instaladas correspondan al trabajo intervenido y sean  ensambladas o cambiados en cualquiera de nuestras sedes  
                siempre que se cumplan por parte del comprador las recomendaciones de uso , los cuidados y recomendaciones dadas por el vendedor, recepcionista o técnico de 
                servicios de nuestra Empresa; para su reclamo es indispensable presentar la factura de compra de los repuestos y servicios verificando que la garantía solicitada 
                corresponda a la parte intervenida. {"\n"}
                La Empresa “NO”  instalará  repuestos ni servicios externos contratados por el cliente que no hayan sido FACTURADOS en  MOTOEXPLORER SAS 
                Las presentes condiciones se entienden comprendidas y aceptadas irrevocablemente con la firma de esta orden de salida.
                </Text>
            </View>

            {/** Segunda pagina con el inventario */}
            {/** Cabecera */}  
            <View break />  
            <Head title='ORDEN DE SERVICIO' break sede={resSede.sed_nombre} direction={resSede.sed_direccion} phone={resSede.sed_telefono}/>
            {/** Inventario */}
            <View style={[styles.sectionFull, {marginTop: 8}]}>
                <Text style={[styles.title, {textAlign: 'left'}]}>INVENTARIO</Text>
                <View style={[styles.box, {borderWidth: .8, borderColor: '#B4B4B4'}]}>
                    <View style={styles.section}>
                        <View style={[styles.boxTr, {backgroundColor: '#dedede'}]}>
                            <TableChildBorder value="DETALLES" fontWeight="bold" width="30%" />
                            <TableChildBorder value="B" fontWeight="bold" width="5%" />
                            <TableChildBorder value="R" fontWeight="bold" width="5%" />
                            <TableChildBorder value="M" fontWeight="bold" width="5%" />
                            <TableChildBorder value="OBSERVACIÓN" fontWeight="bold" width="55%" />
                        </View>
                        {!!inventario1 && inventario1.map((x) =>
                            <View key={x.invo_id} style={styles.boxTr}>
                                <TableChildBorder value={x.invo_nombre} fontWeight="bold" width="30%" />
                                {inventarioform[x.invo_id] === "1" ? 
                                    <TableChildBorder fontWeight="bold" width="5%">
                                        <View style={[styles.checkBox, {backgroundColor: successColor}]}/>
                                    </TableChildBorder>
                                : <TableChildBorder fontWeight="bold" width="5%" />
                                }
                                {inventarioform[x.invo_id] === "2" ? 
                                    <TableChildBorder fontWeight="bold" width="5%">
                                        <View style={[styles.checkBox, {backgroundColor: alternateColor}]}/>
                                    </TableChildBorder>
                                : <TableChildBorder fontWeight="bold" width="5%" />
                                }
                                {inventarioform[x.invo_id] === "3" ? 
                                    <TableChildBorder fontWeight="bold" width="5%">
                                        <View style={[styles.checkBox, {backgroundColor: errorColor}]}/>
                                    </TableChildBorder>
                                : <TableChildBorder fontWeight="bold" width="5%" />
                                }
                                <TableChildBorder fontWeight="bold" width="55%">
                                    <Text style={[styles.label, {fontSize: 4}]}>{comments[`obs${x.invo_id}`]}</Text>
                                </TableChildBorder>
                            </View>
                        )}
                    </View>
                     <View style={styles.section}>
                        <View style={[styles.boxTr, {backgroundColor: '#dedede'}]}>
                            <TableChildBorder value="DETALLES" fontWeight="bold" width="30%" />
                            <TableChildBorder value="B" fontWeight="bold" width="5%" />
                            <TableChildBorder value="R" fontWeight="bold" width="5%" />
                            <TableChildBorder value="M" fontWeight="bold" width="5%" />
                            <TableChildBorder value="OBSERVACIÓN" fontWeight="bold" width="55%" />
                        </View>
                        {!!inventario2 && inventario2.map((x) =>
                            <View key={x.invo_id} style={styles.boxTr}>
                                <TableChildBorder value={x.invo_nombre} fontWeight="bold" width="30%" />
                                {inventarioform[x.invo_id] === "1" ? 
                                    <TableChildBorder fontWeight="bold" width="5%">
                                        <View style={[styles.checkBox, {backgroundColor: successColor}]}/>
                                    </TableChildBorder>
                                : <TableChildBorder fontWeight="bold" width="5%" />
                                }
                                {inventarioform[x.invo_id] === "2" ? 
                                    <TableChildBorder fontWeight="bold" width="5%">
                                        <View style={[styles.checkBox, {backgroundColor: alternateColor}]}/>
                                    </TableChildBorder>
                                : <TableChildBorder fontWeight="bold" width="5%" />
                                }
                                {inventarioform[x.invo_id] === "3" ? 
                                    <TableChildBorder fontWeight="bold" width="5%">
                                        <View style={[styles.checkBox, {backgroundColor: errorColor}]}/>
                                    </TableChildBorder>
                                : <TableChildBorder fontWeight="bold" width="5%" />
                                }
                                <TableChildBorder fontWeight="bold" width="55%">
                                    <Text>{comments[`obs${x.invo_id}`]}</Text>
                                </TableChildBorder>
                            </View>
                        )}
                    </View>
                </View>
            </View>
            {/** COndiciones en las que entro la moto */}
            <View style={[styles.sectionFull, {marginTop: 8}]}>
                <View style={[styles.boxTr, {backgroundColor: '#dedede'}]}>
                    <TableChildBorder value="Condiciones en las que entra la moto" fontWeight="bold" width="100%" />
                </View>
                <View style={styles.boxTr}>
                    <TableChildBorder borderLeft={true} value={cond.formconditionOne === "1" ? 'ENCENDIDA' : ( cond.formconditionOne === "2" && 'APAGADA')} fontWeight="bold" width="50%" />
                    <TableChildBorder value={cond.formconditionSec === "1" ? 'ENSAMBLADA' : ( cond.formconditionSec === "2" && 'NO ENSAMBLADA')} fontWeight="bold" width="50%" />
                </View>
                <View style={styles.boxTr}>
                    <TableChildBorder borderLeft={true} value={cond.formconditionOne === "2" && 'LA MOTO ENTRÓ AL TALLER APAGADA POR LO CUAL MOTOEXPLORER NO SE RESPONSABILIZA POR FALTA DE PIEZAS'} fontWeight="bold" width="50%" />
                    <TableChildBorder value={cond.formconditionSec === "2" && 'LA MOTO ENTRÓ AL TALLER NO ENSAMBLADA POR LO CUAL MOTOEXPLORER NO SE RESPONSABILIZA POR FALTA DE PIEZAS'} fontWeight="bold" width="50%" />
                </View>
            </View>
            {/** autorización */}
            <View style={[styles.sectionFull, {marginTop: 8}]}>
                <View style={[styles.box, {backgroundColor: '#dedede'}]}>
                    <TableChild value="SI" fontWeight="bold" width="8%" />
                    <TableChild value="NO" fontWeight="bold" width="8%" />
                    <TableChild value="AUTORIZACIÓN" fontWeight="bold" width="70%" />
                    <TableChild value="FIRMA" fontWeight="bold" width="10%" />
                </View>
                <View style={styles.box}>
                    <View style={{width: '80%'}}>
                        <View style={styles.box}>
                            {termsform.oneItem === '1' ?
                                <TableChildBorder width="10%">
                                    <Image src={CheckImg} style={styles.check}/>
                                </TableChildBorder>
                                : <TableChild value="" width="10%" /> 
                            }
                            {termsform.oneItem === '2' ?
                                <TableChildBorder width="10%">
                                    <Image src={CheckImg} style={styles.check}/>
                                </TableChildBorder>
                                : <TableChild value="" width="10%" /> 
                            }
                            
                            <TableChild textAlign="left" 
                                value="Autorizo a que INCOLMOTOS y/o MOTOEXPLORER, realice prueba de ruta a mi motocicleta cuando lo considere necesario y bajo su responsabilidad."
                                width="80%" />
                        </View>
                        <View style={styles.box}>
                            {termsform.secItem === '1' ?
                                <TableChildBorder width="10%">
                                    <Image src={CheckImg} style={styles.check}/>
                                </TableChildBorder>
                                : <TableChild value="" width="10%" /> 
                            }
                            {termsform.secItem === '2' ?
                                <TableChildBorder width="10%">
                                    <Image src={CheckImg} style={styles.check}/>
                                </TableChildBorder>
                                : <TableChild value="" width="10%" /> 
                            }
                            <TableChild textAlign="left"
                                value="Autorizo a que INCOLMOTOS y/o MOTOEXPLORER, realice prueba de ruta a mi motocicleta cuando lo considere necesario y bajo su responsabilidad."
                                width="80%" />
                        </View>
                        <View style={styles.box}>
                            {termsform.terItem === '1' ?
                                <TableChildBorder width="10%">
                                    <Image src={CheckImg} style={styles.check}/>
                                </TableChildBorder>
                                : <TableChild value="" width="10%" /> 
                            }
                            {termsform.terItem === '2' ?
                                <TableChildBorder width="10%">
                                    <Image src={CheckImg} style={styles.check}/>
                                </TableChildBorder>
                                : <TableChild value="" width="10%" /> 
                            }
                            <TableChild textAlign="left"
                                value="Autorizo a que INCOLMOTOS y/o MOTOEXPLORER, realice prueba de ruta a mi motocicleta cuando lo considere necesario y bajo su responsabilidad."
                                width="80%" />
                        </View>
                    </View>
                    <View style={{width: '20%', height: '82px', padding: 10, borderWidth: 1, borderColor: '#dedede'}}>
                        <Image src={clif.firmacliente} style={{width: '100%', height: '100%',}} />
                    </View>
                </View>
            </View>

        </Page>
    </Document>
)}

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    paddingVertical: 40,
    paddingHorizontal: 10
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  sectionFull: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    marginVertical: 3,
  },
  boxTr: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    fontSize: 10,
    color: blackColor,
    padding: 5,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  label: {
    fontSize: 6,
    color: blackColor,
    fontWeight: 500
  },
  lineRed: {
    borderWidth: .7,
    borderColor: primayColor,
    marginVertical: 5
  },
  tableText: {
    fontSize: 6,
    color: blackColor,
    paddingVertical: 4,
    paddingHorizontal: 6,
    textAlign: 'center'
  },
  check: {
      width: 8,
      height: 8
  },
  checkBox: {
    height: 8,
    width: 8,
    borderWidth: 1,
    borderColor: '#777777',
    borderRadius: 100
  }
});
