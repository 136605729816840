import { createAction } from 'redux-actions'
import { GET_MOTORCYCLES, DELETE_MOTORCYCLES, GET_INVENTARIO_MOTORCYCLES, GET_SOAT_LIST, POST_MOTORCYCLES, POST_INV_MOTORCYCLES, GET_MOTORCYCLES_ID, UPDATE_MOTORCYCLES, 
    UPDATE_INV_MOTORCYCLES, GET_VALUES_VEHICLES, POST_VAL_MOTORCYCLES, DEL_VAL_MOTORCYCLE, UPDATE_VAL_VEHICLE, DEL_TYPE_SERVICE, UP_TYPE_SERVICE, POST_TYPE_SERVICE,
    GET_SERVICES_WORKS_LIST, DEL_TYPE_WORKS, UP_TYPE_WORK, POST_TYPE_WORK, GET_VALUES_LIST, UP_VALUES, GET_ALL_SEDES_ID } from '../types'
import { apiGetMotorcycles, apiDelMotorcycles, apiGetInvMotorcycles, apiGetSoatList, apiPostMotorcycles, apiPostInvMotorcycles, apiGetMotorcyclesId, apiUpMotorcycles, 
    apiUpInvMotorcycles, apiGetValuesVehicles, apiPostValMotorcycles, apiDelValMotorcycle, apiUpValVehicles, apiDelTSer, apiUpTSer, apiPostTSer, apiGetTSerWorks, 
    apiDelWorks, apiUpWorks, apiPostWorks, apiGetValues, apiUpValues, apiGetAllSedesId } from '../api/Parameterizations'

/** Motorcycles -- obtener */
export const getMotorcycles = createAction(GET_MOTORCYCLES,
    () => apiGetMotorcycles()())

/** Motorcycles -- por id */
export const getMotorcyclesId = createAction(GET_MOTORCYCLES_ID,
    (data) => apiGetMotorcyclesId(data)())

/** Motorcycles -- New */
export const postMotorcycles = createAction(POST_MOTORCYCLES,
    (data) => apiPostMotorcycles(data)())

/** Motorcycles -- Update */
export const upMotorcycles = createAction(UPDATE_MOTORCYCLES,
    (data) => apiUpMotorcycles(data)())

/** Motorcycles -- Inventario */
export const postInvMotorcycles = createAction(POST_INV_MOTORCYCLES,
    (data) => apiPostInvMotorcycles(data)())

/** Motorcycles -- Inventario -- Update */
export const upInvMotorcycles = createAction(UPDATE_INV_MOTORCYCLES,
    (data) => apiUpInvMotorcycles(data)())

/** Motorcycles -- Eliminar */
export const delMotorcycles = createAction(DELETE_MOTORCYCLES,
    (data) => apiDelMotorcycles(data)())

/** Motorcycles -- lista de inventario */
export const getInvMotorcycles = createAction(GET_INVENTARIO_MOTORCYCLES,
    () => apiGetInvMotorcycles()())

/** Lista Soat */
export const getSoatList = createAction(GET_SOAT_LIST,
    () => apiGetSoatList()())

/** Lista de valores -- vehiculos */
export const getValVehicles = createAction(GET_VALUES_VEHICLES,
    () => apiGetValuesVehicles()())

/** Nuevo valor -- moto */
export const postValVehicles = createAction(POST_VAL_MOTORCYCLES,
    (data) => apiPostValMotorcycles(data)())

/** Actualizar valor -- moto */
export const upValVehicles = createAction(UPDATE_VAL_VEHICLE,
    (data) => apiUpValVehicles(data)())

/** Eliminando valores de motos */
export const delValVehicles = createAction(DEL_VAL_MOTORCYCLE,
    (data) => apiDelValMotorcycle(data)())

/** Eliminando tipos de servicios */
export const delTSer = createAction(DEL_TYPE_SERVICE,
    (data) => apiDelTSer(data)())

/** Actualizando tipos de servicios */
export const upTSer = createAction(UP_TYPE_SERVICE,
    (data) => apiUpTSer(data)())

/** Agregando tipos de servicios */
export const postTSer = createAction(POST_TYPE_SERVICE,
    (data) => apiPostTSer(data)())

/** Obetiendo trabajos de servicios */
export const getTSerWorks = createAction(GET_SERVICES_WORKS_LIST,
    () => apiGetTSerWorks()())

/** Eliminando trabajos de servicios */
export const delSerWorks = createAction(DEL_TYPE_WORKS,
    (data) => apiDelWorks(data)())

/** Actualizando trabajos de servicios */
export const upSerWorks = createAction(UP_TYPE_WORK,
    (data) => apiUpWorks(data)())

/** Agregando trabajos de servicios */
export const postSerWorks = createAction(POST_TYPE_WORK,
    (data) => apiPostWorks(data)())

/** Obteniendo lista de valores fijos */
export const getValuesList = createAction(GET_VALUES_LIST,
    () => apiGetValues()())

/** Obteniendo lista de valores fijos */
export const upValues = createAction(UP_VALUES,
    (data) => apiUpValues(data)())

/** Obteniendo lista de valores fijos */
export const getAllSedesId = createAction(GET_ALL_SEDES_ID,
    (data) => apiGetAllSedesId(data)())
