import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import toastr from 'toastr'
import "toastr/build/toastr.css"

import MapaTallerFormV from '../../components/services/MapaTallerFormV'
import { getMapaTaller, postMapaTaller, getServicesMap, updateMapaTaller, postCerrarOrden, getRequestServiceId, upRegisterGesTaller, upTecnicoOrden } from '../../../redux/actions/service-orders'
import { getTecnicos } from '../../../redux/actions/userActions'
import { getOneWorks } from '../../../redux/actions/works'
import Axios from 'axios'
import { url_base } from '../../../redux/api/urls'
import { onCloseModal } from '../../common/Modal'
import { iconColor } from '../../../constanst'

class MapaTallerForm extends Component {
    
    constructor(props){
        super(props)
        this.state={
            loading: false,
            clienteSolicitud: [],
            clienteSol: {},
            mapataller:{
                formtiposervicioorden: '0',
                formaceite: 0,
                formrepuesto: 0,
                formmo: 0,
                formtercero: 0,
                formfacturatotalmapa: 0,
                formidservicio: 0,
                formcomentarios: '',
                formcierre: moment().format('YYYY-MM-DD')
            },
            listServicesMap: []
        }
        this.isMount = false
        this.sigPad = {}
    }

    async componentDidMount(){
        this.isMount = true

        const formidservicio =  this.props.match.params.id

        await this.props.getServicesMap()
        const listServicesMap =  this.props.serviciosMapaTaller.data

         
        // Resultados de las solicitudes y solucion de servicios         
        await this.getInfo(formidservicio)
        await this.props.getTecnicos()

        /**
         * 
         * petición para cargar las sugerencias del técnico
         * 
        */
        const suggestion = await Axios.get(`${url_base}/servicio/sugerenciastecnico/${formidservicio}`).then( v => v.data).catch( ({success: 0, message:"Ha ocurrido un error obteniendo los datos"}))
        !!suggestion && this.setState({ mapataller: {...this.state.mapataller, formcomentarios: suggestion.dt_sugerencias} })

        /** Resultados de las solicitudes y solucion de servicios */
        await this.props.getRequestServiceId(formidservicio)
                    
        if(this.props.servicesRequestList.success === 1 && this.isMount)
            this.setState({ clienteSolicitud: this.props.servicesRequestList.data })
        else 
            toastr.error("Ha ocurrido un error cargando la solución dada")

            
        this.isMount &&
           this.setState({ listServicesMap, tecnicos: this.props.tecnicos })
    }

    getInfo = async (formidservicio) => {
        await this.props.getMapaTaller(formidservicio)
        if (this.props.mapaTaller.success === 1 && this.isMount) {
            const mapataller = this.props.mapaTaller.data
            
            this.setState({ mapataller })
        } else 
            this.isMount && this.setState( prevProps => ({ mapataller: {...prevProps.mapataller, formidservicio}  }))

            
    }

    /** Guardando los valores en los states de react */
    onChange = e => 
        this.setState({ mapataller: { ...this.state.mapataller, [e.target.name]: e.target.value === '' ? 0 : parseInt(e.target.value.replace(/,/g, '')) }}, () => {
            const formfacturatotalmapa =  this.state.mapataller.formaceite + this.state.mapataller.formrepuesto + this.state.mapataller.formmo + this.state.mapataller.formtercero
            this.setState({ mapataller: { ...this.state.mapataller, formfacturatotalmapa } })
        })
    
    /** Registro de mapa de taller */
    handleSubmit = async e => {
        e.preventDefault()
        this.setState({ loading: true })
        const {ds_id, formtecnico} = this.state.mapataller
        
        if (!this.state.firmacliente) {
            this.setState({ loading: false })
            return toastr.warning('Debe autorizar la firma para pode continuar.')
        }
         /** Actulizando el tecnico */
         if(this.props.postResponse.success === 1)
            await this.props.upTecnicoOrden({ds_id, formtecnico})

        if(this.state.mapataller.formtiposervicioorden !== '0') {
            if(this.state.mapataller.formidmapa){
                await this.props.updateMapaTaller({ ...this.state.mapataller, firmaclientecierre: this.state.firmacliente?.replace(/^data:image\/[a-z]+;base64,/, "") })
                if(this.props.postResponse.success === 1)
                    await this.props.postCerrarOrden({estado: 2, idservicio: this.state.mapataller.formidservicio })   
            } else {
                await this.props.postMapaTaller({ ...this.state.mapataller, firmaclientecierre: this.state.firmacliente?.replace(/^data:image\/[a-z]+;base64,/, "") })
                if(this.props.postResponse.success === 1)
                    await this.props.postCerrarOrden({estado: 2, idservicio: this.state.mapataller.formidservicio })
            }


            /** guardar datos de el cliente solicita, solución y solicitud */
            if(this.state.clienteSolicitud.length > 0){
                this.state.clienteSolicitud.map( async x =>
                    await this.props.upRegisterGesTaller({ servicio: x.formsolicitudes, solucion : x.formsolucion, idobs: x.idobs })
                )
            }

            /** Quitando el loading */
            this.isMount &&
                this.setState({ loading: false })

            /** Mostrando el mensage */
            if(this.props.postResponse.success === 1){
                toastr.success(this.props.postResponse.message)
                this.props.history.push('/servicios/seguimiento')
            }

        } else
            toastr.warning(this.props.postResponse.message)

    }

    /** Click a las soluciones */
    handleClickSol = (e, id) => {
        e.preventDefault()

        const sol = this.state.clienteSolicitud.filter( x => x.idobs === id)
        this.setState({clienteSol: sol[0] })
    }

    /** Multiples names */
    handleChangeMultiple = e => {
        const {name} = e.target
        const value = e.target.value.toUpperCase()
        

        this.setState({ clienteSol: {...this.state.clienteSol, [name]: value } })
    }
     

    /** Click para agregar solicitudes */
    handleClickButton = e => {
        e.preventDefault()
        const {idobs} = this.state.clienteSol
        
            this.setState(ps => {
                const clienteSolicitud = ps.clienteSolicitud.map( x => {
                    if(x.idobs === idobs)
                        return {idobs: x.idobs, formsolicitudes: x.formsolicitudes, formsolucion: ps.clienteSol.formsolucion}
                    
                    return  x
                })
                
                return {clienteSolicitud, clienteSol: ''}
            })
    }

    // Modal Firma
    /** Limpiar el Canvas de la firma */
    clear = () => {
        this.sigPad.clear()
    }

    /** Obtener la firma del pad */
    trim = e => {
        e.preventDefault()
        const firmacliente = this.sigPad.getTrimmedCanvas().toDataURL('image/png');
        this.setState({ firmacliente })

        document.getElementById("imgfirma").style.border = `1px solid ${iconColor}` 
        this.error = true
        onCloseModal("modalFirma")
    }

    render() {
        return (
            <MapaTallerFormV
                state={this.state}
                onChange={this.onChange}
                onChangeMultiple={this.handleChangeMultiple}
                onGoBack={ e => { this.props.history.goBack(); e.preventDefault() }}
                onClickSol={this.handleClickSol}
                onClickButton={this.handleClickButton}
                onSubmit={this.handleSubmit}
                onChangeSelect={e => this.setState({ mapataller: {...this.state.mapataller, [e.target.name]: e.target.value} })}
                sigPad={ref => this.sigPad = ref }
                clearPad={this.clear}
                trimPad={this.trim}
            />
        )
    }
}

const mapStateToProps = ({ mapaTaller, serviciosMapaTaller, postResponse, tecnicos, servicesRequestList, worksList }) => ({
     mapaTaller, serviciosMapaTaller, postResponse, tecnicos, servicesRequestList, worksList
})

export default connect(mapStateToProps, {
    getMapaTaller, postMapaTaller, getServicesMap, updateMapaTaller, postCerrarOrden, getTecnicos, getRequestServiceId, upRegisterGesTaller, upTecnicoOrden,
    getOneWorks, 
})(MapaTallerForm)