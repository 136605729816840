import React from 'react'
import Scrollbars from 'react-custom-scrollbars'
import styled from 'styled-components'

import { secundaryColor, primayVariantColor, successColor, alternateVariantColor, errorColor } from '../../../constanst'
import { Tooltip, TooltipText } from '../../components/services/ComponentsForm'
import Table, { Tr, Td, Th, ButtonsContainer, ButtonIcon } from '../../common/Table'
import { IconEdit, IconDelete, IconPlus } from '../../../assets/icons'
import { Loading } from '../../common/Loading'
import Button from '../../common/Button'

export default ({ state, onClickPage, onDelete }) => 
<Main>
{state.loading && <Loading /> }
<Scrollbars autoHide>
    <Container>
        <Title>MOTOS</Title>
        <Table
            minWidth="1000px"
            renderButtons={
                <ButtonsContainer>
                    <Button label="Nueva Moto" type="button" bgColor={successColor} color={secundaryColor} icon={<IconPlus width="15px" height="15px" color={secundaryColor} />} onClick={onClickPage} />
                </ButtonsContainer>
            }
            renderHead={
                <Tr bgColor={true}>
                    <Th>#</Th>
                    <Th>NOMBRE</Th>
                    <Th>CILINDRAJE</Th>
                    <Th>ACCIONES</Th>
                </Tr>
            }
            renderBody={
            state.motorcycles.length > 0 ?
                state.motorcycles.map( (x, index) => (
                    <Tr key={x.id}>
                        <Td>{index + 1}</Td>
                        <Td>{x.nombre}</Td>
                        <Td>{x.cilindraje}</Td>
                        <Td>
                            <Tooltip className="tooltip">
                                <ButtonIcon type="button" onClick={e => onClickPage(e, x.id)} >
                                    <IconEdit width="30px" height="20px" color={secundaryColor} bgColor={alternateVariantColor} />
                                </ButtonIcon>
                                <TooltipText className="tooltiptext" bgColor={alternateVariantColor}>Editar</TooltipText>
                            </Tooltip>
                            <Tooltip className="tooltip">
                                <ButtonIcon type="button" onClick={e => onDelete(e, x.id)} >
                                    <IconDelete width="30px" height="20px" color={secundaryColor} bgColor={errorColor} />
                                </ButtonIcon>
                                <TooltipText className="tooltiptext" bgColor={errorColor}>Eliminar</TooltipText>
                            </Tooltip>
                        </Td>
                    </Tr>
                )) : 
                <Tr>
                    <Td colSpan="6">No se encontraron elementos para mostrar</Td>
                </Tr>
            }
        >
        </Table>
    </Container>
    <br/>
    <br/>
</Scrollbars>
</Main>


const Main = styled.main`
    flex: 1;
    display: flex;
    overflow: hidden;
    box-sizing: border-box;
`
const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: ${secundaryColor};
    width: 100%;
    margin-bottom: 30px;
    margin: 0px 2%;
    /* border-radius: 15px; */
    box-shadow: 0px 0px 6px 1px rgba(0,0,0,.4);
    @media (min-width: 1000px){
        width: 98%;
        margin-left: 1%;
    }
 `
 const Title = styled.h3`
    color: ${secundaryColor};
    padding: 5px;
    margin: 0px;
    text-align: center;
    background-color: ${primayVariantColor};
`