import React from 'react'
import styled, { css } from 'styled-components'
import Scrollbars from 'react-custom-scrollbars'
import { MenuItem } from '@material-ui/core'

import Button  from "../../common/Button"
import {Loading}  from "../../common/Loading"
import CustomSelect from '../../common/CustomSelect'
import { Tooltip, TooltipText } from './ComponentsForm'
import { primayVariantColor, bgVariantColor, secundaryColor, iconVariantColor, blackColor } from '../../../constanst'
// import { IconArrowDown } from '../../../assets/icons'

export const WokrshopMapV = ({state, onClick, onChange, onClickArrow}) => 
    <Main>
        {state.loading && <Loading />}
        <Container>
            <Scrollbars>
                <Header>
                    <Left>
                        <Title>MAPA DE TALLER</Title>
                    </Left>
                     {/** Planilla diaria, menú oculto */}
                    {/* <MenuOculto id="menuOculto">
                        <Left width={'70%'} direction="column">
                            <LabelInfo>Tipo de servicio</LabelInfo>
                            
                            <Label color={secundaryColor}>TECNICO</Label>
                            <CustomSelect inputw="72%" value={state.tecnicoSelect} name="tecnicoSelect" onChange={onChange}>
                                <MenuItem value="0">TODOS LOS TÉCNICOS</MenuItem>
                                {state.tecnicosList &&                        
                                    state.tecnicosList.map(x => (
                                        <MenuItem key={x.usu_id} value={x.usu_id}>{x.per_nombre}</MenuItem>
                                    ))
                                }
                            </CustomSelect>
                            <CustomSelect inputw="72%" value={state.tipoServ} name="tipoServ" onChange={onChange}>
                                <MenuItem value="0">TODOS LOS SERVICIOS</MenuItem>
                                {state.typeServices &&                        
                                    state.typeServices.map(x => (
                                        <MenuItem key={x.usu_id} value={x.usu_id}>{x.per_nombre}</MenuItem>
                                    ))
                                }
                            </CustomSelect>
                        </Left>
                        <Right width={'30%'}>
                            
                        </Right>
                       
                    </MenuOculto> */}
                    {/* <Arrow type="button" onClick={onClickArrow}>
                        <IconArrowDown width="20px" height="20px" color={iconColor} />
                    </Arrow> */}
                </Header>

            {/** Body */}
            <Body>
                {/** banner */}
                <Banner>
                    <Left borderC={secundaryColor} justify="space-between">
                        <Label>{state.userActive.per_identificacion}</Label>
                        <Label>{state.userActive.per_nombre}</Label>
                        <Label>{state.userActive.per_identificacion && `TÉCNICO`}</Label>
                    </Left>
                    <Right>
                        <CustomSelect inputw="72%" value={state.yearSel} name="yearSel" onChange={onChange} margin="0" title='AÑO'>
                            <MenuItem key={1} value="2020">2020</MenuItem>
                            <MenuItem key={2} value="2021">2021</MenuItem>
                        </CustomSelect>
                        <CustomSelect inputw="72%" value={state.monthsSel} name="monthsSel" onChange={onChange} margin="0" title='MES'>
                            {state.monthsList &&                        
                                state.monthsList.map(x => (
                                    <MenuItem key={x.id} value={x.id}>{x.nombre}</MenuItem>
                                ))
                            }
                        </CustomSelect>
                        <CustomSelect inputw="72%" value={state.tecnicoSelect} name="tecnicoSelect" onChange={onChange} title='TÉCNICOS'>
                            <MenuItem value="0">TODOS LOS TÉCNICOS</MenuItem>
                            {state.tecnicosList &&                        
                                state.tecnicosList.map(x => (
                                    <MenuItem key={x.usu_id} value={x.usu_id}>{x.per_nombre}</MenuItem>
                                ))
                            }
                        </CustomSelect>
                    </Right>
                </Banner>

                {/** Información de mapa de taller */}
                <MapContainer>
                    <Left width="100%" borderC={primayVariantColor} marginTop="20px" direction={"column"}>
                        {/** totales */}
                        <Totals>
                            <TotalInfo>
                                <LabelInfo>Facturación por repuestos</LabelInfo>
                                <Title color={blackColor}>{format(state.totRepuesto / 1.19)}</Title>
                            </TotalInfo>
                            <TotalInfo>
                                <LabelInfo>Facturación por M.O.</LabelInfo>
                                <Title color={blackColor}>{format(state.totMo / 1.19)}</Title>
                            </TotalInfo>
                            <TotalInfo>
                                <LabelInfo>Facturación aceites</LabelInfo>
                                <Title color={blackColor}>{format(state.totAceite)}</Title>
                            </TotalInfo>
                            <TotalInfo>
                                <LabelInfo>Facturación terceros</LabelInfo>
                                <Title color={blackColor}>{format(state.totTercero)}</Title>
                            </TotalInfo>
                        </Totals>
                    
                        {/** Graficos */}
                        <Graphics>
                            <Canvas rightCanvas md="300px" height="100px" id="myChart3" />
                            <Canvas md="300px" height="99px" id="myChart2" />
                        </Graphics>
                        <Contaienrbar>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}>
                                    <Canvas width="450px" height="200px" id="myChartBar" />
                                </div>
                                <div style={{ width: '50%' }}>
                                    <Canvas width="450px" height="200px" id="myChart" />
                                </div>
                            </div>
                            <Left width={'100%'} direction="row">
                                <LabelInfo>Tipo de servicio</LabelInfo>
                                {
                                    state.typeServices.map( x => (
                                        <Tooltip key={x.tser_id} className="tooltip">
                                            <Button bgColor={primayVariantColor} onClick={e => onClick(e, x.tser_id, x.tser_nombre)} label={x.tser_nombre.substr(0, 10).concat('...')} color={secundaryColor} marginV="5px" width="100%" />
                                            <TooltipText className="tooltiptext" bgColor="#000000" position="-100%" arrowPosition="left">{x.tser_nombre}</TooltipText>
                                        </Tooltip>
                                    ))
                                }
                            </Left>
                        </Contaienrbar>
                    </Left>
                </MapContainer>
            </Body>

            </Scrollbars>
        </Container>
        {/* <ButtonPDF>
            <Button bgColor={primayVariantColor} onClick={onClickPdf} label="GENERAR PDF" color={secundaryColor} marginV="5px" width="100%"  />
            <LinkOculto href="" id="pdfMT"/>
        </ButtonPDF> */}
    </Main>


/** Formateo de Valores */
const format = value => new Intl.NumberFormat('de-DE').format(value)

const Main = styled.main`
    flex: 1;
    display: flex;
    overflow: hidden;
    padding: 0px;
`
const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    /* border-radius: 15px; */
    @media (min-width: 1000px){
        width: 98%;
        margin-left: 1%;
    }
 `
 
const Header = styled.div`
    position: relative;
    background-color: ${primayVariantColor};
    display: flex;
    width: 100%;
    align-items: center;
`
const Title = styled.h4`
    color: ${({ color }) => color ? color : secundaryColor};
    font-size: 16px;
    font-weight: bold;
    margin: 0;
`
const Right = styled.div`
    padding: 0px 10px;
    width: ${({width}) => width ? width : '50%'};
    height: ${({height}) => height ? height : 'auto'};
    display: flex;
    align-items: ${({ align }) => align ? align : 'center'};
    justify-content: space-between;
    ${({marginTop}) => marginTop && css`margin-top: ${marginTop};`}
`
const Left = styled.div`
    display: flex;
    ${({justify}) => justify && css`justify-content: ${justify};`}
    padding: 0px 10px;
    width: ${({width}) => width ? width : '50%'};
    ${ ({borderC}) => borderC && css`border-right: 2px solid ${borderC};` }
    ${({marginTop}) => marginTop && css`margin-top: ${marginTop};`}
    ${({direction}) => css`flex-direction: ${direction};`}
`
const Label = styled.label`
    font-size: 16px;
    color: ${({color}) => color ? color : blackColor};
`
const Banner = styled.div`
    align-items: center;
    display: flex;
    width: 100%;
    background-color: ${iconVariantColor};
    padding: 5px 0;
    border-radius: 2em;
    /* margin: 10px 0px; */
`
const Body = styled.div`
    background-color: ${bgVariantColor};
    box-shadow: 0px 0px 6px 1px rgba(0,0,0,.4);
    padding: 10px 3%;
`
const MapContainer = styled.div`
    display: flex;
`

/** Información de totales */
const Totals = styled.div`
    width: 100%;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #57575757;
    border-bottom: 2px solid ${primayVariantColor};
`
const TotalInfo = styled.div`
    padding: 5px;
    text-align: center;
`

const LabelInfo = styled.label`
    color: ${blackColor};
    padding: 10px 0px;
    font-size: 12px;
    font-weight: 700;
`
const Graphics = styled.div`
    width: 50%;
    padding: 20px 0px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 2px solid ${primayVariantColor};
`
const Canvas = styled.canvas`
    width: ${({md}) => md};
    height: ${({height}) => height};
    border: 1px solid #dddddd;
    ${ props => props.rightCanvas && css`border-right: none;` };
`
const Contaienrbar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`
// const ButtonPDF = styled.div`
//     position: fixed;
//     bottom: 0;
//     right: 0;
//     padding: 0 20px 10px 0;
// `
// const LinkOculto = styled.a`
//     display: none;
// `
/** Menu oculto */
// const MenuOculto = styled.div`
//     background-color: ${secundaryColor};
//     height: 0px;
//     position: absolute;
//     z-index: 1;
//     bottom: 0px;
//     width: 100%;
//     overflow: hidden;
//     transition: .5s;
//     border-bottom: 1px solid ${iconColor};
//     border-top: 1px solid ${iconColor};
//     display: flex;
//     justify-content: space-between;
//     align-items: center;

//     box-shadow: 1px 5px 8px 1px rgba(0,0,0,.25);
// `
// const Arrow = styled.button`
//     position: absolute;
//     z-index: 1;
//     bottom: -20px;
//     border: 1px solid ${iconColor};
//     outline: none;
//     width: 25px;
//     height: 25px;
//     left: 48%;
//     border-radius: 0 0 100% 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     background-color: ${secundaryColor};

//     &:hover {cursor: pointer;}
// `