import React from 'react'
import styled, { css } from 'styled-components'
import Logo from '../../../assets/imagenes/logomotoexplorer.jpg'
import Modal from '../../components/services/Modal'


export default ({ state, docTitle, sedeUser, onClickNotify }) =>
<CabeceraLogo>
    <ContainerLogo bgColor="white">
        <Box>
            <ImgLogo src={Logo} alt="membrete" />
            <Parr marginTop="0">www.motoexplorer.wowdesarrollos.com</Parr>
        </Box>
    </ContainerLogo>
    <ContainerLogo>
            <Modal state={state} visible={state?.visible} onClickNotify={onClickNotify} />
        <Div>
            <Parr textAlign="right" weight="bold"fontSize="18px">{docTitle}</Parr>
            <Parr marginTop="0" padding="0 8px 0 0">{sedeUser && sedeUser[0].sed_nombre}</Parr>
            <Parr marginTop="0" padding="0 8px 0 0">{sedeUser && sedeUser[0].sed_direccion}</Parr>
            <Parr marginTop="0" padding="0 8px 0 0">Tlf: {sedeUser && sedeUser[0].sed_telefono}</Parr>
        </Div>
    </ContainerLogo>
</CabeceraLogo>


/** styles */
const CabeceraLogo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 15px;
    & > div {width: 100%;}
    
    @media(min-width: 600px){
        & > div {width: 50%;}
        flex-direction: row;
    }
`
const ImgLogo = styled.img`
    height: 100px; 
    width:300px;
`
const Parr = styled.p`
    font-size:${({ fontSize }) => fontSize ? fontSize : '12px'}; 
    font-weight: ${({ weight }) => weight ? weight : '600'};
    margin-bottom: 0px; 
    color: #545454;
    ${({ marginTop }) => marginTop && css`margin-top: ${marginTop};`}
    ${({ textAlign }) => textAlign && css`text-align: ${textAlign};`}
    ${({ padding }) => padding && css`padding: ${padding};`}
`
const Div = styled.div`
    text-align: right;
`
const ContainerLogo = styled.div`
    width: 50%; 
    float: left;
`
const Box = styled.div`
    background-color: white;
    text-align: center;
    width: 320px;
    margin-top: 15px;
    padding: 0px 10px;
`