import React, { Component } from 'react'
import { connect } from 'react-redux'
import toastr from 'toastr'
import "toastr/build/toastr.css"

import ValuesVehiclesV from "../../components/parameterization/ValuesVehicles"
import { getValVehicles, getMotorcycles, postValVehicles, delValVehicles, upValVehicles } from "../../../redux/actions/Parameterizations"
import { validations } from '../../../constanst'
import { openModal, onCloseModal } from '../../common/Modal'


class ValuesVehicles extends Component {

    constructor(props){
        super(props)
        this.state = {
            loading: false,
            motorcyclesVal: [],
            vehiclesList: [],
            error: {}
        }
        this.isMount = false
    }

    async componentDidMount(){
        this.isMount = true
        this.setState({loading: true})

        const idUser = JSON.parse(localStorage.getItem('userdata'))
        /** Obteniendo el listado de valores */
        await this.props.getValVehicles()
        await this.props.getMotorcycles()

        if(this.props.valuesVehicles.success === 1 && this.isMount){
            this.setState({ motorcyclesVal: this.props.valuesVehicles.data, vehiclesList: this.props.motorcycles.data, idUser: idUser[0].idusuario })
        }

        if(this.isMount)
           this.setState({loading: false})
    }

    componentWillUnmount(){
        this.isMount = false
    }


    /** Método on Change */
    handleChange = (e, isNull, isLetter, isNumber, isRange, minRange, maxRange, isEmail) => {
        const error = validations(e, isNull, isLetter, isNumber, isRange, minRange, maxRange, isEmail)
        this.setState({ [e.target.name]: e.target.value, error: {[e.target.name]: error} })
    }

    /** Metodo OnSubmit (envío del formulario) */
    handleSubmit = async e => {
        e.preventDefault()
        this.setState({loading: true})
        onCloseModal("modalVehicle")

        let errorSubmit = false 
        const { error, formvehiculo, formvalor, idUser, idval } = this.state

        for(let x in error){
            if(error[x])
                errorSubmit = true
        }
        if(!formvehiculo || !formvalor)
            errorSubmit = true

        if(errorSubmit)
            return toastr.warning("Rellene los campos")
        else{
            if(!!idval)
                await this.props.upValVehicles({idval, formvehiculo, formvalor, idUser})
            else
                await this.props.postValVehicles({formvehiculo, formvalor, idUser})

            if(this.props.postResponseParam.success === 1) {
                await this.props.getValVehicles()
                if(this.props.valuesVehicles.success === 1 && this.isMount)
                    this.setState({ motorcyclesVal: this.props.valuesVehicles.data, idval: '', formvehiculo: '', formvalor: '' })
                toastr.success("Registro exitoso")
            } else
                toastr.warning("Ha ocurrido un error")
        }

        if(this.isMount)
            this.setState({loading: false})
    }

    /** onClickParams */
    handleParams = (e, idElem, idModal) => {
        e.preventDefault()
        const eSel = this.state.motorcyclesVal.find(e => e.idvalaores === idElem)

        this.isMount &&
            this.setState({ idval: eSel.idvalaores, formvehiculo: eSel.rm_id, formvalor: eSel.val_valor })
            
        openModal(idModal)
    }

    /** Función para eliminar valores de motos */
    handleDelete = async (e, id) => {
        this.setState({loading: true})

        await this.props.delValVehicles(id)
        if(this.props.valuesVehicles.success === 1 && this.isMount){
            this.setState({ motorcyclesVal: this.props.valuesVehicles.data })
            toastr.success("Registro exitoso")
        } else
            toastr.warning("Ha ocurrido un error")

        this.setState({loading: false})
    }

    /** Cancelar modal */
    handleClickCancel = e => {
        e.preventDefault()
        onCloseModal("modalVehicle")

        this.isMount &&
            this.setState({ idval: '', formvehiculo: '', formvalor: '' })
    }

    render() {
        return (
            <ValuesVehiclesV
                state={this.state}
                onChange={this.handleChange}
                onChangeSelect={e => this.setState({ [e.target.name]: e.target.value })}
                onClickParams={this.handleParams}
                onClickCancel={this.handleClickCancel}
                onDelete={this.handleDelete}
                onSubmit={this.handleSubmit}
            />
        )
    }
}

const mapStateToProps = ({ valuesVehicles, motorcycles, postResponseParam }) => ({ valuesVehicles, motorcycles,postResponseParam })

export default connect(mapStateToProps, {getValVehicles, getMotorcycles, postValVehicles, delValVehicles, upValVehicles})(ValuesVehicles)