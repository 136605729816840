import React, { Component } from 'react'
import { connect } from 'react-redux'
import toastr from 'toastr'
import "toastr/build/toastr.css"

import ValuesV from '../../components/parameterization/Values'
import { validations } from '../../../constanst'
import { getValuesList, upValues } from "../../../redux/actions/Parameterizations"


class Values extends Component {
    constructor(props){
        super(props)
        this.state = {
            valuesList: [],
            loading: false
        }
        this.isMount = false
    }

    async componentDidMount(){
        // this.setState({loading: true})
        this.isMount = true

        await this.props.getValuesList()
        
        if(this.props.valuesList.success === 1 && this.isMount) 
            this.setState({valuesList: this.props.valuesList.data})
        else
            toastr.warning('Ha ocurrido un error cargando la información')
    }

     /** Método on Change */
     handleChange = (e, isNull, isLetter, isNumber, isRange, minRange, maxRange, isEmail) => {
        const error = validations(e, isNull, isLetter, isNumber, isRange, minRange, maxRange, isEmail)
        this.setState({ [e.target.name]: e.target.value, error: {[e.target.name]: error} })
    }

    /** Cargando los valores en el input para editar */
    handleClickVal = (e, id, valor) => {
        e.preventDefault()

        this.isMount &&
            this.setState({id, valor})
    }

    handleSubmit = async e => {
        e.preventDefault()
        const {id, valor} = this.state

        if(!!this.state.id){
            this.setState({loading: true})
            await this.props.upValues({id, valor})

            if(this.props.postResponseParam.success === 1){
                toastr.success(this.props.postResponseParam.message)
                await this.props.getValuesList()
                
                if(this.props.valuesList.success === 1 && this.isMount) 
                    this.setState({valuesList: this.props.valuesList.data})
            }
            else
                toastr.warning(this.props.postResponseParam.message)
            }
        else
            toastr.warning('Debe ingresar un valor')


            
        this.setState({loading: false})
            
    }

    componentWillUnmount(){
        this.isMount = false
    }

    render() {
        return (
            <ValuesV state={this.state} onSubmit={this.handleSubmit} onClickVal={this.handleClickVal} onChange={this.handleChange} onGoBack={ () => this.props.history.push('/servicios/seguimiento')} />
        )
    }
}

const mapStateToProps = ({valuesList, postResponseParam}) => ({valuesList, postResponseParam})

export default connect(mapStateToProps, {getValuesList, upValues})(Values)