import React from 'react'
import Scrollbars from 'react-custom-scrollbars'
import styled, { css } from 'styled-components'

import { secundaryColor, primayVariantColor, blackColor, iconColor, primayColor } from '../../../constanst'
import Table, { Tr, Th, Td } from '../../common/Table'
import { IconArrowLeft } from '../../../assets/icons'
import ButtonSubmit from '../../common/ButtonSubmit'
// import InputText from '../../common/InputText'
import { Loading } from '../../common/Loading'
import Button from '../../common/Button'
import { Form, FormContent, ContainerButton } from '../../components/services/ComponentsForm'
import ModuleInput from '../../common/ModuleInput'

export default ({ state, onSubmit, onCheckAll, onChange, onGoBack }) => 
<Main>
    {state.loading && <Loading /> }
    <Scrollbars>
        <Container>
            <ButtonContainer onClick={onGoBack}>
                <Button label="REGRESAR" icon={<IconArrowLeft width="15px" height="15px" color={blackColor} />}></Button>
            </ButtonContainer>

            <Form onSubmit={onSubmit} method="POST" id="formMot">
                <FormContent>
                    <ModuleContainer>
                        <Title>REGISTRAR MOTO</Title>

                        <Row>
                            <ModuleInput label="REFERENCIA" inputw="40%" name="nombre" ignore={true} type="text"
                                onChange={e => onChange(e, false, false, false, true, 3, 100)}
                                value={state.motform.nombre || ''} 
                                borderC={iconColor} backgroundC={secundaryColor} 
                            />
                            <ModuleInput label="CILINDRAJE" inputw="40%" name="cilindraje" ignore={true} type="text"
                                onChange={e => onChange(e, false, false, true, true, 1, 10)}
                                value={state.motform.cilindraje || ''} 
                                borderC={iconColor} backgroundC={secundaryColor} 
                            />
                        </Row>

                        <Table
                            renderHead={
                                <Tr bgColor={primayVariantColor} >
                                    <Th>#</Th>
                                    <Th><input type="checkbox" name="checkAlls" value="alls" onChange={onCheckAll} /></Th>
                                    <Th style={{ textAlign: 'left', paddingLeft: '5%' }}>NOMBRE</Th>
                                    {/* <Th>CANTIDAD</Th> */}
                                </Tr>
                            }
                            renderBody={
                                !!state.inventarioList &&
                                    state.inventarioList.map( (x, i) => (
                                        <Tr style={{ transition: '1s' }} key={x.invo_id}>
                                            <Td>{i+1}</Td>
                                            <Td><input type="checkbox" name={`${x.invo_id}`} value={state.inventarioform[x.invo_id]} checked={state.inventarioform[x.invo_id] === x.invo_id}  onChange={onChange} /></Td>
                                            <Td style={{ textAlign: 'left', paddingLeft: '4%' }}><Span>{x.invo_nombre}</Span></Td>
                                            {/* <Td>
                                                <InputText inputW="20%" name={`obs${x.invo_id}`} ignore={true} type="text"
                                                    onChange={e => onChange(e, false, false, false, true, 3, 200)}
                                                    value={state.inventarioform[`obs${x.invo_id}`] || ''} 
                                                    borderC={iconColor} backgroundC={secundaryColor} />
                                            </Td> */}
                                        </Tr>
                                    ))
                            }
                        />
                    
                    </ModuleContainer>
                    
                </FormContent>

                {/* button */}
                <ContainerButton>
                    <ButtonSubmit width="30%" label="Guardar" bgColor={primayColor} color={secundaryColor} type="submit" />
                </ContainerButton>
            </Form>

    
        </Container>
        <br/>
        <br/>
    </Scrollbars>
</Main>

const Main = styled.main`
    flex: 1;
    display: flex;
    overflow: hidden;
    box-sizing: border-box;
`
const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: ${secundaryColor};
    width: 100%;
    margin-bottom: 30px;
    margin: 0px 2%;
    /* border-radius: 15px; */
    box-shadow: 0px 0px 6px 1px rgba(0,0,0,.4);
    @media (min-width: 1000px){
        width: 98%;
        margin-left: 1%;
    }
 `
const Title = styled.h3`
    color: ${secundaryColor};
    padding: 5px;
    margin: 0px;
    text-align: center;
    background-color: ${primayVariantColor};
` 
const ModuleContainer = styled.div`
    flex: 1;
    padding: 0 10px 0px 20px;
    display: inline-block;

    ${({ borderLeft }) => borderLeft && css`border-left: 1px solid ${primayColor};`}
`
const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
`
const Span = styled.span`
    display: inline-block;
    padding-left: 10px;
`
const Row = styled.div`
    padding: 20px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
`