import { handleActions } from 'redux-actions'
import { GET_LIST_HV, POST_CURRICULUM_VITAE } from '../types'
import { GET_INDENTITY_VITAE } from '../types'
import { GET_WARRANTY_VITAE } from '../types'
import { GET_REVIEWSSERVICES_VITAE } from '../types'

export const curriculumVitae = handleActions({
    [GET_LIST_HV]: (state, action) => action.payload,
    [POST_CURRICULUM_VITAE]: (state, action) => ({...state, ...action.payload}),
    }, {})
    
export const identityVitae = handleActions({ 
    [GET_INDENTITY_VITAE]: (state, action) => ({...state, ...action.payload}),
    }, {})

export const warrantyVitae = handleActions({ 
    [GET_WARRANTY_VITAE]: (state, action) => ({...state, ...action.payload}),
    }, {})


export const reviewsVitae  = handleActions({ 
    [GET_REVIEWSSERVICES_VITAE]: (state, action) => ({...state, ...action.payload}),
    }, {})