import React from 'react'
import Scrollbars from 'react-custom-scrollbars'
// import moment from 'moment'
import styled, { css } from 'styled-components'

import { secundaryColor, blackColor, bgVariantColor, primayColor, primayVariantColor } from '../../../constanst'
// import { IconPlus } from "../../../assets/icons/"
/** Vistas */
import ClientForm from './ClientForm'
import PersonaForm from './PersonaForm'
import VehicleForm from './VehicleForm'
import ServiceForm from './ServicesForm'
import SolicitudForm from './SolicitudForm'
import LlamadasForm from './LlamadasForm'
import InventarioForm from './InventarioForm'
import TermsForm from './TermsForm'
import Modal from '../../common/Modal'
import ModalImprimir from '../../common/ModalImprimir'
import { Loading } from '../../common/Loading'
import ButtonSubmit from '../../common/ButtonSubmit'
import { Form, ContainerButton, Box as Bbox } from './ComponentsForm'
import Logo from '../../../assets/imagenes/logomotoexplorer.jpg'

const OrdenV = ({ state, stateOrder, onChange, onBlur, onChangeSelect, onChangeMultiple, onClickSelect, onChangeCheck, params, sigPad, clearPad, trimPad, onSubmit, onClickButton, onDel, onClickModal, onCloseModal, onKeyPress, onGoBack, onUpdate, onCanUp, onCheckReadiness }) => (
    <Main id="main">
        {state.loading &&
            <Loading />    
        }
        {state.modalImp && 
            <ModalImprimir orderRoad logo={Logo} onClickModal={onClickModal} onCloseModal={onCloseModal} state={state.stateImp} />
        }
       
        <Modal sigPad={(ref) => sigPad(ref) } clearPad={clearPad} trimPad={trimPad} />
        <Scrollbars className="scroll" style={{flex: 1}}>


            <Container>

             {/** Cabecera */}
             <CabeceraLogo>
                <ContainerLogo bgColor="white">
                    <Box>
                        <ImgLogo src={Logo} alt="membrete" />
                        <Parr marginTop="0">www.motoexplorer.wowdesarrollos.com</Parr>
                    </Box>
                </ContainerLogo>
                <ContainerLogo>
                    <Div>
                        <Parr textAlign="right" weight="bold"fontSize="18px">ORDEN DE SERVICIO</Parr>
                        {/* <Parr marginTop="0">Barranquilla, {moment().format('YYYY-MM-DD')} </Parr> */}
                        <Parr marginTop="0" padding="0 8px 0 0">{state.sedeUser && state.sedeUser[0].sed_nombre}</Parr>
                        <Parr marginTop="0" padding="0 8px 0 0">{state.sedeUser && state.sedeUser[0].sed_direccion}</Parr>
                        <Parr marginTop="0" padding="0 8px 0 0">Tlf: {state.sedeUser && state.sedeUser[0].sed_telefono}</Parr>
                    </Div>
                </ContainerLogo>
            </CabeceraLogo>

                {/* <ButtonContainer>
                    <NewButton>NUEVO&nbsp; <IconPlus width={"10px"} height={"10px"} color={blackColor} /></NewButton>
                    <NewButton>IR AL SEGUIMIENTO&nbsp; <IconPlus width={"10px"} height={"10px"} color={blackColor} /></NewButton>
                </ButtonContainer> */}

                <Title>ORDEN DE SERVICIO</Title>

                {/** Formulario */}

                
            <Form onSubmit={onSubmit} method="POST" id="formOrden">
                <FormContainer>
        
                    <FormTitle>{state.clientform.formcosn}</FormTitle>
                    <Bbox justify="space-between" direction="row" flex=".5" align="none">
                        <ClientForm onChange={onChange} state={state.clientform} errorM={state.errorMessage} clientList={state.clientList} 
                            menuActive={state.menuActive} onBlur={onBlur} onClickSelect={onClickSelect} autoCom={state.autoCom} onChangeSelect={onChangeSelect} />
                        <LineRed />
                        <VehicleForm onChange={onChange} onChangeSelect={onChangeSelect} onBlur={onBlur} state={state} onSubmit={onSubmit} onGoBack={onGoBack} onClickSelect={onClickSelect} />
                    </Bbox>
                    <PersonaForm onChange={onChange} state={state.clientform} equal={state.equal} onChangeCheck={onChangeCheck} />
                    <LineRed />
                    <Title bgcolor="transparent" color={blackColor}>DETALLES DEL SERVICIO</Title>
                        <ServiceForm onCheckReadiness={onCheckReadiness} onChange={onChange} onChangeSelect={onChangeSelect} state={state} onSubmit={onSubmit} onGoBack={onGoBack} />
                        <LineRed />
                        <SolicitudForm onChangeMultiple={onChangeMultiple} onDel={onDel} onClickButton={onClickButton} state={state} onSubmit={onSubmit} onKeyPress={onKeyPress} onGoBack={onGoBack} onUpdate={onUpdate} onCanUp={onCanUp} />
                        <LineRed />
                        <LlamadasForm onChangeMultiple={onChangeMultiple} onDel={onDel} onClickButton={onClickButton} state={state} onSubmit={onSubmit} onKeyPress={onKeyPress} onGoBack={onGoBack} onUpdate={onUpdate} onCanUp={onCanUp} />
                        <LineRed />
                        <InventarioForm stateOrder={stateOrder} onChange={onChange} state={state} onSubmit={onSubmit} onGoBack={onGoBack} params={params} />
                        <LineRed />
                        <TermsForm stateOrder={stateOrder} onChange={onChange} state={state.termsform} firmacliente={state.clientform.firmacliente} onSubmit={onSubmit} onGoBack={onGoBack} params={params} />
                </FormContainer>


                {/* button */}
                <ContainerButton>
                    <ButtonSubmit width="30%" label="Guardar" bgColor={primayColor} color={secundaryColor} type="submit" />
                    <ButtonSubmit width="30%" label="Atrás" bgColor={blackColor} color={secundaryColor} type="buttton" onClick={onGoBack} />
                </ContainerButton>
            </Form>
            </Container>
        </Scrollbars>
    </Main>
)

export default OrdenV

const Main = styled.main`
    flex: 1;
    position: relative;
`
const Container = styled.div`
    background-color: ${bgVariantColor};
    margin: 0px 3%;
    height: 100;
    box-shadow: 0px 0px 8px 2px rgba(0,0,0,.4);
    margin-bottom: 40px;
 `
//  const ButtonContainer = styled.div`
//     display: flex;
//     width: 100%;
//  `
//  const NewButton = styled.button`
//     background-color: transparent;
//     color: ${blackColor};
//     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
//     border: none;
//     padding: 10px;
//     font-size: 12px;
//     font-weight: 500;

//     :hover {cursor: pointer;}
//     :focus {outline: none;}
//  `

/** Formulario Orden de Servicio */
const FormContainer = styled.div`
    padding: 0px 30px;
    margin-bottom: 30px;
`
const FormTitle = styled.p`
    width: 100%;
    text-align: center;
    color: ${primayVariantColor};
    font-size: 18px;
    font-weight: 600;
`
const LineRed = styled.hr`border: 1px solid ${primayColor}; margin-bottom: 20px;`

const Title = styled.h3`
    color: ${secundaryColor};
    padding: 5px;
    margin: 0px;
    text-align: center;
    background-color: ${primayVariantColor};
    `
/** cabecera */
const CabeceraLogo = styled.div`
    display: flex;
    align-items: center;
    padding: 0px 15px;
`
const ImgLogo = styled.img`
    height: 100px; 
    width:300px;
`
const Parr = styled.p`
    font-size:${({fontSize}) => fontSize ? fontSize : '12px'}; 
    font-weight: ${({weight}) => weight ? weight : '600'};
    margin-bottom: 0px; 
    color: #545454;
    ${({marginTop}) => marginTop && css`margin-top: ${marginTop};`}
    ${({textAlign}) => textAlign && css`text-align: ${textAlign};`}
    ${({padding}) => padding && css`padding: ${padding};`}
`
const Div = styled.div`
    text-align: right;
`
const ContainerLogo = styled.div`
    width: 50%; 
    float: left;
`
const Box = styled.div`
    background-color: white;
    text-align: center;
    width: 300px;
    margin-top: 15px;
    padding: 0px 10px;
`