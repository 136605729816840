import { handleActions } from 'redux-actions'
import { GET_ONE_WORK_SUSPENSION, GET_WORKS_ID, GET_WORKS_ONE, POST_REGISTER_WORK, POST_REGISTER_WORK_SUSPENSION, SET_WORK_CLOSE_TECN } from '../types'


export const worksList = handleActions({ 
    [GET_WORKS_ID]: (state, action) => ({...state, ...action.payload}),
    [GET_WORKS_ONE]: (state, action) => ({...state, successOne: action.payload.success, messageOne: action.payload.message, dataOne: action.payload.data }),
    [GET_ONE_WORK_SUSPENSION]: (state, action) => ({...state, successOne: action.payload.success, messageOne: action.payload.message, dataOne: action.payload.data }),
    [POST_REGISTER_WORK]: (state, action) => ({...state, successPost: action.payload.success, message: action.payload.message}),
    [POST_REGISTER_WORK_SUSPENSION]: (state, action) => ({...state, successPost: action.payload.success, message: action.payload.message}),
    [SET_WORK_CLOSE_TECN]: (state, action) => ({...state, successPost: action.payload.success, message: action.payload.message}),
}, {})