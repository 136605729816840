import { createAction } from 'redux-actions'
import { apiGetAllCotizations } from '../api/cotizations'
import { GET_COTIZATIONS_LIST } from '../types'


/**  HISTORIAL DE LA MOTO*/
export const getAllCotizations = createAction(GET_COTIZATIONS_LIST,
    data => apiGetAllCotizations(data)())
// import {  apiGetReviewsServices } from '../api/curriculumVitae'
// import {  npm } from '../types'

// /**  REVICION DE LA MOTO*/
//  export const getAllCotizations = createAction(GET_REVIEWSSERVICES_VITAE, 
//     data => apiGetReviewsServices(data)()
//     )
